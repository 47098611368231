import { useCallback, useState } from "react";

import Paper from "@mui/material/Paper";

import {
  ProjectScenariosTable
} from "features/projects/components/tables/ProjectScenariosTable";
import {
  AddScenariosToProjectDialog
} from "features/projectScenarios/dialogs/AddScenariosToProjectDialog";
import {
  EditProjectScenarioDialog
} from "features/projectScenarios/dialogs/EditProjectScenarioDialog";

import type { CustomScenario } from "features/customScenarios/types";
import type { DefaultScenario } from "features/defaultScenarios/types";
import type {
  ProjectScenario
} from "features/projectScenarios/types";


interface ProjectScenarioProps {
  customScenariosNotInProject: Array<CustomScenario>;
  defaultScenariosNotInProject: Array<DefaultScenario>;
  isLoading: boolean;
  isProjectRunning: boolean;
  onAddCustomScenario: (scenario: CustomScenario) => void;
  onAddDefaultScenario: (scenario: DefaultScenario) => void;
  onRemoveScenarios: (scenario: ProjectScenario) => void;
  projectScenarios: Array<ProjectScenario>;
  projectTitle: string;
}

export function ProjectScenarios(
  {
    projectTitle,
    projectScenarios,
    customScenariosNotInProject,
    defaultScenariosNotInProject,
    onAddCustomScenario,
    onAddDefaultScenario,
    onRemoveScenarios,
    isLoading,
    isProjectRunning
  }: ProjectScenarioProps
) {

  const [
    showAddScenariosModal, setShowAddScenariosModal
  ] = useState<boolean>(false);

  const handleCloseAddScenariosModal = () => {
    setShowAddScenariosModal(false);
  };

  const [
    editingScenario, setEditingScenario
  ] = useState<ProjectScenario | undefined>();

  const [
    showEditScenarioModal, setShowEditScenariosModal
  ] = useState(false);

  const handleCloseEditScenariosModal = () => {
    setShowEditScenariosModal(false);
    setEditingScenario(undefined);
  };

  const handleOnRemoveProjectScenarios = useCallback(
    (scenario: ProjectScenario) => {
      onRemoveScenarios(scenario);
    },
    [
      onRemoveScenarios
    ]
  );

  return (
    <Paper
      elevation={0}
      variant="outlined"
      sx={{
        borderWidth: "0px",
        borderRadius: "20px"
      }}
    >

      <ProjectScenariosTable
        isLoading={isLoading}
        isProjectRunning={isProjectRunning}
        projectScenarios={projectScenarios}
        onRemoveScenario={handleOnRemoveProjectScenarios}
        setEditingScenario={setEditingScenario}
        setShowAddScenariosModal={setShowAddScenariosModal}
        setShowEditScenariosModal={setShowEditScenariosModal}
      />

      {
        customScenariosNotInProject && defaultScenariosNotInProject ? <AddScenariosToProjectDialog
          customScenarios={customScenariosNotInProject}
          defaultScenarios={defaultScenariosNotInProject}
          isLoading={isLoading}
          onAddCustomScenario={onAddCustomScenario}
          onAddDefaultScenario={onAddDefaultScenario}
          onClose={handleCloseAddScenariosModal}
          open={showAddScenariosModal}
          projectTitle={projectTitle}
        /> : null
      }


      {
        editingScenario ? <EditProjectScenarioDialog
          onClose={handleCloseEditScenariosModal}
          open={showEditScenarioModal}
          projectScenarioId={editingScenario.id}
        /> : null
      }

    </Paper>
  );
}

import type {PaginatedResponse} from 'common/api/types';
import type {DwellingApiResponse} from 'features/dwellings/api/types';
import type {Dwelling} from 'features/dwellings/types';


export function paginatedDwellingsApiTransformer(
    response: PaginatedResponse<DwellingApiResponse>
): PaginatedResponse<Dwelling> {
    const transformedResponse: PaginatedResponse<Dwelling> = {
        ...response,
        results: [],
    };

    transformedResponse.results = response.results.map(
        (dwellingApiResponse): Dwelling => ({
            id: dwellingApiResponse.id,
            mprn: dwellingApiResponse.mprn_reference,
            postcode: dwellingApiResponse.postcode?.trim(),
            street: dwellingApiResponse.address_line1?.trim(),
            area: dwellingApiResponse.address_line2?.trim(),
            city: dwellingApiResponse.address_line3?.trim(),
            county: dwellingApiResponse.county?.trim(),
            latitude_longitude: dwellingApiResponse.latitude_longitude,
            performance: dwellingApiResponse.performance,
            system: dwellingApiResponse.system,
            archetype: dwellingApiResponse.archetype,
            type: dwellingApiResponse.type
        })
    );

    return transformedResponse;
}

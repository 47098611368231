import type {
  DefaultMeasureApiResponse
} from "features/defaultMeasures/api/types";
import type { DefaultMeasure } from "features/defaultMeasures/types";


export function defaultMeasuresApiTransformer(
  response: Array<DefaultMeasureApiResponse>
): Array<DefaultMeasure> {

  return response.map(
    (defaultMeasureApiResponse): DefaultMeasure => ({
      id: defaultMeasureApiResponse.id,
      retrokit_identifier: defaultMeasureApiResponse.retrokit_identifier,
      category_code: defaultMeasureApiResponse.category_code,
      retrokit_category: defaultMeasureApiResponse.retrokit_category,
      title: defaultMeasureApiResponse.title,
      description: defaultMeasureApiResponse.description,
      bench_value: defaultMeasureApiResponse.bench_value,
      retro_value: defaultMeasureApiResponse.retro_value,
      energy_credit_apartment: defaultMeasureApiResponse.energy_credit_apartment,
      energy_credit_house: defaultMeasureApiResponse.energy_credit_house,
      variable_multiplier: defaultMeasureApiResponse.variable_multiplier,
      variable_unit: defaultMeasureApiResponse.variable_unit,
      variable_costs: defaultMeasureApiResponse.variable_costs,
      fixed_costs: defaultMeasureApiResponse.fixed_costs,
      fixed_costs_up_to_50: defaultMeasureApiResponse.fixed_costs_up_to_50,
      fixed_costs_up_to_100: defaultMeasureApiResponse.fixed_costs_up_to_100,
      fixed_costs_up_to_150: defaultMeasureApiResponse.fixed_costs_up_to_150,
      fixed_costs_over_150: defaultMeasureApiResponse.fixed_costs_over_150,
      maintenance_costs: defaultMeasureApiResponse.maintenance_costs,
      target_u_value: defaultMeasureApiResponse.target_u_value,
      notes: defaultMeasureApiResponse.notes,
      icon_link: defaultMeasureApiResponse.icon_link,
      incompatible_with: defaultMeasureApiResponse.incompatible_with,
      incompatible_reason: defaultMeasureApiResponse.incompatible_reason,
      type: defaultMeasureApiResponse.type
    })
  );
}
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Close as CloseIcon } from "@mui/icons-material";


interface ConfirmationDialogProps {
  buttonText?: string;
  message: string;
  title: string;
  onClose: () => void;
  onSubmit: () => void | undefined;
  open: boolean;
}

export function ConfirmationDialog(
  {
    buttonText,
    message,
    title,
    onSubmit,
    onClose,
    open
  }: ConfirmationDialogProps) {


  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >

      <DialogTitle>
        {title}
      </DialogTitle>

      <Box
        position="absolute"
        top={0}
        right={0}
      >

        <IconButton
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>

      </Box>

      <DialogContent>

        <Typography>
          {message}
        </Typography>

      </DialogContent>

      <DialogActions>

        {
          buttonText ? null : <Button
            onClick={onClose}
          >
            Cancel
          </Button>
        }

        {
          buttonText ? <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              onClose();
            }}
          >
            {buttonText}
          </Button> : <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              if (onSubmit !== undefined) {
                onSubmit();
              }
              onClose();
            }}
          >
            Confirm
          </Button>
        }

      </DialogActions>
    </Dialog>
  );
}
import React from "react";

import EastIcon from "@mui/icons-material/East";
import Box from "@mui/material/Box";
import { green } from "@mui/material/colors";
import Typography from "@mui/material/Typography";

import {
  BERChevron
} from "common/components/BERChevron";


interface DwellingAndScenarioBerGradesProps {
  dwellingDetail: string;
  dwellingRating: string;
  scenarioDetail: string;
  scenarioRating: string;
}

export function DwellingAndScenarioBerGrades(
  {
    dwellingDetail,
    dwellingRating,
    scenarioDetail,
    scenarioRating
  }: DwellingAndScenarioBerGradesProps) {

  return <Box
    sx={{
      flex: 1,
      display: "flex",
      flexDirection: "row",
      width: "100%"
    }}
  >

    <Box
      sx={{
        marginRight: "8px",
        display: "flex",
        flexDirection: "row"
      }}
    >
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
          fontSize: "1rem"
        }}
        variant="h6"
      >
        Dwelling: {dwellingDetail}
      </Typography>

      <BERChevron
        rating={dwellingRating}
      />

    </Box>

    <EastIcon
      sx={{
        color: green[500],
      }}
    />

    <Box
      sx={{
        marginLeft: "16px",
        marginRight: "16px",
        display: "flex",
        flexDirection: "row"
      }}
    >
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
          fontSize: "1rem"
        }}
        variant="h6"
      >
        Scenario: {scenarioDetail}
      </Typography>

      <BERChevron
        rating={scenarioRating}
      />

    </Box>
    
  </Box>
}
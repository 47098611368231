import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";


interface TechnicalAssessmentFormHeaderProps {
  changeTafStep: (newStep: number) => void;
  closeModal: () => void;
  tafStep: number;
}


export function TechnicalAssessmentFormHeader(
  {
    changeTafStep,
    closeModal,
    tafStep
  }: TechnicalAssessmentFormHeaderProps) {

  const handleSliderChange = (
    _event: Event, 
    newValue: number | number[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _activeThumb: number
  ) => {
    changeTafStep(newValue as number);
  };

  const marks = [
    {
      "value": 1,
      "label": "Admin"
    },
    {
      "value": 2,
      "label": "Sign"
    },
    {
      "value": 3,
      "label": "Walls"
    },
    {
      "value": 4,
      "label": "Roofs"
    },
    {
      "value": 5,
      "label": "Floors"
    },
    {
      "value": 6,
      "label": "Windows"
    },
    {
      "value": 7,
      "label": "Doors"
    },
    {
      "value": 8,
      "label": "Openings"
    },
    {
      "value": 9,
      "label": "Sealing"
    },
    {
      "value": 10,
      "label": "MV"
    }
  ];


  return (
    <Box 
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        margin: 0,
        padding: "16px"
      }}
    >

      <Typography
        sx={{
          marginRight: "1rem",
          display: "flex",
          alignItems: "center"
        }}
        variant="h6"
      >
        Technical Assessment
      </Typography>

      <Slider
        defaultValue={tafStep}
        step={1}
        min={1}
        max={10}
        valueLabelDisplay="off"
        marks={marks}
        sx={{
          borderRadius: 2,
          flex: 1,
          marginRight: "16px",
          marginLeft: "32px",
          color: "#e91e63"
        }}
        onChange={handleSliderChange}
      />

      <Box
        sx={{
          marginLeft: "1rem",
          paddingTop: "4px",
          paddingRight: "4px"
        }}
      >

        <IconButton
          onClick={closeModal}
        >

          <CloseIcon />

        </IconButton>

      </Box>

    </Box>
  );
}
import React from "react";

import { Animation, EventTracker } from "@devexpress/dx-react-chart";
import {
  ArgumentAxis,
  BarSeries,
  Chart,
  Title,
  Tooltip,
  ValueAxis
} from "@devexpress/dx-react-chart-material-ui";
import convert from "convert-units";
import { easeExpOut } from "d3-ease";
import { format } from "d3-format";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import {
  BER_COLOUR_CODE_MAP
} from "common/assets/rk_type_mappings/ber";
import {
  withMaterialUserInterfaceTheme
} from "common/hocs/theme";
import Box from "@mui/material/Box";

class DashboardGraph extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.props.graphData !== nextProps.graphData;
  }

  render() {
    const {
      cardTitle,
      cardSubTitle,
      graphArgumentField,
      graphAxisLabelHalfWidth,
      graphAxisLabelMinimumGapWidth,
      graphChartHeight,
      graphColour,
      graphData,
      graphKey,
      graphRotated,
      graphTitle,
      graphValueField,
      theme
    } = this.props;

    let lastAxisLabelCoordinate;

    return (
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        key={graphKey}
      >
        <Paper
          square
        >
          <Box
            sx={{
              alignItems: "center",
              background: theme.palette.primary.main,
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "16px",
              paddingRight: "16px",
              paddingTop: "8px"
            }}
          >
            <Typography
              sx={{
                color: "#ffffff"
              }}
              gutterBottom
              variant="subtitle1"
              color="textPrimary"
            >
              {cardTitle}
            </Typography>

            <Typography
              sx={{
                color: "#ffffff"
              }}
              variant="subtitle2"
              color="textPrimary"
              gutterBottom
            >
              {cardSubTitle}
            </Typography>
          </Box>

          <Chart
            data={graphData}
            height={graphChartHeight}
            rotated={graphRotated}
            style={{
              paddingLeft: 24,
              paddingRight: 24,
              paddingBottom: 24,
              paddingTop: 16,
              width: "100%"
            }}
          >
            <ArgumentAxis />

            <ValueAxis
              showGrid={false}
              showLine
              showTicks
              tickFormat={
                graphKey === "CO2"
                  ? (obj) => obj.tickFormat(null, format("~r"))
                  : undefined
              }
              labelComponent={(props) => {
                const { text } = props;
                if (graphKey === "BER") {
                  const { y } = props;
                  if (lastAxisLabelCoordinate != null) {
                    if (lastAxisLabelCoordinate === 0) {
                      if (
                        lastAxisLabelCoordinate > y &&
                        y <= graphAxisLabelMinimumGapWidth &&
                        y <= graphAxisLabelHalfWidth
                      ) {
                        return null;
                      }
                    } else if (
                      lastAxisLabelCoordinate > y &&
                      lastAxisLabelCoordinate - y <= graphAxisLabelHalfWidth
                    ) {
                      return null;
                    }
                  }
                  lastAxisLabelCoordinate = y;
                } else {
                  const { x } = props;
                  if (lastAxisLabelCoordinate != null) {
                    if (lastAxisLabelCoordinate === 0) {
                      if (
                        lastAxisLabelCoordinate < x &&
                        x <= graphAxisLabelMinimumGapWidth &&
                        x <= graphAxisLabelHalfWidth
                      ) {
                        return null;
                      }
                    } else if (
                      lastAxisLabelCoordinate < x &&
                      x - lastAxisLabelCoordinate <= graphAxisLabelHalfWidth
                    ) {
                      return null;
                    }
                  }
                  lastAxisLabelCoordinate = x;
                }

                if (graphKey === "CO2") {
                  return (
                    <ArgumentAxis.Label
                      {...props}
                      text={parseInt(text.replace(",", "")) / 1000}
                    />
                  );
                }
                if (graphKey === "EnergyUsage") {
                  return (
                    <ArgumentAxis.Label
                      {...props}
                      text={parseInt(text.replace(",", "")) / 1000}
                    />
                  );
                }
                if (graphKey === "BER") {
                  return (
                    <ArgumentAxis.Label
                      {...props}
                      text={Math.round(text.replace(/,/g, ""))}
                    />
                  );
                }
                if (graphKey === "All") {
                  return (
                    <ArgumentAxis.Label
                      {...props}
                      text={Math.round(text.replace(/,/g, ""))}
                    />
                  );
                }
                return <ArgumentAxis.Label {...props} />;
              }}
            />

            {graphKey === "BER" ? (
              <BarSeries
                valueField={graphValueField}
                argumentField={graphArgumentField}
                // TODO: This BarSeries point component possibly causes a memory leak, investigate
                pointComponent={({ ...props }) => (
                  <BarSeries.Point
                    {...props}
                    color={[BER_COLOUR_CODE_MAP[props.argument]]}
                  />
                )}
              />
            ) : (
              <BarSeries
                valueField={graphValueField}
                argumentField={graphArgumentField}
                color={[graphColour]}
              />
            )}

            <Title
              text={graphTitle}
              textComponent={({ ...props }) => (
                <Title.Text
                  {...props}
                  className="MuiTypography-subtitle2"
                  style={{
                    marginBottom: 8,
                    fontSize: "16px"
                  }}
                />
              )}
            />
            <EventTracker />
            <Tooltip
              placement="top"
              arrowComponent={React.forwardRef(
                ({ placement, classes, ...props }, ref) => (
                  <Tooltip.Arrow
                    {...props}
                    classes={classes}
                    placement={placement}
                    ref={ref}
                    style={{
                      arrowRight: {
                        "&::after": {
                          background: graphColour
                        }
                      },
                      arrowTop: {
                        "&::after": {
                          background: graphColour
                        }
                      }
                    }}
                  />
                )
              )}
              contentComponent={({ text, targetItem, ...props }) => {
                let tooltipText = "";
                if (graphKey === "EnergyCost") {
                  tooltipText = `€${parseFloat(text)
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
                } else if (graphKey === "CO2") {
                  const temp = convert(parseFloat(text)).from("kg").toBest();
                  tooltipText = `${temp.val.toFixed(2)} ${
                    temp.unit === "mt" ? "t" : temp.unit
                  }`;
                } else if (graphKey === "EnergyUsage") {
                  const temp = convert(parseFloat(text)).from("kWh").toBest();
                  tooltipText = `${temp.val.toFixed(2)} ${temp.unit}`;
                } else if (graphKey === "Renewable") {
                  tooltipText = `${parseFloat(text).toFixed(2)} %`;
                } else {
                  tooltipText = text;
                }
                return (
                  <Tooltip.Content
                    {...props}
                    text={tooltipText}
                    targetItem={targetItem}
                  />
                );
              }}
              sheetComponent={({ children, ...props }) => (
                <Tooltip.Sheet
                  {...props}
                  children={children}
                  style={{
                    borderStyle: "solid",
                    borderColor: graphColour,
                    borderWidth: "2px 2px 2px"
                  }}
                />
              )}
            />
            <Animation
              duration={2000}
              easing={easeExpOut}
            />
          </Chart>
        </Paper>
      </Grid>
    );
  }
}

DashboardGraph.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  cardSubTitle: PropTypes.string.isRequired,
  graphArgumentField: PropTypes.string.isRequired,
  graphAxisLabelHalfWidth: PropTypes.number.isRequired,
  graphAxisLabelMinimumGapWidth: PropTypes.number.isRequired,
  graphChartHeight: PropTypes.number.isRequired,
  graphColour: PropTypes.string.isRequired,
  graphData: PropTypes.array.isRequired,
  graphKey: PropTypes.string.isRequired,
  graphRotated: PropTypes.bool.isRequired,
  graphTitle: PropTypes.string.isRequired,
  graphValueField: PropTypes.string.isRequired,

  theme: PropTypes.object.isRequired
};

export default withMaterialUserInterfaceTheme(DashboardGraph);

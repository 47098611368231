import { RetroKitRoutes } from "common/constants/retroKitRoutes";

export const GET_PROJECTS = "@@projects/GET_PROJECTS";
export const ADD_PROJECT = "@@projects/ADD_PROJECT";
export const UPDATE_PROJECT = "@@projects/UPDATE_PROJECT";
export const DELETE_PROJECT = "@@projects/DELETE_PROJECT";


export const submitProject = (data, history) => (dispatch, getState) => {
  const headers = {
    "Content-Type": "application/json"
  };

  const { token } = getState().authentication;

  if (token) {
    headers.Authorization = `Token ${token}`;
  }

  let dwellingIds = []

  if (data.dwellings) {
    dwellingIds = data.dwellings.map((dwelling) => `${dwelling.id}`);
  }

  return fetch(
    `${process.env.REACT_APP_API_BASE_URL || ""}/api/v1/projects/`,
    {
      headers,
      method: "POST",
      body: JSON.stringify({
        title: data.title,
        description: data.description,
        dwellings: dwellingIds
      })
    }
  ).then((response) => {
    if (response.status === 201) {
      history.push({
        pathname: RetroKitRoutes.PROJECTS,
        state: {
          newProject: res.json()
        }
      })
    }
  });
};

import { retrokitApi } from "common/api/retrokitApi";
import {
  customMeasuresApiTransformer
} from "features/customMeasures/api/transformers";
import {
  customScenariosApiTransformer 
} from "features/customScenarios/api/transformers";
import {
  defaultMeasuresApiTransformer
} from "features/defaultMeasures/api/transformers";
import { 
  defaultScenariosApiTransformer 
} from "features/defaultScenarios/api/transformers";
import {
  projectScenarioApiTransformer,
  projectScenariosApiTransformer
} from "features/projectScenarios/api/transformers";

import type { CustomMeasure } from "features/customMeasures/types";
import type { CustomScenario } from "features/customScenarios/types";
import type { DefaultMeasure } from "features/defaultMeasures/types";
import type { DefaultScenario } from "features/defaultScenarios/types";
import type {
  UpdateProjectCustomScenariosRequest,
  UpdateProjectDefaultScenariosRequest,
  UpdateProjectScenariosRequest
} from "features/projects/api/types";
import type {
  GetCustomScenariosNotInProjectParameters,
  GetDefaultMeasuresNotInProjectScenarioByIDParameters,
  GetDefaultScenariosNotInProjectParameters,
  GetProjectScenariosParameters,
  UpdateProjectScenarioCustomMeasuresRequest,
  UpdateProjectScenarioDefaultMeasuresRequest,
  UpdateProjectScenarioDetailsRequest,
  GetCustomMeasuresNotInProjectScenarioByIDParameters, 
  GetProjectScenarioByIDParameters
} from "features/projectScenarios/api/types";
import type { ProjectScenario } from "features/projectScenarios/types";



export const projectScenariosApi = retrokitApi.injectEndpoints({
  endpoints: (builder) => ({
    
    getProjectScenarios: builder.query<Array<ProjectScenario>,
      GetProjectScenariosParameters>({
      query: (arg) => ({
        url: `projects/${arg.id}/scenarios/`,
        method: "GET",
      }),
      providesTags: ["ProjectScenarios"],
      transformResponse: projectScenariosApiTransformer
    }),

    getCustomScenariosNotInProject: builder.query<Array<CustomScenario>,
      GetCustomScenariosNotInProjectParameters>({
      query: (arg) => ({
        url: `projects/${arg.id}/custom_scenarios/`,
        method: "GET",
        params: {
          inProject: arg.inProject
        }
      }),
      providesTags: ["CustomScenariosNotInProject"],
      transformResponse: customScenariosApiTransformer
    }),
    
    getDefaultScenariosNotInProject: builder.query<Array<DefaultScenario>,
      GetDefaultScenariosNotInProjectParameters>({
      query: (arg) => ({
        url: `projects/${arg.id}/default_scenarios/`,
        method: "GET",
        params: {
          inProject: arg.inProject
        }
      }),
      providesTags: ["DefaultScenariosNotInProject"],
      transformResponse: defaultScenariosApiTransformer
    }),

    updateProjectCustomScenarios: builder.mutation<void, UpdateProjectCustomScenariosRequest>({
      query: (arg) =>  ({
        url: `projects/${arg.id}/custom_scenarios/`,
        method: "POST",
        params: {
          customScenarios: arg.customScenarios
        }
      }),
      invalidatesTags: [
        "ProjectScenarios",
        "CustomScenariosNotInProject"
      ]
    }),

    updateProjectDefaultScenarios: builder.mutation<void, UpdateProjectDefaultScenariosRequest>({
      query: (arg) =>  ({
        url: `projects/${arg.id}/default_scenarios/`,
        method: "POST",
        params: {
          defaultScenarios: arg.defaultScenarios
        }
      }),
      invalidatesTags: [
        "ProjectScenarios",
        "DefaultScenariosNotInProject"
      ]
    }),

    getProjectScenarioById: builder.query<ProjectScenario, GetProjectScenarioByIDParameters>({
      query: ({ id }) => ({
        url: `scenarios/${id}/`,
        method: "GET"
      }),
      providesTags: ["ProjectScenario"],
      transformResponse: projectScenarioApiTransformer
    }),
    
    updateProjectScenarios: builder.mutation<void, UpdateProjectScenariosRequest>({
      query: (arg) => ({
        url: `projects/${arg.id}/scenarios/`,
        method: "PUT",
        body: arg
      }),
      invalidatesTags: [
        "ProjectScenarios",
        "CustomScenariosNotInProject",
        "DefaultScenariosNotInProject"
      ]
    }),

    updateProjectScenarioDetails: builder.mutation<void, UpdateProjectScenarioDetailsRequest>({
      query: (arg) => ({
        url: `scenarios/${arg.id}/`,
        method: "PUT",
        params: {
          title: arg.title,
          description: arg.description,
          oss_eligible: arg.oss_eligible,
        }
      }),
      invalidatesTags: [
        "ProjectScenario",
        "ProjectScenarios",
      ]
    }),

    getCustomMeasuresNotInProjectScenario: builder.query<Array<CustomMeasure>, GetCustomMeasuresNotInProjectScenarioByIDParameters>({
      query: (arg) => ({
        url: `scenarios/${arg.id}/custom_measures/`,
        method: "GET",
        params: {
          inScenario: arg.inScenario
        }
      }),
      providesTags: ["CustomMeasuresNotInProjectScenario"],
      transformResponse: customMeasuresApiTransformer
    }),

    getDefaultMeasuresNotInProjectScenario: builder.query<Array<DefaultMeasure>, GetDefaultMeasuresNotInProjectScenarioByIDParameters>({
      query: (arg) => ({
        url: `scenarios/${arg.id}/measures/`,
        method: "GET",
        params: {
          inScenario: arg.inScenario
        }
      }),
      providesTags: ["DefaultMeasuresNotInProjectScenario"],
      transformResponse: defaultMeasuresApiTransformer
    }),


    updateProjectScenarioCustomMeasures: builder.mutation<void, UpdateProjectScenarioCustomMeasuresRequest>({
      query: (arg) => ({
        url: `scenarios/${arg.id}/custom_measures/`,
        method: "PUT",
        body: arg
      }),
      invalidatesTags: [
        "ProjectScenario",
        "ProjectScenarios",
        "CustomMeasuresNotInProjectScenario"
      ]
    }),

    updateProjectScenarioDefaultMeasures: builder.mutation<void, UpdateProjectScenarioDefaultMeasuresRequest>({
      query: (arg) => ({
        url: `scenarios/${arg.id}/measures/`,
        method: "PUT",
        body: arg
      }),
      invalidatesTags: [
        "ProjectScenario",
        "ProjectScenarios",
        "DefaultMeasuresNotInProjectScenario",
        "CustomScenariosNotInProject"
      ]
    }),

  })
});

export const {
  useGetProjectScenariosQuery,
  useGetCustomScenariosNotInProjectQuery,
  useGetDefaultScenariosNotInProjectQuery,
  useUpdateProjectCustomScenariosMutation,
  useUpdateProjectDefaultScenariosMutation,
  useUpdateProjectScenariosMutation,
  useGetCustomMeasuresNotInProjectScenarioQuery,
  useGetDefaultMeasuresNotInProjectScenarioQuery,
  useGetProjectScenarioByIdQuery,
  useUpdateProjectScenarioDetailsMutation,
  useUpdateProjectScenarioCustomMeasuresMutation,
  useUpdateProjectScenarioDefaultMeasuresMutation
} = projectScenariosApi;
import React, { forwardRef } from 'react';

import MaterialTable, { MTableToolbar } from '@material-table/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import Box from "@mui/material/Box";
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import {
  UPLOADS_TABLE_COLUMNS
} from 'common/assets/definitions/checkbox_groups';
import { withMaterialUserInterfaceTheme } from "common/hocs/theme";
import {
  FetchDwellingBerDataView
} from "features/uploads/components/FetchDwellingBerDataView";
import { fetchUploads, fetchBerFileFromMPRN } from 'v1/actions/uploads';
import DropZone from 'v1/components/uploads/dropzone/DropZone';


const tableIcons = {
  // eslint-disable-next-line react/display-name
  DetailPanel: forwardRef((props, ref) =>
    <ChevronRight {...props} ref={ref} />
  ),
  // eslint-disable-next-line react/display-name
  Filter: forwardRef((props, ref) =>
    <FilterList {...props} ref={ref} />
  ),
  // eslint-disable-next-line react/display-name
  FirstPage: forwardRef((props, ref) =>
    <FirstPage {...props} ref={ref} />
  ),
  // eslint-disable-next-line react/display-name
  LastPage: forwardRef((props, ref) =>
    <LastPage {...props} ref={ref} />
  ),
  // eslint-disable-next-line react/display-name
  NextPage: forwardRef((props, ref) =>
    <ChevronRight {...props} ref={ref} />
  ),
  // eslint-disable-next-line react/display-name
  PreviousPage: forwardRef((props, ref) =>
    <ChevronLeft {...props} ref={ref} />
  ),
  // eslint-disable-next-line react/display-name
  Search: forwardRef((props, ref) => 
    <Search {...props} ref={ref} />
  ),
  // eslint-disable-next-line react/display-name
  ViewColumn: forwardRef((props, ref) => 
    <ViewColumn {...props} ref={ref} />
  ),
};

class Uploads extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        ...UPLOADS_TABLE_COLUMNS
      ],
    };

    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);

    this.mprnTextRef = React.createRef();
    this.consentFileInputRef = React.createRef();

    this.state = {
      isFetchMPRNDisabled: true,
    };
  }

  openFileDialog() {
    this.fileInputRef.current.click();
  }

  componentDidMount() {
    this.props.fetchUploads();

    this.props.setDrawerSelectedIndex(1);
    this.props.setToolbarTitle('Uploads');
  }

  validateFetchBerButtonStatus() {
    const mprnRegex = /^\d{11}$/m;

    this.setState({
      isFetchMPRNDisabled: !(
        this.mprnTextRef.current.value &&
        this.consentFileInputRef.current.value &&
        mprnRegex.test(this.mprnTextRef.current.value)
      ),
    });
  }

  render() {
    const { theme, filesAdded } = this.props;

    const pageSizes = [];
    const maximumLength = this.props.uploadList.length;
    if (maximumLength > 50 && maximumLength < 450) {
      pageSizes.push(50);
      pageSizes.push(maximumLength);
    } else if (maximumLength > 450) {
      pageSizes.push(100);
      pageSizes.push(maximumLength);
    } else {
      pageSizes.push(maximumLength);
    }

    let appBarHeight = 62;
    if (this.props.breakpoint === 'sm') {
      appBarHeight = 64;
    }

    let uploadsTable;

    const bodyHeight = Math.round(
      document.getElementById('root').offsetHeight - appBarHeight
    );

    if (this.props.uploadList.length > 0) {
      uploadsTable = <MaterialTable
        columns={UPLOADS_TABLE_COLUMNS}
        components={{
          Toolbar: (props) => (
            <Box>
              <MTableToolbar {...props} />
            </Box>
          ),
        }}
        data={this.props.uploadList}
        icons={tableIcons}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} of {count}',
          },
          body: {
            emptyDataSourceMessage: 'No records to display',
            filterRow: {
              filterTooltip: 'Filter',
            },
          },
        }}
        options={{
          columnsButton: true,
          draggable: true,
          headerStyle: {
            backgroundColor: theme.palette.primary.light,
            color: '#ffffff',
            fontWeight: 'bold',
            position: 'sticky',
            top: 0,
            whiteSpace: 'nowrap',
            zIndex: 2,
          },
          // initialPage: initialPage,
          minBodyHeight: 350,
          maxBodyHeight: 500,
          paging: true,
          pageSize: pageSizes[0],
          pageSizeOptions: pageSizes,
          rowStyle: (rowData, index) => {
            if (index % 2) {
              return {
                backgroundColor: '#EEEEEE',
                whiteSpace: 'nowrap',
              };
            }
            return {
              whiteSpace: 'nowrap',
            };
          },
          searchFieldAlignment: 'right',
          // searchText: searchText,
          selection: false,
          showFirstLastPageButtons: true,
          showSelectAllCheckbox: true,
          // showTextRowsSelected: true,
          showTitle: true,
        }}
        selection={false}
        sorting
        title={`${this.props.uploadList.length} uploads`}
      />;
    } else {
      uploadsTable = <Typography
        variant="body1"
        color="inherit"
        noWrap
      >
        Upload some files and they&#39;ll be listed here.
      </Typography>;
    }

    return <Box
      sx={{
        width: '100%',
        maxWidth: "100%",
        padding: "24px",
        height: bodyHeight
      }}
    >
      <Paper
        square={false}
        sx={{
          padding: "16px",
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >

        <DropZone
          fileInputRef={this.fileInputRef}
          filesAdded={filesAdded}
          openFileDialog={this.openFileDialog}
        />

      </Paper>

      {
        this.props.user.can_view_seai_api === true ? <Typography
          variant="h5"
          align="center"
          sx={{
            margin: '1rem 0px'
          }}
        >
          OR
        </Typography> : null
      }

      {
        this.props.user.can_view_seai_api === true ?
          <FetchDwellingBerDataView/> : null
      }

      <Paper
        square={false}
        sx={{
          flex: 1,
          marginTop: "24px",
          marginBottom: "48px",
          width: '100%',
          maxWidth: "100%",
        }}
      >

        {uploadsTable}

      </Paper>

      <Toolbar
        sx={{
          ...theme.mixins.toolbar,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          minHeight: 56,
          [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
            minHeight: 48,
          },
          [theme.breakpoints.up('sm')]: {
            minHeight: 64,
          },
          padding: theme.spacing(0, 1),
        }}
      />{' '}
      {/* This is just a spacer item */}

    </Box>
  }
}

const mapStateToProps = (state) => ({
  uploadList: state.uploads.uploadList,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  fetchUploads: () => dispatch(fetchUploads()),
  fetchBerFromMPRN: (mprn, consent_file) =>
    dispatch(fetchBerFileFromMPRN(mprn, consent_file)),
});

Uploads.propTypes = {
  navDrawerOpen: PropTypes.bool.isRequired,
  setDrawerSelectedIndex: PropTypes.func.isRequired,
  setToolbarTitle: PropTypes.func.isRequired,

  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withMaterialUserInterfaceTheme(
    withRouter(Uploads)
  )
);

import { retrokitApi } from "common/api/retrokitApi";

import type {
  GenerateHEARequest,
  GenerateHEAResponse,
  GenerateHUP1Request,
  GenerateHUP1Response,
  GenerateHUP2Request,
  GenerateHUP2Response,
  MinimalReportApiResponse
} from "features/reports/api/types";
import type { MinimalReport, ReportPDFLink } from "features/reports/types";


export const reportsApi = retrokitApi.injectEndpoints({
  endpoints: (builder) => ({
    generateHEAReport: builder.mutation<ReportPDFLink, GenerateHEARequest>({
      query: (arg) => ({
        url: `integrations/reports/hea/generate`,
        method: 'POST',
        body: arg,
      }),
      transformResponse: (response: GenerateHEAResponse): ReportPDFLink => ({
        url: response.signedPDFLink,
      }),
    }),

    generateHUP1Report: builder.mutation<ReportPDFLink, GenerateHUP1Request>({
      query: (arg) => ({
        url: `reports/generate_housing_upgrade_plan_1`,
        method: 'POST',
        body: arg,
      }),
      invalidatesTags: ['HUP1', 'ProjectScenarios'],
      transformResponse: (response: GenerateHUP1Response): ReportPDFLink => ({
        url: response.signedPDFLink,
      }),
    }),

    generateHUP2Report: builder.mutation<ReportPDFLink, GenerateHUP2Request>({
      query: (arg) => ({
        url: `reports/generate_housing_upgrade_plan_2`,
        method: 'POST',
        body: arg,
      }),
      invalidatesTags: ['HUP2', 'ProjectScenarios'],
      transformResponse: (response: GenerateHUP2Response): ReportPDFLink => ({
        url: response.signedPDFLink,
      }),
    }),

    getListOfHEA1Reports: builder.query<Array<MinimalReport>, void>({
      query: () => ({
        url: `reports/hea1/all/`,
        method: 'GET'
      }),
      transformResponse: (response: MinimalReportApiResponse): Array<MinimalReport> => response.reports.map(
        (report) => ({
          ...report,
        })
      ),
    }),

    getListOfHEA2Reports: builder.query<Array<MinimalReport>, void>({
      query: () => ({
        url: `reports/hea2/all/`,
        method: 'GET'
      }),
      transformResponse: (response: MinimalReportApiResponse): Array<MinimalReport> => response.reports.map(
        (report) => ({
          ...report,
        })
      ),
    }),

  }),
});

export const {
  useGenerateHEAReportMutation,
  useGenerateHUP1ReportMutation,
  useGenerateHUP2ReportMutation,
  useGetListOfHEA1ReportsQuery,
  useGetListOfHEA2ReportsQuery
} = reportsApi;

import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type {
  BreadCrumb,
  GlobalLayoutSliceState,
} from 'common/types';

const initialState: GlobalLayoutSliceState = {
  breadcrumbs: [],
};

const globalLayoutSlice = createSlice({
  name: 'globalLayout',
  initialState,
  reducers: {
    setGlobalBreadCrumbs: (state, action: PayloadAction<Array<BreadCrumb>>) => {
      state.breadcrumbs = action.payload;
    },
  },
});

export const { setGlobalBreadCrumbs } = globalLayoutSlice.actions;

export default globalLayoutSlice.reducer;

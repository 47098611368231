import {
  useFetchDwellingBerDataMutation
} from 'features/uploads/api/uploadsApi';

export function useFetchDwellingBerDataView() {
  const [
    fetchBerFile,
    {
      isLoading,
      isSuccess,
      isUninitialized
    }
  ] = useFetchDwellingBerDataMutation();

  return {
    isLoading,
    isFetchBerSuccess: isUninitialized || isLoading ? undefined : isSuccess,
    fetchBerFile
  };
}

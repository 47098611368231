import {BER_COLOUR_CODE_MAP} from "../../../../common/assets/rk_type_mappings/ber";
import "./BERLegendControl.scss"


class BERLegendControl {

    constructor(layerOpacity) {
        this.layerOpacity = layerOpacity
    }

    onAdd(map) {
        this.map = map;

        this.controlContainer = window.document.createElement('div');
        this.controlContainer.classList.add("mapboxgl-ctrl");
        this.controlContainer.classList.add("mapboxgl-ctrl-group");

        const heading = window.document.createElement('h4')
        heading.textContent = "BER Legend";
        heading.style.marginTop = 0;
        heading.style.marginBottom = 0;
        heading.style.paddingLeft = "8px"
        heading.style.paddingRight = "8px"
        this.controlContainer.style.pointerEvents = "auto";
        this.controlContainer.appendChild(heading);

        const tableBody = window.document.createElement('tbody')

        tableBody.appendChild(this.createTableRow('A'));
        tableBody.appendChild(this.createTableRow('B'));
        tableBody.appendChild(this.createTableRow('C'));
        tableBody.appendChild(this.createTableRow('D'));
        tableBody.appendChild(this.createTableRow('E'));
        tableBody.appendChild(this.createTableRow('F'));
        tableBody.appendChild(this.createTableRow('G'));

        const table = window.document.createElement('table')
        table.className = "legend-table";
        table.style.paddingLeft = "4px"
        table.style.paddingRight = "4px"
        table.appendChild(tableBody);

        this.controlContainer.appendChild(table);

        const opacitySliderTitle = window.document.createElement('span');
        opacitySliderTitle.className = "label";
        opacitySliderTitle.textContent = "Opacity";

        const opacitySliderWrapper = window.document.createElement('div');
        opacitySliderWrapper.className = "opacity-slider-wrapper";
        opacitySliderWrapper.style.width = "100%";

        const opacitySliderInput = window.document.createElement('input');
        opacitySliderInput.id = "uploads-opacity-slider";
        opacitySliderInput.type = "range";
        opacitySliderInput.min = "1";
        opacitySliderInput.max = "100";
        opacitySliderInput.defaultValue = this.layerOpacity * 100;
        opacitySliderInput.style.width = "72px";

        opacitySliderWrapper.appendChild(opacitySliderInput);

        const opacitySliderContainer = window.document.createElement('div');
        opacitySliderContainer.className = "legend-item";
        opacitySliderContainer.style.height = "48px";
        opacitySliderContainer.style.display = "flex";
        opacitySliderContainer.style.flexDirection = "column";
        opacitySliderContainer.style.justifyContent = "center";
        opacitySliderContainer.style.alignItems = "center";

        opacitySliderContainer.appendChild(opacitySliderTitle);
        opacitySliderContainer.appendChild(opacitySliderWrapper);

        this.controlContainer.appendChild(opacitySliderContainer);

        return this.controlContainer;
    }

    onRemove() {
        this.controlContainer.parentNode.removeChild(this.controlContainer);
        this.map = undefined;
    }

    createTableRow(grade) {

        const entireRow = window.document.createElement('div');
        entireRow.style.position = "relative";
        entireRow.style.textAlign = "center";



        let chevronAngle = 160;

        const chevronTop = window.document.createElement('div');
        chevronTop.style.position = "absolute";
        chevronTop.style.top = 0;
        chevronTop.style.left = 0;
        chevronTop.style.background = BER_COLOUR_CODE_MAP[`${grade}`];
        let topAngle = `skew(-${chevronAngle}deg, ${0}deg)`;
        chevronTop.style.webkitTransform = topAngle;
        chevronTop.style.msTransform = topAngle;
        chevronTop.style.transform = topAngle;
        chevronTop.style.width = "100%";
        chevronTop.style.height = "50%";
        entireRow.appendChild(chevronTop);

        const chevronBottom = window.document.createElement('div');
        chevronBottom.style.position = "absolute";
        chevronBottom.style.top = "50%";
        chevronBottom.style.bottom = 0;
        chevronBottom.style.left = 0;
        chevronBottom.style.background = BER_COLOUR_CODE_MAP[`${grade}`];
        let bottomAngle = `skew(${chevronAngle}deg, ${0}deg)`;
        chevronBottom.style.webkitTransform = bottomAngle;
        chevronBottom.style.msTransform = bottomAngle;
        chevronBottom.style.transform = bottomAngle;
        chevronBottom.style.width = "100%";
        chevronBottom.style.height = "50%";
        entireRow.appendChild(chevronBottom);

        const span = window.document.createElement('span')
        span.textContent = `${grade}`
        span.style.display = "inline-block";
        span.style.width = "100%";
        span.style.fontWeight = "bold";
        span.style.color = "white";
        span.style.position = "relative";

        entireRow.appendChild(span);

        const tableData = window.document.createElement('td')
        tableData.appendChild(entireRow);

        const tableRow = window.document.createElement('tr')
        tableRow.appendChild(tableData);

        return tableRow;
    }
}

export default BERLegendControl;
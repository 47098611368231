import {AUTHENTICATION_ERROR} from '../actions/authentication'
import {
    ELECTORAL_DIVISIONS_TASK_ID_LOADED,
    ELECTORAL_DIVISIONS_TASK_SUCCESS,
    ELECTORAL_DIVISIONS_TASK_DOWNLOADING_DATA,
    ELECTORAL_DIVISIONS_TASK_EXECUTING,
    ELECTORAL_DIVISIONS_TASK_FAILED,
    ELECTORAL_DIVISIONS_TASK_QUEUED,
    GEOSTOCK_TASK_ID_LOADED,
    GEOSTOCK_TASK_SUCCESS,
    GEOSTOCK_TASK_DOWNLOADING_DATA,
    GEOSTOCK_TASK_EXECUTING,
    GEOSTOCK_TASK_FAILED,
    GEOSTOCK_TASK_QUEUED
} from '../actions/dashboard'

const initialState = {
    geostockTaskId: null,
    geostockTaskStatus: null,
    geostock: {
        "type": "FeatureCollection",
        "features": []
    },
    electoralDivisionsTaskId: null,
    electoralDivisionsTaskStatus: null,
    electoralDivisions: null,
    // {
    //   "type": "FeatureCollection",
    //   "features": [
    //
    //   ]
    // }
    errors: {},
};

export default function dashboardReducer(state = initialState, action) {

    switch (action.type) {
        case ELECTORAL_DIVISIONS_TASK_ID_LOADED:
            return {
                ...state,
                electoralDivisionsTaskId: action.electoralDivisionsTaskId,
                electoralDivisionsTaskStatus: action.electoralDivisionsTaskStatus
            };
        case ELECTORAL_DIVISIONS_TASK_EXECUTING:
        case ELECTORAL_DIVISIONS_TASK_FAILED:
        case ELECTORAL_DIVISIONS_TASK_QUEUED:
        case ELECTORAL_DIVISIONS_TASK_DOWNLOADING_DATA:
            return {
                ...state,
                electoralDivisionsTaskId: action.electoralDivisionsTaskId,
                electoralDivisionsTaskStatus: action.electoralDivisionsTaskStatus
            };
        case ELECTORAL_DIVISIONS_TASK_SUCCESS:
            return {
                ...state,
                electoralDivisionsTaskId: action.electoralDivisionsTaskId,
                electoralDivisionsTaskStatus: action.electoralDivisionsTaskStatus,
                electoralDivisions: JSON.parse(action.electoralDivisionsTaskResult)
            };
        case GEOSTOCK_TASK_ID_LOADED:
            return {
                ...state,
                geostockTaskId: action.geostockTaskId,
                geostockTaskStatus: action.geostockTaskStatus
            };
        case GEOSTOCK_TASK_EXECUTING:
        case GEOSTOCK_TASK_FAILED:
        case GEOSTOCK_TASK_QUEUED:
        case GEOSTOCK_TASK_DOWNLOADING_DATA:
            return {
                ...state,
                geostockTaskId: action.geostockTaskId,
                geostockTaskStatus: action.geostockTaskStatus
            };
        case GEOSTOCK_TASK_SUCCESS:
            return {
                ...state,
                geostockTaskId: action.geostockTaskId,
                geostockTaskStatus: action.geostockTaskStatus,
                geostock: JSON.parse(action.geostockTaskResult)
            };
        case AUTHENTICATION_ERROR:
            return {
                ...state,
                errors: action.data,
                geostock: null,
                geostockTaskId: null,
                geostockTaskStatus: null,
                electoralDivisions: null,
                electoralDivisionsTaskId: null,
                electoralDivisionsTaskStatus: null,
            };
        default:
            return state
    }
};

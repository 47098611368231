import {
    LOGIN_SUCCESSFUL,
    LOGOUT_SUCCESSFUL,
    LOGIN_FAILED,
    REGISTRATION_SUCCESSFUL,
    REGISTRATION_FAILED,
    AUTHENTICATION_ERROR
} from '../actions/authentication'

const initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: null,
    errors: null,
};

export default function authReducer(state=initialState, action) {
    switch (action.type) {

        case LOGIN_SUCCESSFUL:
        case REGISTRATION_SUCCESSFUL:
            localStorage.setItem("token", action.data.token);
            return {...state, ...action.data, isAuthenticated: true, errors: null};

        case AUTHENTICATION_ERROR:
        case LOGIN_FAILED:
        case REGISTRATION_FAILED:
            localStorage.removeItem("token");
            return {...state, errors: action.data, token: null, isAuthenticated: false};

        case LOGOUT_SUCCESSFUL:
            localStorage.removeItem("token");
            return {...state, errors: null, token: null, isAuthenticated: null};
        default:
            return state;
    }
}
import { useEffect, useState } from "react";

import {
  useGetProjectCostsQuery,
  useUpdateProjectCostsMutation
} from "features/projectCosts/api/projectCostsApi";

import type { GetProjectCostsRequest } from "../api/types";

export function useProjectCosts(projectDetails: GetProjectCostsRequest) {

  const [
    isProjectCostsLoading,
    setIsProjectCostsLoading
  ] = useState(false);

  const {
    isLoading: isGetProjectCostsLoading,
    data: projectCosts
  } = useGetProjectCostsQuery(projectDetails);

  const [
    updateProjectCosts
  ] = useUpdateProjectCostsMutation();

  useEffect(() => {
    setIsProjectCostsLoading(
      isGetProjectCostsLoading
    );
  }, [
    isGetProjectCostsLoading
  ]);
  
  return {
    isProjectCostsLoading,
    projectCosts,
    updateProjectCosts
  };
}
import React from 'react';

import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import {
  ARCHETYPE_AGE_BAND_MAP,
  ARCHETYPE_DWELLING_TYPE_MAP,
  ARCHETYPE_FUEL_TYPE_MAP,
} from 'common/assets/rk_type_mappings/archetype';
import withMaterialBreakpointQuery from 'common/assets/utilities/withMaterialBreakpointQuery';
import { withMaterialUserInterfaceTheme } from "common/hocs/theme";

import DashboardGraph from './DashboardGraph';
import Box from "@mui/material/Box";

const convert = require('convert-units');

class DashboardGraphs extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      this.props.dwellings !== nextProps.dwellings ||
      this.props.mode !== nextProps.mode ||
      this.props.view !== nextProps.view
    );
  }

  render() {
    const renewableGraphColour = '#00c853';
    const energyUsageGraphColour = '#fb8c00';
    const cO2UsageGraphColour = '#7200ca';
    const energyCostGraphColour = '#2962ff';
    const berGraphColour = '#353551';
    const allGraphColour = '#d50000';

    const chartHeight = 180;

    const axisLabelHalfWidth = 90;
    const axisLabelMinimumGapWidth = 60;
    const berAxisLabelHalfWidth = 25;
    const berAxisLabelMinimumGapWidth = 15;

    let dwellingData;
    let renewableData;

    const {
      dwellings,
      numberOfSelectedDwellings
    } = this.props;

    let tickValues;
    if (this.props.view === 'age') {
      tickValues = [
        '< 1971',
        '< 1991',
        '< 2001',
        '< 2011',
        `< ${new Date().getFullYear() + 1}`,
      ];
    } else if (this.props.view === 'type') {
      tickValues = ['Apartment', 'Terrace', 'Semi', 'Detached'];
    } else {
      tickValues = ['Solid', 'Electric', 'Oil', 'Gas', 'Group'];
    }

    if (this.props.view === 'type') {
      dwellingData = [
        {
          x: tickValues[0],
          count: 0,
          co2_cost: 0,
          energy_cost: 0,
          energy_use: 0,
          renewable: 0,
        },
        {
          x: tickValues[1],
          count: 0,
          co2_cost: 0,
          energy_cost: 0,
          energy_use: 0,
          renewable: 0,
        },
        {
          x: tickValues[2],
          count: 0,
          co2_cost: 0,
          energy_cost: 0,
          energy_use: 0,
          renewable: 0,
        },
        {
          x: tickValues[3],
          count: 0,
          co2_cost: 0,
          energy_cost: 0,
          energy_use: 0,
          renewable: 0,
        },
      ];
      renewableData = [
        { x: tickValues[0], renewable: 0 },
        { x: tickValues[1], renewable: 0 },
        { x: tickValues[2], renewable: 0 },
        { x: tickValues[3], renewable: 0 },
      ];
    } else {
      dwellingData = [
        {
          x: tickValues[0],
          count: 0,
          co2_cost: 0,
          energy_cost: 0,
          energy_use: 0,
          renewable: 0,
        },
        {
          x: tickValues[1],
          count: 0,
          co2_cost: 0,
          energy_cost: 0,
          energy_use: 0,
          renewable: 0,
        },
        {
          x: tickValues[2],
          count: 0,
          co2_cost: 0,
          energy_cost: 0,
          energy_use: 0,
          renewable: 0,
        },
        {
          x: tickValues[3],
          count: 0,
          co2_cost: 0,
          energy_cost: 0,
          energy_use: 0,
          renewable: 0,
        },
        {
          x: tickValues[4],
          count: 0,
          co2_cost: 0,
          energy_cost: 0,
          energy_use: 0,
          renewable: 0,
        },
      ];
      renewableData = [
        { x: tickValues[0], renewable: 0 },
        { x: tickValues[1], renewable: 0 },
        { x: tickValues[2], renewable: 0 },
        { x: tickValues[3], renewable: 0 },
        { x: tickValues[4], renewable: 0 },
      ];
    }

    // Get details for the BER graph and sort it
    let berData = this.props.dwellings.reduce((bers, dwelling) => {
      let rating;
      try {
        rating = dwelling.properties.latest_performance.rating.toUpperCase();
      } catch (e) {
        return bers;
      }

      if (rating in bers) {
        bers[rating] += 1;
      } else {
        bers[rating] = 1;
      }

      return bers;
    }, {});
    berData = Object.entries(berData)
      .map(([ber, count]) => ({
        ber,
        count,
      }))
      .sort((a, b) => a.ber.localeCompare(b.ber));

    // Get details for the other 5 graphs
    this.props.dwellings.forEach((dwelling) => {
      let co2 = 0;
        let energy_cost = 0;
        let energy_use = 0;
        let renewable = 0;
      try {
        co2 = parseFloat(dwelling.properties.latest_performance.total_co2);
        energy_cost = parseFloat(
          dwelling.properties.latest_performance.energy_cost
        );
        energy_use = parseFloat(
          dwelling.properties.latest_performance.total_de
        );
        renewable = Math.abs(
          parseFloat(
            dwelling.properties.latest_performance.renewable_graph_value
          )
        );
      } catch (e) {
        return;
      }

      let index;
      if (this.props.view === 'age') {
        const age_band = ARCHETYPE_AGE_BAND_MAP(
          dwelling.properties.latest_archetype.age_band
        );
        if (age_band > 0) {
          index = age_band - 1;
        } else {
          // console.log("age_band is " + age_band + ", what to do next?")
        }
      } else if (this.props.view === 'type') {
        const dwelling_type =
          ARCHETYPE_DWELLING_TYPE_MAP[
            dwelling.properties.latest_archetype.dwelling_type
          ];

        if (dwelling_type > 0) {
          index = dwelling_type - 1;
        } else {
          // console.log("dwelling_type is ", dwelling_type, ", what to do next?")
        }
      } else {
        const main_fuel =
          ARCHETYPE_FUEL_TYPE_MAP[
            dwelling.properties.latest_archetype.main_fuel
          ];

        if (main_fuel > 0) {
          index = main_fuel - 1;
        } else {
          // console.log("main_fuel is ", main_fuel, ", what to do next?")
        }
      }

      dwellingData[index].count += 1;
      dwellingData[index].co2_cost += co2;
      dwellingData[index].energy_cost += energy_cost;
      dwellingData[index].energy_use += energy_use;
      dwellingData[index].renewable += renewable;
    });

    // Get the totals
    const totals = {
      co2_cost_total: 0,
      energy_cost_total: 0,
      energy_use_total: 0,
      renewable_total: 0,
    };
    for (let i = 0; i < dwellingData.length; i++) {
      totals.co2_cost_total += parseFloat(dwellingData[i].co2_cost);
      totals.energy_cost_total += parseFloat(dwellingData[i].energy_cost);
      totals.energy_use_total += parseFloat(dwellingData[i].energy_use);
      totals.renewable_total += parseFloat(dwellingData[i].renewable);
    }

    // Get the averages
    let averages = {};
      let allGraphTitle;

    if (numberOfSelectedDwellings > 0) {
      if (numberOfSelectedDwellings > 1) {
        allGraphTitle = `${numberOfSelectedDwellings} Dwellings`;
      } else {
        allGraphTitle = `${numberOfSelectedDwellings} Dwelling`;
      }
    } else {
      allGraphTitle = `${dwellings.length} Dwellings`;
    }

    let cardHeaderSecondaryText;
    let renewablesGraphTitle;
      let energyUsageGraphTitle;
      let co2UsageGraphTitle;
      let energyCostGraphTitle;
    let energyUsage; let co2Usage;
    if (this.props.mode === 'total') {
      // noinspection JSValidateTypes
      energyUsage = convert(totals.energy_use_total).from('kWh').toBest();
      co2Usage = convert(totals.co2_cost_total).from('kg').toBest();

      cardHeaderSecondaryText = 'Total';
      renewablesGraphTitle = `${(
        (totals.renewable_total * 100) /
        totals.energy_use_total
      ).toFixed(2)} %`;
      energyUsageGraphTitle = `${energyUsage.val.toFixed(2)} ${
        energyUsage.unit
      }`;
      co2UsageGraphTitle = `${co2Usage.val.toFixed(2)} ${
        co2Usage.unit === 'mt' ? 't' : co2Usage.unit
      }`;
      energyCostGraphTitle = `€${totals.energy_cost_total
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;

      for (let i = 0; i < dwellingData.length; i++) {
        renewableData[i].renewable =
          dwellingData[i].energy_use && dwellingData[i].renewable
            ? dwellingData[i].energy_use > 0 && dwellingData[i].renewable > 0
              ? (dwellingData[i].renewable * 100) / dwellingData[i].energy_use
              : 0
            : 0;
      }
    } else {
      averages = {
        co2_cost_avg: totals.co2_cost_total / dwellings.length,
        energy_cost_avg: totals.energy_cost_total / dwellings.length,
        energy_use_avg: totals.energy_use_total / dwellings.length,
        renewable_avg: totals.renewable_total / dwellings.length,
      };
      // noinspection JSValidateTypes
      energyUsage = convert(averages.energy_use_avg).from('kWh').toBest();
      co2Usage = convert(averages.co2_cost_avg / 1000)
        .from('kg')
        .toBest();
      co2Usage = convert(averages.co2_cost_avg).from('kg').toBest();

      cardHeaderSecondaryText = 'Average';
      renewablesGraphTitle = `${(
        (averages.renewable_avg * 100) /
        averages.energy_use_avg
      ).toFixed(2)} %`;
      energyUsageGraphTitle = `${energyUsage.val.toFixed(2)} ${
        energyUsage.unit
      }`;
      co2UsageGraphTitle = `${co2Usage.val.toFixed(2)} ${
        co2Usage.unit === 'mt' ? 't' : co2Usage.unit
      }`;
      energyCostGraphTitle = `€${averages.energy_cost_avg
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;

      for (let i = 0; i < dwellingData.length; i++) {
        if (dwellingData[i].count > 0) {
          dwellingData[i].co2_cost =
            dwellingData[i].co2_cost / dwellingData[i].count;
          dwellingData[i].energy_cost =
            dwellingData[i].energy_cost / dwellingData[i].count;
          dwellingData[i].energy_use =
            dwellingData[i].energy_use / dwellingData[i].count;
        }
      }

      for (let i = 0; i < dwellingData.length; i++) {
        renewableData[i].renewable =
          dwellingData[i].energy_use &&
          dwellingData[i].renewable &&
          dwellingData[i].count
            ? dwellingData[i].energy_use > 0 &&
              dwellingData[i].renewable > 0 &&
              dwellingData[i].count > 0
              ? (dwellingData[i].renewable * 100) /
                dwellingData[i].energy_use /
                dwellingData[i].count
              : 0
            : 0;
      }
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: 'calc(100vh - 64px - 48px - 64px)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            overflowY: 'auto',
          }}
        >
          <Grid
            container
            spacing={3}
            sx={{
              padding: "16px",
              maxWidth: "100%",
            }}
          >
            <DashboardGraph
              cardTitle="Renewable"
              cardSubTitle="% of energy usage"
              graphArgumentField="x"
              graphAxisLabelHalfWidth={axisLabelHalfWidth}
              graphAxisLabelMinimumGapWidth={axisLabelMinimumGapWidth}
              graphChartHeight={chartHeight}
              graphColour={renewableGraphColour}
              graphData={renewableData}
              graphKey="Renewable"
              graphRotated
              graphTitle={renewablesGraphTitle}
              graphValueField="renewable"
            />

            <DashboardGraph
              cardTitle="Energy Usage"
              cardSubTitle={`${cardHeaderSecondaryText} ${energyUsage.unit}/year`}
              graphArgumentField="x"
              graphAxisLabelHalfWidth={axisLabelHalfWidth}
              graphAxisLabelMinimumGapWidth={axisLabelMinimumGapWidth}
              graphChartHeight={chartHeight}
              graphColour={energyUsageGraphColour}
              graphData={dwellingData}
              graphKey="EnergyUsage"
              graphRotated
              graphTitle={energyUsageGraphTitle}
              graphValueField="energy_use"
            />

            <DashboardGraph
              cardTitle="CO₂"
              cardSubTitle={`${cardHeaderSecondaryText} ${
                co2Usage.unit === 'mt' ? 't' : co2Usage.unit
              }/year`}
              graphArgumentField="x"
              graphAxisLabelHalfWidth={axisLabelHalfWidth}
              graphAxisLabelMinimumGapWidth={axisLabelMinimumGapWidth}
              graphChartHeight={chartHeight}
              graphColour={cO2UsageGraphColour}
              graphData={dwellingData}
              graphKey="CO2"
              graphRotated
              graphTitle={co2UsageGraphTitle}
              graphValueField="co2_cost"
            />

            <DashboardGraph
              cardTitle="Energy Cost"
              cardSubTitle={`${cardHeaderSecondaryText} €/year`}
              graphArgumentField="x"
              graphAxisLabelHalfWidth={axisLabelHalfWidth}
              graphAxisLabelMinimumGapWidth={axisLabelMinimumGapWidth}
              graphChartHeight={chartHeight}
              graphColour={energyCostGraphColour}
              graphData={dwellingData}
              graphKey="EnergyCost"
              graphRotated
              graphTitle={energyCostGraphTitle}
              graphValueField="energy_cost"
            />

            <DashboardGraph
              cardTitle="BER"
              cardSubTitle=""
              graphArgumentField="ber"
              graphAxisLabelHalfWidth={berAxisLabelHalfWidth}
              graphAxisLabelMinimumGapWidth={berAxisLabelMinimumGapWidth}
              graphChartHeight={chartHeight}
              graphColour={berGraphColour}
              graphData={berData}
              graphKey="BER"
              graphRotated={false}
              graphTitle={allGraphTitle}
              graphValueField="count"
            />

            <DashboardGraph
              cardTitle="Breakdown"
              cardSubTitle=""
              graphArgumentField="x"
              graphAxisLabelHalfWidth={axisLabelHalfWidth}
              graphAxisLabelMinimumGapWidth={axisLabelMinimumGapWidth}
              graphChartHeight={chartHeight}
              graphColour={allGraphColour}
              graphData={dwellingData}
              graphKey="All"
              graphRotated
              graphTitle={allGraphTitle}
              graphValueField="count"
            />
          </Grid>

          <div style={{ height: 220 }} />
        </Box>
      </Box>
    );
  }
}

DashboardGraphs.propTypes = {
  dwellings: PropTypes.array.isRequired,
  mode: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
  numberOfSelectedDwellings: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,

  theme: PropTypes.object.isRequired,
};

export default withMaterialUserInterfaceTheme(
  withMaterialBreakpointQuery()(DashboardGraphs)
);

import type {
  TechnicalAssessmentFormApiResponse,
  UpdateTAFApiRequest
} from "./types";
import type {
  TechnicalAssessmentForm,
  TechnicalAssessmentFormDoor,
  TechnicalAssessmentFormFloor,
  TechnicalAssessmentFormRoof,
  TechnicalAssessmentFormWall,
  TechnicalAssessmentFormWindow
} from "../types";


function emptyStringIfNull(variable: string | null) {
  return variable === null ? "" : variable;
}

function emptyNumberIfNull(variable: number | null) {
  return variable === null ? 0 : variable;
}

function emptyBooleanIfNull(variable: boolean | null) {
  return variable === null ? false : variable;
}

function parseDateIfNotNull(variable: string | null) {
  return variable === null ? null
    : new Date(variable);
}


export function tafApiTransformer(
  response: TechnicalAssessmentFormApiResponse
): TechnicalAssessmentForm {
  const transformedResponse: TechnicalAssessmentForm = {
    ...response,
    id: response.pk,
    technical_advisor_signature_date: parseDateIfNotNull(
      response.technical_advisor_signature_date
    ),
    home_owner_signature_date: parseDateIfNotNull(
      response.home_owner_signature_date
    ),
    dwelling_date_of_construction: parseDateIfNotNull(
      response.dwelling_date_of_construction
    ),
    technical_advisor_name: emptyStringIfNull(
      response.technical_advisor_name
    ),
    home_owner_name: emptyStringIfNull(
      response.home_owner_name
    ),
    technical_advisor_recommendations: emptyStringIfNull(
      response.technical_advisor_recommendations
    ),
    home_owner_address_line1: emptyStringIfNull(
      response.home_owner_address_line1
    ),
    home_owner_address_line2: emptyStringIfNull(
      response.home_owner_address_line2
    ),
    home_owner_address_line3: emptyStringIfNull(
      response.home_owner_address_line3
    ),
    home_owner_postcode: emptyStringIfNull(
      response.home_owner_postcode
    ),
    home_owner_signature: emptyStringIfNull(
      response.home_owner_signature
    ),
    other_details_comments_openings: emptyStringIfNull(
      response.other_details_comments_openings
    ),
    other_details_comments_mechanical_ventilation: emptyStringIfNull(
      response.other_details_comments_mechanical_ventilation
    ),
    measures_to_improve_structural_air_tightness_upgrades: emptyStringIfNull(
      response.measures_to_improve_structural_air_tightness_upgrades
    ),
    technical_advisor_signature: emptyStringIfNull(
      response.technical_advisor_signature
    ),
    group_heating_dwelling: emptyNumberIfNull(
      response.group_heating_dwelling
    )
  };
  const { system } = transformedResponse;

  system.ventilation_no_of_wet_rooms_comment = emptyStringIfNull(
    response.system.ventilation_no_of_wet_rooms_comment
  );
  system.ventilation_vent_manufacturer = emptyStringIfNull(
    response.system.ventilation_vent_manufacturer
  );
  system.ventilation_vent_model = emptyStringIfNull(
    response.system.ventilation_vent_model
  );
  system.solar_water_heating_solar_panel_manu_name = emptyStringIfNull(
    response.system.solar_water_heating_solar_panel_manu_name
  );
  system.solar_water_heating_solar_panel_model = emptyStringIfNull(
    response.system.solar_water_heating_solar_panel_model
  );
  system.solar_water_heating_aperture_area = emptyNumberIfNull(
    response.system.solar_water_heating_aperture_area
  );
  system.solar_water_heating_annual_solar_radiation = emptyNumberIfNull(
    response.system.solar_water_heating_annual_solar_radiation
  );
  system.solar_water_heating_collector_heat_loss_coeff = emptyNumberIfNull(
    response.system.solar_water_heating_collector_heat_loss_coeff
  );
  system.solar_water_heating_combined_cylinder = emptyBooleanIfNull(
    response.system.solar_water_heating_combined_cylinder
  );
  system.solar_water_heating_cylinder_stat = emptyBooleanIfNull(
    response.system.solar_water_heating_cylinder_stat
  );
  system.solar_water_heating_electricity_consumption = emptyNumberIfNull(
    response.system.solar_water_heating_electricity_consumption
  );
  system.solar_water_heating_over_shading_factor = emptyNumberIfNull(
    response.system.solar_water_heating_over_shading_factor
  );
  system.solar_water_heating_solar_storage_volume = emptyNumberIfNull(
    response.system.solar_water_heating_solar_storage_volume
  );
  system.solar_water_heating_pump_solar_powered = emptyBooleanIfNull(
    response.system.solar_water_heating_pump_solar_powered
  );
  system.solar_water_heating_zero_loss_collector_eff = emptyNumberIfNull(
    response.system.solar_water_heating_zero_loss_collector_eff
  );
  transformedResponse.system = system;

  const doors = [];
  for (let i = 0; i < response.doors.length; i += 1) {
    const door = response.doors[i];
    door.other_details_comments = emptyStringIfNull(
      door.other_details_comments
    );
    doors.push(door);
  }
  transformedResponse.doors = doors.sort(
    (doorA, doorB) => {
      if (doorA.identifier < doorB.identifier) {
        return -1;
      }
      if (doorA.identifier > doorB.identifier) {
        return 1;
      }
      return 0;
    }
  );

  const floors = [];
  for (let i = 0; i < response.floors.length; i += 1) {
    const floor = { ...response.floors[i] };
    floor.other_details_comments = emptyStringIfNull(
      floor.other_details_comments
    );
    floors.push(floor);
  }
  transformedResponse.floors = floors.sort(
    (floorA, floorB) => {
      if (floorA.identifier < floorB.identifier) {
        return -1;
      }
      if (floorA.identifier > floorB.identifier) {
        return 1;
      }
      return 0;
    }
  );

  const roofs = [];
  for (let i = 0; i < response.roofs.length; i += 1) {
    const roof = { ...response.roofs[i] };
    roof.other_details_comments = emptyStringIfNull(
      roof.other_details_comments
    );
    roofs.push(roof);
  }
  transformedResponse.roofs = roofs.sort(
    (roofA, roofB) => {
      if (roofA.identifier < roofB.identifier) {
        return -1;
      }
      if (roofA.identifier > roofB.identifier) {
        return 1;
      }
      return 0;
    }
  );

  const walls = [];
  for (let i = 0; i < response.walls.length; i += 1) {
    const wall = { ...response.walls[i] };
    wall.other_details_comments = emptyStringIfNull(
      wall.other_details_comments
    );
    walls.push(wall);
  }
  transformedResponse.walls = walls.sort(
    (wallA, wallB) => {
      if (wallA.identifier < wallB.identifier) {
        return -1;
      }
      if (wallA.identifier > wallB.identifier) {
        return 1;
      }
      return 0;
    }
  );

  const windows = [];
  for (let i = 0; i < response.windows.length; i += 1) {
    const window = { ...response.windows[i] };
    window.other_details_comments = emptyStringIfNull(
      window.other_details_comments
    );
    windows.push(window);
  }
  transformedResponse.windows = windows.sort(
    (windowA, windowB) => {
      if (windowA.window_ref < windowB.window_ref) {
        return -1;
      }
      if (windowA.window_ref > windowB.window_ref) {
        return 1;
      }
      return 0;
    }
  );
  return transformedResponse;
}


export function tafApiUpdateTransformer(
  variable: UpdateTAFApiRequest
): FormData {
  const formData = new FormData();
  Object.entries(
    variable
  ).forEach(
    (entry) => {
      const [
        key,
        value
      ] = entry;

      const buildingElementArrays = [
        "doors",
        "floors",
        "roofs",
        "walls",
        "windows"
      ];

      if (buildingElementArrays.indexOf(key) > -1) {

        if (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          value instanceof Array<TechnicalAssessmentFormDoor> ||
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          value instanceof Array<TechnicalAssessmentFormFloor> ||
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          value instanceof Array<TechnicalAssessmentFormRoof> ||
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          value instanceof Array<TechnicalAssessmentFormWall> ||
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          value instanceof Array<TechnicalAssessmentFormWindow>
        ) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < value.length; i++) {
            formData.append(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              `${key}[${i}]`, JSON.stringify(value[i])
            );
          }
        }
      } else if (key === "system") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.append(key, JSON.stringify(value));
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.append(key, value);
      }
    }
  );
  return formData;
}
import type { KeyboardEvent } from "react";

export const floatRegex = /^(\d+|\d+\.\d{1,2})$/;

export const integerRegex = /^\d+$/;

export function floatKeyCheck(event: KeyboardEvent) {
  if (
    event.key === "e" ||
    event.key === "E" ||
    event.key === "-" ||
    event.key === "+"
  ) {
    event.preventDefault()
  }
}

export function integerKeyCheck(event: KeyboardEvent) {
  if (
    event.key === "e" ||
    event.key === "E" ||
    event.key === "-" ||
    event.key === "+" ||
    event.key === "."
  ) {
    event.preventDefault()
  }
}

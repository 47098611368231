import React from "react";

import { connect } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import { getAuthToken } from "../actions/authentication";
import Login from "../containers/login/Login";
import RetroKit from "../containers/retrokit/RetroKit";

class RootContainer extends React.Component {

  componentDidMount() {
    this.props.getAuthToken();
  }

  PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={
        (props) =>
          this.props.authentication.isAuthenticated ?
            <Component
              {...props}
              {...rest}
            />
            : <Redirect
              to="/login"
              push
            />
      }
    />
  );

  render() {
    const { PrivateRoute } = this;

    return (
      <BrowserRouter>
        <Switch>

          <Route
            path="/login"
            component={
              Login
            }
          />

          <PrivateRoute
            path="/"
            component={
              RetroKit
            }
          />

          {/* <Redirect
            from="*"
            to="/"
          /> */}

        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.authentication
});

const mapDispatchToProps = (dispatch) => ({
  getAuthToken: () => dispatch(
    getAuthToken()
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  RootContainer
);
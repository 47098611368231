import { useEffect, useState } from "react";

import type { TechnicalAssessmentForm } from "../types";

import {
  useLazyCreateOrGetTAFQuery,
  useUpdateTAFMutation
} from "../api/tafApi";

export function useTafModal() {

  const [
    taf,
    setTaf
  ] = useState<TechnicalAssessmentForm>();

  const [
    isTafLoading,
    setIsTafLoading
  ] = useState(false);

  const [
    isTafUpdating,
    setIsTafUpdating
  ] = useState(false);

  const [
    createOrGetTAF,
    {
      data: createOrGetTAFResult,
      isLoading: createOrGetTAFLoading
    }
  ] = useLazyCreateOrGetTAFQuery();

  const [
    updateTAF,
    {
      data: updateTAFResult,
      isLoading: updateTAFLoading
    }
  ] = useUpdateTAFMutation();

  useEffect(() => {
    setIsTafLoading(
      createOrGetTAFLoading
    );
  }, [
    createOrGetTAFLoading
  ]);

  useEffect(() => {
    setIsTafUpdating(
      updateTAFLoading
    );
  }, [
    updateTAFLoading
  ]);

  useEffect(() => {
    if (createOrGetTAFResult) {
      setTaf(
        createOrGetTAFResult
      );
    }
  }, [
    createOrGetTAFResult
  ]);

  useEffect(() => {
    if (updateTAFResult) {
      setTaf(
        updateTAFResult
      );
    }
  }, [
    updateTAFResult
  ]);


  return {
    createOrGetTAF,
    isTafLoading,
    isTafUpdating,
    taf,
    updateTAF
  };
}
import React, { useEffect, useState } from "react";

import { ListSubheader, Select } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import {
  DwellingAndScenarioBerGrades
} from "common/components/DwellingAndScenarioBerGrades";
import {
  WhiteBackgroundTextField
} from "common/components/WhiteBackgroundTextField";
import {
  frameTypeOptions,
  glazingGapOptions,
  glazingTypeOptions,
  orientationOptions
} from "common/maps/dwelling";
import {
  floatKeyCheck,
  floatRegex
} from "common/utils/numbers";
import {
  TechnicalAssessmentFormHeader
} from "features/technicalAssessmentForm/dialogs/common/TechnicalAssessmentFormHeader";

import type {
  UpdateTAFApiRequest
} from "features/technicalAssessmentForm/api/types";
import type {
  TechnicalAssessmentForm,
  TechnicalAssessmentFormWindow
} from "features/technicalAssessmentForm/types";



interface TechnicalAssessmentFormModal6Props {
  isTafUpdating: boolean;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  setTafStep: (newStep: number) => void;
  taf: TechnicalAssessmentForm;
  tafStep: number;
  updateTAF: (variable: UpdateTAFApiRequest) => Promise<TechnicalAssessmentForm>;
}


export function TechnicalAssessmentFormModal6(
  {
    isTafUpdating,
    showModal,
    setShowModal,
    setTafStep,
    taf,
    tafStep,
    updateTAF
  }: TechnicalAssessmentFormModal6Props
) {
  const theme = useTheme();

  const [
    selectedIndex,
    setSelectedIndex
  ] = useState<number>(0);

  const [
    windows,
    setWindows
  ] = useState<TechnicalAssessmentFormWindow[]>(
    taf.windows
  );

  const [
    otherDetailsComments,
    setOtherDetailsComments
  ] = useState<string | null>("");

  const [
    windowDescription,
    setWindowDescription
  ] = useState('');

  const [
    windowArea,
    setWindowArea
  ] = useState<number>(0);

  const [
    totalHeatLossArea,
    setTotalHeatLossArea
  ] = useState<number>(0);

  const [
    windowGlazingTypeId,
    setWindowGlazingTypeId
  ] = useState<number>(0);

  const [
    windowFrameFactorId,
    setWindowFrameFactorId
  ] = useState<number>(0);

  const [
    windowGapId,
    setWindowGapId
  ] = useState<number>(0);

  const [
    windowSolarTransmittance,
    setWindowSolarTransmittance
  ] = useState<number>(0);

  const [
    windowUValue,
    setWindowUValue
  ] = useState<number>(0);

  const [
    uValueBasis,
    setUValueBasis
  ] = useState<string>("false");

  const [
    scenarioGlazingTypeId,
    setScenarioGlazingTypeId
  ] = useState<number>(0);

  const [
    scenarioFrameFactorId,
    setScenarioFrameFactorId
  ] = useState<number>(0);

  const [
    scenarioGapId,
    setScenarioGapId
  ] = useState<number>(0);

  const [
    scenarioSolarTransmittance,
    setScenarioSolarTransmittance
  ] = useState<number>(0);

  const [
    scenarioUValue,
    setScenarioUValue
  ] = useState<number>(0);

  const [
    orientationId,
    setOrientationId
  ] = useState<number>(0);

  useEffect(
    () => {

      if (windows.length > 0) {
        setUValueBasis(
          String(
            !windows[selectedIndex].user_defined_u_value
          )
        );

        let totalHeatLoss = 0;
        windows.forEach(window => {
          totalHeatLoss += window.area;
        });
        setTotalHeatLossArea(totalHeatLoss)

        if (windows[selectedIndex].orientation_id) {
          setOrientationId(
            windows[selectedIndex].orientation_id
          )
        }

        if (windows[selectedIndex].scenario_u_value) {
          setScenarioUValue(
            Number(
              windows[selectedIndex].scenario_u_value.toFixed(2)
            )
          )
        }

        if (windows[selectedIndex].scenario_solar_transmittance) {
          setScenarioSolarTransmittance(
            Number(
              windows[selectedIndex].scenario_solar_transmittance.toFixed(2)
            )
          )
        }

        if (windows[selectedIndex].scenario_gap_id) {
          setScenarioGapId(
            windows[selectedIndex].scenario_gap_id
          )
        }

        if (windows[selectedIndex].scenario_frame_factor_id) {
          setScenarioFrameFactorId(
            windows[selectedIndex].scenario_frame_factor_id
          )
        }

        if (windows[selectedIndex].scenario_glazing_type_id) {
          setScenarioGlazingTypeId(
            windows[selectedIndex].scenario_glazing_type_id
          )
        }

        if (windows[selectedIndex].u_value) {
          setWindowUValue(
            Number(
              windows[selectedIndex].u_value.toFixed(2)
            )
          )
        }

        if (windows[selectedIndex].solar_transmittance) {
          setWindowSolarTransmittance(
            Number(
              windows[selectedIndex].solar_transmittance.toFixed(2)
            )
          )
        }

        if (windows[selectedIndex].gap_id) {
          setWindowGapId(
            windows[selectedIndex].gap_id
          )
        }

        if (windows[selectedIndex].frame_factor_id) {
          setWindowFrameFactorId(
            windows[selectedIndex].frame_factor_id
          )
        }

        if (windows[selectedIndex].glazing_type_id) {
          setWindowGlazingTypeId(
            windows[selectedIndex].glazing_type_id
          )
        }

        if (windows[selectedIndex].area) {
          setWindowArea(
            Number(
              windows[selectedIndex].area.toFixed(2)
            )
          )
        }

        if (windows[selectedIndex].description) {
          setWindowDescription(
            windows[selectedIndex].description
          )
        }

        if (windows[selectedIndex].other_details_comments) {
          setOtherDetailsComments(
            windows[selectedIndex].other_details_comments
          )
        }
      }
    },
    [
      selectedIndex,
      windows
    ]
  );

  const handleWindowEdits = () => {

    if (windows.length > 0) {
      const newWindow = {
        window_ref: windows[selectedIndex].window_ref,
        area: windowArea,
        u_value: windowUValue,
        description: windowDescription,
        glazing_type_id: windowGlazingTypeId,
        frame_factor_id: windowFrameFactorId,
        gap_id: windowGapId,
        solar_transmittance: windowSolarTransmittance,
        orientation_id: orientationId,
        other_details_comments: otherDetailsComments,
        scenario_glazing_type_id: scenarioGlazingTypeId,
        scenario_gap_id: scenarioGapId,
        scenario_frame_factor_id: scenarioFrameFactorId,
        scenario_u_value: scenarioUValue,
        scenario_solar_transmittance: scenarioSolarTransmittance
      };

      const newWindows = [...windows];

      newWindows[selectedIndex] = newWindow;

      setWindows(newWindows);

      return newWindows;
    }
    return [];
  };

  const handleListItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    if (windows.length > 0) {

      const latestWindows = handleWindowEdits();

      setSelectedIndex(index);

      setWindowArea(
        Number(
          latestWindows[index].area.toFixed(2)
        )
      );
      setWindowUValue(
        Number(
          latestWindows[index].u_value.toFixed(2)
        )
      );
      setWindowDescription(
        latestWindows[index].description
      );
      setWindowGlazingTypeId(
        latestWindows[index].glazing_type_id
      );
      setWindowFrameFactorId(
        latestWindows[index].frame_factor_id
      );
      setUValueBasis(
        String(
          !latestWindows[selectedIndex].user_defined_u_value
        )
      );
      setWindowGapId(
        latestWindows[index].gap_id
      );
      setWindowSolarTransmittance(
        Number(
          latestWindows[index].solar_transmittance.toFixed(2)
        )
      );
      setOrientationId(
        latestWindows[index].orientation_id
      );
      setOtherDetailsComments(
        latestWindows[index].other_details_comments
      );
      setScenarioGlazingTypeId(
        latestWindows[index].scenario_glazing_type_id
      );
      setScenarioGapId(
        latestWindows[index].scenario_gap_id
      );
      setScenarioFrameFactorId(
        latestWindows[index].scenario_frame_factor_id
      );
      setScenarioUValue(
        Number(
          latestWindows[index].scenario_u_value.toFixed(2)
        )
      );
      setScenarioSolarTransmittance(
        Number(
          latestWindows[index].scenario_solar_transmittance.toFixed(2)
        )
      );
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleUpdateTAF = (dialogNumber: number) => {

    const latestWindows = handleWindowEdits();

    const requestBody: UpdateTAFApiRequest = {
      technical_assessment_form_id: taf.id,
      windows: latestWindows
    };

    updateTAF(
      requestBody
    ).then(
      (response) => {
        if (response) {
          setTafStep(dialogNumber);
        }
      }
    ).catch(
      (error) => console.log("Error while updating TAF:", error)
    );
  };

  const handleBackButtonClick = () => {
    handleUpdateTAF(5);
  };

  const handleNextButtonClick = () => {
    handleUpdateTAF(7);
  };

  const handleSliderChanged = (dialogNumber: number) => {
    handleUpdateTAF(dialogNumber);
  };

  const outlinedInputReducedPadding = {
    style: {
      padding: "8px"
    }
  };

  const contentSectionHeaderText = {
    color: "#71717a"
  };

  const inputLabelVariableWidth = {
    display: "flex",
    alignItems: "center"
  };

  const labelAndInputBox = {
    display: "flex"
  };

  const outlinedInputStyle = {
    flex: 1,
    marginLeft: "24px",
    backgroundColor: "white"
  };

  const outlinedInputStyleDropdown = {
    flex: 1,
    marginLeft: "24px",
    backgroundColor: "white",
    paddingLeft: "8px",
    paddingRight: "8px",
    overflowWrap: "break-word",
    width: "10rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  };

  const sectionTitle = {
    marginTop: "8px"
  };

  return (
    <Dialog
      open={showModal}
      onClose={closeModal}
      maxWidth="lg"
      fullWidth
      scroll="paper"
      sx={{
        padding: "16px"
      }}
    >

      <TechnicalAssessmentFormHeader
        changeTafStep={handleSliderChanged}
        closeModal={closeModal}
        tafStep={tafStep}
      />

      <DialogContent
        sx={{
          padding: "0px 8px 0px 8px"
        }}
      >

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "5px",
            backgroundColor: "#e0dede",
            padding: "0px 8px 8px 8px"
          }}
        >

          {/* SECTION TITLE */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "24px",
              paddingTop: "24px",
              paddingRight: "24px",
              paddingBottom: "16px"
            }}
          >

            <img
              alt="section title"
              style={{
                width: "60px",
                paddingRight: "16px"
              }}
              src="https://rk-staging-static.s3.eu-west-1.amazonaws.com/static/public/images/housing_upgrade_plans/icon-windows.svg"
            />

            <Typography
              sx={{
                display: "flex",
                alignItems: "center"
              }}
              variant="h5"
            >
              Fabric Upgrades: Windows
            </Typography>

          </Box>

          {
            windows.length > 0 ? <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                borderStyle: "solid",
                borderColor: theme.palette.primary.main,
                borderRadius: "3px",
                borderWidth: "1px",
                backgroundColor: "white"
              }}
            >
              <List
                component="nav"
                sx={{
                  backgroundColor: "#ffffff"
                }}
                aria-label="windows"
                subheader={
                  <ListSubheader
                    component="div"
                    id="nested-list-subheader"
                  >
                    Window #
                  </ListSubheader>
                }
              >
                {
                  windows.map(
                    (_window, index) =>
                      <ListItem
                        disablePadding
                        key={index}
                        onClick={
                          (event) => handleListItemClick(event, index)
                        }
                      >
                        <ListItemButton
                          selected={selectedIndex === index}
                        >

                          <ListItemText
                            primary={index + 1}
                          />

                        </ListItemButton>

                      </ListItem>
                  )
                }
              </List>

              <Box
                sx={{
                  flex: 1,
                  backgroundColor: theme.palette.action.selected,
                  padding: "8px"
                }}
              >

                {/* SECTION DESCRIPTION */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "8px",
                    paddingRight: "24px",
                    borderRadius: "5px",
                    border: 1,
                    borderColor: "#71717a",
                    padding: "16px",
                    gap: "8px"
                  }}
                >

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row"
                    }}
                  >

                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      Description
                    </Typography>

                    <OutlinedInput
                      sx={outlinedInputStyle}
                      placeholder=""
                      inputProps={outlinedInputReducedPadding}
                      value={windowDescription}
                      onChange={
                        e => {
                          setWindowDescription(e.target.value);
                        }
                      }
                    />

                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row"
                    }}
                  >

                    <Box
                      sx={{
                        display: "flex",
                        flex: 1,
                        paddingRight: "20px"
                      }}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Area
                      </Typography>

                      <WhiteBackgroundTextField
                        type="number"
                        variant="outlined"
                        InputProps={{
                          startAdornment: <InputAdornment
                            position="start"
                          >
                            m&#178;
                          </InputAdornment>,
                          inputProps: {
                            min: 0,
                            step: "0.01"
                          }
                        }}
                        onChange={
                          (event) => {
                            if (
                              event.target.value === "" ||
                              floatRegex.test(event.target.value)
                            ) {
                              setWindowArea(
                                Number(
                                  event.target.value
                                )
                              );
                            }
                          }
                        }
                        onKeyDown={floatKeyCheck}
                        value={windowArea}
                        sx={{
                          flex: 1,
                          marginLeft: "24px"
                        }}
                      />

                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        paddingLeft: "20px",
                        flex: 1
                      }}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Total heat loss area shown in BER
                      </Typography>

                      <Typography
                        sx={{
                          marginLeft: "16px",
                          flex: 1,
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center"
                        }}
                      >
                        {
                          totalHeatLossArea.toFixed(2)
                        } m&#178;
                      </Typography>

                    </Box>

                  </Box>

                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    padding: "8px 0px 8px 0px"
                  }}
                >
                  {/* SECTION CURRENT */}
                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "5px",
                      border: 1,
                      borderColor: "#71717a",
                      padding: "0px 16px 16px 16px",
                      gap: "8px"
                    }}
                  >

                    <Box
                      sx={sectionTitle}
                    >
                      <Typography
                        align="center"
                        variant="h5"
                        sx={contentSectionHeaderText}
                      >
                        Current (as per published BER)
                      </Typography>

                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Glazing Type
                      </Typography>

                      <Select
                        sx={outlinedInputStyleDropdown}
                        inputProps={{
                          sx: {
                            padding: "8px"
                          }
                        }}
                        value={String(windowGlazingTypeId)}
                        label=""
                        onChange={
                          e => setWindowGlazingTypeId(Number(e.target.value))
                        }
                        variant="outlined"
                      >
                        {
                          glazingTypeOptions.map(
                            (heatingOption) => <MenuItem
                              value={heatingOption.value}
                              key={heatingOption.label}
                            >
                              {heatingOption.label}
                            </MenuItem>
                          )
                        }
                      </Select>
                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Frame Type
                      </Typography>

                      <Select
                        sx={outlinedInputStyleDropdown}
                        inputProps={{
                          sx: {
                            padding: "8px"
                          }
                        }}
                        value={String(windowFrameFactorId)}
                        label=""
                        onChange={
                          e => setWindowFrameFactorId(Number(e.target.value))
                        }
                        variant="outlined"
                      >
                        {
                          frameTypeOptions.map((heatingOption) => <MenuItem
                              value={heatingOption.value}
                              key={heatingOption.label}
                            >
                              {heatingOption.label}
                            </MenuItem>
                          )
                        }
                      </Select>
                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Glazing Gap
                      </Typography>

                      <Select
                        sx={outlinedInputStyleDropdown}
                        inputProps={{
                          sx: {
                            padding: "8px"
                          }
                        }}
                        value={String(windowGapId)}
                        label=""
                        onChange={
                          e => setWindowGapId(Number(e.target.value))
                        }
                        variant="outlined"
                      >
                        {
                          glazingGapOptions.map(
                            (heatingOption) => <MenuItem
                              value={heatingOption.value}
                              key={heatingOption.label}
                            >
                              {heatingOption.label}
                            </MenuItem>
                          )
                        }
                      </Select>
                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Solar Transmittance
                      </Typography>

                      <WhiteBackgroundTextField
                        type="number"
                        variant="outlined"
                        InputProps={{
                          startAdornment: <InputAdornment
                            position="start"
                          >
                            g
                          </InputAdornment>,
                          inputProps: {
                            min: 0,
                            step: "0.01"
                          }
                        }}
                        onChange={
                          (event) => {
                            if (
                              event.target.value === "" ||
                              floatRegex.test(event.target.value)
                            ) {
                              setWindowSolarTransmittance(
                                Number(
                                  event.target.value
                                )
                              );
                            }
                          }
                        }
                        onKeyDown={floatKeyCheck}
                        value={windowSolarTransmittance}
                        sx={{
                          flex: 1,
                          marginLeft: "24px"
                        }}
                      />

                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        U-value
                      </Typography>

                      <WhiteBackgroundTextField
                        type="number"
                        variant="outlined"
                        InputProps={{
                          startAdornment: <InputAdornment
                            position="start"
                          >
                            W/m&#178;K
                          </InputAdornment>,
                          inputProps: {
                            min: 0,
                            step: "0.01"
                          }
                        }}
                        onChange={
                          (event) => {
                            if (
                              event.target.value === "" ||
                              floatRegex.test(event.target.value)
                            ) {
                              setWindowUValue(
                                Number(
                                  event.target.value
                                )
                              );
                            }
                          }
                        }
                        onKeyDown={floatKeyCheck}
                        value={windowUValue}
                        sx={{
                          flex: 1,
                          marginLeft: "24px"
                        }}
                      />

                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        U-value basis
                      </Typography>

                      <Typography
                        sx={{
                          marginLeft: "16px",
                          flex: 1,
                          textAlign: "right"
                        }}
                      >
                        {
                          uValueBasis === "true" ? "Default" : "Non default"
                        }

                      </Typography>

                    </Box>
                  </Box>

                  {/* SECTION PROPOSED */}
                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "5px",
                      border: 1,
                      borderColor: "#71717a",
                      padding: "0px 16px 16px 16px",
                      gap: "8px"
                    }}
                  >

                    <Box
                      sx={sectionTitle}
                    >
                      <Typography
                        align="center"
                        variant="h5"
                        sx={contentSectionHeaderText}
                      >
                        Proposed upgrade
                      </Typography>

                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Glazing Type
                      </Typography>

                      <Select
                        sx={outlinedInputStyleDropdown}
                        inputProps={{
                          sx: {
                            padding: "8px"
                          }
                        }}
                        value={String(scenarioGlazingTypeId)}
                        label=""
                        onChange={
                          e => setScenarioGlazingTypeId(Number(e.target.value))
                        }
                        variant="outlined"
                      >
                        {
                          glazingTypeOptions.map(
                            (heatingOption) => <MenuItem
                              value={heatingOption.value}
                              key={heatingOption.label}
                            >
                              {heatingOption.label}
                            </MenuItem>
                          )
                        }
                      </Select>
                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Frame Type
                      </Typography>

                      <Select
                        sx={outlinedInputStyleDropdown}
                        inputProps={{
                          sx: {
                            padding: "8px"
                          }
                        }}
                        value={String(scenarioFrameFactorId)}
                        label=""
                        onChange={
                          e => setScenarioFrameFactorId(Number(e.target.value))
                        }
                        variant="outlined"
                      >
                        {
                          frameTypeOptions.map(
                            (heatingOption) => <MenuItem
                              value={heatingOption.value}
                              key={heatingOption.label}
                            >
                              {heatingOption.label}
                            </MenuItem>
                          )
                        }
                      </Select>
                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Glazing Gap
                      </Typography>

                      <Select
                        sx={outlinedInputStyleDropdown}
                        inputProps={{
                          sx: {
                            padding: "8px"
                          }
                        }}
                        value={String(scenarioGapId)}
                        label=""
                        onChange={
                          e => setScenarioGapId(Number(e.target.value))
                        }
                        variant="outlined"
                      >
                        {
                          glazingGapOptions.map(
                            (heatingOption) => <MenuItem
                              value={heatingOption.value}
                              key={heatingOption.label}
                            >
                              {heatingOption.label}
                            </MenuItem>
                          )
                        }
                      </Select>
                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Solar Transmittance
                      </Typography>

                      <WhiteBackgroundTextField
                        type="number"
                        variant="outlined"
                        InputProps={{
                          startAdornment: <InputAdornment
                            position="start"
                          >
                            g
                          </InputAdornment>,
                          inputProps: {
                            min: 0,
                            step: "0.01"
                          }
                        }}
                        onChange={
                          (event) => {
                            if (
                              event.target.value === "" ||
                              floatRegex.test(event.target.value)
                            ) {
                              setScenarioSolarTransmittance(
                                Number(
                                  event.target.value
                                )
                              );
                            }
                          }
                        }
                        onKeyDown={floatKeyCheck}
                        value={scenarioSolarTransmittance}
                        sx={{
                          flex: 1,
                          marginLeft: "24px"
                        }}
                      />

                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        U-value required after upgrade
                      </Typography>

                      <WhiteBackgroundTextField
                        type="number"
                        variant="outlined"
                        InputProps={{
                          startAdornment: <InputAdornment
                            position="start"
                          >
                            W/m&#178;K
                          </InputAdornment>,
                          inputProps: {
                            min: 0,
                            step: "0.01"
                          }
                        }}
                        onChange={
                          (event) => {
                            if (
                              event.target.value === "" ||
                              floatRegex.test(event.target.value)
                            ) {
                              setScenarioUValue(
                                Number(
                                  event.target.value
                                )
                              );
                            }
                          }
                        }
                        onKeyDown={floatKeyCheck}
                        value={scenarioUValue}
                        sx={{
                          flex: 1,
                          marginLeft: "24px"
                        }}
                      />

                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Orientation
                      </Typography>

                      <Select
                        sx={outlinedInputStyleDropdown}
                        inputProps={{
                          sx: {
                            padding: "8px"
                          }
                        }}
                        value={String(orientationId)}
                        label=""
                        onChange={
                          e => setOrientationId(Number(e.target.value))
                        }
                        variant="outlined"
                      >
                        {
                          orientationOptions.map(
                            (heatingOption) => <MenuItem
                              value={heatingOption.value}
                              key={heatingOption.label}
                            >
                              {heatingOption.label}
                            </MenuItem>
                          )
                        }
                      </Select>
                    </Box>
                  </Box>

                </Box>

                {/* SECTION OTHER DETAILS */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "5px",
                    border: 1,
                    borderColor: "#71717a",
                    padding: "16px",
                    gap: "8px"
                  }}
                >
                  <Typography>
                    Other details/comments (include other info for contractor here)
                  </Typography>

                  <TextField
                    sx={{
                      flex: 1,
                      backgroundColor: "white",
                      width: "100%"
                    }}
                    multiline
                    value={otherDetailsComments}
                    onChange={
                      event => setOtherDetailsComments(
                        event.target.value
                      )
                    }
                  />

                </Box>
              </Box>
            </Box> : <Box
              sx={{
                flex: 1,
                padding: "24px",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Typography>
                This dwelling has no windows
              </Typography>
            </Box>
          }
        </Box>

      </DialogContent>

      <DialogActions>

        <DwellingAndScenarioBerGrades
          dwellingDetail={
            String(taf.dwelling_mprn_reference)
          }
          dwellingRating={taf.dwelling_rating}
          scenarioDetail={taf.scenario_title}
          scenarioRating={taf.scenario_rating}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleBackButtonClick}
        >
          Back
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleNextButtonClick}
        >
          Next
        </Button>

      </DialogActions>

    </Dialog>
  );
}
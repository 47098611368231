import { useEffect, useState } from "react";

import {
  useGetCustomScenarioByIdQuery,
  useUpdateCustomScenarioDefaultMeasuresMutation,
  useUpdateCustomScenarioCustomMeasuresMutation,
  useGetDefaultMeasuresNotInCustomScenarioQuery,
  useGetCustomMeasuresNotInCustomScenarioQuery,
  useUpdateCustomScenarioDetailsMutation
} from "features/customScenarios/api/customScenariosApi";

export function useCustomScenarioView(customScenarioId: number) {

  const [
    isCustomScenarioLoading, setIsCustomScenarioLoading
  ] = useState(false);
  
  const {
    isLoading: isGetCustomScenarioLoading,
    data: customScenario
  } = useGetCustomScenarioByIdQuery({
    id: customScenarioId
  });

  const {
    isLoading: isGetDefaultMeasuresNotInCustomScenarioLoading,
    data: defaultMeasuresNotInCustomScenario
  } = useGetDefaultMeasuresNotInCustomScenarioQuery({
    id: customScenarioId,
    inScenario: false
  });

  const {
    isLoading: isGetCustomMeasuresNotInCustomScenarioLoading,
    data: customMeasuresNotInCustomScenario
  } = useGetCustomMeasuresNotInCustomScenarioQuery({
    id: customScenarioId,
    inScenario: false
  });

  const [updateCustomScenarioDetails] = useUpdateCustomScenarioDetailsMutation();

  const [
    updateCustomScenarioDefaultMeasures
  ] = useUpdateCustomScenarioDefaultMeasuresMutation();

  const [
    updateCustomScenarioCustomMeasures
  ] = useUpdateCustomScenarioCustomMeasuresMutation();
  
  // Update loading state
  useEffect(() => {
    setIsCustomScenarioLoading(
      isGetCustomScenarioLoading ||
      isGetDefaultMeasuresNotInCustomScenarioLoading ||
      isGetCustomMeasuresNotInCustomScenarioLoading
    );
  }, [
    isGetCustomScenarioLoading,
    isGetDefaultMeasuresNotInCustomScenarioLoading,
    isGetCustomMeasuresNotInCustomScenarioLoading
  ]);

  return {
    customScenario,
    isCustomScenarioLoading,
    defaultMeasuresNotInCustomScenario,
    customMeasuresNotInCustomScenario,
    updateCustomScenarioDetails,
    updateCustomScenarioDefaultMeasures,
    updateCustomScenarioCustomMeasures
  };
}
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";

export const WhiteBackgroundTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#FFFFFF"
  },
  "& .MuiOutlinedInput-input": {
    padding: "8px"
  }
});
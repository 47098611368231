import React, { useEffect, useState } from "react";

import { ListSubheader, Select } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import {
  DwellingAndScenarioBerGrades
} from "common/components/DwellingAndScenarioBerGrades";
import { 
  WhiteBackgroundTextField 
} from "common/components/WhiteBackgroundTextField";
import {
  floorInsulationLocationOptions,
  floorTypeOptions,
  insulationTypeOptions,
  thermalConductivityOptions
} from "common/maps/dwelling";
import {
  floatKeyCheck,
  floatRegex
} from "common/utils/numbers";
import {
  TechnicalAssessmentFormHeader
} from "features/technicalAssessmentForm/dialogs/common/TechnicalAssessmentFormHeader";

import type {
  UpdateTAFApiRequest
} from "features/technicalAssessmentForm/api/types";
import type {
  TechnicalAssessmentForm,
  TechnicalAssessmentFormFloor
} from "features/technicalAssessmentForm/types";




interface TechnicalAssessmentFormModal5Props {
  isTafUpdating: boolean;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  setTafStep: (newStep: number) => void;
  taf: TechnicalAssessmentForm;
  tafStep: number;
  updateTAF: (variable: UpdateTAFApiRequest) => Promise<TechnicalAssessmentForm>;
}


export function TechnicalAssessmentFormModal5(
  {
    isTafUpdating,
    showModal,
    setShowModal,
    setTafStep,
    taf,
    tafStep,
    updateTAF
  }: TechnicalAssessmentFormModal5Props
) {
  const theme = useTheme();

  // STATE VARIABLES
  const [
    selectedIndex,
    setSelectedIndex
  ] = useState<number>(0);

  const [
    floors,
    setFloors
  ] = useState<TechnicalAssessmentFormFloor[]>(
    taf.floors
  );

  const [
    otherDetailsComments,
    setOtherDetailsComments
  ] = useState<string | null>("");

  const [
    floorDescription,
    setFloorDescription
  ] = useState<string>("");

  const [
    floorArea,
    setFloorArea
  ] = useState<number>(0);

  const [
    totalHeatLossArea,
    setTotalHeatLossArea
  ] = useState<number>(0);

  const [
    floorTypeId,
    setFloorTypeId
  ] = useState<number>(0);

  const [
    existingInsulationType,
    setExistingInsulationType
  ] = useState<number>(0);

  const [
    existingInsulationThickness,
    setExistingInsulationThickness
  ] = useState<number>(0);

  const [
    existingUValue,
    setExistingUValue
  ] = useState<number>(0);

  const [
    uValueBasis,
    setUValueBasis
  ] = useState<string>("false");

  const [
    proposedInsulationType,
    setProposedInsulationType
  ] = useState<number>(0);

  const [
    proposedInsulationThickness,
    setProposedInsulationThickness
  ] = useState<number>(0);

  const [
    proposedInsulationLocation,
    setProposedInsulationLocation
  ] = useState<number>(0);

  const [
    proposedInsulationThermalConductivity,
    setProposedInsulationThermalConductivity
  ] = useState<number>(0);

  const [
    scenarioUValue,
    setScenarioUValue
  ] = useState<number>(0);

  useEffect(
    () => {

      if (floors.length > 0) {

        setUValueBasis(
          String(
            floors[selectedIndex].u_value === floors[selectedIndex].default_u_value
          )
        );

        let totalHeatLoss = 0;
        floors.forEach(floor => {
          totalHeatLoss += floor.area;
        });
        setTotalHeatLossArea(totalHeatLoss);

        if (floors[selectedIndex].scenario_u_value) {
          setScenarioUValue(
            Number(
              floors[selectedIndex].scenario_u_value.toFixed(2)
            )
          );
        }

        if (floors[selectedIndex].proposed_additional_insulation_thermal_conductivity) {
          setProposedInsulationThermalConductivity(
            floors[selectedIndex].proposed_additional_insulation_thermal_conductivity
          );
        }

        if (floors[selectedIndex].proposed_additional_insulation_location) {
          setProposedInsulationLocation(
            floors[selectedIndex].proposed_additional_insulation_location
          );
        }

        if (floors[selectedIndex].proposed_additional_insulation_thickness) {
          setProposedInsulationThickness(
            Number(
              floors[selectedIndex].proposed_additional_insulation_thickness.toFixed(2)
            )
          );
        }

        if (floors[selectedIndex].proposed_additional_insulation_type) {
          setProposedInsulationType(
            floors[selectedIndex].proposed_additional_insulation_type
          );
        }

        if (floors[selectedIndex].u_value) {
          setExistingUValue(
            Number(
              floors[selectedIndex].u_value.toFixed(2)
            )
          );
        }

        if (floors[selectedIndex].existing_insulation_thickness) {
          setExistingInsulationThickness(
            Number(
              floors[selectedIndex].existing_insulation_thickness.toFixed(2)
            )
          );
        }

        if (floors[selectedIndex].existing_insulation_type) {
          setExistingInsulationType(
            floors[selectedIndex].existing_insulation_type
          );
        }

        if (floors[selectedIndex].type_id) {
          setFloorTypeId(floors[selectedIndex].type_id);
        }

        if (floors[selectedIndex].area) {
          setFloorArea(
            Number(
              floors[selectedIndex].area.toFixed(2)
            )
          );
        }

        if (floors[selectedIndex].description) {
          setFloorDescription(
            floors[selectedIndex].description
          );
        }

        if (floors[selectedIndex].other_details_comments) {
          setOtherDetailsComments(
            floors[selectedIndex].other_details_comments
          );
        }
      }
    },
    [
      selectedIndex,
      floors
    ]
  );

  const handleFloorEdits = () => {

    if (floors.length > 0) {
      const newFloor = {
        identifier: floors[selectedIndex].identifier,
        area: floorArea,
        u_value: existingUValue,
        description: floorDescription,
        type_id: floorTypeId,
        existing_insulation_type: existingInsulationType,
        existing_insulation_thickness: existingInsulationThickness,
        proposed_additional_insulation_type: proposedInsulationType,
        proposed_additional_insulation_thickness: proposedInsulationThickness,
        proposed_additional_insulation_thermal_conductivity: proposedInsulationThermalConductivity,
        requirements_for_beh_wall_grant: floors[selectedIndex].requirements_for_beh_wall_grant,
        other_details_comments: otherDetailsComments,
        proposed_additional_insulation_location: proposedInsulationLocation,
        scenario_u_value: scenarioUValue
      };

      const newFloors = [...floors];

      newFloors[selectedIndex] = newFloor;

      setFloors(newFloors);

      return newFloors;
    }

    return [];
  };

  const handleListItemClick = (
    _event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    if (floors.length > 0) {

      const latestFloors = handleFloorEdits();

      setSelectedIndex(index);

      setFloorDescription(
        latestFloors[index].description
      );
      setFloorArea(
        Number(
          latestFloors[index].area.toFixed(2)
        )
      );
      setFloorTypeId(
        latestFloors[index].type_id
      );
      setExistingInsulationType(
        latestFloors[index].existing_insulation_type
      );
      setOtherDetailsComments(
        latestFloors[index].other_details_comments
      );
      setExistingInsulationThickness(
        latestFloors[index].existing_insulation_thickness
      );
      setExistingUValue(
        Number(
          latestFloors[index].u_value.toFixed(2)
        )
      );
      setUValueBasis(
        String(
          latestFloors[index].u_value === latestFloors[index].default_u_value
        )
      );
      setProposedInsulationType(
        latestFloors[index].proposed_additional_insulation_type
      );
      setProposedInsulationThickness(
        Number(
          latestFloors[index].proposed_additional_insulation_thickness.toFixed(2)
        )
      );
      setProposedInsulationLocation(
        latestFloors[index].proposed_additional_insulation_location
      );
      setProposedInsulationThermalConductivity(
        latestFloors[index].proposed_additional_insulation_thermal_conductivity
      );
      setScenarioUValue(
        Number(
          latestFloors[index].scenario_u_value.toFixed(2)
        )
      );
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleUpdateTAF = (dialogNumber: number) => {

    const latestFloors = handleFloorEdits();

    const requestBody: UpdateTAFApiRequest = {
      technical_assessment_form_id: taf.id,
      floors: latestFloors
    };

    updateTAF(
      requestBody
    ).then(
      (response) => {
        if (response) {
          setTafStep(dialogNumber);
        }
      }
    ).catch(
      (error) => console.log("Error while updating TAF:", error)
    );
  };

  const handleBackButtonClick = () => {
    handleUpdateTAF(4);
  };

  const handleNextButtonClick = () => {
    handleUpdateTAF(6);
  };

  const handleSliderChanged = (dialogNumber: number) => {
    handleUpdateTAF(dialogNumber);
  };

  const outlinedInputReducedPadding = {
    style: {
      padding: "8px"
    }
  };

  const contentSectionHeaderText = {
    color: "#71717a"
  };

  const inputLabelVariableWidth = {
    display: "flex",
    alignItems: "center"
  };

  const labelAndInputBox = {
    display: "flex"
  };

  const outlinedInputStyle = {
    flex: 1,
    marginLeft: "24px",
    backgroundColor: "white"
  };

  const outlinedInputStyleDropdown = {
    flex: 1,
    marginLeft: "24px",
    backgroundColor: "white",
    paddingLeft: "8px",
    paddingRight: "8px",
    overflowWrap: "break-word",
    width: "10rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  };

  const sectionTitle = {
    marginTop: "8px"
  };

  return (
    <Dialog
      open={showModal}
      onClose={closeModal}
      maxWidth="lg"
      fullWidth
      scroll="paper"
      sx={{
        padding: "16px"
      }}
    >
      <TechnicalAssessmentFormHeader
        changeTafStep={handleSliderChanged}
        closeModal={closeModal}
        tafStep={tafStep}
      />

      <DialogContent
        sx={{
          padding: "0px 8px 0px 8px"
        }}
      >

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "5px",
            backgroundColor: "#e0dede",
            padding: "0px 8px 8px 8px"
          }}
        >

          {/* SECTION TITLE */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "24px",
              paddingTop: "24px",
              paddingRight: "24px",
              paddingBottom: "16px"
            }}
          >
            <img
              alt="section title"
              style={{
                width: "60px",
                paddingRight: "16px"
              }}
              src="https://rk-staging-static.s3.eu-west-1.amazonaws.com/static/public/images/housing_upgrade_plans/icon-floor.svg"
            />

            <Typography
              sx={{
                display: "flex",
                alignItems: "center"
              }}
              variant="h5"
            >
              Fabric Upgrades: Floors
            </Typography>

          </Box>

          {
            floors.length > 0 ? <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                borderStyle: "solid",
                borderColor: theme.palette.primary.main,
                borderRadius: "3px",
                borderWidth: "1px",
                backgroundColor: "white"
              }}
            >
              <List
                component="nav"
                sx={{
                  backgroundColor: "#ffffff"
                }}
                aria-label="floors"
                subheader={
                  <ListSubheader
                    component="div"
                    id="nested-list-subheader"
                  >
                    Floor #
                  </ListSubheader>
                }
              >
                {
                  floors.map(
                    (_floor, index) =>
                      <ListItem
                        disablePadding
                        key={index}
                        onClick={
                          (event) => handleListItemClick(event, index)
                        }
                      >

                        <ListItemButton
                          selected={selectedIndex === index}
                        >

                          <ListItemText
                            primary={index + 1}
                          />

                        </ListItemButton>

                      </ListItem>
                  )
                }
              </List>

              <Box
                sx={{
                  flex: 1,
                  backgroundColor: theme.palette.action.selected,
                  padding: "8px"
                }}
              >

                {/* SECTION DESCRIPTION */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "8px",
                    paddingRight: "24px",
                    borderRadius: "5px",
                    border: 1,
                    borderColor: "#71717a",
                    padding: "16px",
                    gap: "8px"
                  }}
                >

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row"
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      Description
                    </Typography>

                    <OutlinedInput
                      sx={outlinedInputStyle}
                      placeholder=""
                      inputProps={outlinedInputReducedPadding}
                      value={floorDescription}
                      onChange={
                        e => {
                          setFloorDescription(e.target.value);
                        }
                      }
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row"
                    }}
                  >

                    <Box
                      sx={{
                        display: "flex",
                        flex: 1,
                        paddingRight: "20px"
                      }}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Area
                      </Typography>

                      <WhiteBackgroundTextField
                        type="number"
                        variant="outlined"
                        InputProps={{
                          startAdornment: <InputAdornment
                            position="start"
                          >
                            m&#178;
                          </InputAdornment>,
                          inputProps: {
                            min: 0,
                            step: "0.01"
                          }
                        }}
                        onChange={
                          (event) => {
                            if (
                              event.target.value === "" ||
                              floatRegex.test(event.target.value)
                            ) {
                              setFloorArea(
                                Number(
                                  event.target.value
                                )
                              );
                            }
                          }
                        }
                        onKeyDown={floatKeyCheck}
                        value={floorArea}
                        sx={{
                          flex: 1,
                          marginLeft: "24px"
                        }}
                      />

                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        paddingLeft: "20px",
                        flex: 1
                      }}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Total heat loss area shown in BER
                      </Typography>

                      <Typography
                        sx={{
                          marginLeft: "16px",
                          flex: 1,
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center"
                        }}
                      >
                        {
                          totalHeatLossArea.toFixed(2)
                        } m&#178;
                      </Typography>

                    </Box>

                  </Box>

                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    padding: "8px 0px 8px 0px"
                  }}
                >
                  {/* SECTION CURRENT */}
                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "5px",
                      border: 1,
                      borderColor: "#71717a",
                      padding: "0px 16px 16px 16px",
                      gap: "8px"
                    }}
                  >

                    <Box
                      sx={sectionTitle}
                    >
                      <Typography
                        align="center"
                        variant="h5"
                        sx={contentSectionHeaderText}
                      >
                        Current (as per published BER)
                      </Typography>

                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Type
                      </Typography>

                      <Select
                        sx={outlinedInputStyleDropdown}
                        inputProps={{
                          sx: {
                            padding: "8px"
                          }
                        }}
                        value={String(floorTypeId)}
                        label=""
                        onChange={
                          e => setFloorTypeId(Number(e.target.value))
                        }
                        variant="outlined"
                      >
                        {
                          floorTypeOptions.map(
                            (heatingOption) => <MenuItem
                              value={heatingOption.value}
                              key={heatingOption.label}
                            >
                              {heatingOption.label}
                            </MenuItem>
                          )
                        }
                      </Select>

                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Insulation type
                      </Typography>

                      <Select
                        sx={outlinedInputStyleDropdown}
                        inputProps={{
                          sx: {
                            padding: "8px"
                          }
                        }}
                        value={String(existingInsulationType)}
                        label=""
                        onChange={
                          event => setExistingInsulationType(
                            Number(event.target.value)
                          )
                        }
                        variant="outlined"
                      >
                        {
                          insulationTypeOptions.map(
                            (heatingOption) => <MenuItem
                              value={heatingOption.value}
                              key={heatingOption.label}
                            >
                              {heatingOption.label}
                            </MenuItem>
                          )
                        }
                      </Select>

                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Insulation thickness
                      </Typography>

                      <WhiteBackgroundTextField
                        type="number"
                        variant="outlined"
                        InputProps={{
                          startAdornment: <InputAdornment
                            position="start"
                          >
                            mm
                          </InputAdornment>,
                          inputProps: {
                            min: 0,
                            step: "0.01"
                          }
                        }}
                        onChange={
                          (event) => {
                            if (
                              event.target.value === "" ||
                              floatRegex.test(event.target.value)
                            ) {
                              setExistingInsulationThickness(
                                Number(
                                  event.target.value
                                )
                              );
                            }
                          }
                        }
                        onKeyDown={floatKeyCheck}
                        value={existingInsulationThickness}
                        sx={{
                          flex: 1,
                          marginLeft: "24px"
                        }}
                      />

                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        U-value
                      </Typography>

                      <WhiteBackgroundTextField
                        type="number"
                        variant="outlined"
                        InputProps={{
                          startAdornment: <InputAdornment
                            position="start"
                          >
                            W/m&#178;K
                          </InputAdornment>,
                          inputProps: {
                            min: 0,
                            step: "0.01"
                          }
                        }}
                        onChange={
                          (event) => {
                            if (
                              event.target.value === "" ||
                              floatRegex.test(event.target.value)
                            ) {
                              setExistingUValue(
                                Number(
                                  event.target.value
                                )
                              );
                            }
                          }
                        }
                        onKeyDown={floatKeyCheck}
                        value={existingUValue}
                        sx={{
                          flex: 1,
                          marginLeft: "24px"
                        }}
                      />

                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        U-value basis
                      </Typography>

                      <Typography
                        sx={{
                          marginLeft: "16px",
                          flex: 1,
                          textAlign: "right"
                        }}
                      >
                        {
                          uValueBasis === "true" ? "Default" : "Non default"
                        }

                      </Typography>

                    </Box>

                  </Box>

                  {/* SECTION PROPOSED */}
                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "5px",
                      border: 1,
                      borderColor: "#71717a",
                      padding: "0px 16px 16px 16px",
                      gap: "8px"
                    }}
                  >

                    <Box
                      sx={sectionTitle}
                    >
                      <Typography
                        align="center"
                        variant="h5"
                        sx={contentSectionHeaderText}
                      >
                        Proposed upgrade
                      </Typography>

                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Insulation Type
                      </Typography>

                      <Select
                        sx={outlinedInputStyleDropdown}
                        inputProps={{
                          sx: {
                            padding: "8px"
                          }
                        }}
                        value={String(proposedInsulationType)}
                        label=""
                        onChange={
                          event => {
                            const newValue = Number(event.target.value);
                            setProposedInsulationType(
                              newValue
                            )
                            setProposedInsulationThermalConductivity(
                              newValue
                            )
                          }
                        }
                        variant="outlined"
                      >
                        {
                          insulationTypeOptions.map(
                            (heatingOption) => <MenuItem
                              value={heatingOption.value}
                              key={heatingOption.label}
                            >
                              {heatingOption.label}
                            </MenuItem>
                          )
                        }
                      </Select>

                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Insulation Thickness
                      </Typography>

                      <WhiteBackgroundTextField
                        type="number"
                        variant="outlined"
                        InputProps={{
                          startAdornment: <InputAdornment
                            position="start"
                          >
                            mm
                          </InputAdornment>,
                          inputProps: {
                            min: 0,
                            step: "0.01"
                          }
                        }}
                        onChange={
                          (event) => {
                            if (
                              event.target.value === "" ||
                              floatRegex.test(event.target.value)
                            ) {
                              setProposedInsulationThickness(
                                Number(
                                  event.target.value
                                )
                              );
                            }
                          }
                        }
                        onKeyDown={floatKeyCheck}
                        value={proposedInsulationThickness}
                        sx={{
                          flex: 1,
                          marginLeft: "24px"
                        }}
                      />

                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Insulation Location
                      </Typography>

                      <Select
                        sx={outlinedInputStyleDropdown}
                        inputProps={{
                          sx: {
                            padding: "8px"
                          }
                        }}
                        value={String(proposedInsulationLocation)}
                        label=""
                        onChange={
                          e => setProposedInsulationLocation(Number(e.target.value))
                        }
                        variant="outlined"
                      >
                        {
                          floorInsulationLocationOptions.map(
                            (heatingOption) => <MenuItem
                              value={heatingOption.value}
                              key={heatingOption.label}
                            >
                              {heatingOption.label}
                            </MenuItem>
                          )
                        }
                      </Select>

                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Insulation thermal conductivity
                      </Typography>

                      <Select
                        sx={outlinedInputStyleDropdown}
                        inputProps={{
                          sx: {
                            padding: "8px"
                          }
                        }}
                        value={
                          String(proposedInsulationThermalConductivity)
                        }
                        label=""
                        onChange={
                          (event) => setProposedInsulationThermalConductivity(
                            Number(event.target.value)
                          )
                        }
                        variant="outlined"
                      >
                        {
                          thermalConductivityOptions.map(
                            (heatingOption) => <MenuItem
                              value={heatingOption.value}
                              key={heatingOption.label}
                            >
                              {heatingOption.label}
                            </MenuItem>
                          )
                        }
                      </Select>

                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        U-value after upgrade
                      </Typography>

                      <WhiteBackgroundTextField
                        type="number"
                        variant="outlined"
                        InputProps={{
                          startAdornment: <InputAdornment
                            position="start"
                          >
                            W/m&#178;K
                          </InputAdornment>,
                          inputProps: {
                            min: 0,
                            step: "0.01"
                          }
                        }}
                        onChange={
                          (event) => {
                            if (
                              event.target.value === "" ||
                              floatRegex.test(event.target.value)
                            ) {
                              setScenarioUValue(
                                Number(
                                  event.target.value
                                )
                              );
                            }
                          }
                        }
                        onKeyDown={floatKeyCheck}
                        value={scenarioUValue}
                        sx={{
                          flex: 1,
                          marginLeft: "24px"
                        }}
                      />

                    </Box>

                  </Box>

                </Box>

                {/* SECTION OTHER DETAILS */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "5px",
                    border: 1,
                    borderColor: "#71717a",
                    padding: "16px",
                    gap: "8px"
                  }}
                >

                  <Typography>
                    Other details/comments (include other info for contractor here)
                  </Typography>

                  <TextField
                    sx={{
                      flex: 1,
                      backgroundColor: "white",
                      width: "100%"
                    }}
                    multiline
                    value={otherDetailsComments}
                    onChange={
                      event => setOtherDetailsComments(
                        event.target.value
                      )
                    }
                  />

                </Box>

              </Box>

            </Box> : <Box
              sx={{
                flex: 1,
                padding: "24px",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Typography>
                This dwelling has no floors
              </Typography>

            </Box>
          }

        </Box>
        
      </DialogContent>

      <DialogActions>

        <DwellingAndScenarioBerGrades
          dwellingDetail={
            String(taf.dwelling_mprn_reference)
          }
          dwellingRating={taf.dwelling_rating}
          scenarioDetail={taf.scenario_title}
          scenarioRating={taf.scenario_rating}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleBackButtonClick}
        >
          Back
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleNextButtonClick}
        >
          Next
        </Button>

      </DialogActions>

    </Dialog>
  );
}
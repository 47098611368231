import React, {
  useCallback,
  useEffect,
  useState
} from "react";

import { format } from "date-fns";

import {
  Add as AddIcon,
  SortByAlpha as SortByAlphaIcon,
  Search as SearchIcon,
  Sort as SortIcon,
  Stop as StopIcon
} from "@mui/icons-material";
import { Autocomplete, colors } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import type { MinimalProject } from "features/projects/types";


type ProjectDrawerProps = {
  onAddProjectClick: () => void;
  onClose: () => void;
  open: boolean;
  projects: Array<MinimalProject>;
  setSelectedProject: (project: MinimalProject) => void;
};

const drawerWidth = 350;


export function ProjectsDrawer(
  {
    onAddProjectClick,
    onClose,
    open,
    projects,
    setSelectedProject
  }: ProjectDrawerProps
) {

  const theme = useTheme();

  const [
    sortedProjects,
    setSortedProjects
  ] = useState<Array<MinimalProject>>(
    projects.slice()
  );

  const [
    selectedIndex,
    setSelectedIndex
  ] = useState<number>();

  const [
    showSearchBox,
    setShowSearchBox
  ] = useState<boolean>(false);

  const handleProjectSelected = (project: MinimalProject) => {
    setSelectedIndex(project.id);
    setSelectedProject(project);
  };

  const handleDrawerClose = () => {
    onClose();
  };

  const [
    sortBy,
    setSortBy
  ] = useState<string>(
    "alpha+"
  );

  const handleSortByAlpha = () => {
    if (sortBy !== "alpha+") {
      setSortBy("alpha+");
    } else {
      setSortBy("alpha-");
    }
  };

  const handleSortByDate = () => {
    if (sortBy !== "date+") {
      setSortBy("date+");
    } else {
      setSortBy("date-");
    }
  };

  const sortProjects = useCallback(() => {
      let sorted = projects.slice().sort(
        (projectA, projectB) => {
          if (projectA.title.toLowerCase() < projectB.title.toLowerCase()) {
            return -1;
          }
          if (projectA.title.toLowerCase() > projectB.title.toLowerCase()) {
            return 1;
          }
          return 0;
        }
      );
      if (sortBy === "date-") {
        sorted = projects.slice().sort(
          (projectA, projectB) =>
            projectA.created_on.getTime() - projectB.created_on.getTime()
        );
      }
      if (sortBy === "date+") {
        sorted = projects.slice().sort(
          (projectA, projectB) =>
            projectB.created_on.getTime() - projectA.created_on.getTime()
        );
      }
      if (sortBy === "alpha-") {
        sorted = projects.slice().sort(
          (projectA, projectB) => {
            if (projectA.title.toLowerCase() > projectB.title.toLowerCase()) {
              return -1;
            }
            if (projectA.title.toLowerCase() < projectB.title.toLowerCase()) {
              return 1;
            }
            return 0;
          }
        );
      }
      setSortedProjects(sorted);
    },
    [
      projects,
      sortBy
    ]
  );

  // This runs every time sortBy or projects changes.
  // NOTE: Adding the sortProjects function to
  // the dependencies here causes a memory
  // leak.
  useEffect(
    () => {
      sortProjects();
    },
    [
      sortBy,
      projects
    ]
  );

  // This only runs on first render.
  // This is because it has no dependencies
  useEffect(
    () => {
      sortProjects();
    },
    []
  );

  return <Collapse
    orientation="horizontal"
    in={open}
    sx={{
      zIndex: 1150,
      position: "absolute",
      height: {
        xs: `calc(100vh - ${56}px)`,
        sm: `calc(100vh - ${64}px)`,
        md: `calc(100vh - ${64}px)`,
        lg: `calc(100vh - ${64}px)`,
        xl: `calc(100vh - ${64}px)`
      }
    }}
  >


    <Box
      sx={{
        height: "100%",
        width: drawerWidth,
        flexShrink: 0,
        backgroundColor: "#FFFFFF",
        borderRight: "1px solid #dadce0",
        display: "flex",
        flexDirection: "column"
      }}
    >

      {
        showSearchBox ? <Box
          sx={{
            padding: "8px",
            width: "100%"
          }}
        >
          <Autocomplete
            autoFocus
            disablePortal
            clearOnEscape
            freeSolo
            options={
              projects.map(project => project.title)
            }
            sx={{
              width: "100%"
            }}
            renderInput={
              (params) => <TextField
                {...params}
                label="Project name"
                inputRef={
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
                  input => input && input.focus()
                }
              />
            }
            onChange={
              (_event, newValue) => {
                const selectedProject = sortedProjects.find(
                  project => project.title === newValue
                );
                if (selectedProject !== undefined) {
                  setSelectedIndex(selectedProject.id);
                  setSelectedProject(selectedProject);
                  setShowSearchBox(false);
                  handleDrawerClose();
                }
              }
            }
          />
        </Box> : <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingBottom: "8px",
            paddingTop: "8px"
          }}
        >

          <Typography
            variant="h6"
            style={{
              fontWeight: 400,
              fontSize: 18,
              alignContent: "center"
            }}
          >
            Projects
          </Typography>

          <Box
            sx={{
              flex: 1
            }}
          />

          <Tooltip
            title="Search"
          >
            <IconButton
              aria-label="search"
              onClick={
                () => {
                  setShowSearchBox(true);
                }
              }
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>

          <Tooltip
            title="Sort alphabetically"
          >
            <IconButton
              aria-label="sort-by-alpha"
              onClick={
                () => {
                  handleSortByAlpha();
                }
              }
            >
              <SortByAlphaIcon />
            </IconButton>
          </Tooltip>

          <Tooltip
            title="Sort by date"
          >
            <IconButton
              aria-label="sort-by-date"
              onClick={
                () => {
                  handleSortByDate();
                }
              }
            >
              <SortIcon />
            </IconButton>
          </Tooltip>

          <Tooltip
            title="Add new"
          >
            <IconButton
              aria-label="add-new"
              onClick={
                () => onAddProjectClick()
              }
            >
              <AddIcon
                sx={{
                  color: theme.retrokitPalette.green.dark
                }}
              />
            </IconButton>
          </Tooltip>

        </Box>
      }

      <Divider />

      {
        projects.length === 0 ? <Tooltip
          title="Create a new project"
          placement="right"
        >
          <Box
            sx={{
              padding: "8px"
            }}
            onClick={
              () => {
                if (showSearchBox) {
                  setShowSearchBox(false);
                }
              }
            }
          >
            <Button
              sx={{
                width: "100%"
              }}
              variant="outlined"
              color="secondary"
              endIcon={
                <AddIcon />
              }
              onClick={
                () => onAddProjectClick()
              }
            >
              Create Project
            </Button>
          </Box>
        </Tooltip> : <Box
          sx={{
            flex: 1,
            overflowX: "hidden",
            overflowY: "auto"
          }}
          onClick={
            () => {
              if (showSearchBox) {
                setShowSearchBox(false);
              }
            }
          }
        >
          <List
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
              "&& .Mui-selected": {
                bgcolor: colors.indigo["100"]
              }
            }}
          >
            {
              sortedProjects.map(
                (project) => (
                  <Box
                    key={project.id}
                    sx={{
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    <Tooltip
                      title={project.description}
                      placement="right"
                    >
                      <ListItem
                        disablePadding
                        onClick={
                          () => {
                            handleProjectSelected(project);
                            setShowSearchBox(false);
                            handleDrawerClose();
                          }
                        }
                      >
                        <ListItemButton
                          selected={project.id === selectedIndex}
                        >
                          <ListItemAvatar>
                            <Avatar>
                              <StopIcon />
                            </Avatar>
                          </ListItemAvatar>

                          <ListItemText
                            primary={project.title}
                            secondary={
                              String(
                                format(
                                  project.created_on,
                                  "do MMM yyyy"
                                )
                              )
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    </Tooltip>
                    <Divider
                      sx={{
                        marginLeft: "24px",
                        marginRight: "24px"
                      }}
                    />
                  </Box>
                )
              )
            }

          </List>
        </Box>
      }

    </Box>

  </Collapse>;
}

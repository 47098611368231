import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";

import { Select } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { 
  DwellingAndScenarioBerGrades
} from "common/components/DwellingAndScenarioBerGrades";
import { 
  WhiteBackgroundTextField 
} from "common/components/WhiteBackgroundTextField";
import {
  ventilationDuctingOptions,
  ventilationMethodOptions
} from "common/maps/dwelling";
import {
  floatKeyCheck, 
  floatRegex
} from "common/utils/numbers";
import {
  TechnicalAssessmentFormHeader
} from "features/technicalAssessmentForm/dialogs/common/TechnicalAssessmentFormHeader";

import type {
  UpdateTAFApiRequest
} from "features/technicalAssessmentForm/api/types";
import type {
  TechnicalAssessmentForm
} from "features/technicalAssessmentForm/types";



interface TechnicalAssessmentFormModal10Props {
  isTafUpdating: boolean;
  setShowModal: (show: boolean) => void;
  setTafStep: (newStep: number) => void;
  showModal: boolean;
  taf: TechnicalAssessmentForm;
  tafStep: number;
  updateTAF: (variable: UpdateTAFApiRequest) => Promise<TechnicalAssessmentForm>;
}


export function TechnicalAssessmentFormModal10(
  {
    isTafUpdating,
    setShowModal,
    setTafStep,
    showModal,
    taf,
    tafStep,
    updateTAF
  }: TechnicalAssessmentFormModal10Props
) {

  const [
    otherDetailsComments,
    setOtherDetailsComments
  ] = useState<string>('');

  const [
    ventilationMethod,
    setVentilationMethod
  ] = useState<number>(0);

  const [
    ductingIfMechVentPresent,
    setDuctingIfMechVentPresent
  ] = useState<number>(0);

  const [
    proposedVentilationMethod,
    setProposedVentilationMethod
  ] = useState<number>(0);

  const [
    proposedDuctingIfMechVentPresent,
    setProposedDuctingIfMechVentPresent
  ] = useState<number>(0);

  const [
    specificFanPower,
    setSpecificFanPower
  ] = useState<number>(0);

  const [
    proposedSpecificFanPower,
    setProposedSpecificFanPower
  ] = useState<number>(0);

  const [
    heatExchangerEff,
    setHeatExchangerEff
  ] = useState<number>(0);

  const [
    proposedHeatExchangerEff,
    setProposedHeatExchangerEff
  ] = useState<number>(0);

  useEffect(
    () => {

      setProposedHeatExchangerEff(
        Number(
          taf.system.proposed_ventilation_heat_exchanger_eff.toFixed(2)
        )
      )

      setHeatExchangerEff(
        Number(
          taf.system.ventilation_heat_exchanger_eff.toFixed(2)
        )
      )

      setProposedSpecificFanPower(
        Number(
          taf.system.proposed_ventilation_fan_power_manu_declared_value.toFixed(2)
        )
      )

      setSpecificFanPower(
        Number(
          taf.system.ventilation_fan_power_manu_declared_value.toFixed(2)
        )
      )

      setProposedDuctingIfMechVentPresent(
        taf.system.proposed_ventilation_ducting
      )

      setProposedVentilationMethod(
        taf.system.proposed_ventilation_method
      )

      setDuctingIfMechVentPresent(
        taf.system.ventilation_ducting
      )

      setVentilationMethod(
        taf.system.ventilation_method
      )

      setOtherDetailsComments(
        taf.other_details_comments_mechanical_ventilation
      )
    },
    [
      taf.system.ventilation_ducting,
      taf.other_details_comments_mechanical_ventilation,
      taf.system.proposed_ventilation_ducting,
      taf.system.proposed_ventilation_fan_power_manu_declared_value,
      taf.system.proposed_ventilation_heat_exchanger_eff,
      taf.system.proposed_ventilation_method,
      taf.system.ventilation_fan_power_manu_declared_value,
      taf.system.ventilation_heat_exchanger_eff,
      taf.system.ventilation_method
    ]
  );


  const closeModal = () => {
    setShowModal(false);
  };

  const handleUpdateTAF = (dialogNumber: number) => {

    if (dialogNumber === 10) {
      toast.dismiss();
      toast.info(`Generating Technical Assessment Form`, {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored",
        autoClose: false
      });
    }

    const requestBody: UpdateTAFApiRequest = {
      technical_assessment_form_id: taf.id,
      is_last_step: dialogNumber === 10,
      other_details_comments_mechanical_ventilation: otherDetailsComments,
      system: {
        ventilation_method: ventilationMethod,
        ventilation_ducting: ductingIfMechVentPresent,
        ventilation_heat_exchanger_eff: heatExchangerEff,
        ventilation_fan_power_manu_declared_value: specificFanPower,
        proposed_ventilation_method: proposedVentilationMethod,
        proposed_ventilation_ducting: proposedDuctingIfMechVentPresent,
        proposed_ventilation_fan_power_manu_declared_value: proposedSpecificFanPower,
        proposed_ventilation_heat_exchanger_eff: proposedHeatExchangerEff
      }
    };

    updateTAF(
      requestBody
    ).then(
      (response: TechnicalAssessmentForm) => {
        if (response && dialogNumber === 10) {
          if (response.filename !== undefined && response.url !== undefined) {
            const link = document.createElement("a");
            link.download = response.filename;

            if (response.url.includes('http')) {
              link.href = response.url;
            }
            else {
              link.href = `${process.env.REACT_APP_API_BASE_URL}${response.url}`;
            }
            link.href = response.url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            toast.dismiss();

            setShowModal(false);
          }
        }
        else if (response) {
          setTafStep(dialogNumber)
        }
      }
    ).catch(
      (error) => console.log("Error while updating TAF:", error)
    );
  }

  const handleBackButtonClick = () => {
    handleUpdateTAF(9);
  };

  const handleNextButtonClick = () => {
    handleUpdateTAF(10);
  };

  const handleSliderChanged = (dialogNumber: number) => {
    handleUpdateTAF(dialogNumber);
  }

  const contentSectionHeaderText = {
    color: "#71717a"
  }

  const labelAndInputBox = {
    display: "flex"
  }

  const inputLabelVariableWidth = {
    display: "flex",
    alignItems: "center"
  }

  const outlinedInputStyleDropdown = {
    flex: 1,
    marginLeft: "24px",
    backgroundColor: "white",
    paddingLeft: "8px",
    paddingRight: "8px",
    overflowWrap: "break-word",
    width: "10rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }

  const sectionTitle = {
    marginTop: "8px"
  }

  return (
    <Dialog
      open={showModal}
      onClose={closeModal}
      maxWidth="lg"
      fullWidth
      scroll="paper"
      sx={{
        padding: "16px"
      }}
    >
      <TechnicalAssessmentFormHeader
        changeTafStep={handleSliderChanged}
        closeModal={closeModal}
        tafStep={tafStep}
      />

      <DialogContent
        sx={{
          padding: "0px 8px 0px 8px"
        }}
      >

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "5px",
            backgroundColor: "#e0dede",
            padding: "0px 8px 8px 8px"
          }}
        >

          {/* SECTION TITLE */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "24px",
              paddingTop: "24px",
              paddingRight: "24px",
              paddingBottom: "16px"
            }}
          >

            <img
              alt="section title"
              style={{
                width: "60px",
                paddingRight: "16px"
              }}
              src="https://rk-staging-static.s3.eu-west-1.amazonaws.com/static/public/images/housing_upgrade_plans/icon-ventilation.svg"
            />

            <Typography
              sx={{
                display: "flex",
                alignItems: "center"
              }}
              variant="h5"
            >
              Mechanical Ventilation Upgrades
            </Typography>

          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              paddingBottom: "8px"
            }}
          >

            {/* SECTION CURRENT */}
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                borderRadius: "5px",
                border: 1,
                borderColor: "#71717a",
                padding: "0px 16px 16px 16px",
                gap: "8px"
              }}
            >

              <Box
                sx={sectionTitle}
              >

                <Typography
                  align="center"
                  variant="h6"
                  sx={contentSectionHeaderText}
                >
                  Current (as per published BER)
                </Typography>

              </Box>

              <Box
                sx={labelAndInputBox}
              >

                <Typography
                  sx={inputLabelVariableWidth}
                >
                  Ventilation method
                </Typography>

                <Select
                  sx={outlinedInputStyleDropdown}
                  inputProps={{
                    sx: {
                      padding: "8px"
                    }
                  }}
                  value={ventilationMethod}
                  label=""
                  onChange={
                    e => setVentilationMethod(Number(e.target.value))
                  }
                  variant="outlined"
                >
                  {
                    ventilationMethodOptions.map(
                      (methodOption) => <MenuItem
                        value={methodOption.value}
                        key={methodOption.value}
                      >
                        {methodOption.label}
                      </MenuItem>
                    )
                  }
                </Select>

              </Box>

              <Box
                sx={labelAndInputBox}
              >

                <Typography
                  sx={inputLabelVariableWidth}
                >
                  Ducting if mech. ventilation present
                </Typography>

                <Select
                  sx={outlinedInputStyleDropdown}
                  inputProps={{
                    sx: {
                      padding: "8px"
                    }
                  }}
                  value={String(ductingIfMechVentPresent)}
                  label=""
                  onChange={
                    e => setDuctingIfMechVentPresent(Number(e.target.value))
                  }
                  variant="outlined"
                >
                  {
                    ventilationDuctingOptions.map(
                      (ductingOption) => <MenuItem
                        value={ductingOption.value}
                        key={ductingOption.value}
                      >
                        {ductingOption.label}
                      </MenuItem>
                    )
                  }
                </Select>
              </Box>

              <Box
                sx={labelAndInputBox}
              >
                <Typography
                  sx={inputLabelVariableWidth}
                >
                  Specific fan power
                </Typography>

                <WhiteBackgroundTextField
                  type="number"
                  variant="outlined"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      step: "0.01"
                    }
                  }}
                  onChange={
                    (event) => {
                      if (
                        event.target.value === "" ||
                        floatRegex.test(event.target.value)
                      ) {
                        setSpecificFanPower(
                          Number(
                            event.target.value
                          )
                        );
                      }
                    }
                  }
                  onKeyDown={floatKeyCheck}
                  value={specificFanPower}
                  sx={{
                    flex: 1,
                    marginLeft: "24px"
                  }}
                />

              </Box>

              <Box
                sx={labelAndInputBox}
              >
                <Typography
                  sx={inputLabelVariableWidth}
                >
                  Heat exchanger efficiency
                </Typography>

                <WhiteBackgroundTextField
                  type="number"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment
                      position="start"
                    >
                      %
                    </InputAdornment>,
                    inputProps: {
                      min: 0,
                      step: 1
                    }
                  }}
                  onChange={
                    (event) => {
                      if (
                        event.target.value === "" ||
                        floatRegex.test(event.target.value)
                      ) {
                        setHeatExchangerEff(
                          Number(
                            event.target.value
                          )
                        );
                      }
                    }
                  }
                  onKeyDown={floatKeyCheck}
                  value={heatExchangerEff}
                  sx={{
                    flex: 1,
                    marginLeft: "24px"
                  }}
                />

              </Box>

            </Box>

            {/* SECTION PROPOSED */}
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                borderRadius: "5px",
                border: 1,
                borderColor: "#71717a",
                padding: "0px 16px 16px 16px",
                gap: "8px"
              }}
            >
              <Box
                sx={sectionTitle}
              >
                <Typography
                  align="center"
                  variant="h6"
                  sx={contentSectionHeaderText}
                >
                  Proposed upgrade
                </Typography>
              </Box>

              <Box
                sx={labelAndInputBox}
              >
                <Typography
                  sx={inputLabelVariableWidth}
                >
                  Ventilation method
                </Typography>

                <Select
                  sx={outlinedInputStyleDropdown}
                  inputProps={{
                    sx: {
                      padding: "8px"
                    }
                  }}
                  value={String(proposedVentilationMethod)}
                  label=""
                  onChange={
                    e => setProposedVentilationMethod(Number(e.target.value))
                  }
                  variant="outlined"
                >
                  {
                    ventilationMethodOptions.map(
                      (heatingOption) => <MenuItem
                        value={heatingOption.value}
                        key={heatingOption.value}
                      >
                        {heatingOption.label}
                      </MenuItem>
                    )
                  }
                </Select>
              </Box>

              <Box
                sx={labelAndInputBox}
              >
                <Typography
                  sx={inputLabelVariableWidth}
                >
                  Ducting if mech. ventilation present
                </Typography>

                <Select
                  sx={outlinedInputStyleDropdown}
                  inputProps={{
                    sx: {
                      padding: "8px"
                    }
                  }}
                  value={String(proposedDuctingIfMechVentPresent)}
                  label=""
                  onChange={
                    e => setProposedDuctingIfMechVentPresent(
                      Number(e.target.value)
                    )
                  }
                  variant="outlined"
                >
                  {
                    ventilationDuctingOptions.map(
                      (ductingOption) => <MenuItem
                        value={ductingOption.value}
                        key={ductingOption.value}
                      >
                        {ductingOption.label}
                      </MenuItem>
                    )
                  }
                </Select>
              </Box>

              <Box
                sx={labelAndInputBox}
              >
                <Typography
                  sx={inputLabelVariableWidth}
                >
                  Specific fan power
                </Typography>

                <WhiteBackgroundTextField
                  type="number"
                  variant="outlined"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      step: "0.01"
                    }
                  }}
                  onChange={
                    (event) => {
                      if (
                        event.target.value === "" ||
                        floatRegex.test(event.target.value)
                      ) {
                        setProposedSpecificFanPower(
                          Number(
                            event.target.value
                          )
                        );
                      }
                    }
                  }
                  onKeyDown={floatKeyCheck}
                  value={proposedSpecificFanPower}
                  sx={{
                    flex: 1,
                    marginLeft: "24px"
                  }}
                />

              </Box>

              <Box
                sx={labelAndInputBox}
              >
                <Typography
                  sx={inputLabelVariableWidth}
                >
                  Heat exchanger efficiency
                </Typography>

                <WhiteBackgroundTextField
                  type="number"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment
                      position="start"
                    >
                      %
                    </InputAdornment>,
                    inputProps: {
                      min: 0,
                      step: 1
                    }
                  }}
                  onChange={
                    (event) => {
                      if (
                        event.target.value === "" ||
                        floatRegex.test(event.target.value)
                      ) {
                        setProposedHeatExchangerEff(
                          Number(
                            event.target.value
                          )
                        );
                      }
                    }
                  }
                  onKeyDown={floatKeyCheck}
                  value={proposedHeatExchangerEff}
                  sx={{
                    flex: 1,
                    marginLeft: "24px"
                  }}
                />

              </Box>

            </Box>

          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              borderRadius: "5px",
              border: 1,
              borderColor: "#71717a",
              padding: "16px",
              gap: "8px"
            }}
          >
            <Typography>
              Other details/comments (include other info for contractor here)
            </Typography>

            <TextField
              sx={{
                flex: 1,
                backgroundColor: "white",
                width: "100%"
              }}
              multiline
              value={otherDetailsComments}
              onChange={
                event => setOtherDetailsComments(
                  event.target.value
                )
              }
            />

          </Box>

        </Box>

      </DialogContent>

      <DialogActions>

        <DwellingAndScenarioBerGrades
          dwellingDetail={
            String(taf.dwelling_mprn_reference)
          }
          dwellingRating={taf.dwelling_rating}
          scenarioDetail={taf.scenario_title}
          scenarioRating={taf.scenario_rating}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleBackButtonClick}
        >
          Back
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleNextButtonClick}
        >
          Finish
        </Button>

      </DialogActions>

    </Dialog>
  );
}
import React from "react";


import MaterialTable from "@material-table/core";
import MTableToolbar from "@material-table/core/dist/components/MTableToolbar";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import {
  withMaterialUserInterfaceTheme
} from "common/hocs/theme";

class DashboardTable extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      this.props.dwellings !== nextProps.dwellings ||
      this.props.tableColumns !== nextProps.tableColumns ||
      this.props.searchQuery !== nextProps.searchQuery
    );
  }

  render() {
    const {
      theme,
      selectedDwellings
    } = this.props;

    let tableData;
    const pageSizes = [];

    const maximumLength = this.props.dwellings.length;
    if (maximumLength > 50 && maximumLength < 450) {
      pageSizes.push(50);
      pageSizes.push(maximumLength);
    } else if (maximumLength > 450) {
      pageSizes.push(100);
      pageSizes.push(maximumLength);
    } else {
      pageSizes.push(maximumLength);
    }

    if (this.props.searchQuery.length > 0) {
      const queries = this.props.searchQuery.split(",");

      function tableQuerySearch(dwelling) {
        const searchChecks = [];
        queries.forEach((query) => {
          searchChecks.push(
            dwelling.properties.address_line1
              ? dwelling.properties.address_line1.toLowerCase().includes(query)
              : false
          );
          searchChecks.push(
            dwelling.properties.address_line2
              ? dwelling.properties.address_line2.toLowerCase().includes(query)
              : false
          );
          searchChecks.push(
            dwelling.properties.address_line3
              ? dwelling.properties.address_line3.toLowerCase().includes(query)
              : false
          );
          searchChecks.push(
            dwelling.properties.county
              ? dwelling.properties.county.toLowerCase().includes(query)
              : false
          );
          searchChecks.push(
            dwelling.properties.postcode
              ? dwelling.properties.postcode.toLowerCase().includes(query)
              : false
          );
          searchChecks.push(
            dwelling.properties.ber_number
              ? dwelling.properties.ber_number.toString().includes(query)
              : false
          );
          searchChecks.push(
            dwelling.properties.mprn_reference
              ? dwelling.properties.mprn_reference.toString().includes(query)
              : false
          );
          searchChecks.push(
            dwelling.properties.date_of_construction
              ? dwelling.properties.date_of_construction.includes(query)
              : false
          );
          searchChecks.push(
            dwelling.properties.date_of_construction
              ? dwelling.properties.latest_performance.rating.includes(query)
              : false
          );
          searchChecks.push(
            dwelling.properties.date_of_construction
              ? dwelling.properties.latest_archetype.main_fuel.includes(query)
              : false
          );
          searchChecks.push(
            dwelling.properties.date_of_construction
              ? dwelling.properties.latest_archetype.dwelling_type.includes(
                query
              )
              : false
          );
          searchChecks.push(
            dwelling.properties.date_of_construction
              ? dwelling.properties.latest_archetype.main_wall_type.includes(
                query
              )
              : false
          );
          searchChecks.push(
            dwelling.properties.date_of_construction
              ? dwelling.properties.latest_archetype.age_band.includes(query)
              : false
          );
        });

        const result = [];
        result.push(
          searchChecks.some((searchQueryCheck) => searchQueryCheck === true)
        );
        return result.every((searchQueryCheck) => searchQueryCheck === true);
      }

      tableData = this.props.dwellings.filter(tableQuerySearch);
    } else {
      tableData = this.props.dwellings;
    }

    // TODO: Sort by dwelling selected status
    // console.log("tableData BEFORE =", tableData);
    // tableData = tableData.sort(
    //   (dwellingA, dwellingB) => {
    //     const selectedDwellingIds = selectedDwellings.map(
    //       dwelling => dwelling.id
    //     );
    //     console.log("A =", dwellingA.id, ": B =", dwellingB.id);
    //     console.log("selectedDwellingIds =", selectedDwellingIds);
    //     console.log("A =", selectedDwellingIds.includes(dwellingA.id), ": B =", selectedDwellingIds.includes(dwellingB.id));
    //     if (selectedDwellingIds.includes(dwellingA.id)) {
    //       console.log("move", dwellingA.id, "left\n\n");
    //       return -1;
    //     }
    //     if (selectedDwellingIds.includes(dwellingB.id)) {
    //       console.log("move", dwellingA.id, "right\n\n");
    //       return 1;
    //     }
    //     console.log(dwellingA.id, "stay\n\n");
    //     return 0;
    //   }
    // );
    // console.log("tableData AFTER =", tableData);

    return <MaterialTable
      columns={this.props.tableColumns}
      components={{
        Container: props => <Paper
          sx={{
            background: "#FFFFFF",
            width: "100%",
            flex: "1"
          }}
        >
          {props.children}
        </Paper>,
        Toolbar: (props) => <Box
          sx={{
            display: "none",
            height: "0px"
          }}
        >
          <MTableToolbar
            {...props}
          />
        </Box>
      }}
      data={
        tableData
      }
      localization={{
        toolbar: {
          nRowsSelected: "{0} dwelling(s) selected"
        },
        body: {
          emptyDataSourceMessage: "No records to display",
          filterRow: {
            filterTooltip: "Filter"
          }
        }
      }}
      onColumnDragged={this.props.onColumnDragged}
      onOrderCollectionChange={this.props.onOrderCollectionChange}
      onSelectionChange={(rows) => this.props.onSelectionChange(rows)}
      options={{
        columnsButton: true,
        defaultOrderByCollection: this.props.orderByCollection,
        draggable: true,
        headerStyle: {
          backgroundColor: theme.palette.primary.light,
          color: "#ffffff",
          fontWeight: "bold",
          whiteSpace: "nowrap",
          zIndex: theme.zIndex.drawer - 3,
          position: "sticky",
          top: 0
        },
        initialPage: this.props.initialPage,
        minBodyHeight: this.props.height,
        maxBodyHeight: this.props.height,
        maxColumnSort: 2,
        padding: "dense",
        pageSize: pageSizes[0],
        pageSizeOptions: pageSizes,
        rowStyle: (rowData, index) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (rowData.tableData.checked === true) {
            return {
              backgroundColor: "#fce4ec",
              color: theme.palette.secondary.main,
              fontWeight: "bold",
              whiteSpace: "nowrap"
            };
          }
          if (index % 2) {
            return {
              backgroundColor: "#EEEEEE",
              whiteSpace: "nowrap"
            };
          }
          return {
            whiteSpace: "nowrap"
          };

        },
        searchFieldAlignment: "right",
        search: false,
        selection: true,
        showFirstLastPageButtons: true,
        showSelectAllCheckbox: true,
        showTextRowsSelected: true,
        showTitle: false,
        sorting: selectedDwellings.length > 0 ? false : true
      }}
    />;
  }
}

DashboardTable.propTypes = {
  dwellings: PropTypes.array.isRequired,
  initialPage: PropTypes.number.isRequired,
  tableColumns: PropTypes.array.isRequired,
  searchQuery: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  onColumnDragged: PropTypes.func.isRequired,
  orderByCollection: PropTypes.object.isRequired,
  onOrderCollectionChange: PropTypes.func.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  selectedDwellings: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired
};

export default withMaterialUserInterfaceTheme(DashboardTable);

import React, { useEffect, useMemo, useRef, useState } from "react";

import {
  ArrowDownward as ArrowDownwardIcon
} from "@mui/icons-material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import {
  BER_COLOUR_CODE_MAP
} from "common/assets/rk_type_mappings/ber_typed";


interface BERScaleBeforeAndAfterProps {
  beforeRating?: string;
  afterRating?: string;
}

const defaultBERScaleBeforeAndAfterProps: BERScaleBeforeAndAfterProps = {
  beforeRating: "",
  afterRating: ""
};

export function BERScaleBeforeAndAfter(
  {
    beforeRating,
    afterRating
  }: BERScaleBeforeAndAfterProps) {

  const theme = useTheme();
  
  const barContentArray = [
    { label: "E2", colorCode: BER_COLOUR_CODE_MAP.E2 },
    { label: "E1", colorCode: BER_COLOUR_CODE_MAP.E1 },
    { label: "D2", colorCode: BER_COLOUR_CODE_MAP.D2 },
    { label: "D1", colorCode: BER_COLOUR_CODE_MAP.D1 },
    { label: "C3", colorCode: BER_COLOUR_CODE_MAP.C3 },
    { label: "C2", colorCode: BER_COLOUR_CODE_MAP.C2 },
    { label: "C1", colorCode: BER_COLOUR_CODE_MAP.C1 },
    { label: "B3", colorCode: BER_COLOUR_CODE_MAP.B3 },
    { label: "B2", colorCode: BER_COLOUR_CODE_MAP.B2 },
    { label: "B1", colorCode: BER_COLOUR_CODE_MAP.B1 },
    { label: "A3", colorCode: BER_COLOUR_CODE_MAP.A3 },
    { label: "A2", colorCode: BER_COLOUR_CODE_MAP.A2 },
    { label: "A1", colorCode: BER_COLOUR_CODE_MAP.A1 }
  ];

  const firstElementRef =
    useRef<HTMLSpanElement | null>(null);

  const secondElementRef =
    useRef<HTMLSpanElement | null>(null);

  const thirdElementRef =
    useRef<HTMLSpanElement | null>(null);

  const parentRef =
    useRef<HTMLDivElement | null>(null);

  const [
    firstElementWidth,
    setFirstElementWidth
  ] = useState<number>(0);

  const [
    secondElementWidth,
    setSecondElementWidth
  ] = useState<number>(0);

  const [
    thirdElementWidth,
    setThirdElementWidth
  ] = useState<number>(0);

  const [
    beforeContentWidth,
    setBeforeContentWidth
  ] = useState<number>(0);

  const [
    afterContentWidth,
    setAfterContentWidth
  ] = useState<number>(0);

  const [
    parentWidth,
    setParentWidth
  ] = useState<number>(0);

  useEffect(() => {
    if (parentRef.current) {
      const parentElement = parentRef.current.getBoundingClientRect();

      // console.log("parentWidth =", parentElement.width)

      setParentWidth(parentElement.width);
    }
  }, []);

  useEffect(
    () => {
      if (firstElementRef.current) {
        const beforeChildPosition =
          firstElementRef.current.getBoundingClientRect();

        setFirstElementWidth(beforeChildPosition.left);
      }

      if (secondElementRef.current) {
        const afterChildPosition =
          secondElementRef.current.getBoundingClientRect();

        setSecondElementWidth(afterChildPosition.left);
      }

      if (thirdElementRef.current) {
        const afterChildPosition =
          thirdElementRef.current.getBoundingClientRect();

        setThirdElementWidth(afterChildPosition.left);
      }
    },
    [
      parentWidth
    ]
  );

  const berCoordinates = useMemo(
    () => (
      {
        G: 0,
        F: 1,
        E2: 2,
        E1: 3,
        D2: 4,
        D1: 5,
        C3: 6,
        C2: 7,
        C1: 8,
        B3: 9,
        B2: 10,
        B1: 11,
        A3: 12,
        A2: 13,
        A1: 14
      }
    ),
    []
  );

  const [
    beforeContentPosition,
    setBeforeContentPosition
  ] = useState<number>(0);

  const [
    afterContentPosition,
    setAfterContentPosition
  ] = useState<number>(14);

  useEffect(() => {
      const beforeContent = beforeRating
        ? // eslint-disable-next-line
        (berCoordinates as { [key: string]: number })[beforeRating]
        : berCoordinates.G;
      setBeforeContentPosition(beforeContent);
    },
    [
      beforeRating,
      berCoordinates
    ]
  );

  useEffect(() => {
      const afterContent = afterRating ?
        (berCoordinates as { [key: string]: number })[afterRating]
        : berCoordinates.A1;

      // console.log("afterContent =", afterContent)

      setAfterContentPosition(afterContent);
    },
    [
      afterRating,
      berCoordinates
    ]
  );

  // const classes = useStyles({
  //   firstElementWidth,
  //   secondElementWidth,
  //   thirdElementWidth,
  //   parentWidth,
  //   beforeContentWidth,
  //   afterContentWidth
  // });

  useEffect(
    () => {
      const updatedWidthForBefore =
        (thirdElementWidth - secondElementWidth) * (beforeContentPosition + 0.5);

      // console.log("BeforeContentWidth =", updatedWidthForBefore)

      setBeforeContentWidth(updatedWidthForBefore);

      const updatedWidthForAfter =
        (thirdElementWidth - secondElementWidth) * (afterContentPosition + 0.5);

      // console.log("AfterContentWidth =", updatedWidthForAfter)

      setAfterContentWidth(updatedWidthForAfter);
    }, [
      firstElementWidth,
      secondElementWidth,
      thirdElementWidth,
      beforeContentPosition,
      afterContentPosition
    ]
  );


  return <Box
    {...{ ref: parentRef }}
    sx={{
      marginTop: "1rem",
      width: "100%",
      position: "relative"
    }}
  >
    {
      (beforeContentWidth || afterContentWidth) && <Box 
          sx={{
            marginTop: '1rem',
            width: '100%',
            position: 'relative'
          }}
        >
          <Box 
            sx={{
              display: 'flex'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                left: beforeContentWidth,
                position: 'absolute',
                top: '-34px'
              }}
            >
              <Typography
                variant='h5'
                sx={{
                  fontSize: '1rem',
                  fontWeight: "bold"
                }}
              >
                BEFORE
              </Typography>

              <ArrowDownwardIcon
                sx={{
                  color: theme.palette.primary.main,
                  height: '30px',
                  width: '30px',
                  marginLeft: '8px'
                }}
              />
              
            </Box>

            <Box
              sx={{
                display: 'flex',
                left: afterContentWidth,
                position: 'absolute',
                top: '-34px'
            }}
            >
              
              <Typography
                variant='h5'
                sx={{
                  fontSize: '1rem',
                  fontWeight: "bold"
                }}
              >
                AFTER
              </Typography>

              <ArrowDownwardIcon
                sx={{
                  color: theme.palette.primary.main,
                  height: '30px',
                  width: '30px',
                  marginLeft: '8px'
                }}
              />
              
            </Box>
            
          </Box>
          
        </Box>
    }
      <Box
        sx={{
          display: "flex"
        }}
      >

        <Typography
          variant="body1"
          sx={{
            clipPath:
              "polygon(0% 0%, calc(100% - 1.5rem) 0%, 100% 50%, calc(100% - 1.5rem) 100%, 0% 100%)",
            fontSize: "1.4rem",
            paddingRight: "1.5rem",
            paddingLeft: "1rem",
            color: "white",
            float: "left",
            clear: "left",
            backgroundColor: "black",
            borderTopLeftRadius: "7px",
            borderBottomLeftRadius: "7px",
            height: "65px",
            display: "flex",
            alignItems: "center"
          }}
          ref={firstElementRef}
        >
          BER
        </Typography>

        <Typography
          variant="body1"
          sx={{
            background: BER_COLOUR_CODE_MAP.G,
            backgroundColor: BER_COLOUR_CODE_MAP.G,
            paddingLeft: "1em",
            width: parentWidth / 12,
            clipPath:
              "polygon( calc(75% - 0.50rem) 0%,  calc(100% - 0.50rem) 50%,  calc(75% - 0.50rem) 100%, 0% 100%, 24% 50%, 0% 0%)",
            color: "white",
            float: "left",
            clear: "left",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            fontFamily: "Roboto",
            height: "65px",
            fontSize: "1.4rem",
            marginLeft: "-0.2rem"
          }}
          ref={secondElementRef}
        >
          G
        </Typography>

        <Typography
          variant="body1"
          sx={{
            background: BER_COLOUR_CODE_MAP.F,
            backgroundColor: BER_COLOUR_CODE_MAP.F,
            paddingLeft: "1em",
            width: parentWidth / 12,
            clipPath:
              "polygon( calc(75% - 0.50rem) 0%,  calc(100% - 0.50rem) 50%,  calc(75% - 0.50rem) 100%, 0% 100%, 24% 50%, 0% 0%)",
            color: "white",
            float: "left",
            clear: "left",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            fontFamily: "Roboto",
            height: "65px",
            fontSize: "1.4rem",
            marginLeft: "-1rem"
          }}
          ref={thirdElementRef}
        >
          F
        </Typography>

        {
          barContentArray?.map(
            (
              content,
              index
            ) => (
              <Typography
                key={index}
                variant="h6"
                sx={{
                  background: content.colorCode,
                  backgroundColor: content.colorCode,
                  paddingLeft: "0.9em",
                  width: parentWidth / 12,
                  clipPath:
                    "polygon( calc(75% - 0.50rem) 0%,  calc(100% - 0.50rem) 50%,  calc(75% - 0.50rem) 100%, 0% 100%, 24% 50%, 0% 0%)",
                  color: "white",
                  float: "left",
                  clear: "left",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontFamily: "Roboto",
                  height: "65px",
                  marginLeft: "-1rem",
                  fontSize: "1.4rem"
                }}
              >
                {content.label}
              </Typography>
            )
          )
        }
      </Box>
  </Box>;
}

BERScaleBeforeAndAfter.defaultProps = defaultBERScaleBeforeAndAfterProps;
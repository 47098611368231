import type { CustomMeasure } from "features/customMeasures/types";
import type { CustomScenario } from "features/customScenarios/types";
import type { DefaultMeasure } from "features/defaultMeasures/types";
import type { DefaultScenario } from "features/defaultScenarios/types";


export function isCustomMeasure(
  measure: DefaultMeasure | CustomMeasure
): measure is DefaultMeasure | CustomMeasure {
  return !(measure as DefaultMeasure).type;
}

export function isDefaultMeasure(
  measure: DefaultMeasure | CustomMeasure
): measure is DefaultMeasure | CustomMeasure {
  return !!(measure as DefaultMeasure).type;
}

export function isCustomScenario(
  scenario: CustomScenario | DefaultScenario
): scenario is CustomScenario | DefaultScenario {
  return !(scenario as CustomScenario).custom_measures;
}

export function isDefaultScenario(
  scenario: CustomScenario | DefaultScenario
): scenario is CustomScenario | DefaultScenario {
  return !!(scenario as CustomScenario).custom_measures;
}
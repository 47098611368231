import React, { useCallback, useEffect, useRef, useState } from "react";


import {
  colors,
} from "@mui/material";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

import { BEREdited } from "common/components/BEREdited";
import {
  DwellingAndScenarioPerformanceBanner
} from "common/components/DwellingAndScenarioPerformanceBanner";
import iconTitleHomeBlank from "common/icons/icon-title-home-blank.png"
import { 
  useHomeEnergyAssessment 
} from "features/homeEnergyAssessment/hooks/useHomeEnergyAssessment";

import type { Dwelling } from "features/dwellings/types";


interface HomeEnergyAssessmentDialogContent10Props {
  contentBackgroundColour: string;
  pageBackgroundColour: string;
  dwelling: Dwelling;
  scenarioDetail: string;
  scenarioRating: string;
  inputError: boolean;
  setInputError: React.Dispatch<React.SetStateAction<boolean>>;
  handleHeaFieldBlur: (
    value: string | number | File,
    field: string
  ) => void;
}


export function HomeEnergyAssessmentDialogContent10(
  {
    contentBackgroundColour,
    pageBackgroundColour,
    dwelling,
    scenarioDetail,
    scenarioRating,
    inputError,
    setInputError,
    handleHeaFieldBlur
  }: HomeEnergyAssessmentDialogContent10Props
) {

  const {
    homeEnergyAssessment
  } = useHomeEnergyAssessment();

  const [
    sectionHeadingTerms,
    setSectionHeadingTerms
  ] = useState<string>("");
  
  const [
    sectionHeadingTermsError,
    setSectionHeadingTermsError
  ] = useState<boolean>(false);

  const [
    sectionHeadingTermsLength,
    setSectionHeadingTermsLength
  ] = useState<number>(0);

  const sectionHeadingTermsLengthLimit = 100;

  const sectionHeadingTermsInputRef = useRef<HTMLInputElement>();

  const [
    ossRoles,
    setOssRoles
  ] = useState<string>("");
  
  const [
    ossRolesError,
    setOssRolesError
  ] = useState<boolean>(false);

  const [
    ossRolesLength,
    setOssRolesLength
  ] = useState<number>(0);

  const ossRolesLengthLimit = 1000;

  const ossRolesInputRef = useRef<HTMLInputElement>();

  const [
    sectionHeadingOssRoles,
    setSectionHeadingOssRoles
  ] = useState<string>("");

  const [
    financialTerms,
    setFinancialTerms
  ] = useState<string>("");
  
  const [
    financialTermsError,
    setFinancialTermsError
  ] = useState<boolean>(false);

  const [
    financialTermsLength,
    setFinancialTermsLength
  ] = useState<number>(0);

  const financialTermsLengthLimit = 1000;

  const [
    sectionHeadingFinancialTerms,
    setSectionHeadingFinancialTerms
  ] = useState<string>("");

  const [
    disclaimers,
    setDisclaimers
  ] = useState<string>("");
  
  const [
    disclaimersError,
    setDisclaimersError
  ] = useState<boolean>(false);

  const [
    disclaimersLength,
    setDisclaimersLength
  ] = useState<number>(0);

  const disclaimersLengthLimit = 1000;

  const [
    sectionHeadingDisclaimers,
    setSectionHeadingDisclaimers
  ] = useState<string>("");

  useEffect(
    () => {

      if (
        ossRolesError ||
        financialTermsError ||
        disclaimersError ||
        sectionHeadingTermsError
      ) {
        setInputError(true)
      }

      if (inputError) {
        if (
          !ossRolesError &&
          !financialTermsError &&
          !disclaimersError &&
          !sectionHeadingTermsError
        ) {
          setInputError(false)
        }
      }

    },
    [
      ossRolesError,
      financialTermsError,
      disclaimersError,
      sectionHeadingTermsError,
      inputError,
      setInputError
    ]
  );

  useEffect(
    () => {

      if (homeEnergyAssessment !== undefined) {
        setSectionHeadingTerms(
          homeEnergyAssessment.section_heading_terms
        )
        
        setSectionHeadingTermsLength(
          homeEnergyAssessment.section_heading_terms !== null ?
            homeEnergyAssessment.section_heading_terms.length : 0
        )
        
        setOssRoles(
          homeEnergyAssessment.oss_roles
        )
        
        setOssRolesLength(
          homeEnergyAssessment.oss_roles !== null ?
            homeEnergyAssessment.oss_roles.length : 0
        )
        
        setFinancialTerms(
          homeEnergyAssessment.financial_terms
        )
        
        setFinancialTermsLength(
          homeEnergyAssessment.financial_terms !== null ?
            homeEnergyAssessment.financial_terms.length : 0
        )
        
        setDisclaimers(
          homeEnergyAssessment.disclaimers
        )
        
        setDisclaimersLength(
          homeEnergyAssessment.disclaimers !== null ?
            homeEnergyAssessment.disclaimers.length : 0
        )
        
        setSectionHeadingOssRoles(
          homeEnergyAssessment.section_heading_oss_roles
        )
        
        setSectionHeadingFinancialTerms(
          homeEnergyAssessment.section_heading_financial_terms
        )
        
        setSectionHeadingDisclaimers(
          homeEnergyAssessment.section_heading_disclaimers
        )
      }
    },
    [
      homeEnergyAssessment
    ]
  );

  const handleFieldUpdated = useCallback(
    (
      value: string | number | File,
      field: string
    ) => {
      handleHeaFieldBlur(value, field);
    },
    [
      handleHeaFieldBlur
    ]
  );

  const theme = useTheme();

  return (
    <DialogContent
      sx={{
        width: "100%",
        flex: 1,
        overflowX: "hidden",
        overflowY: "hidden",
        padding: "0px 8px 0px 8px",
        gap: "2rem"
      }}
    >

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            borderRadius: "20px",
            backgroundColor: contentBackgroundColour,
            padding: "16px"
          }}
        >
          <img
            src={iconTitleHomeBlank}
            alt="Icon"
            style={{
              maxHeight: "48px"
            }}
          />

          <TextField
            error={sectionHeadingTermsError}
            variant="standard"
            fullWidth
            inputProps={{
              style: {
                fontSize: "1.4rem",
                backgroundColor: theme.palette.common.white
              }
            }}
            InputLabelProps={{
              style: {
                fontSize: "1.4rem"
              }
            }}
            FormHelperTextProps={{
              sx: {
                textAlign: "right",
                width: "100%"
              }
            }}
            value={sectionHeadingTerms}
            sx={{
              display: "flex",
              alignItems: "center",
              marginLeft: "16px",
              color: colors.grey["800"]
            }}
            onBlur={(event) => {
              if (!inputError) {
                handleFieldUpdated(
                  event.target.value,
                  "section_heading_terms"
                );
              }
            }}
            onChange={(event) => {
              const {
                value
              } = event.target;
              setSectionHeadingTermsLength(value.length);
              if (value.length > sectionHeadingTermsLengthLimit) {
                setSectionHeadingTermsError(true);
              } else if (sectionHeadingTermsError) {
                  setSectionHeadingTermsError(false);
                }
              setSectionHeadingTerms(value)
            }}
            helperText={
              sectionHeadingTermsLengthLimit - sectionHeadingTermsLength <= (sectionHeadingTermsLengthLimit / 2) ?
                `${sectionHeadingTermsLength}/${sectionHeadingTermsLengthLimit}` : ""
            }
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                if (!inputError) {
                  if (
                    ossRolesInputRef !== undefined &&
                    ossRolesInputRef.current !== undefined
                  ) {
                    ossRolesInputRef.current.focus()
                  }
                }
              }
            }}
            inputRef={sectionHeadingTermsInputRef}
          />

        </Box>

      </Box>

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >
        <DwellingAndScenarioPerformanceBanner
          dwellingDetail={String(dwelling.mprn)}
          dwellingRating={dwelling.performance.rating}
          scenarioDetail={scenarioDetail}
          scenarioRating={scenarioRating}
        />

      </Box>

      {
        dwelling.performance.ber_edited ? <BEREdited/> : null
      }

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >
        <TextField
          error={ossRolesError}
          label={sectionHeadingOssRoles}
          sx={{
            backgroundColor: pageBackgroundColour,
            width: "100%"
          }}
          multiline
          size="small"
          variant="outlined"
          InputProps={{
            sx: {
              backgroundColor: theme.palette.common.white
            }
          }}
          FormHelperTextProps={{
            sx: {
              textAlign: "right"
            }
          }}
          value={ossRoles}
          onBlur={(event) => {
            if (!inputError) {
              handleFieldUpdated(
                event.target.value,
                "oss_roles"
              );
            }
          }}
          onChange={(event) => {
            const {
              value
            } = event.target
            setOssRolesLength(value.length)
            if (value.length > ossRolesLengthLimit) {
              setOssRolesError(true)
            }
            else if (ossRolesError) {
                setOssRolesError(false)
              }
            setOssRoles(value)
          }}
          helperText={
            ossRolesLengthLimit - ossRolesLength <= (ossRolesLengthLimit / 2) ?
              `${ossRolesLength}/${ossRolesLengthLimit}` : ''
          }
          inputRef={ossRolesInputRef}
        />

      </Box>

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >
        <TextField
          error={financialTermsError}
          label={sectionHeadingFinancialTerms}
          sx={{
            backgroundColor: pageBackgroundColour,
            width: "100%"
          }}
          multiline
          size="small"
          variant="outlined"
          InputProps={{
            sx: {
              backgroundColor: theme.palette.common.white
            }
          }}
          FormHelperTextProps={{
            sx: {
              textAlign: "right"
            }
          }}
          value={financialTerms}
          onBlur={(event) => {
            if (!inputError) {
              handleFieldUpdated(
                event.target.value,
                "financial_terms"
              );
            }
          }}
          onChange={(event) => {
            const {
              value
            } = event.target
            setFinancialTermsLength(value.length)
            if (value.length > financialTermsLengthLimit) {
              setFinancialTermsError(true)
            }
            else if (financialTermsError) {
                setFinancialTermsError(false)
              }
            setFinancialTerms(value)
          }}
          helperText={
            financialTermsLengthLimit - financialTermsLength <= (financialTermsLengthLimit / 2) ?
              `${financialTermsLength}/${financialTermsLengthLimit}` : ''
          }
        />

      </Box>

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >
        <TextField
          error={disclaimersError}
          label={sectionHeadingDisclaimers}
          sx={{
            backgroundColor: pageBackgroundColour,
            width: "100%"
          }}
          multiline
          size="small"
          variant="outlined"
          InputProps={{
            sx: {
              backgroundColor: theme.palette.common.white
            }
          }}
          FormHelperTextProps={{
            sx: {
              textAlign: "right"
            }
          }}
          value={disclaimers}
          onBlur={(event) => {
            if (!inputError) {
              handleFieldUpdated(
                event.target.value,
                "disclaimers"
              );
            }
          }}
          onChange={(event) => {
            const {
              value
            } = event.target
            setDisclaimersLength(value.length)
            if (value.length > disclaimersLengthLimit) {
              setDisclaimersError(true)
            }
            else if (disclaimersError) {
                setDisclaimersError(false)
              }
            setDisclaimers(value)
          }}
          helperText={
            disclaimersLengthLimit - disclaimersLength <= (disclaimersLengthLimit / 2) ?
              `${disclaimersLength}/${disclaimersLengthLimit}` : ''
          }
        />

      </Box>

    </DialogContent>
  );
}
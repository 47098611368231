import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";

import moment from "moment/moment";
import {
  useDispatch
} from "react-redux";

import { logUserOut } from "v1/actions/authentication";
import SlideInAlertDialog from "v1/components/alerts/SlideInAlertDialog";


// This component is used to handle user inactivity and automatic log out.
function UserActivityListener() {

  // Set the default state values for the dialogs
  const [
    logoutDialogOpen,
    setLogoutDialogOpen
  ] = useState(false);

  const [
    logoutDialogDescription,
    setLogoutDialogDescription
  ] = useState("");

  // Set the timeout variables.
  const warningTimeLimit = 1000 * 60 * 10;  // 10 minutes
  const logOutTimeLimit = 1000 * 60 * 5;    // 5 minutes

  const logOutTimeout = useRef(null);
  const warningTimeout = useRef(null);

  // Get the dispatch function to log the user out.
  const dispatch = useDispatch();

  // Define a callback function for the automatic log out.
  const autoLogOut = useCallback(
    () => {
      dispatch(
        logUserOut()
      );
    },
    [
      dispatch
    ]
  );

  // Define a callback function to reset the timers.
  const resetTimers = useCallback(
    () => {
      if (logOutTimeout.current) {
        clearTimeout(logOutTimeout.current);
        logOutTimeout.current = null;
      }

      if (warningTimeout.current) {
        clearTimeout(warningTimeout.current);
        warningTimeout.current = null;
      }

      setLogoutDialogOpen(false);
    },
    [
      logOutTimeout,
      warningTimeout
    ]
  );

  // Define a callback function to handle timers for user inactivity.
  const handleTimers = useCallback(
    () => {

      warningTimeout.current = setTimeout(
        () => {

          if (warningTimeout.current) {
            clearTimeout(warningTimeout.current);
            warningTimeout.current = null;
          }

          const newDate = moment(
            new Date()
          ).add(5, "m").toDate();

          setLogoutDialogDescription(
            "You will be automatically logged out " +
            "due to inactivity in 5 minutes." +
            `(${newDate.toLocaleString(
              navigator.language,
              {
                hour: "2-digit",
                minute: "2-digit"
              }
            )})`
          );
          setLogoutDialogOpen(true);

          logOutTimeout.current = setTimeout(
            () => {

              if (logOutTimeout.current) {
                clearTimeout(logOutTimeout.current);
                logOutTimeout.current = null;
              }

              setLogoutDialogDescription(
                "You have been automatically " +
                "logged out due to inactivity."
              );
              setLogoutDialogOpen(true);

              autoLogOut();
            },
            logOutTimeLimit
          );

        },
        warningTimeLimit
      );
    }, [
      autoLogOut,
      logOutTimeLimit,
      warningTimeLimit
    ]
  );

  // Define a callback function for the events that trigger user activity.
  const activityDetected = useCallback(
    () => {
      resetTimers();
      handleTimers();
    },
    [
      handleTimers,
      resetTimers
    ]
  );

  // Use an effect hook to add and remove the event listeners.
  useEffect(
    () => {

      // Set the events to handle user inactivity.
      const events = [
        "click",
        "keypress",
        "mousemove",
        "scroll"
      ];

      Object.values(events).forEach(
        (item) => {
          window.addEventListener(
            item,
            activityDetected
          );
        });

      return () => {
        if (warningTimeout.current) {
          clearTimeout(warningTimeout.current);
          warningTimeout.current = null;
        }

        if (logOutTimeout.current) {
          clearTimeout(logOutTimeout.current);
          logOutTimeout.current = null;
        }

        Object.values(events).forEach(
          (item) => {
            window.removeEventListener(
              item,
              activityDetected
            );
          }
        );
      };
    },
    [
      activityDetected
    ]
  );

  return <SlideInAlertDialog
    open={logoutDialogOpen}
    setOpen={setLogoutDialogOpen}
    title="Inactivity log out"
    description={logoutDialogDescription}
  />;
}

export default UserActivityListener;
import React, { useEffect, useState } from "react";

import { ListSubheader, Select } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";


import {
  DwellingAndScenarioBerGrades
} from "common/components/DwellingAndScenarioBerGrades";
import {
  WhiteBackgroundTextField
} from "common/components/WhiteBackgroundTextField";
import {
  thermalConductivityOptions,
  insulationTypeOptions,
  wallTypeOptions,
  wallInsulationLocationOptions
} from "common/maps/dwelling";
import {
  floatKeyCheck, 
  floatRegex
} from "common/utils/numbers";
import {
  TechnicalAssessmentFormHeader
} from "features/technicalAssessmentForm/dialogs/common/TechnicalAssessmentFormHeader";

import type {
  UpdateTAFApiRequest
} from "features/technicalAssessmentForm/api/types";
import type {
  TechnicalAssessmentForm,
  TechnicalAssessmentFormWall
} from "features/technicalAssessmentForm/types";



interface TechnicalAssessmentFormModal3Props {
  isTafUpdating: boolean;
  setShowModal: (show: boolean) => void;
  setTafStep: (newStep: number) => void;
  showModal: boolean;
  taf: TechnicalAssessmentForm;
  tafStep: number;
  updateTAF: (variable: UpdateTAFApiRequest) => Promise<TechnicalAssessmentForm>;
}


export function TechnicalAssessmentFormModal3(
  {
    isTafUpdating,
    setShowModal,
    setTafStep,
    showModal,
    taf,
    tafStep,
    updateTAF
  }: TechnicalAssessmentFormModal3Props
) {
  const theme = useTheme();

  const [
    selectedIndex,
    setSelectedIndex
  ] = useState<number>(0);

  const [
    walls,
    setWalls
  ] = useState<TechnicalAssessmentFormWall[]>(
    taf.walls
  );

  const [
    otherDetailsComments,
    setOtherDetailsComments
  ] = useState<string | null>("");

  const [
    wallDescription,
    setWallDescription
  ] = useState<string>("");

  const [
    wallArea,
    setWallArea
  ] = useState<number>(0);

  const [
    totalHeatLossArea,
    setTotalHeatLossArea
  ] = useState<number>(0);

  const [
    wallTypeId,
    setWallTypeId
  ] = useState<number>(0);

  const [
    wallThickness,
    setWallThickness
  ] = useState<number>(0);

  const [
    existingInsulationType,
    setExistingInsulationType
  ] = useState<number>(0);

  const [
    existingInsulationThickness,
    setExistingInsulationThickness
  ] = useState<number>(0);

  const [
    existingUValue,
    setExistingUValue
  ] = useState<number>(0);

  const [
    uValueBasis,
    setUValueBasis
  ] = useState<string>("false");

  const [
    proposedInsulationType,
    setProposedInsulationType
  ] = useState<number>(0);

  const [
    proposedInsulationThickness,
    setProposedInsulationThickness
  ] = useState<number>(0);

  const [
    proposedInsulationLocation,
    setProposedInsulationLocation
  ] = useState<number>(0);

  const [
    proposedInsulationThermalConductivity,
    setProposedInsulationThermalConductivity
  ] = useState<number>(0);

  const [
    scenarioUValue,
    setScenarioUValue
  ] = useState<number>(0);

  const [
    requirementsForBEHWallGrant,
    setRequirementsForBEHWallGrant
  ] = useState<string>("false");

  useEffect(
    () => {

      if (walls.length > 0) {

        setUValueBasis(
          String(
            walls[selectedIndex].u_value === walls[selectedIndex].default_u_value
          )
        );

        setRequirementsForBEHWallGrant(
          String(
            walls[selectedIndex].requirements_for_beh_wall_grant
          )
        );

        let totalHeatLoss = 0;
        walls.forEach(
          wall => {
            totalHeatLoss += wall.area;
          }
        );
        setTotalHeatLossArea(totalHeatLoss);

        if (walls[selectedIndex].scenario_u_value) {
          setScenarioUValue(
            walls[selectedIndex].scenario_u_value
          );
        }

        if (walls[selectedIndex].proposed_additional_insulation_thermal_conductivity) {
          setProposedInsulationThermalConductivity(
            walls[selectedIndex].proposed_additional_insulation_thermal_conductivity
          );
        }

        if (walls[selectedIndex].proposed_additional_insulation_location) {
          setProposedInsulationLocation(
            walls[selectedIndex].proposed_additional_insulation_location
          );
        }

        if (walls[selectedIndex].proposed_additional_insulation_thickness) {
          setProposedInsulationThickness(
            walls[selectedIndex].proposed_additional_insulation_thickness
          );
        }

        if (walls[selectedIndex].proposed_additional_insulation_type) {
          setProposedInsulationType(
            walls[selectedIndex].proposed_additional_insulation_type
          );
        }

        if (walls[selectedIndex].u_value) {
          setExistingUValue(
            walls[selectedIndex].u_value
          );
        }

        if (walls[selectedIndex].existing_insulation_thickness) {
          setExistingInsulationThickness(
            walls[selectedIndex].existing_insulation_thickness
          );
        }

        if (walls[selectedIndex].existing_insulation_type) {
          setExistingInsulationType(
            walls[selectedIndex].existing_insulation_type
          );
        }

        if (walls[selectedIndex].wall_thickness) {
          setWallThickness(
            walls[selectedIndex].wall_thickness
          );
        }

        if (walls[selectedIndex].wall_type_id) {
          setWallTypeId(
            walls[selectedIndex].wall_type_id
          );
        }

        if (walls[selectedIndex].area) {
          setWallArea(
            walls[selectedIndex].area
          );
        }

        if (walls[selectedIndex].description) {
          setWallDescription(
            walls[selectedIndex].description
          );
        }

        if (walls[selectedIndex].other_details_comments) {
          setOtherDetailsComments(
            walls[selectedIndex].other_details_comments
          );
        }
      }
    },
    [
      selectedIndex,
      taf.walls,
      walls
    ]
  );

  const handleWallEdits = () => {

    if (walls.length > 0) {

      const newWall = {
        identifier: walls[selectedIndex].identifier,
        area: wallArea,
        u_value: existingUValue,
        description: wallDescription,
        wall_type_id: wallTypeId,
        wall_thickness: wallThickness,
        existing_insulation_type: existingInsulationType,
        existing_insulation_thickness: existingInsulationThickness,
        proposed_additional_insulation_type: proposedInsulationType,
        proposed_additional_insulation_thickness: proposedInsulationThickness,
        proposed_additional_insulation_thermal_conductivity: proposedInsulationThermalConductivity,
        requirements_for_beh_wall_grant: requirementsForBEHWallGrant === "true",
        other_details_comments: otherDetailsComments,
        proposed_additional_insulation_location: proposedInsulationLocation,
        scenario_u_value: scenarioUValue
      };

      const newWalls = [...walls];

      newWalls[selectedIndex] = newWall;

      setWalls(newWalls);

      return newWalls;
    }
    return [];
  };

  const handleListItemClick = (
    _event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {

    const latestWalls = handleWallEdits();

    setSelectedIndex(index);

    setWallDescription(
      latestWalls[index].description
    );
    setWallArea(
      latestWalls[index].area
    );
    setWallTypeId(
      latestWalls[index].wall_type_id
    );
    setWallThickness(
      latestWalls[index].wall_thickness
    );
    setExistingInsulationType(
      latestWalls[index].existing_insulation_type
    );
    setExistingInsulationThickness(
      latestWalls[index].existing_insulation_thickness
    );
    setExistingUValue(
      latestWalls[index].u_value
    );
    setUValueBasis(
      String(
        latestWalls[index].u_value === latestWalls[index].default_u_value
      )
    );
    setOtherDetailsComments(
      latestWalls[index].other_details_comments
    );
    setProposedInsulationType(
      latestWalls[index].proposed_additional_insulation_type
    );
    setProposedInsulationThickness(
      latestWalls[index].proposed_additional_insulation_thickness
    );
    setProposedInsulationLocation(
      latestWalls[index].proposed_additional_insulation_location
    );
    setProposedInsulationThermalConductivity(
      latestWalls[index].proposed_additional_insulation_thermal_conductivity
    );
    setScenarioUValue(
      latestWalls[index].scenario_u_value
    );
    setRequirementsForBEHWallGrant(
      String(latestWalls[index].requirements_for_beh_wall_grant)
    );
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleUpdateTAF = (dialogNumber: number) => {

    const latestWalls = handleWallEdits();

    const requestBody: UpdateTAFApiRequest = {
      technical_assessment_form_id: taf.id,
      walls: latestWalls
    };

    updateTAF(
      requestBody
    ).then(
      (response) => {
        if (response) {
          setTafStep(dialogNumber);
        }
      }
    ).catch(
      (error) => console.log("Error while updating TAF:", error)
    );
  };

  const handleNextButtonClick = () => {
    handleUpdateTAF(4);
  };
  const handleBackButtonClick = () => {
    handleUpdateTAF(2);
  };

  const handleSliderChanged = (dialogNumber: number) => {
    handleUpdateTAF(dialogNumber);
  };

  const outlinedInputReducedPadding = {
    style: {
      padding: "8px"
    }
  };

  const contentSectionHeaderText = {
    color: "#71717a"
  };

  const inputLabelVariableWidth = {
    display: "flex",
    alignItems: "center"
  };

  const labelAndInputBox = {
    display: "flex"
  };

  const outlinedInputStyle = {
    flex: 1,
    marginLeft: "24px",
    backgroundColor: "white"
  };

  const outlinedInputStyleDropdown = {
    flex: 1,
    marginLeft: "24px",
    backgroundColor: "white",
    paddingLeft: "8px",
    paddingRight: "8px",
    overflowWrap: "break-word",
    width: "10rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  };

  const sectionTitle = {
    marginTop: "8px"
  };

  return (
    <Dialog
      open={showModal}
      onClose={closeModal}
      maxWidth="lg"
      fullWidth
      scroll="paper"
      sx={{
        padding: "16px"
      }}
    >
      <TechnicalAssessmentFormHeader
        changeTafStep={handleSliderChanged}
        closeModal={closeModal}
        tafStep={tafStep}
      />

      <DialogContent
        sx={{
          padding: "0px 8px 0px 8px"
        }}
      >

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "5px",
            backgroundColor: "#e0dede",
            padding: "0px 8px 8px 8px"
          }}
        >

          {/* SECTION TITLE */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "24px",
              paddingTop: "24px",
              paddingRight: "24px",
              paddingBottom: "16px"
            }}
          >
            <img
              alt="section title"
              style={{
                width: "60px",
                paddingRight: "16px"
              }}
              src="https://rk-staging-static.s3.eu-west-1.amazonaws.com/static/public/images/housing_upgrade_plans/icon-house-type.svg"
            />

            <Typography
              sx={{
                display: "flex",
                alignItems: "center"
              }}
              variant="h5"
            >
              Fabric Upgrades: Walls
            </Typography>

          </Box>

          {
            walls.length > 0 ? <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                borderStyle: "solid",
                borderColor: theme.palette.primary.main,
                borderRadius: "5px",
                border: 1,
                backgroundColor: "white"
              }}
            >
              <List
                component="nav"
                sx={{
                  backgroundColor: "#ffffff",
                  borderRadius: "7px"
                }}
                aria-label="walls"
                subheader={
                  <ListSubheader
                    component="div"
                    id="nested-list-subheader"
                  >
                    Wall #
                  </ListSubheader>
                }
              >
                {
                  walls.map(
                    (_wall, index) =>
                      <ListItem
                        disablePadding
                        key={index}
                        onClick={
                          (event) => handleListItemClick(event, index)
                        }
                      >
                        <ListItemButton
                          selected={selectedIndex === index}
                        >
                          <ListItemText
                            primary={index + 1}
                          />
                        </ListItemButton>
                      </ListItem>
                  )
                }
              </List>

              <Box
                sx={{
                  flex: 1,
                  backgroundColor: theme.palette.action.selected,
                  padding: "8px"
                }}
              >

                {/* SECTION DESCRIPTION */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "8px",
                    paddingRight: "24px",
                    borderRadius: "5px",
                    border: 1,
                    borderColor: "#71717a",
                    padding: "16px",
                    gap: "8px"
                  }}
                >

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row"
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      Description
                    </Typography>

                    <OutlinedInput
                      sx={outlinedInputStyle}
                      placeholder=""
                      inputProps={outlinedInputReducedPadding}
                      value={wallDescription}
                      onChange={
                        e => {
                          setWallDescription(e.target.value);
                        }
                      }
                    />

                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row"
                    }}
                  >

                    <Box
                      sx={{
                        display: "flex",
                        flex: 1,
                        paddingRight: "20px"
                      }}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Area
                      </Typography>

                      <WhiteBackgroundTextField
                        type="number"
                        variant="outlined"
                        InputProps={{
                          startAdornment: <InputAdornment
                            position="start"
                          >
                            m&#178;
                          </InputAdornment>,
                          inputProps: {
                            min: 0,
                            step: "0.01"
                          }
                        }}
                        onChange={
                          (event) => {
                            if (
                              event.target.value === "" ||
                              floatRegex.test(event.target.value)
                            ) {
                              setWallArea(
                                Number(
                                  event.target.value
                                )
                              );
                            }
                          }
                        }
                        onKeyDown={floatKeyCheck}
                        value={wallArea}
                        sx={{
                          flex: 1,
                          marginLeft: "24px"
                        }}
                      />

                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        paddingLeft: "20px",
                        flex: 1
                      }}
                    >

                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Total heat loss area shown in BER
                      </Typography>

                      <Typography
                        sx={{
                          marginLeft: "16px",
                          flex: 1,
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center"
                        }}
                      >
                        {
                          totalHeatLossArea.toFixed(2)
                        } m&#178;
                      </Typography>

                    </Box>

                  </Box>

                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    padding: "8px 0px 8px 0px"
                  }}
                >


                  {/* SECTION CURRENT */}
                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "5px",
                      border: 1,
                      borderColor: "#71717a",
                      padding: "0px 16px 16px 16px",
                      gap: "8px"
                    }}
                  >

                    <Box
                      sx={sectionTitle}
                    >
                      <Typography
                        align="center"
                        variant="h5"
                        sx={contentSectionHeaderText}
                      >
                        Current (as per published BER)
                      </Typography>
                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Type
                      </Typography>

                      <Select
                        sx={outlinedInputStyleDropdown}
                        inputProps={{
                          sx: {
                            padding: "8px"
                          }
                        }}
                        value={String(wallTypeId)}
                        label=""
                        onChange={
                          e => setWallTypeId(
                            Number(e.target.value)
                          )
                        }
                        variant="outlined"
                      >
                        {
                          wallTypeOptions.map(
                            (heatingOption) => <MenuItem
                              value={heatingOption.value}
                              key={heatingOption.label}
                            >
                              {heatingOption.label}
                            </MenuItem>
                          )
                        }
                      </Select>

                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Thickness
                      </Typography>

                      <WhiteBackgroundTextField
                        type="number"
                        variant="outlined"
                        InputProps={{
                          startAdornment: <InputAdornment
                            position="start"
                          >
                            mm
                          </InputAdornment>,
                          inputProps: {
                            min: 0,
                            step: "0.01"
                          }
                        }}
                        onChange={
                          (event) => {
                            if (
                              event.target.value === "" ||
                              floatRegex.test(event.target.value)
                            ) {
                              setWallThickness(
                                Number(
                                  event.target.value
                                )
                              );
                            }
                          }
                        }
                        onKeyDown={floatKeyCheck}
                        value={wallThickness}
                        sx={{
                          flex: 1,
                          marginLeft: "24px"
                        }}
                      />

                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Insulation type
                      </Typography>

                      <Select
                        sx={outlinedInputStyleDropdown}
                        inputProps={{
                          sx: {
                            padding: "8px"
                          }
                        }}
                        value={String(existingInsulationType)}
                        label=""
                        onChange={
                          e => setExistingInsulationType(
                            Number(e.target.value)
                          )
                        }
                        variant="outlined"
                      >
                        {
                          insulationTypeOptions.map(
                            (heatingOption) => <MenuItem
                              value={heatingOption.value}
                              key={heatingOption.label}
                            >
                              {heatingOption.label}
                            </MenuItem>
                          )
                        }
                      </Select>
                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Insulation thickness
                      </Typography>

                      <WhiteBackgroundTextField
                        type="number"
                        variant="outlined"
                        InputProps={{
                          startAdornment: <InputAdornment
                            position="start"
                          >
                            mm
                          </InputAdornment>,
                          inputProps: {
                            min: 0,
                            step: "0.01"
                          }
                        }}
                        onChange={
                          (event) => {
                            if (
                              event.target.value === "" ||
                              floatRegex.test(event.target.value)
                            ) {
                              setExistingInsulationThickness(
                                Number(
                                  event.target.value
                                )
                              );
                            }
                          }
                        }
                        onKeyDown={floatKeyCheck}
                        value={existingInsulationThickness}
                        sx={{
                          flex: 1,
                          marginLeft: "24px"
                        }}
                      />

                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        U-value
                      </Typography>

                      <WhiteBackgroundTextField
                        type="number"
                        variant="outlined"
                        InputProps={{
                          startAdornment: <InputAdornment
                            position="start"
                          >
                            W/m&#178;K
                          </InputAdornment>,
                          inputProps: {
                            min: 0,
                            step: "0.01"
                          }
                        }}
                        onChange={
                          (event) => {
                            if (
                              event.target.value === "" ||
                              floatRegex.test(event.target.value)
                            ) {
                              setExistingUValue(
                                Number(
                                  event.target.value
                                )
                              );
                            }
                          }
                        }
                        onKeyDown={floatKeyCheck}
                        value={existingUValue}
                        sx={{
                          flex: 1,
                          marginLeft: "24px"
                        }}
                      />

                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        U-value basis
                      </Typography>

                      <Typography
                        sx={{
                          marginLeft: "16px",
                          flex: 1,
                          textAlign: "right"
                        }}
                      >
                        {
                          uValueBasis === "true" ? "Default" : "Non default"
                        }

                      </Typography>

                    </Box>

                  </Box>

                  {/* SECTION PROPOSED */}
                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "5px",
                      border: 1,
                      borderColor: "#71717a",
                      padding: "0px 16px 16px 16px",
                      gap: "8px"
                    }}
                  >

                    <Box
                      sx={sectionTitle}
                    >
                      <Typography
                        align="center"
                        variant="h5"
                        sx={contentSectionHeaderText}
                      >
                        Proposed upgrade
                      </Typography>
                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Insulation type
                      </Typography>

                      <Select
                        sx={outlinedInputStyleDropdown}
                        inputProps={{
                          sx: {
                            padding: "8px"
                          }
                        }}
                        value={String(proposedInsulationType)}
                        label=""
                        onChange={
                          event => {
                            const newValue = Number(event.target.value);

                            setProposedInsulationType(
                              newValue
                            )

                            setProposedInsulationThermalConductivity(
                              newValue
                            )
                          }
                        }
                        variant="outlined"
                      >
                        {
                          insulationTypeOptions.map(
                            (heatingOption) => <MenuItem
                              value={heatingOption.value}
                              key={heatingOption.label}
                            >
                              {heatingOption.label}
                            </MenuItem>
                          )
                        }
                      </Select>
                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Insulation thickness
                      </Typography>

                      <WhiteBackgroundTextField
                        type="number"
                        variant="outlined"
                        InputProps={{
                          startAdornment: <InputAdornment
                            position="start"
                          >
                            mm
                          </InputAdornment>,
                          inputProps: {
                            min: 0,
                            step: 1
                          }
                        }}
                        onChange={
                          (event) => {
                            if (
                              event.target.value === "" ||
                              floatRegex.test(event.target.value)
                            ) {
                              setProposedInsulationThickness(
                                Number(
                                  event.target.value
                                )
                              );
                            }
                          }
                        }
                        onKeyDown={floatKeyCheck}
                        value={proposedInsulationThickness}
                        sx={{
                          flex: 1,
                          marginLeft: "24px"
                        }}
                      />

                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Insulation location
                      </Typography>

                      <Select
                        sx={outlinedInputStyleDropdown}
                        inputProps={{
                          sx: {
                            padding: "8px"
                          }
                        }}
                        value={
                          String(
                            proposedInsulationLocation
                          )
                        }
                        label=""
                        onChange={
                          event => setProposedInsulationLocation(
                            Number(event.target.value)
                          )
                        }
                        variant="outlined"
                      >
                        {
                          wallInsulationLocationOptions.map(
                            (locationOption) => <MenuItem
                              value={locationOption.value}
                              key={locationOption.label}
                            >
                              {locationOption.label}
                            </MenuItem>
                          )
                        }
                      </Select>
                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Insulation thermal conductivity
                      </Typography>

                      <Select
                        sx={outlinedInputStyleDropdown}
                        inputProps={{
                          sx: {
                            padding: "8px"
                          }
                        }}
                        value={
                          String(proposedInsulationThermalConductivity)
                        }
                        label=""
                        onChange={
                          e => setProposedInsulationThermalConductivity(
                            Number(e.target.value)
                          )
                        }
                        variant="outlined"
                      >
                        {
                          thermalConductivityOptions.map(
                            (heatingOption) => <MenuItem
                              value={heatingOption.value}
                              key={heatingOption.label}
                            >
                              {heatingOption.label}
                            </MenuItem>
                          )
                        }
                      </Select>
                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        U-value after upgrade
                      </Typography>

                      <WhiteBackgroundTextField
                        type="number"
                        variant="outlined"
                        InputProps={{
                          startAdornment: <InputAdornment
                            position="start"
                          >
                            W/m&#178;K
                          </InputAdornment>,
                          inputProps: {
                            min: 0,
                            step: "0.01"
                          }
                        }}
                        onChange={
                          (event) => {
                            if (
                              event.target.value === "" ||
                              floatRegex.test(event.target.value)
                            ) {
                              setScenarioUValue(
                                Number(
                                  event.target.value
                                )
                              );
                            }
                          }
                        }
                        onKeyDown={floatKeyCheck}
                        value={scenarioUValue}
                        sx={{
                          flex: 1,
                          marginLeft: "24px"
                        }}
                      />

                    </Box>

                    <Box
                      sx={labelAndInputBox}
                    >
                      <Typography
                        sx={inputLabelVariableWidth}
                      >
                        Meets requirements for BEH grant?
                      </Typography>

                      <RadioGroup
                        sx={{
                          marginLeft: "24px"
                        }}
                        row
                        value={
                          requirementsForBEHWallGrant
                        }
                        onChange={
                          event => setRequirementsForBEHWallGrant(
                            (event.target as HTMLInputElement).value
                          )
                        }
                      >
                        <FormControlLabel
                          value="true"
                          control={
                            <Radio />
                          }
                          label="Yes"
                        />

                        <FormControlLabel
                          value="false"
                          control={
                            <Radio />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    </Box>

                  </Box>
                </Box>

                {/* SECTION OTHER DETAILS */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "5px",
                    border: 1,
                    borderColor: "#71717a",
                    padding: "16px",
                    gap: "8px"
                  }}
                >

                  <Typography>
                    Other details/comments (include other info for contractor here)
                  </Typography>

                  <TextField
                    sx={{
                      flex: 1,
                      backgroundColor: "white",
                      width: "100%"
                    }}
                    multiline
                    value={otherDetailsComments}
                    onChange={
                      event => setOtherDetailsComments(
                        event.target.value
                      )
                    }
                  />

                </Box>

              </Box>

            </Box> : <Box
              sx={{
                flex: 1,
                padding: "24px",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Typography>
                This dwelling has no walls
              </Typography>

            </Box>
          }
        </Box>

      </DialogContent>

      <DialogActions>

        <DwellingAndScenarioBerGrades
          dwellingDetail={
            String(taf.dwelling_mprn_reference)
          }
          dwellingRating={taf.dwelling_rating}
          scenarioDetail={taf.scenario_title}
          scenarioRating={taf.scenario_rating}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleBackButtonClick}
        >
          Back
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleNextButtonClick}
        >
          Next
        </Button>

      </DialogActions>
    </Dialog>
  );
}
export const RetroKitRoutes = {
  DASHBOARD: '/',
  LOGIN: '/login',
  UPLOADS: '/uploads',
  GUIDE: '/guide',
  ACCOUNT: '/account',
  DWELLINGS: '/dwellings',
  DWELLING_DETAIL: (id: string) => `/dwellings/${id}`,
  PROJECTS: '/projects',
  PROJECT_DETAIL: (id: string) => `/projects/${id}`,
  SCENARIO: '/scenarios',
  SCENARIO_DETAIL: (id: string) => `/scenarios/${id}`,
  MEASURE: '/measures',
  MEASURE_DETAIL: (id: string) => `/measures/${id}`,
} as const;

export const PARAM_PROJECT_ID = 'projectId';
export const PARAM_DWELLING_ID = 'dwellingId';
export const PARAM_SCENARIO_ID = 'scenarioId';
export const PARAM_MEASURE_ID = 'measureId';
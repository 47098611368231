import type {
  ChangeEvent
} from "react";
import React, {
  useState,
  useEffect
} from "react";

import {enGB} from "date-fns/locale/en-GB";

import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { blue } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {
  LocalizationProvider
} from "@mui/x-date-pickers";
import {
  AdapterDateFns
} from "@mui/x-date-pickers/AdapterDateFnsV3";

import {
  DwellingAndScenarioBerGrades
} from "common/components/DwellingAndScenarioBerGrades";
import {
  WhiteBackgroundTextField
} from "common/components/WhiteBackgroundTextField";
import {
  floatKeyCheck,
  floatRegex,
  integerKeyCheck,
  integerRegex
} from "common/utils/numbers";
import {
  TechnicalAssessmentFormHeader
} from "features/technicalAssessmentForm/dialogs/common/TechnicalAssessmentFormHeader";

import type {
  UpdateTAFApiRequest
} from "features/technicalAssessmentForm/api/types";
import type {
  TechnicalAssessmentForm
} from "features/technicalAssessmentForm/types";



interface TechnicalAssessmentFormModal1Props {
  isTafUpdating: boolean;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  setTafStep: (newStep: number) => void;
  taf: TechnicalAssessmentForm;
  tafStep: number;
  updateTAF: (requestArgs: UpdateTAFApiRequest) => Promise<TechnicalAssessmentForm>;
}


export function TechnicalAssessmentFormModal1(
  {
    isTafUpdating,
    showModal,
    setShowModal,
    tafStep,
    taf,
    setTafStep,
    updateTAF
  }: TechnicalAssessmentFormModal1Props
) {

  const [
    inputError,
    setInputError
  ] = useState<boolean>(false);

  const [
    homeOwnerName,
    setHomeOwnerName
  ] = useState<string>("");

  const [
    dwellingAddressSameAsHomeowner,
    setDwellingAddressSameAsHomeowner
  ] = useState<boolean>(false);

  const [
    homeownerAddress1,
    setHomeownerAddress1
  ] = useState<string>("");

  const [
    homeownerAddress2,
    setHomeownerAddress2
  ] = useState<string>("");

  const [
    homeownerAddress3,
    setHomeownerAddress3
  ] = useState<string>("");

  const [
    homeownerCounty,
    setHomeownerCounty
  ] = useState<string>("");

  const [
    homeownerPostcode,
    setHomeownerPostcode
  ] = useState<string>("");

  const [
    costOfTechnicalAdvisorService,
    setCostOfTechnicalAdvisorService
  ] = useState<number>(0);

  const [
    technicalAdvisorName,
    setTechnicalAdvisorName
  ] = useState<string>("");

  const [
    berAssessorNumber,
    setBerAssessorNumber
  ] = useState<number>(0);

  const [
    dwellingAddress1,
    setDwellingAddress1
  ] = useState<string>("");

  const [
    dwellingAddress2,
    setDwellingAddress2
  ] = useState<string>("");

  const [
    dwellingAddress3,
    setDwellingAddress3
  ] = useState<string>("");

  const [
    dwellingCounty,
    setDwellingCounty
  ] = useState<string>("");

  const [
    dwellingPostcode,
    setDwellingPostcode
  ] = useState<string>("");

  const [
    dwellingTotalFloorArea,
    setDwellingTotalFloorArea
  ] = useState<number>(0);

  const [
    dwellingDateOfConstruction,
    setDwellingDateOfConstruction
  ] = useState<Date | null>(null);

  const [
    dwellingBERNumber,
    setDwellingBERNumber
  ] = useState(0);

  const [
    dwellingMPRNNumber,
    setDwellingMPRNNumber
  ] = useState<number>(0);

  const [
    dwellingMainSpaceHeatingFuel,
    setDwellingMainSpaceHeatingFuel
  ] = useState<number>(0);

  const [
    dwellingMainHeatingSystemEfficiency,
    setDwellingMainHeatingSystemEfficiency
  ] = useState<number>(0);

  useEffect(
    () => {
      setHomeOwnerName(
        taf.home_owner_name
      );
      setDwellingAddressSameAsHomeowner(
        taf.dwelling_address_same_as_homeowner_address
      );
      setHomeownerAddress1(
        taf.home_owner_address_line1
      );
      setHomeownerAddress2(
        taf.home_owner_address_line2
      );
      setHomeownerAddress3(
        taf.home_owner_address_line3
      );
      setHomeownerCounty(
        taf.home_owner_county
      );
      setHomeownerPostcode(
        taf.home_owner_postcode
      );
      setCostOfTechnicalAdvisorService(
        taf.cost_of_technical_advisor_service
      );
      setTechnicalAdvisorName(
        taf.technical_advisor_name
      );
      setBerAssessorNumber(
        taf.ber_assessor_number
      );
      setDwellingAddress1(
        taf.dwelling_address_line1
      );
      setDwellingAddress2(
        taf.dwelling_address_line2
      );
      setDwellingAddress3(
        taf.dwelling_address_line3
      );
      setDwellingCounty(
        taf.dwelling_county
      );
      setDwellingPostcode(
        taf.dwelling_postcode
      );
      setDwellingTotalFloorArea(
        Number(
          taf.total_floor_area.toFixed(2)
        )
      );
      setDwellingDateOfConstruction(
        taf.dwelling_date_of_construction
      );
      setDwellingBERNumber(
        taf.dwelling_ber_number
      );
      setDwellingMPRNNumber(
        taf.dwelling_mprn_reference
      );
      setDwellingMainSpaceHeatingFuel(
        taf.system.main_sh_fuel
      );
      setDwellingMainHeatingSystemEfficiency(
        Number(
          taf.system.hs_main_system_efficiency.toFixed(2)
        )
      );
    },
    [
      taf.ber_assessor_number,
      taf.cost_of_technical_advisor_service,
      taf.dwelling_address_line1,
      taf.dwelling_address_line2,
      taf.dwelling_address_line3,
      taf.dwelling_address_same_as_homeowner_address,
      taf.dwelling_ber_number,
      taf.dwelling_county,
      taf.dwelling_date_of_construction,
      taf.dwelling_mprn_reference,
      taf.dwelling_postcode,
      taf.home_owner_address_line1,
      taf.home_owner_address_line2,
      taf.home_owner_address_line3,
      taf.home_owner_county,
      taf.home_owner_name,
      taf.home_owner_postcode,
      taf.system.hs_main_system_efficiency,
      taf.system.main_sh_fuel,
      taf.technical_advisor_name,
      taf.total_floor_area
    ]
  );


  const closeModal = () => {
    setShowModal(false);
  };

  const handleUpdateTAF = (dialogNumber: number) => {

    const requestBody: UpdateTAFApiRequest = {
      technical_assessment_form_id: taf.id,
      home_owner_name: homeOwnerName,
      dwelling_address_same_as_homeowner_address: dwellingAddressSameAsHomeowner,
      home_owner_address_line1: homeownerAddress1,
      home_owner_address_line2: homeownerAddress2,
      home_owner_address_line3: homeownerAddress3,
      home_owner_county: homeownerCounty,
      home_owner_postcode: homeownerPostcode,
      cost_of_technical_advisor_service: costOfTechnicalAdvisorService,
      technical_advisor_name: technicalAdvisorName,
      ber_assessor_number: berAssessorNumber,
      dwelling_address_line1: dwellingAddress1,
      dwelling_address_line2: dwellingAddress2,
      dwelling_address_line3: dwellingAddress3,
      dwelling_county: dwellingCounty,
      dwelling_postcode: dwellingPostcode,
      total_floor_area: dwellingTotalFloorArea,
      dwelling_ber_number: dwellingBERNumber,
      dwelling_mprn_reference: dwellingMPRNNumber,
      system: {
        hs_main_system_efficiency: dwellingMainHeatingSystemEfficiency,
        main_sh_fuel: dwellingMainSpaceHeatingFuel
      }
    };

    if (dwellingDateOfConstruction !== null) {
      // eslint-disable-next-line prefer-destructuring
      requestBody.dwelling_date_of_construction = dwellingDateOfConstruction.toISOString().split("T")[0];
    }

    updateTAF(
      requestBody
    ).then(
      (response) => {
        if (response) {
          setTafStep(dialogNumber);
        }
      }
    ).catch(
      (error) => console.log("Error while updating TAF:", error)
    );
  };

  const handleNextButtonClick = () => {
    handleUpdateTAF(2);
  };

  const handleSliderChanged = (dialogNumber: number) => {
    if (!inputError) {
      handleUpdateTAF(dialogNumber);
    }
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {

    setDwellingAddressSameAsHomeowner(event.target.checked);

    if (event.target.checked) {
      setHomeownerAddress1(dwellingAddress1);
      setHomeownerAddress2(dwellingAddress2);
      setHomeownerAddress3(dwellingAddress3);
      setHomeownerCounty(dwellingCounty);
      setHomeownerPostcode(dwellingPostcode);
    }
  };

  const mainHeatingOptions = [
    {
      "label": "Not set",
      "value": 0
    },
    {
      "label": "Mains Gas",
      "value": 2
    },
    {
      "label": "Bulk LPG (propane or butane)",
      "value": 3
    },
    {
      "label": "Bottled LPG",
      "value": 4
    },
    {
      "label": "Heating Oil",
      "value": 5
    },
    {
      "label": "House Coal",
      "value": 6
    },
    {
      "label": "Anthracite",
      "value": 7
    },
    {
      "label": "Manufactured Smokeless Fuel",
      "value": 8
    },
    {
      "label": "Peat Briquettes",
      "value": 9
    },
    {
      "label": "Sod Peat",
      "value": 10
    },
    {
      "label": "Wood Logs",
      "value": 11
    },
    {
      "label": "Wood Pellets (in bags)",
      "value": 12
    },
    {
      "label": "Wood Pellets (bulk supply)",
      "value": 13
    },
    {
      "label": "Wood Chips",
      "value": 14
    },
    {
      "label": "Solid Multi-Fuel",
      "value": 15
    },
    {
      "label": "Electricity - Standard",
      "value": 16
    },
    {
      "label": "Electricity - On-peak Night-Rate",
      "value": 17
    },
    {
      "label": "Electricity - Off-peak Night-Rate",
      "value": 18
    },
    {
      "label": "Waste Combustion",
      "value": 20
    },
    {
      "label": "Biomass or Biogas",
      "value": 21
    },
    {
      "label": "Electricity",
      "value": 28
    },
    {
      "label": "Biodiesel from renewable sources",
      "value": 28029
    },
    {
      "label": "Bioethanol from renewable sources",
      "value": 28030
    }
  ];

  const yearOptions = [];
  for (let index = 1900; index <= new Date().getFullYear(); index += 1) {
    yearOptions.push(
      {
        "label": index,
        "value": index
      }
    );
  }

  const contentSectionHeaderText = {
    color: "#71717a"
  };

  const labelAndInputBox = {
    display: "flex"
  };

  const iconSpacer = {
    width: "48px"
  };

  const inputLabel = {
    display: "flex",
    alignItems: "center",
    width: "70px"
  };

  const inputLabelVariableWidthTechnicalAdvisor = {
    display: "flex",
    alignItems: "center",
    width: "128px"
  };

  const inputLabelVariableWidthNumber = {
    display: "flex",
    alignItems: "center",
    flex: 1
  };

  const outlinedInputStyle = {
    flex: 1,
    marginLeft: "24px",
    backgroundColor: "white"
  };

  const sectionTitle = {
    marginTop: "8px"
  };

  const outlinedInputStyleDropdown = {
    flex: 1,
    marginLeft: "24px",
    backgroundColor: "white",
    paddingLeft: "8px",
    paddingRight: "8px",
    overflowWrap: "break-word",
    width: "10rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  };

  const outlinedInputReducedPadding = {
    style: {
      padding: "8px"
    }
  };

  return <LocalizationProvider
    dateAdapter={AdapterDateFns}
    adapterLocale={enGB}
  >
    <Dialog
      open={showModal}
      onClose={closeModal}
      maxWidth="lg"
      fullWidth
      scroll="paper"
      sx={{
        padding: "16px"
      }}
    >

      <TechnicalAssessmentFormHeader
        changeTafStep={handleSliderChanged}
        closeModal={closeModal}
        tafStep={tafStep}
      />

      <DialogContent
        sx={{
          padding: "0px 8px 0px 8px"
        }}
      >

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "5px",
            backgroundColor: "#e0dede"
          }}
        >

          {/* SECTION TITLE */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "24px",
              paddingTop: "24px",
              paddingRight: "24px",
              paddingBottom: "16px"
            }}
          >
            <img
              alt="house icon"
              style={{
                width: "60px",
                paddingRight: "16px"
              }}
              src="https://rk-staging-static.s3.eu-west-1.amazonaws.com/static/public/images/hea/icon-title-home.svg"
            />

            <Typography
              sx={{
                display: "flex",
                alignItems: "center"
              }}
              variant="h5"
            >
              Admin Information and Homeowner Declaration
            </Typography>

            <Tooltip
              title={
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: 12
                  }}
                >
                  Please complete all fields in this report generator.
                  These will be used to populate your SEAI Technical Assessment
                  form in Microsoft Excel. Any fields you edit here will be
                  edited in your report but not in your source data in RetroKit.<br />
                  <br />
                  RetroKit has populated as many fields as possible from the
                  initial BER assessment and the retrofit scenario you have
                  selected for this dwelling.
                </Typography>
              }
            >

              <IconButton>

                <InfoIcon
                  sx={{
                    color: blue[500]
                  }}
                />

              </IconButton>

            </Tooltip>

          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "8px"
            }}
          >

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                paddingLeft: "8px",
                paddingBottom: "8px",
                gap: "8px"
              }}
            >

              {/* SECTION HOMEOWNER */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "5px",
                  border: 1,
                  borderColor: "#71717a",
                  padding: "0px 16px 16px 16px",
                  gap: "8px"
                }}
              >

                <Box
                  sx={sectionTitle}
                >
                  <Typography
                    align="center"
                    variant="h5"
                    sx={contentSectionHeaderText}
                  >
                    Homeowner
                  </Typography>

                </Box>

                <Box
                  sx={labelAndInputBox}
                >

                  <Typography
                    sx={inputLabel}
                  >
                    Name
                  </Typography>

                  <OutlinedInput
                    sx={outlinedInputStyle}
                    placeholder=""
                    inputProps={outlinedInputReducedPadding}
                    value={homeOwnerName}
                    onChange={
                      e => {
                        setHomeOwnerName(e.target.value);
                      }
                    }
                  />

                  <Box
                    sx={iconSpacer}
                  />

                </Box>

                <Box
                  sx={labelAndInputBox}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flex: 1
                    }}
                  >
                    Is address same as the dwelling?
                  </Typography>

                  <Checkbox
                    onChange={handleCheckboxChange}
                    checked={dwellingAddressSameAsHomeowner}
                  />

                  <Box
                    sx={iconSpacer}
                  />

                </Box>

                <Box
                  sx={labelAndInputBox}
                >

                  <Typography
                    sx={inputLabel}
                  >
                    Address
                  </Typography>

                  <OutlinedInput
                    sx={outlinedInputStyle}
                    placeholder=""
                    inputProps={outlinedInputReducedPadding}
                    value={homeownerAddress1}
                    onChange={
                      e => setHomeownerAddress1(e.target.value)
                    }
                  />

                  <Box
                    sx={iconSpacer}
                  />

                </Box>

                <Box
                  sx={labelAndInputBox}
                >

                  <Typography
                    sx={inputLabel}
                  />

                  <OutlinedInput
                    sx={outlinedInputStyle}
                    placeholder=""
                    inputProps={outlinedInputReducedPadding}
                    value={homeownerAddress2}
                    onChange={
                      e => setHomeownerAddress2(e.target.value)
                    }
                  />

                  <Box
                    sx={iconSpacer}
                  />

                </Box>

                <Box
                  sx={labelAndInputBox}
                >
                  <Typography
                    sx={inputLabel}
                  />

                  <OutlinedInput
                    sx={outlinedInputStyle}
                    placeholder=""
                    inputProps={outlinedInputReducedPadding}
                    value={homeownerAddress3}
                    onChange={
                      e => setHomeownerAddress3(e.target.value)
                    }
                  />

                  <Box
                    sx={iconSpacer}
                  />

                </Box>

                <Box
                  sx={labelAndInputBox}
                >
                  <Typography
                    sx={inputLabel}
                  >
                    County
                  </Typography>

                  <OutlinedInput
                    sx={outlinedInputStyle}
                    placeholder=""
                    inputProps={outlinedInputReducedPadding}
                    value={homeownerCounty}
                    onChange={
                      e => setHomeownerCounty(e.target.value)
                    }
                  />

                  <Box
                    sx={iconSpacer}
                  />

                </Box>

                <Box
                  sx={labelAndInputBox}
                >
                  <Typography
                    sx={inputLabel}
                  >
                    Eircode
                  </Typography>

                  <OutlinedInput
                    sx={outlinedInputStyle}
                    placeholder=""
                    inputProps={outlinedInputReducedPadding}
                    value={homeownerPostcode}
                    onChange={e =>
                      setHomeownerPostcode(e.target.value)
                    }
                  />

                  <Box
                    sx={iconSpacer}
                  />

                </Box>

                <Box
                  sx={labelAndInputBox}
                >

                  <Box
                    sx={iconSpacer}
                  />

                </Box>

                <Box
                  sx={labelAndInputBox}
                 />

              </Box>

              {/* SECTION TECHNICAL ADVISOR */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "5px",
                  border: 1,
                  borderColor: "#71717a",
                  padding: "0px 16px 16px 16px",
                  gap: "8px"
                }}
              >

                <Box
                  sx={sectionTitle}
                >
                  <Typography
                    align="center"
                    variant="h5"
                    sx={contentSectionHeaderText}
                  >
                    Technical Advisor Service
                  </Typography>
                </Box>

                <Box
                  sx={labelAndInputBox}
                >

                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "128px",
                      justifyContent: "flex-end"
                    }}
                  />

                  <WhiteBackgroundTextField
                    type="number"
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment
                        position="start"
                      >
                        €
                      </InputAdornment>,
                      inputProps: {
                        min: 0,
                        step: "0.01"
                      }
                    }}
                    onChange={
                      (event) => {
                        if (
                          event.target.value === "" ||
                          floatRegex.test(event.target.value)
                        ) {
                          setCostOfTechnicalAdvisorService(
                            Number(
                              event.target.value
                            )
                          );
                        }
                      }
                    }
                    onKeyDown={floatKeyCheck}
                    value={costOfTechnicalAdvisorService}
                    sx={{
                      flex: 1,
                      marginLeft: "24px"
                    }}
                    // error={
                    //   numericRegex.test(costOfTechnicalAdvisorService)
                    // }
                    // helperText={
                    //   Number.isNaN(
                    //     parseFloat(costOfTechnicalAdvisorService)
                    //   ) ? "Input must be numeric" : ""
                    // }
                  />

                  <Tooltip
                    title={
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: 12
                        }}
                      >
                        Cost of delivering the service includes advice,
                        Technical Assessment and, if carried out, a BER
                        publication prior to works. Don&#39;t include
                        cost of BER post works in this figure. SEAI do
                        not charge a fee for submission of Technical
                        Assessments. Cost is agreed between Technical
                        Advisor and homeowner.
                      </Typography>
                    }
                  >
                    <IconButton
                      sx={{
                        marginLeft: "8px"
                      }}
                    >

                      <InfoIcon
                        sx={{
                          color: blue[500]
                        }}
                      />

                    </IconButton>

                  </Tooltip>

                </Box>

                <Box
                  sx={labelAndInputBox}
                >

                  <Typography
                    sx={inputLabelVariableWidthTechnicalAdvisor}
                  >
                    Advisor name
                  </Typography>

                  <OutlinedInput
                    sx={outlinedInputStyle}
                    placeholder=""
                    inputProps={outlinedInputReducedPadding}
                    value={technicalAdvisorName}
                    onChange={
                      e => setTechnicalAdvisorName(e.target.value)
                    }
                  />

                  <Box
                    sx={iconSpacer}
                  />

                </Box>

                <Box sx={labelAndInputBox}>

                  <Typography
                    sx={inputLabelVariableWidthTechnicalAdvisor}
                  >
                    BER Assessor no
                  </Typography>

                  <WhiteBackgroundTextField
                    type="number"
                    variant="outlined"
                    InputProps={{
                      inputProps: {
                        min: 0,
                        step: 1
                      }
                    }}
                    onChange={
                      (event) => {
                        if (
                          event.target.value === "" ||
                          integerRegex.test(event.target.value)
                        ) {
                          setBerAssessorNumber(
                            Number(
                              event.target.value
                            )
                          );
                        }
                      }
                    }
                    onKeyDown={integerKeyCheck}
                    value={berAssessorNumber}
                    sx={{
                      flex: 1,
                      marginLeft: "24px"
                    }}
                    error={
                      String(berAssessorNumber).length !== 6
                    }
                    helperText={
                      String(berAssessorNumber).length !== 6 ?
                        "BER Assessor number must be 6 digit numeric value" : ""
                    }
                  />

                  <Box
                    sx={iconSpacer}
                  />
                </Box>

              </Box>

            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                paddingRight: "8px",
                paddingBottom: "8px",
                gap: "8px"
              }}
            >
              {/* SECTION DWELLING */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "5px",
                  border: 1,
                  borderColor: "#71717a",
                  padding: "0px 16px 16px 16px",
                  height: "100%",
                  gap: "8px"
                }}
              >
                <Box
                  sx={sectionTitle}
                >
                  <Typography
                    align="center"
                    variant="h5"
                    sx={contentSectionHeaderText}
                  >
                    Dwelling
                  </Typography>

                </Box>

                <Box
                  sx={labelAndInputBox}
                >
                  <Typography
                    sx={inputLabel}
                  >
                    Address
                  </Typography>

                  <OutlinedInput
                    sx={outlinedInputStyle}
                    placeholder=""
                    inputProps={outlinedInputReducedPadding}
                    value={dwellingAddress1}
                    onChange={
                      e =>
                        setDwellingAddress1(e.target.value)
                    }
                    disabled={dwellingAddressSameAsHomeowner}
                  />

                  <Box
                    sx={iconSpacer}
                  />

                </Box>

                <Box
                  sx={labelAndInputBox}
                >

                  <Typography
                    sx={inputLabel}
                  />

                  <OutlinedInput
                    sx={outlinedInputStyle}
                    placeholder=""
                    inputProps={outlinedInputReducedPadding}
                    value={dwellingAddress2}
                    onChange={
                      e =>
                        setDwellingAddress2(e.target.value)
                    }
                    disabled={dwellingAddressSameAsHomeowner}
                  />

                  <Box
                    sx={iconSpacer}
                  />
                </Box>

                <Box
                  sx={labelAndInputBox}
                >

                  <Typography
                    sx={inputLabel}
                  />

                  <OutlinedInput
                    sx={outlinedInputStyle}
                    placeholder=""
                    inputProps={outlinedInputReducedPadding}
                    value={dwellingAddress3}
                    onChange={
                      e =>
                        setDwellingAddress3(e.target.value)
                    }
                    disabled={dwellingAddressSameAsHomeowner}
                  />

                  <Box
                    sx={iconSpacer}
                  />

                </Box>

                <Box
                  sx={labelAndInputBox}
                >
                  <Typography
                    sx={inputLabel}
                  >
                    County
                  </Typography>

                  <OutlinedInput
                    sx={outlinedInputStyle}
                    placeholder=""
                    inputProps={outlinedInputReducedPadding}
                    value={dwellingCounty}
                    onChange={
                      e =>
                        setDwellingCounty(e.target.value)
                    }
                    disabled={dwellingAddressSameAsHomeowner}
                  />

                  <Box
                    sx={iconSpacer}
                  />

                </Box>

                <Box
                  sx={labelAndInputBox}
                >
                  <Typography
                    sx={inputLabel}
                  >
                    Eircode
                  </Typography>

                  <OutlinedInput
                    sx={outlinedInputStyle}
                    placeholder=""
                    inputProps={outlinedInputReducedPadding}
                    value={dwellingPostcode}
                    onChange={
                      e =>
                        setDwellingPostcode(e.target.value)
                    }
                    disabled={dwellingAddressSameAsHomeowner}
                  />
                  <Box
                    sx={iconSpacer}
                  />
                </Box>

                <Box
                  sx={labelAndInputBox}
                >
                  <Typography
                    sx={inputLabelVariableWidthNumber}
                  >
                    Floor area
                  </Typography>

                  <WhiteBackgroundTextField
                    type="number"
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment
                        position="start"
                      >
                        m&#178;
                      </InputAdornment>,
                      inputProps: {
                        min: 0,
                        step: "0.01"
                      }
                    }}
                    onChange={
                      (event) => {
                        if (
                          event.target.value === "" ||
                          floatRegex.test(event.target.value)
                        ) {
                          setDwellingTotalFloorArea(
                            Number(
                              event.target.value
                            )
                          );
                        }
                      }
                    }
                    onKeyDown={floatKeyCheck}
                    value={dwellingTotalFloorArea}
                    sx={{
                      flex: 1,
                      marginLeft: "24px"
                    }}
                  />

                  <Box
                    sx={iconSpacer}
                  />

                </Box>

                <Box
                  sx={labelAndInputBox}
                >
                  <Typography
                    sx={inputLabelVariableWidthNumber}
                  >
                    Year constructed
                  </Typography>

                  <Select
                    sx={outlinedInputStyleDropdown}
                    inputProps={{
                      sx: {
                        padding: "8px"
                      }
                    }}
                    value={
                      String(
                        dwellingDateOfConstruction !== null ?
                          dwellingDateOfConstruction.getFullYear() : 1900
                      )
                    }
                    label=""
                    onChange={
                      e =>
                        setDwellingDateOfConstruction(
                          new Date(
                            String(e.target.value)
                          )
                        )
                    }
                    variant="outlined"
                  >
                    {
                      yearOptions.map(
                        (yearOption) => <MenuItem
                          key={yearOption.value}
                          value={yearOption.value}
                        >
                          {yearOption.label}
                        </MenuItem>
                      )
                    }
                  </Select>

                  <Box
                    sx={iconSpacer}
                  />

                </Box>

                <Box
                  sx={labelAndInputBox}
                >
                  <Typography
                    sx={inputLabel}
                  >
                    BER
                  </Typography>

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flex: 1
                    }}
                  >
                    {dwellingBERNumber}
                  </Typography>


                  <Box
                    sx={iconSpacer}
                  />

                </Box>

                <Box
                  sx={labelAndInputBox}
                >
                  <Typography
                    sx={inputLabel}
                  >
                    MPRN
                  </Typography>

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flex: 1
                    }}
                  >
                    {dwellingMPRNNumber}
                  </Typography>

                  <Box
                    sx={iconSpacer}
                  />

                </Box>

                <Box
                  sx={labelAndInputBox}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    Heating system
                  </Typography>

                  <Select
                    sx={outlinedInputStyleDropdown}
                    inputProps={{
                      sx: {
                        padding: "8px"
                      }
                    }}
                    value={String(dwellingMainSpaceHeatingFuel)}
                    label=""
                    onChange={
                      e =>
                        setDwellingMainSpaceHeatingFuel(Number(e.target.value))
                    }
                    variant="outlined"
                  >
                    {
                      mainHeatingOptions.map(
                        (heatingOption) => <MenuItem
                          key={heatingOption.value}
                          value={heatingOption.value}
                        >
                          {heatingOption.label}
                        </MenuItem>
                      )
                    }
                  </Select>

                  <Tooltip
                    title={
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: 12
                        }}
                      >
                        Heat Pump Grants under the Better Energy
                        Homes Programme are only available for
                        first time systems. The Programme is not
                        intended to support Heat Pump system
                        installations or upgrades which have
                        previously had a Heat Pump system
                        installed (whether or not such system
                        is functional at the time of the
                        application)
                      </Typography>
                    }
                  >
                    <IconButton
                      sx={{
                        marginLeft: "8px"
                      }}
                    >
                      <InfoIcon
                        sx={{
                          color: blue[500]
                        }}
                      />
                    </IconButton>

                  </Tooltip>

                </Box>
                <Box
                  sx={labelAndInputBox}
                >
                  <Typography
                    sx={inputLabelVariableWidthNumber}
                  >
                    Efficiency
                  </Typography>

                  <WhiteBackgroundTextField
                    type="number"
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment
                        position="start"
                      >
                        %
                      </InputAdornment>,
                      inputProps: {
                        min: 0,
                        max: 100,
                        step: "0.01"
                      }
                    }}
                    onChange={
                      (event) => {
                        if (
                          event.target.value === "" ||
                          floatRegex.test(event.target.value)
                        ) {
                          setDwellingMainHeatingSystemEfficiency(
                            Number(
                              event.target.value
                            )
                          );
                        }
                      }
                    }
                    onKeyDown={floatKeyCheck}
                    value={
                      dwellingMainHeatingSystemEfficiency
                    }
                    sx={{
                      flex: 1,
                      marginLeft: "24px"
                    }}
                  />

                  <Box
                    sx={iconSpacer}
                  />
                </Box>
              </Box>

            </Box>

          </Box>

        </Box>

      </DialogContent>

      <DialogActions>

        <DwellingAndScenarioBerGrades
          dwellingDetail={
            String(dwellingMPRNNumber)
          }
          dwellingRating={taf.dwelling_rating}
          scenarioDetail={taf.scenario_title}
          scenarioRating={taf.scenario_rating}
        />

        <Button
          variant="contained"
          color="secondary"
          onClick={handleNextButtonClick}
        >
          Next
        </Button>

      </DialogActions>

    </Dialog>
  </LocalizationProvider>;
}
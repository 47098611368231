import { useEffect, useState } from "react";

import {
  useGetCustomMeasuresNotInProjectScenarioQuery,
  useGetDefaultMeasuresNotInProjectScenarioQuery,
  useGetProjectScenarioByIdQuery,
  useUpdateProjectScenarioCustomMeasuresMutation,
  useUpdateProjectScenarioDefaultMeasuresMutation,
  useUpdateProjectScenarioDetailsMutation
} from "features/projectScenarios/api/projectScenariosApi";


export function useEditProjectScenarioDialog(projectScenarioId: number) {

  const [
    isProjectScenarioLoading,
    setIsProjectScenarioLoading
  ] = useState(false);

  const {
    isLoading: isGetProjectScenarioLoading,
    data: projectScenario
  } = useGetProjectScenarioByIdQuery({
    id: projectScenarioId
  });

  const {
    isLoading: isGetCustomMeasuresNotInProjectScenarioLoading,
    data: customMeasuresNotInProjectScenario
  } = useGetCustomMeasuresNotInProjectScenarioQuery({
    id: projectScenarioId,
    inScenario: false
  });

  const {
    isLoading: isGetDefaultMeasuresNotInProjectScenarioLoading,
    data: defaultMeasuresNotInProjectScenario
  } = useGetDefaultMeasuresNotInProjectScenarioQuery({
    id: projectScenarioId,
    inScenario: false
  });

  const [
    updateProjectScenarioDetails
  ] = useUpdateProjectScenarioDetailsMutation();

  const [
    updateProjectScenarioDefaultMeasures
  ] = useUpdateProjectScenarioDefaultMeasuresMutation();

  const [
    updateProjectScenarioCustomMeasures
  ] = useUpdateProjectScenarioCustomMeasuresMutation();

  // Update loading state
  useEffect(() => {
    setIsProjectScenarioLoading(
      isGetProjectScenarioLoading ||
      isGetDefaultMeasuresNotInProjectScenarioLoading ||
      isGetCustomMeasuresNotInProjectScenarioLoading
    );
  }, [
    isGetProjectScenarioLoading,
    isGetDefaultMeasuresNotInProjectScenarioLoading,
    isGetCustomMeasuresNotInProjectScenarioLoading
  ]);

  return {
    projectScenario,
    customMeasuresNotInProjectScenario,
    defaultMeasuresNotInProjectScenario,
    isProjectScenarioLoading,
    updateProjectScenarioDetails,
    updateProjectScenarioDefaultMeasures,
    updateProjectScenarioCustomMeasures
  }
}

import { retrokitApi } from "common/api/retrokitApi";
import {
  paginatedDwellingsApiTransformer
} from 'features/dwellings/api/transformers';

import type { PaginatedResponse } from 'common/api/types';
import type { DwellingsListQueryParams } from 'features/dwellings/api/types';
import type { Dwelling } from 'features/dwellings/types';

export const dwellingsApi = retrokitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDwellings: builder.query<
      PaginatedResponse<Dwelling>,
      DwellingsListQueryParams
    >({
      query: (params) => ({
        url: `dwellings/minimal/`,
        method: 'GET',
        params: {
          // TODO: Change this after pagination
          page: 1,
          size: 99999,
          ...params,
        },
      }),
      providesTags: ['Dwellings'],
      transformResponse: paginatedDwellingsApiTransformer,
    }),
  }),
});

export const { useGetDwellingsQuery } = dwellingsApi;

import React, { useCallback, useState } from "react";

import { Document, Page, pdfjs } from "react-pdf";


import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import type { PaperProps } from "@mui/material/Paper";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import type { DocumentCallback } from "react-pdf/src/shared/types";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import "./DisplayPdfDialog.css";


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${
  pdfjs.version
}/build/pdf.worker.min.js`

// NOTE: This variable is required by react-pdf
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   // NOTE: This refers to an installed library - pdfjs.
//   // Ensure that this variable points to an existing file.
//   'pdfjs-dist/build/pdf.worker.min.mjs',
//   import.meta.url,
// ).toString();


interface DisplayPDFModalProps {
  open: boolean;
  reportUrl: string;
  title: string;
  onClose: () => void;
}

export function DisplayPdfDialog(
  {
    open,
    onClose,
    title,
    reportUrl
  }: DisplayPDFModalProps) {

  const [
    numberOfPages,
    setNumberOfPages
  ] = useState(0);
  const [
    pageNumber,
    setPageNumber
  ] = useState(1);

  function checkUrlContainsProtocol(link: string) {
    if (link.includes("http") || link.includes("https")) {
      return link;
    }
    return `${process.env.REACT_APP_API_BASE_URL || ""}${link}`;
  }

  const handleClose = () => {
    onClose();
    setPageNumber(1);
  };

  const handleOpenInNewTab = (link: string) => {
    window.open(link);
  };

  const onDocumentLoadSuccess = ({ numPages }: DocumentCallback) => {
    setNumberOfPages(numPages);
    setPageNumber(1);
  };

  function changePage(offset: number) {
    setPageNumber(previousPageNumber => previousPageNumber + offset);
  }

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const DialogPaperComponent = useCallback(
    (
      props: JSX.IntrinsicAttributes & PaperProps
    ) => <Paper
      elevation={0}
      sx={{
        borderWidth: "0px",
        borderRadius: "20px"
      }}
      variant="outlined"
      {...props}
    />,
    []
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      scroll="body"
      PaperProps={{
        style: {
          maxWidth: "85%"
        }
      }}
      PaperComponent={DialogPaperComponent}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          paddingTop: "8px",
          paddingBottom: "8px",
          paddingLeft: "24px",
          paddingRight: "8px"
        }}
      >
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
          variant="h5"
        >
          {title}
        </Typography>
        <Box
          sx={{
            flex: 1
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row"
          }}
        >
          {
            handleOpenInNewTab ? (
              <Tooltip
                title="Open in a new tab"
                aria-label="pdf-in-new-tab"
              >
                <IconButton
                  onClick={
                    () => handleOpenInNewTab(
                      checkUrlContainsProtocol(reportUrl)
                    )
                  }>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            ) : null
          }
          {
            handleClose ? (
              <Tooltip
                title="Close"
                aria-label="pdf-close-modal"
              >
                <IconButton
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            ) : null
          }
        </Box>
      </Box>

      <DialogContent
        sx={{
          width: "100%",
          flex: 1,
          overflowX: "hidden",
          overflowY: "auto"
        }}
      >

        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            cursor: "pointer"
          }}
          onClick={
            () => handleOpenInNewTab(
              checkUrlContainsProtocol(reportUrl)
            )
          }
        >
          <Document
            file={checkUrlContainsProtocol(reportUrl)}
            onLoadSuccess={
              onDocumentLoadSuccess
            }
          >

            <Page
              pageNumber={pageNumber}
              renderTextLayer={false}
            />

          </Document>
        </Box>

      </DialogContent>

      <DialogActions
        sx={{
          paddingRight: "24px"
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row"
          }}
        >
          <div
            style={{
              flex: 1
            }}
          />
          <Tooltip
            title="Previous page"
            aria-label="pdf-previous-page"
          >
            <IconButton
              onClick={previousPage}
              disabled={pageNumber <= 1}
            >
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
            variant="body1"
          >
            Page {pageNumber || (numberOfPages ? 1 : "--")} of {numberOfPages || "--"}
          </Typography>
          <Tooltip
            title="Next page"
            aria-label="pdf-next-page"
          >
            <IconButton
              disabled={pageNumber >= numberOfPages}
              onClick={nextPage}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Tooltip>
          <div
            style={{
              flex: 1
            }}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
}

import React, { useCallback, useState } from "react";

import MaterialTable from "@material-table/core";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { useTheme } from "@mui/material/styles";

import { DWELLING_TABLE_COLUMNS } from "common/constants/tables";

import type { Dwelling } from "features/dwellings/types";
import Paper, { PaperProps } from "@mui/material/Paper";


interface AddProjectDwellingsModalProps {
  projectTitle: string;
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  dwellings: Array<Dwelling>;
  onAddDwellings: (dwellings: Array<Dwelling>) => void;
}


export function AddDwellingsToProjectDialog(
  {
    projectTitle,
    open,
    isLoading,
    onClose,
    dwellings,
    onAddDwellings
  }: AddProjectDwellingsModalProps) {

  const theme = useTheme();


  const [
    rowsPerPage,
    setRowsPerPage
  ] = useState<number>(
    dwellings.length > 0 && dwellings.length < 5 ? dwellings.length : 5
  );

  const handleRowsPerPageChange = (pageSize: number) => {
    setRowsPerPage(pageSize);
  };

  const [
    selectedDwellings,
    setSelectedDwellings
  ] = useState<Dwelling[]>(
    []
  );

  const handleClose = useCallback(() => {
    setSelectedDwellings([]);
    onClose();
  }, [onClose, setSelectedDwellings]);

  const handleOnAddDwellingsClicked = useCallback(
    (dwellingArray: Array<Dwelling>) => {
      onAddDwellings(dwellingArray);
      handleClose();
    },
    [handleClose, onAddDwellings]
  );

  const DialogPaperComponent = useCallback(
    (
      props: JSX.IntrinsicAttributes & PaperProps
    ) => <Paper
      elevation={0}
      sx={{
        borderWidth: "0px",
        borderRadius: "20px"
      }}
      variant="outlined"
      {...props}
    />,
    []
  );

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={handleClose}
      scroll="body"
      PaperComponent={DialogPaperComponent}
    >

      <Box
        sx={{
          width: "100%",
          flex: 1,
          overflowX: "hidden",
          overflowY: "auto",
          padding: "0px"
        }}
      >
        <MaterialTable
          title={"Add dwellings to ".concat(projectTitle)}
          columns={DWELLING_TABLE_COLUMNS}
          data={dwellings}
          isLoading={isLoading}
          localization={{
            toolbar: {
              nRowsSelected: "{0} dwelling(s) selected"
            },
            body: {
              emptyDataSourceMessage: "No dwellings to display",
              filterRow: {
                filterTooltip: "Filter"
              }
            }
          }}
          options={{
            headerStyle: {
              backgroundColor: theme.palette.info.main,
              color: theme.palette.common.white,
              fontWeight: "bold",
              whiteSpace: "nowrap",
              padding: "0 16px 0 16px"
            },
            padding: "dense",
            paging: true,
            pageSize: rowsPerPage,
            pageSizeOptions: dwellings.length > 0 && dwellings.length < 5 ? [dwellings.length] : [5, 10, 20, 50],
            showTitle: true,
            selection: true,
            rowStyle: (rowData, index) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (rowData.tableData.checked === true) {
                return {
                  backgroundColor: "#fce4ec",
                  color: theme.palette.secondary.main,
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  fontSize: 14
                };
              }
              if (index % 2) {
                return {
                  backgroundColor: "#EEEEEE",
                  whiteSpace: "nowrap",
                  fontSize: 14
                };
              }
              return {
                whiteSpace: "nowrap",
                fontSize: 14
              };
            }
          }}
          onRowsPerPageChange={
            (pageSize) => handleRowsPerPageChange(pageSize)
          }
          onSelectionChange={
            (selection) => setSelectedDwellings(selection)
          }
        />

      </Box>

      <DialogActions
        sx={{
          paddingRight: "24px"
        }}
      >

        <Button
          onClick={handleClose}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleOnAddDwellingsClicked(selectedDwellings)}
          disabled={selectedDwellings.length === 0}
        >
          Add to Project
        </Button>

      </DialogActions>
    </Dialog>
  );
}
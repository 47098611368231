import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import logo from "common/assets/images/logo.png";
import { withMaterialUserInterfaceTheme } from "common/hocs/theme";

import { logUserIn } from "../../actions/authentication";


class Login extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: ""
      // language: 'English',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.logUserIn(this.state.username, this.state.password);
  }

  render() {

    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    }

    const { theme } = this.props;

    let usernameError = false;
    let passwordError = false;
    const usernameHelperText = "";
    let passwordHelperText = "";

    if (this.props.errors.length > 0) {
      if (
        this.props.errors[0].message[0] ===
        "Unable to log in with provided credentials."
      ) {
        usernameError = true;
        passwordError = true;
        passwordHelperText = this.props.errors[0].message[0];
      }
    }

    return (
      <Box
        sx={{
          height: "100%",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          backgroundColor: theme.palette.primary.main
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{
            width: 500,
            marginLeft: 0,
            paddingLeft: 0,
            paddingTop: 0
          }}
          justify="center"
        >
          <Paper
            sx={{
              borderColor: theme.palette.primary.main,
              padding: "16px"
            }}
            variant="outlined"
          >
            <form
              onSubmit={this.handleSubmit}
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
              }}
            >
              <Box
                sx={{
                  padding: "8px"
                }}
              >
                <img
                  style={{
                    maxWidth: 100,
                    maxHeight: 100
                  }}
                  src={logo}
                  alt="RK Logo"
                />
              </Box>

              <Box
                sx={{
                  textAlign: "center",
                  padding: "8px"
                }}
              >
                <Typography
                  variant="h6"
                  color="textPrimary"
                >
                  Sign in
                </Typography>
                <Typography
                  variant="body1"
                  color="textPrimary"
                >
                  Use your RetroKit account
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "8px",
                  width: "100%"
                }}
              >
                <TextField
                  sx={{
                    paddingBottom: "8px"
                  }}
                  required
                  label="Email or username"
                  variant="outlined"
                  error={usernameError}
                  helperText={usernameHelperText}
                  onInput={(event) =>
                    this.setState({
                      username: event.target.value
                        .replace("&", "&amp;", "g")
                        .replace("<", "&lt;", "g")
                        .replace(">", "&gt;", "g")
                        .replace("\"", "&quot;", "g")
                        .replace("'", "&#x27", "g")
                        .replace("/", "&#x2F", "g")
                    })
                  }
                  inputProps={{ minLength: 6 }}
                />
                <TextField
                  required
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  variant="outlined"
                  error={passwordError}
                  helperText={passwordHelperText}
                  onInput={(event) =>
                    this.setState({
                      password: event.target.value
                        .replace("&", "&amp;", "g")
                        .replace("<", "&lt;", "g")
                        .replace(">", "&gt;", "g")
                        .replace("\"", "&quot;", "g")
                        .replace("'", "&#x27", "g")
                        .replace("/", "&#x2F", "g")
                    })
                  }
                  inputProps={{ minLength: 12 }}
                />
              </Box>

              <Box
                sx={{
                  width: "100%",
                  padding: "8px"
                }}
              >
                <Typography
                  variant="body1"
                  color="textPrimary"
                >
                  Not on your own computer? Use a private browser window to
                  sign in.
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  padding: "8px"
                }}
              >
                <Box
                  sx={{
                    flex: 1
                  }}
                />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                  }}
                >
                  <ButtonGroup
                    color="primary"
                    aria-label="sign in button group"
                    sx={{
                      maxHeight: 42
                    }}
                  >
                    <Button
                      onClick={(event) => {
                        window.location.href = "mailto:help@retrokit.eu?subject=Forgot Password";
                        event.preventDefault();
                      }
                      }
                    >
                      Forgot password?
                    </Button>
                    <Button
                      color="secondary"
                      type="submit"
                      variant="contained"
                    >
                      Next
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>
            </form>
          </Paper>


          <Paper
            sx={{
              borderColor: theme.palette.primary.main,
              padding: "16px",
              marginTop: "48px",
              width: "100%"
            }}
            variant="outlined"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center"
              }}
            >

              <Typography>
                Need help? Email <a
                href="mailto:help@retrokit.eu?subject=Help with RetroKit app"
              >
                help@retrokit.eu
              </a>
              </Typography>

              {/* <FormControl
                  variant="outlined"
                  style={{
                    margin: theme.spacing(1),
                    minWidth: 120,
                  }}
                >
                  <InputLabel id="language-label">Language</InputLabel>
                  <Select
                    label="Language"
                    // onChange={handleChange}
                    value="English"
                    variant="outlined"
                  >
                    <MenuItem value="English">English</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>

                <div
                  style={{
                    flex: 1,
                  }}
                 />

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <ButtonGroup
                    color="primary"
                    aria-label="button group"
                    sx={{
                      maxHeight: 42,
                    }}
                  >
                    <Button
                      color="primary"
                      onClick={() => console.log('Provide help')}
                    >
                      Help
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => console.log('Show privacy statement')}
                    >
                      Privacy
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => console.log('Open the T&Cs')}
                    >
                      Terms
                       <Link to="/terms">Terms</Link>
                    </Button>
                  </ButtonGroup>
                </div> */}
            </Box>
          </Paper>
        </Grid>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  let errors = [];
  if (state.authentication.errors) {
    errors = Object.keys(state.authentication.errors).map((field) => ({
      field,
      message: state.authentication.errors[field]
    }));
  }
  return {
    errors,
    isAuthenticated: state.authentication.isAuthenticated
  };
};

const mapDispatchToProps = (dispatch) => ({
  logUserIn: (username, password) => {
    dispatch(logUserIn(username, password));
  }
});

Login.propTypes = {
  theme: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withMaterialUserInterfaceTheme(Login)
);

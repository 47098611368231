import {
    GET_PROJECT,
    UPDATE_PROJECT,
    DELETE_PROJECT,
    UPDATE_PROJECT_GOALS,
    ADD_PROJECT_DWELLING,
    DELETE_PROJECT_DWELLING, PROJECT_SELECTION
} from '../actions/project'

const initialState = {
    selectedIndex: 0,
    id: 0,
    title: "",
    description: "",
    created_on: "",
    goals: {},
    housing_stock: [],
    scenarios: [],
    summaries: [],
};

export default function projectReducer(state = initialState, action) {

    switch (action.type) {

        case GET_PROJECT:
            return {
                ...state, id: action.project.id, title: action.project.title, description: action.project.description,
                created_on: action.project.created_on, goals: action.project.goals,
                housing_stock: action.project.housing_stock, scenarios: action.project.scenarios,
                summaries: action.project.summaries
            };
        case UPDATE_PROJECT:
            return {
                ...state, title: action.project.title, description: action.project.description
            };
        case DELETE_PROJECT:
            return {
                ...state, id: 0, title: "", description: "", created_on: "", goals: {}, housing_stock: [],
                scenarios: [], summaries: []
            };

        case UPDATE_PROJECT_GOALS:
            return {...state, goals: action.goals};

        case ADD_PROJECT_DWELLING:
            return {...state, housing_stock: [action.housing_stock]};
        case DELETE_PROJECT_DWELLING:
            return {...state, housing_stock: []};

        case PROJECT_SELECTION:
            return {...state, selectedIndex: action.index};

        default:
            return state;
    }
}
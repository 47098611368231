import { GET_PROJECTS, ADD_PROJECT, UPDATE_PROJECT, DELETE_PROJECT} from '../actions/projects'

const initialState = [];

export default function projectsReducer(state=initialState, action) {

    switch (action.type) {
        case GET_PROJECTS:
            return [...action.projects];
        case ADD_PROJECT:
            return [...state, action.project];
        case UPDATE_PROJECT:
            return state;
        case DELETE_PROJECT:
            return state;
        default:
            return state;
    }
}
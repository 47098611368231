import React, {
  useCallback, useEffect, useState
} from "react";


import { MuiColorInput } from "mui-color-input";
import { toast } from "react-toastify";

import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import type { IconButtonProps } from "@mui/material";
import {
  colors, FormLabel, styled
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import type { PaperProps } from "@mui/material/Paper";
import Paper from "@mui/material/Paper";
import Slider from "@mui/material/Slider";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";

import type {
  UpdateHEASection1ApiRequest,
  UpdateHEASection2ApiRequest,
  UpdateHEASection4ApiRequest,
  UpdateHEASection6ApiRequest,
  UpdateHEASection7ApiRequest,
  UpdateHEASection8ApiRequest,
  UpdateHEASection10ApiRequest,
  UpdateHEASection11ApiRequest,
  UpdateHEASection12ApiRequest,
  UpdateHEASection5ApiRequest,
  UpdateHEASection3ApiRequest, UpdateHEASection9ApiRequest
} from "../api/types";
import type { ErrorResponse } from "common/api/types";
import type { Dwelling } from "features/dwellings/types";
import type {
  HomeEnergyAnnexItem
} from "features/homeEnergyAssessment/types";
import type { ProjectReportConfig } from "features/projects/types";
import type { ProjectScenario } from "features/projectScenarios/types";

import {
  HomeEnergyAssessmentDialogContent1
} from "./content/HomeEnergyAssessmentDialogContent1";
import {
  HomeEnergyAssessmentDialogContent10
} from "./content/HomeEnergyAssessmentDialogContent10";
import {
  HomeEnergyAssessmentDialogContent11
} from "./content/HomeEnergyAssessmentDialogContent11";
import {
  HomeEnergyAssessmentDialogContent12
} from "./content/HomeEnergyAssessmentDialogContent12";
import {
  HomeEnergyAssessmentDialogContent2
} from "./content/HomeEnergyAssessmentDialogContent2";
import { 
  HomeEnergyAssessmentDialogContent3 
} from "./content/HomeEnergyAssessmentDialogContent3";
import {
  HomeEnergyAssessmentDialogContent4
} from "./content/HomeEnergyAssessmentDialogContent4";
import { 
  HomeEnergyAssessmentDialogContent5
} from "./content/HomeEnergyAssessmentDialogContent5";
import {
  HomeEnergyAssessmentDialogContent6
} from "./content/HomeEnergyAssessmentDialogContent6";
import {
  HomeEnergyAssessmentDialogContent7
} from "./content/HomeEnergyAssessmentDialogContent7";
import {
  HomeEnergyAssessmentDialogContent8
} from "./content/HomeEnergyAssessmentDialogContent8";
import { 
  HomeEnergyAssessmentDialogContent9 
} from "./content/HomeEnergyAssessmentDialogContent9";
import {
  useHomeEnergyAssessment
} from "../hooks/useHomeEnergyAssessment";


interface HomeEnergyAssessmentDialogProps {
  dwelling: Dwelling;
  reportConfig: ProjectReportConfig;
  projectId: number;
  heaStep: number;
  onClose: () => void;
  open: boolean;
  scenario: ProjectScenario;
  updateHeaStep: (
    newHeaStep: number
  ) => void;
  handleGenerateHeaPdf: () => void;
}


export function HomeEnergyAssessmentDialog(
  {
    dwelling,
    reportConfig,
    projectId,
    heaStep,
    onClose,
    open,
    scenario,
    updateHeaStep,
    handleGenerateHeaPdf
  }: HomeEnergyAssessmentDialogProps
) {

  const {
    homeEnergyAssessment,
    updateHomeEnergyAssessment
  } = useHomeEnergyAssessment();

  const [
    inputError,
    setInputError
  ] = useState<boolean>(false);

  const [
    scenarioRating,
    setScenarioRating
  ] = useState<string>("?");

  const [
    colourChoicesExpanded,
    setColourChoicesExpanded
  ] = useState<boolean>(false);

  const [
    pageBackgroundColour1,
    setPageBackgroundColour1
  ] = useState<string>(
    colors.grey["100"]
  );

  // noinspection JSUnusedLocalSymbols
  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pageBackgroundColour2,
    setPageBackgroundColour2
  ] = useState<string>(
    colors.grey["100"]
  );

  // noinspection JSUnusedLocalSymbols
  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pageBackgroundGradient,
    setPageBackgroundGradient
  ] = useState<boolean>(false);

  const [
    contentBackgroundColour,
    setContentBackgroundColour
  ] = useState<string>("#ffffff");

  const [
    footerColour1,
    setFooterColour1
  ] = useState<string>("#008080");

  // noinspection JSUnusedLocalSymbols
  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    footerColour2,
    setFooterColour2
  ] = useState<string>("#008080");

  // noinspection JSUnusedLocalSymbols
  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    footerGradient,
    setFooterGradient
  ] = useState<boolean>(false);

  useEffect(
    () => {
      const dwellingPerformanceInScenario =
        scenario.related_performances.find(
          (scenarioPerformance) =>
            scenarioPerformance.dwelling === dwelling.id
        );
      if (dwellingPerformanceInScenario !== undefined) {
        setScenarioRating(
          dwellingPerformanceInScenario.rating
        );
      }
    },
    [
      dwelling.id,
      scenario.related_performances
    ]
  );

  useEffect(
    () => {

      if (homeEnergyAssessment !== undefined) {
        setPageBackgroundColour1(
          homeEnergyAssessment.page_background_colour_1
        );

        setPageBackgroundColour2(
          homeEnergyAssessment.page_background_colour_2
        );

        setContentBackgroundColour(
          homeEnergyAssessment.content_background_colour
        );

        setFooterColour1(
          homeEnergyAssessment.footer_colour_1
        );

        setFooterColour2(
          homeEnergyAssessment.footer_colour_2
        );

        setPageBackgroundGradient(
          homeEnergyAssessment.page_background_colour_1 !== homeEnergyAssessment.page_background_colour_2
        );

        setFooterGradient(
          homeEnergyAssessment.footer_colour_1 !== homeEnergyAssessment.footer_colour_2
        );
      }
    },
    [
      homeEnergyAssessment
    ]
  );

  const handleClose = useCallback(() => {
      onClose();
    },
    [onClose]
  );

  const handleHeaFieldBlur = useCallback((
      value: string | number | File | Array<File> | Array<HomeEnergyAnnexItem> | undefined,
      field: string
    ) => {

      if (!inputError && homeEnergyAssessment !== undefined) {

        let heaUpdateArgument: UpdateHEASection1ApiRequest |
          UpdateHEASection2ApiRequest |
          UpdateHEASection3ApiRequest |
          UpdateHEASection4ApiRequest |
          UpdateHEASection5ApiRequest |
          UpdateHEASection6ApiRequest |
          UpdateHEASection7ApiRequest |
          UpdateHEASection8ApiRequest |
          UpdateHEASection9ApiRequest |
          UpdateHEASection10ApiRequest |
          UpdateHEASection11ApiRequest |
          UpdateHEASection12ApiRequest |
          undefined;

        // eslint-disable-next-line prefer-const
        heaUpdateArgument = {
          seai_hea_id: homeEnergyAssessment.id
        };

        if (field === "footer_colour_1") {
          // @ts-expect-error: due to use of any type
          heaUpdateArgument.footer_colour_1 = value;
          // @ts-expect-error: due to use of any type
          heaUpdateArgument.footer_colour_2 = value;
        } else if (field === "page_background_colour_1") {
          // @ts-expect-error: due to use of any type
          heaUpdateArgument.page_background_colour_1 = value;
          // @ts-expect-error: due to use of any type
          heaUpdateArgument.page_background_colour_2 = value;
        } else if (field === "RESET_COLOURS") {
          heaUpdateArgument.page_background_colour_1 = colors.grey["100"];
          heaUpdateArgument.page_background_colour_2 = colors.grey["100"];
          heaUpdateArgument.content_background_colour = "#ffffff";
          heaUpdateArgument.footer_colour_1 = "#008080";
          heaUpdateArgument.footer_colour_2 = "#008080";
        } else if (heaStep === 1) {

          heaUpdateArgument = heaUpdateArgument as UpdateHEASection1ApiRequest

          if (field === "logoFile") {
            // @ts-expect-error: due to use of any type
            heaUpdateArgument.logo = value;
          } else if (field === "dwellingImageFile") {
            // @ts-expect-error: due to use of any type
            heaUpdateArgument.dwelling_image = value;
          } else if (field === "dwelling_image" && value === "") {
            // @ts-expect-error: due to use of any type
            heaUpdateArgument.remove_dwelling_image = true;
          } else {
            // @ts-expect-error: due to use of any type
            heaUpdateArgument[field] = value;
          }
        } else if ([2, 3, 4, 5, 6, 7, 8, 9, 10, 11].includes(heaStep)) {
          // @ts-expect-error: due to use of any type
          heaUpdateArgument[field] = value;
        } else if (heaStep === 12) {

          if (field === "annexAdditionalFiles") {
            // @ts-expect-error: due to use of any type
            heaUpdateArgument.additional_annex_files = value;
          } else if (field === "annex_files") {
            // console.log(field, "typeof value =", typeof value);

            if (value !== undefined) {
              // heaUpdateArgument.annex_files = [
              //   // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
              //   value.map(
              //     (annexFile: HomeEnergyAnnexItem) => annexFile.id
              //   )
              // ]
            }
          } else {
            // @ts-expect-error: due to use of any type
            heaUpdateArgument[field] = value;
          }
        }

        if (Object.keys(heaUpdateArgument).length > 1) {
          updateHomeEnergyAssessment({
            heaUpdate: heaUpdateArgument,
            existingHEACacheKey: {
              project_id: projectId,
              dwelling_id: dwelling.id,
              scenario_id: scenario.id,
              dwelling_type: dwelling.type,
            }
          }).unwrap().catch(
            (errorResponse: ErrorResponse) => {
              toast.dismiss();
              toast.error(
                `Something went wrong. Couldn't update ${field}. ${errorResponse.data}`,
                {
                  autoClose: 10000,
                  position: toast.POSITION.TOP_RIGHT,
                  theme: "colored"
                }
              );
            }
          );
        }
      }
    },
    [
      dwelling.id,
      dwelling.type,
      heaStep,
      homeEnergyAssessment,
      inputError,
      projectId,
      scenario.id,
      updateHomeEnergyAssessment
    ]
  );

  const handleFinishButtonClick = useCallback(() => {
      setColourChoicesExpanded(false);
      handleGenerateHeaPdf();
      onClose();
    },
    [
      handleGenerateHeaPdf,
      onClose
    ]
  );

  interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
  }

  const ExpandMore = styled(
    (props: ExpandMoreProps) => {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        expand,
        ...other
      } = props;
      return <IconButton
        {...other}
      />;
    }
  )(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  }));

  const DialogPaperComponent = useCallback(
    (
      props: JSX.IntrinsicAttributes & PaperProps
    ) => <Paper
      elevation={0}
      sx={{
        borderWidth: "0px",
        borderRadius: "20px"
      }}
      variant="outlined"
      {...props}
    />,
    []
  );

  const marks = [
    {
      "value": 1,
      "label": "Details"
    },
    {
      "value": 2,
      "label": "Objectives"
    },
    {
      "value": 3,
      "label": "Impact"
    },
    {
      "value": 4,
      "label": "Current"
    },
    {
      "value": 5,
      "label": "Plan"
    },
    {
      "value": 6,
      "label": "Sequence"
    },
    {
      "value": 7,
      "label": "Design"
    },
    {
      "value": 8,
      "label": "Notes"
    },
    {
      "value": 9,
      "label": "Steps"
    },
    {
      "value": 10,
      "label": "Terms"
    },
    {
      "value": 11,
      "label": "Costs"
    },
    // {
    //   "value": 12,
    //   "label": "Annexes"
    // }
  ];

  const theme = useTheme();

  const fullScreen = useMediaQuery(
    theme.breakpoints.down("md")
  );

  return (
    <Dialog
      fullWidth
      maxWidth={fullScreen ? "lg" : "md"}
      open={open}
      onClose={handleClose}
      scroll="body"
      PaperComponent={DialogPaperComponent}
    >

      <Box
        sx={{
          backgroundColor: pageBackgroundColour1
        }}
      >

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: 0,
            padding: "16px"
          }}
        >

          <Box
            sx={{
              display: "flex",
              flexDirection: "row"
            }}
          >

            <DialogTitle
              sx={{
                flex: 1
              }}
            >
              Home Energy Assessment
            </DialogTitle>

            <Box
              sx={{
                marginLeft: "1rem",
                paddingTop: "4px",
                paddingRight: "4px"
              }}
            >

              <IconButton
                onClick={handleClose}
              >

                <CloseIcon />

              </IconButton>

            </Box>

          </Box>

          <Box
            sx={{
              flex: 1,
              marginRight: "48px",
              marginLeft: "48px"
            }}
          >
            <Tooltip
              title={
                inputError ? "Fix input errors before changing section" : ""
              }
            >
              <Box>
                <Slider
                  disabled={
                    inputError ||
                    homeEnergyAssessment === undefined
                  }
                  value={heaStep}
                  step={1}
                  min={1}
                  max={marks.length}
                  valueLabelDisplay="off"
                  marks={marks}
                  sx={{
                    borderRadius: 2,
                    color: "#e91e63"
                  }}
                  onChange={(
                    _event: Event,
                    newValue: number | number[],
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    _activeThumb: number
                  ) => {
                    if (!inputError) {
                      if (colourChoicesExpanded) {
                        setColourChoicesExpanded(false);
                      }

                      updateHeaStep(
                        newValue as number
                      );
                    }
                  }}
                />
              </Box>
            </Tooltip>
          </Box>


        </Box>


        {/* {
        homeEnergyAssessment === undefined ? <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              padding: "5rem"
            }}
          >
            <RetroKitGradientCircularProgress />
            <Typography>
              Loading assessment
            </Typography>
          </Box>
          : null
      } */}

        <Box
          sx={{
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingBottom: "16px"
          }}
        >

          <Box
            sx={{
              display: "flex",
              borderRadius: "20px",
              backgroundColor: theme.palette.common.white,
              padding: "8px",
              flexDirection: "column"
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
              onClick={
                () => {
                  setColourChoicesExpanded(
                    !colourChoicesExpanded
                  );
                }
              }
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "2rem"
                }}
              >
                <Box
                  sx={{
                    height: "24px",
                    width: "24px",
                    backgroundColor: contentBackgroundColour,
                    borderStyle: "solid",
                    borderColor: theme.palette.common.black,
                    borderWidth: "1px",
                    borderRadius: "4px"
                  }}
                />

                <Box
                  sx={{
                    height: "24px",
                    width: "24px",
                    backgroundColor: pageBackgroundColour1,
                    borderStyle: "solid",
                    borderColor: theme.palette.common.black,
                    borderWidth: "1px",
                    borderRadius: "4px"
                  }}
                />

                {/* {
                  pageBackgroundGradient ? <Box
                    sx={{
                      height: "24px",
                      width: "24px",
                      backgroundColor: pageBackgroundColour2,
                      borderStyle: "solid",
                      borderColor: theme.palette.common.black,
                      borderWidth: "1px",
                      borderRadius: "4px"
                    }}
                  /> : null
                } */}

                <Box
                  sx={{
                    height: "24px",
                    width: "24px",
                    backgroundColor: footerColour1,
                    borderStyle: "solid",
                    borderColor: theme.palette.common.black,
                    borderWidth: "1px",
                    borderRadius: "4px"
                  }}
                />

                {/* {
                  footerGradient ? <Box
                    sx={{
                      height: "24px",
                      width: "24px",
                      backgroundColor: footerColour2,
                      borderStyle: "solid",
                      borderColor: theme.palette.common.black,
                      borderWidth: "1px",
                      borderRadius: "4px"
                    }}
                  /> : null
                } */}

                <ExpandMore
                  expand={colourChoicesExpanded}
                  aria-expanded={colourChoicesExpanded}
                  aria-label="change colours"
                  sx={{
                    padding: "0px"
                  }}
                >
                  <ExpandMoreIcon />
                </ExpandMore>

              </Box>
            </Box>

            <Collapse
              in={colourChoicesExpanded}
              unmountOnExit
            >
              <Box
                sx={{
                  display: "flex",
                  padding: "8px",
                  flexDirection: "row",
                  gap: "3rem"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1
                  }}
                >
                  <FormLabel
                    component="legend"
                  >
                    Content background
                  </FormLabel>

                  <MuiColorInput
                    format="hex"
                    value={contentBackgroundColour}
                    onBlur={(event) => {
                      if (!inputError) {
                        handleHeaFieldBlur(
                          event.target.value,
                          "content_background_colour"
                        );
                      }
                    }}
                    onChange={(newHexValue) => {
                      setContentBackgroundColour(newHexValue);
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1
                  }}
                >
                  <FormLabel
                    component="legend"
                  >
                    Page background
                  </FormLabel>

                  <MuiColorInput
                    format="hex"
                    value={pageBackgroundColour1}
                    onBlur={(event) => {
                      if (!inputError) {
                        handleHeaFieldBlur(
                          event.target.value,
                          "page_background_colour_1"
                        );
                      }
                    }}
                    onChange={(newHexValue) => {
                      setPageBackgroundColour1(newHexValue);
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1
                  }}
                >
                  <FormLabel
                    component="legend"
                  >
                    Footer
                  </FormLabel>

                  <MuiColorInput
                    format="hex"
                    value={footerColour1}
                    onBlur={(event) => {
                      if (!inputError) {
                        handleHeaFieldBlur(
                          event.target.value,
                          "footer_colour_1"
                        );
                      }
                    }}
                    onChange={(newHexValue) => {
                      setFooterColour1(newHexValue);
                    }}
                  />
                </Box>
              </Box>
            </Collapse>

            {
              colourChoicesExpanded && (
                pageBackgroundColour1 !== "#f5f5f5" ||
                contentBackgroundColour !== "#ffffff" ||
                footerColour1 !== "#008080"
              ) ? <Box
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "right"
                }}
              >
                <Button
                  variant="text"
                  color="secondary"
                  onClick={
                    () => {
                      setPageBackgroundColour1(colors.grey["100"]);
                      setPageBackgroundColour2(colors.grey["100"]);
                      setContentBackgroundColour("#ffffff");
                      setFooterColour1("#008080");
                      setFooterColour2("#008080");
                      handleHeaFieldBlur(
                        undefined,
                        "RESET_COLOURS"
                      );
                    }
                  }
                >
                  Reset to defaults
                </Button>
              </Box> : null
            }


          </Box>

        </Box>

        {
          heaStep === 1 ? <HomeEnergyAssessmentDialogContent1
            dwelling={dwelling}
            contentBackgroundColour={contentBackgroundColour}
            inputError={inputError}
            setInputError={setInputError}
            handleHeaFieldBlur={handleHeaFieldBlur}
          /> : null
        }

        {
          heaStep === 2 ? <HomeEnergyAssessmentDialogContent2
            dwelling={dwelling}
            contentBackgroundColour={contentBackgroundColour}
            scenarioDetail={scenario.title}
            scenarioRating={scenarioRating}
            inputError={inputError}
            setInputError={setInputError}
            handleHeaFieldBlur={handleHeaFieldBlur}
          /> : null
        }

        {
          heaStep === 3 ? <HomeEnergyAssessmentDialogContent3
            dwelling={dwelling}
            contentBackgroundColour={contentBackgroundColour}
            scenarioRating={scenarioRating}
            inputError={inputError}
            setInputError={setInputError}
            handleHeaFieldBlur={handleHeaFieldBlur}
          /> : null
        }

        {
          heaStep === 4 ? <HomeEnergyAssessmentDialogContent4
            dwelling={dwelling}
            contentBackgroundColour={contentBackgroundColour}
            pageBackgroundColour={pageBackgroundColour1}
            scenarioDetail={scenario.title}
            scenarioRating={scenarioRating}
            inputError={inputError}
            setInputError={setInputError}
            handleHeaFieldBlur={handleHeaFieldBlur}
          /> : null
        }

        {
          heaStep === 5 ? <HomeEnergyAssessmentDialogContent5
            dwelling={dwelling}
            contentBackgroundColour={contentBackgroundColour}
            pageBackgroundColour={pageBackgroundColour1}
            scenarioRating={scenarioRating}
            inputError={inputError}
            setInputError={setInputError}
            handleHeaFieldBlur={handleHeaFieldBlur}
          /> : null
        }

        {
          heaStep === 6 ? <HomeEnergyAssessmentDialogContent6
            dwelling={dwelling}
            contentBackgroundColour={contentBackgroundColour}
            pageBackgroundColour={pageBackgroundColour1}
            inputError={inputError}
            setInputError={setInputError}
            handleHeaFieldBlur={handleHeaFieldBlur}
          /> : null
        }

        {
          heaStep === 7 ? <HomeEnergyAssessmentDialogContent7
            contentBackgroundColour={contentBackgroundColour}
            pageBackgroundColour={pageBackgroundColour1}
            dwelling={dwelling}
            scenarioDetail={scenario.title}
            scenarioRating={scenarioRating}
            inputError={inputError}
            setInputError={setInputError}
            handleHeaFieldBlur={handleHeaFieldBlur}
          /> : null
        }

        {
          heaStep === 8 ? <HomeEnergyAssessmentDialogContent8
            contentBackgroundColour={contentBackgroundColour}
            pageBackgroundColour={pageBackgroundColour1}
            dwelling={dwelling}
            scenarioDetail={scenario.title}
            scenarioRating={scenarioRating}
            inputError={inputError}
            setInputError={setInputError}
            handleHeaFieldBlur={handleHeaFieldBlur}
          /> : null
        }

        {
          heaStep === 9 ? <HomeEnergyAssessmentDialogContent9
            contentBackgroundColour={contentBackgroundColour}
            pageBackgroundColour={pageBackgroundColour1}
            dwelling={dwelling}
            scenarioDetail={scenario.title}
            scenarioRating={scenarioRating}
            inputError={inputError}
            setInputError={setInputError}
            handleHeaFieldBlur={handleHeaFieldBlur}
          /> : null
        }
        
        {
          heaStep === 10 ? <HomeEnergyAssessmentDialogContent10
            contentBackgroundColour={contentBackgroundColour}
            pageBackgroundColour={pageBackgroundColour1}
            dwelling={dwelling}
            scenarioDetail={scenario.title}
            scenarioRating={scenarioRating}
            inputError={inputError}
            setInputError={setInputError}
            handleHeaFieldBlur={handleHeaFieldBlur}
          /> : null
        }

        {
          heaStep === 11 ? <HomeEnergyAssessmentDialogContent11
            contentBackgroundColour={contentBackgroundColour}
            pageBackgroundColour={pageBackgroundColour1}
            dwelling={dwelling}
            reportConfig={reportConfig}
            scenarioDetail={scenario.title}
            scenarioRating={scenarioRating}
            inputError={inputError}
            setInputError={setInputError}
            handleHeaFieldBlur={handleHeaFieldBlur}
          /> : null
        }

        {
          heaStep === 12 ? <HomeEnergyAssessmentDialogContent12
            contentBackgroundColour={contentBackgroundColour}
            pageBackgroundColour={pageBackgroundColour1}
            dwelling={dwelling}
            scenarioDetail={scenario.title}
            scenarioRating={scenarioRating}
            inputError={inputError}
            setInputError={setInputError}
            handleHeaFieldBlur={handleHeaFieldBlur}
          /> : null
        }


        <DialogActions
          sx={{
            paddingRight: "24px",
            backgroundColor: footerColour1
          }}
        >

          {
            heaStep > 1 ? <Tooltip
              title={
                inputError ? "Fix input errors before returning" : ""
              }
            >
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (colourChoicesExpanded) {
                      setColourChoicesExpanded(false);
                    }
                    if (!inputError) {
                      updateHeaStep(
                        heaStep - 1
                      );
                    }
                  }}
                  disabled={inputError}
                >
                  Back
                </Button>
              </Box>
            </Tooltip> : null
          }

          {
            heaStep < 11 ? <Tooltip
              title={
                inputError ? "Fix input errors before proceeding" : ""
              }
            >
              <Box>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    if (colourChoicesExpanded) {
                      setColourChoicesExpanded(false);
                    }
                    if (!inputError) {
                      updateHeaStep(
                        heaStep + 1
                      );
                    }
                  }}
                  disabled={
                    inputError ||
                    homeEnergyAssessment === undefined
                  }
                >
                  Next
                </Button>
              </Box>
            </Tooltip> : null
          }

          {
            heaStep === 11 ? <Tooltip
              title={
                inputError ? "Fix input errors before finishing" : ""
              }
            >
              <Box>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleFinishButtonClick}
                  disabled={inputError}
                >
                  Finish
                </Button>
              </Box>
            </Tooltip> : null
          }


        </DialogActions>

      </Box>

    </Dialog>
  );
}
import React from "react";

import { styled } from "@mui/material";
import type { TooltipProps } from "@mui/material/Tooltip";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

export const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{
      popper: className
    }}
  />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 600,
  },
});
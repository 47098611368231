import {
  createContext,
  useContext,
  useEffect,
  useState
} from "react";

import {
  useLazyCreateOrGetHEAQuery,
  useUpdateHEAMutation
} from "features/homeEnergyAssessment/api/heaApi";

import type { 
  HomeEnergyAssessmentHookContext 
} from "../api/types";
import type {
  HomeEnergyAssessment
} from "../types";


const HomeEnergyAssessmentContext = createContext<
  HomeEnergyAssessmentHookContext | null>(
  null
);

// @ts-expect-error: children is any type
// eslint-disable-next-line react/prop-types
export const HomeEnergyAssessmentProvider = ({ children }) => {

  const [
    homeEnergyAssessment,
    setHomeEnergyAssessment
  ] = useState<HomeEnergyAssessment>();

  const [
    isHomeEnergyAssessmentLoading,
    setIsHomeEnergyAssessmentLoading
  ] = useState(false);

  const [
    createOrGetHomeEnergyAssessmentTrigger,
    {
      data: createOrGetHomeEnergyAssessmentResult,
      isFetching: isCreateOrGetHomeEnergyAssessmentLoading
    }
  ] = useLazyCreateOrGetHEAQuery();

  const [
    updateHomeEnergyAssessment,
    {
      data: updateHomeEnergyAssessmentResult,
      isLoading: isUpdateHomeEnergyAssessmentLoading
    }
  ] = useUpdateHEAMutation();

  useEffect(() => {
    setIsHomeEnergyAssessmentLoading(
      isCreateOrGetHomeEnergyAssessmentLoading
    );
    setHomeEnergyAssessment(
      createOrGetHomeEnergyAssessmentResult
    )
  }, [
    createOrGetHomeEnergyAssessmentResult,
    isCreateOrGetHomeEnergyAssessmentLoading
  ]);

  useEffect(() => {
    setIsHomeEnergyAssessmentLoading(
      isUpdateHomeEnergyAssessmentLoading
    );
    setHomeEnergyAssessment(
      updateHomeEnergyAssessmentResult
    )
  }, [
    updateHomeEnergyAssessmentResult,
    isUpdateHomeEnergyAssessmentLoading
  ]);

  return <HomeEnergyAssessmentContext.Provider
    value={{
      createOrGetHomeEnergyAssessmentTrigger,
      isHomeEnergyAssessmentLoading,
      homeEnergyAssessment,
      updateHomeEnergyAssessment
    }}
  >
    {
      children
    }
  </HomeEnergyAssessmentContext.Provider>;
};

export function useHomeEnergyAssessment() {
  const context = useContext(
    HomeEnergyAssessmentContext
  );
  if (!context) {
    throw new Error(
      "useHomeEnergyAssessment hook must be used within a HomeEnergyAssessmentProvider"
    );
  }
  return context;
}

/*
export function useHomeEnergyAssessment() {

  const [
    isHomeEnergyAssessmentLoading,
    setIsHomeEnergyAssessmentLoading
  ] = useState(false);

  const [
    createOrGetHomeEnergyAssessmentTrigger,
    {
      data: homeEnergyAssessment,
      isFetching: isCreateOrGetHomeEnergyAssessmentLoading
    }
  ] = useLazyCreateOrGetHEAQuery();

  const [
    updateHomeEnergyAssessment
  ] = useUpdateHEAMutation();

  useEffect(() => {
    setIsHomeEnergyAssessmentLoading(
      isCreateOrGetHomeEnergyAssessmentLoading
    );

    console.log("useHomeEnergyAssessment(): homeEnergyAssessment = ", homeEnergyAssessment)
  }, [
    homeEnergyAssessment,
    isCreateOrGetHomeEnergyAssessmentLoading
  ]);

  return {
    createOrGetHomeEnergyAssessmentTrigger,
    isHomeEnergyAssessmentLoading,
    homeEnergyAssessment,
    updateHomeEnergyAssessment
  };
}
*/

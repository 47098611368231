import Extents from '../../../../common/assets/maps/zoom_out_map.svg';


class ZoomExtentsControl {
  onAdd(map) {
    this.map = map;
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl';
    this.container.classList.add('mapboxgl-ctrl-group');
    this.button = document.createElement('button');
    this.button.setAttribute('type', 'button');
    this.button.setAttribute('title', 'Zoom to Extents');
    this.button.setAttribute('style', "display: flex; align-items: center; justify-content: center;")
    this.image = document.createElement('img');
    this.image.src = Extents;
    this.button.appendChild(this.image);
    this.container.appendChild(this.button);
    return this.container;
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}

export default ZoomExtentsControl;

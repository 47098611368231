import React, { ChangeEvent } from "react";
import { useCallback, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

import type { CreateProjectApiRequest } from "features/projects/api/types";
import Paper, { PaperProps } from "@mui/material/Paper";


interface CreateProjectDialogProps {
  open: boolean;
  onClose: () => void;
  onCreateProject: (data: CreateProjectApiRequest) => void;
}

export function CreateProjectDialog(
  {
    open,
    onClose,
    onCreateProject
  }: CreateProjectDialogProps) {

  const handleOnClose = () => {
    onClose();
  };

  const [
    projectTitle, setProjectTitle
  ] = useState<string>("Title");

  const [
    projectDescription, setProjectDescription
  ] = useState<string>("Description");

  const handleCreateProjectClicked = useCallback(
    () => {
      if (projectTitle !== "" && projectDescription !== "") {
        onCreateProject({
          title: projectTitle,
          description: projectDescription
        });
      }
    },
    [
      onCreateProject,
      projectDescription,
      projectTitle
    ]
  );

  const handleFocus = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    event.target.focus();
    event.target.setSelectionRange(
      0,
      event.target.value.length
    );
  };

  const DialogPaperComponent = useCallback(
    (
      props: JSX.IntrinsicAttributes & PaperProps
    ) => <Paper
      elevation={0}
      sx={{
        borderWidth: "0px",
        borderRadius: "20px"
      }}
      variant="outlined"
      {...props}
    />,
    []
  );

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleOnClose}
      PaperComponent={DialogPaperComponent}
    >
      <DialogTitle>
        Create a Project
      </DialogTitle>

      <DialogContent
        sx={{
          paddingBottom: "8px"
        }}
      >
        <TextField
          autoFocus
          defaultValue={projectTitle}
          error={projectTitle.length === 0}
          fullWidth
          helperText={
            projectTitle.length === 0 ? "Title can't be blank" : ""
          }
          label="TITLE"
          margin="dense"
          onChange={
            (event) => setProjectTitle(event.target.value)
          }
          onFocus={
            (event) => handleFocus(event)
          }
          required
          variant="outlined"
        />

        <TextField
          fullWidth
          defaultValue={projectDescription}
          error={projectDescription.length === 0}
          helperText={
            projectDescription.length === 0 ? "Description can't be blank" : ""
          }
          label="DESCRIPTION"
          multiline
          margin="dense"
          onChange={
            (event) => setProjectDescription(event.target.value)
          }
          onFocus={
            (event) => handleFocus(event)
          }
          required
          variant="outlined"
        />
      </DialogContent>

      <DialogActions
        sx={{
          paddingRight: "24px"
        }}
      >

        <Button
          onClick={handleOnClose}
          color="primary"
          variant="contained"
        >
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={
            projectDescription.length === 0 ||
            projectTitle.length === 0
          }
          onClick={
            () => handleCreateProjectClicked()
          }
          variant="contained"
        >
          Create
        </Button>

      </DialogActions>
    </Dialog>
  );
}

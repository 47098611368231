import Cookies from 'js-cookie';
import { AUTHENTICATION_ERROR } from './authentication';

let csrf_token = Cookies.get('csrftoken');

export const GET_PROJECT = '@@project/GET_PROJECT';
export const UPDATE_PROJECT = '@@project/UPDATE_PROJECT';
export const DELETE_PROJECT = '@@project/DELETE_PROJECT';

export const GET_PROJECT_DWELLINGS = '@@project/GET_PROJECT_DWELLINGS';
export const ADD_PROJECT_DWELLING = '@@project/ADD_PROJECT_DWELLING';
export const DELETE_PROJECT_DWELLING = '@@project/DELETE_PROJECT_DWELLING';

export const PROJECT_SELECTION = '@@project/PROJECT_SELECTION';

export const GET_PROJECT_GOALS = '@@project/GET_PROJECT_GOALS';
export const UPDATE_PROJECT_GOALS = '@@project/UPDATE_PROJECT_GOALS';

export const GET_PROJECT_SUMMARY = '@@project/GET_PROJECT_SUMMARY';

export const getProject = (index) => {
  return (dispatch, getState) => {
    let headers = { 'Content-Type': 'application/json' };
    let { token } = getState().authentication;

    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }

    return fetch(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/v1/projects/${index}/`,
      { headers }
    )
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          console.log('Server Error!');
          throw res;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return dispatch({ type: GET_PROJECT, project: res.data });
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: AUTHENTICATION_ERROR, data: res.data });
          throw res.data;
        }
      });
  };
};

export const updateProject = (projectId, title, description) => {
  return (dispatch, getState) => {
    let headers = { 'Content-Type': 'application/json' };
    let { token } = getState().authentication;

    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }

    let body = JSON.stringify({ title, description });

    return fetch(
      `${
        process.env.REACT_APP_API_BASE_URL || ''
      }/api/v1/projects/${projectId}/`,
      {
        headers,
        method: 'PUT',
        body,
      }
    )
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          console.log('Server Error!');
          throw res;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return dispatch({ type: UPDATE_PROJECT, project: res.data });
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: AUTHENTICATION_ERROR, data: res.data });
          throw res.data;
        }
      });
  };
};

export const deleteProject = (projectId) => {
  return (dispatch, getState) => {
    let headers = { 'Content-Type': 'application/json' };
    let { token } = getState().authentication;

    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }

    /*let projectIndex = getState().projects.findIndex(
      (project) => project.id === projectId
    );*/

    return fetch(
      `${
        process.env.REACT_APP_API_BASE_URL || ''
      }/api/v1/projects/${projectId}/`,
      {
        headers,
        method: 'DELETE',
      }
    )
      .then((res) => {
        if (res.status === 200 || res.status === 204) {
          return { status: res.status, data: {} };
        } else if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          console.log('Server Error!');
          throw res;
        }
      })
      .then((res) => {
        if (res.status === 200 || res.status === 204) {
          return dispatch({ type: DELETE_PROJECT });
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: AUTHENTICATION_ERROR, data: res.data });
          throw res.data;
        }
      });
  };
};

export const addProjectDwelling = (projectId, dwellingId) => {
  return (dispatch, getState) => {
    let headers = { 'Content-Type': 'application/json' };
    let { token } = getState().authentication;

    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }

    let body = JSON.stringify({ dwellingId });
    return fetch(
      `${
        process.env.REACT_APP_API_BASE_URL || ''
      }/api/v1/projects/${projectId}/dwellings/`,
      {
        headers,
        method: 'POST',
        body,
      }
    )
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          console.log('Server Error!');
          throw res;
        }
      })
      .then((res) => {
        if (res.status === 201) {
          return dispatch({
            type: ADD_PROJECT_DWELLING,
            housing_stock: res.data,
          });
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: AUTHENTICATION_ERROR, data: res.data });
          throw res.data;
        }
      });
  };
};

export const getProjectDwellings = (projectId) => {
  return (dispatch, getState) => {
    let headers = { 'Content-Type': 'application/json' };
    let { token } = getState().authentication;

    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }

    return fetch(
      `${
        process.env.REACT_APP_API_BASE_URL || ''
      }/api/v1/projects/${projectId}/dwellings/`,
      { headers }
    )
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          console.log('Server Error!');
          throw res;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return dispatch({
            type: GET_PROJECT_DWELLINGS,
            housing_stock: res.data,
          });
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: AUTHENTICATION_ERROR, data: res.data });
          throw res.data;
        }
      });
  };
};

export const deleteProjectDwelling = (projectId, dwellingId) => {
  return (dispatch, getState) => {
    let headers = { 'Content-Type': 'application/json' };
    let { token } = getState().authentication;

    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }

    let body = JSON.stringify({ dwellingId });
    return fetch(
      `${
        process.env.REACT_APP_API_BASE_URL || ''
      }/api/v1/projects/${projectId}/dwellings/`,
      {
        headers,
        method: 'DELETE',
        body,
      }
    )
      .then((res) => {
        if (res.status === 204) {
          return { status: res.status, data: {} };
        } else if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          console.log('Server Error!');
          throw res;
        }
      })
      .then((res) => {
        if (res.status === 204) {
          return dispatch({ type: DELETE_PROJECT_DWELLING, dwellingId });
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: AUTHENTICATION_ERROR, data: res.data });
          throw res.data;
        }
      });
  };
};

export const updateProjectGoals = (projectId, ber, co2, cost, fuel, target) => {
  return (dispatch, getState) => {
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': csrf_token,
    };

    let { token } = getState().authentication;

    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }

    let body = JSON.stringify({ ber, co2, cost, fuel, target });

    return fetch(
      `${
        process.env.REACT_APP_API_BASE_URL || ''
      }/api/v1/projects/${projectId}/goals/`,
      {
        headers,
        method: 'PUT',
        body,
      }
    )
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          console.log('Server Error!');
          throw res;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return dispatch({ type: UPDATE_PROJECT_GOALS, goals: res.data });
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: AUTHENTICATION_ERROR, data: res.data });
          throw res.data;
        }
      });
  };
};

export const getProjectSummary = (projectId) => {
  return (dispatch, getState) => {
    let headers = { 'Content-Type': 'application/json' };
    let { token } = getState().authentication;

    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }

    return fetch(
      `${
        process.env.REACT_APP_API_BASE_URL || ''
      }/api/v1/projects/${projectId}/summary/`,
      { headers }
    )
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          console.log('Server Error!');
          throw res;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return dispatch({ type: GET_PROJECT_SUMMARY, summaries: res.data });
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: AUTHENTICATION_ERROR, data: res.data });
          throw res.data;
        }
      });
  };
};

export const setSelectedProjectIndex = (index) => {
  return (dispatch, getState) => {
    dispatch({ type: PROJECT_SELECTION, index: index });
  };
};

import { useCreateProjectMutation } from "features/projects/api/projectsApi";


export function useDashboard() {

  const [
    createProject,
    {
      data: newProject,
      isLoading: creatingNewProject
    }
  ] = useCreateProjectMutation();

  return {
    createProject,
    creatingNewProject,
    newProject
  };
}
import { retrokitApi } from "common/api/retrokitApi";
import { getDateFromUTCSeconds } from "common/utils/date-utils";
import { 
  customMeasuresApiTransformer 
} from "features/customMeasures/api/transformers";
import {
  customScenarioApiTransformer,
  customScenariosApiTransformer
} from "features/customScenarios/api/transformers";
import { 
  defaultMeasuresApiTransformer 
} from "features/defaultMeasures/api/transformers";

import type { CustomMeasure } from "features/customMeasures/types";
import type {
  DeleteCustomScenarioApiRequest,
  CreateCustomScenarioApiRequest,
  GetCustomMeasuresNotInCustomScenarioByIDParameters,
  GetCustomScenarioByIDParameters,
  GetMeasuresNotInCustomScenarioByIDParameters,
  MinimalCustomScenarioApiResponse,
  UpdateCustomScenarioCustomMeasuresRequest,
  UpdateCustomScenarioDefaultMeasuresRequest,
  UpdateCustomScenarioDetailsRequest
} from "features/customScenarios/api/types";
import type {
  CustomScenario,
  MinimalCustomScenario
} from "features/customScenarios/types";
import type { DefaultMeasure } from "features/defaultMeasures/types";


export const customScenariosApi = retrokitApi.injectEndpoints({
  endpoints: (builder) => ({

    createCustomScenario: builder.mutation<MinimalCustomScenario, CreateCustomScenarioApiRequest>({
      query: (arg) => ({
        url: `user/custom_scenarios/`,
        method: "POST",
        params: {
          title: arg.title,
          description: arg.description
        }
      }),
      invalidatesTags: ["CustomScenarios"],
      transformResponse: (response: MinimalCustomScenarioApiResponse): MinimalCustomScenario => ({
        ...response,
        created_on: getDateFromUTCSeconds(parseInt(response.created_on, 10))
      })
    }),

    getCustomScenarioById: builder.query<CustomScenario, GetCustomScenarioByIDParameters>({
      query: ({ id }) => ({
        url: `user/custom_scenarios/${id}/`,
        method: "GET"
      }),
      providesTags: ["CustomScenario"],
      transformResponse: customScenarioApiTransformer
    }),

    getCustomScenarios: builder.query<Array<CustomScenario>, void>({
      query: () => ({
        url: `user/custom_scenarios/`,
        method: "GET"
      }),
      providesTags: ["CustomScenarios"],
      transformResponse: customScenariosApiTransformer
    }),

    getDefaultMeasuresNotInCustomScenario: builder.query<Array<DefaultMeasure>, GetMeasuresNotInCustomScenarioByIDParameters>({
      query: (arg) => ({
        url: `user/custom_scenarios/${arg.id}/measures/`,
        method: "GET",
        params: {
          inScenario: arg.inScenario
        }
      }),
      providesTags: ["DefaultMeasuresNotInCustomScenario"],
      transformResponse: defaultMeasuresApiTransformer
    }),

    getCustomMeasuresNotInCustomScenario: builder.query<Array<CustomMeasure>, GetCustomMeasuresNotInCustomScenarioByIDParameters>({
      query: (arg) => ({
        url: `user/custom_scenarios/${arg.id}/custom_measures/`,
        method: "GET",
        params: {
          inScenario: arg.inScenario
        }
      }),
      providesTags: ["CustomMeasuresNotInCustomScenario"],
      transformResponse: customMeasuresApiTransformer
    }),

    updateCustomScenarioDetails: builder.mutation<void, UpdateCustomScenarioDetailsRequest>({
      query: (arg) => ({
        url: `user/custom_scenarios/${arg.id}/`,
        method: "PUT",
        params: {
          title: arg.title,
          description: arg.description,
          is_default: arg.is_default
        }
      }),
      invalidatesTags: [
        "CustomScenario",
        "CustomScenarios",
        "CustomScenariosNotInProject",
        "Project"
      ]
    }),

    deleteCustomScenario: builder.mutation<CustomScenario, DeleteCustomScenarioApiRequest>({
      query: (arg) => ({
        url: `user/custom_scenarios/${arg.id}/delete/`,
        method: "POST"
      }),
      invalidatesTags: [
        "CustomScenario",
        "CustomScenarios"
      ]
    }),

    updateCustomScenarioDefaultMeasures: builder.mutation<void, UpdateCustomScenarioDefaultMeasuresRequest>({
      query: (arg) => ({
        url: `user/custom_scenarios/${arg.id}/measures/`,
        method: "PUT",
        body: arg
      }),
      invalidatesTags: [
        "CustomScenario",
        "DefaultMeasuresNotInCustomScenario",
        "CustomScenariosNotInProject",
        "ProjectScenarios"
      ]
    }),

    updateCustomScenarioCustomMeasures: builder.mutation<void, UpdateCustomScenarioCustomMeasuresRequest>({
      query: (arg) => ({
        url: `user/custom_scenarios/${arg.id}/custom_measures/`,
        method: "PUT",
        body: arg
      }),
      invalidatesTags: [
        "CustomScenario",
        "CustomMeasuresNotInCustomScenario",
        "CustomScenariosNotInProject",
        "ProjectScenarios"
      ]
    }),

  })
});

export const {
  useCreateCustomScenarioMutation,
  useGetCustomScenarioByIdQuery,
  useGetCustomScenariosQuery,
  useGetDefaultMeasuresNotInCustomScenarioQuery,
  useGetCustomMeasuresNotInCustomScenarioQuery,
  useUpdateCustomScenarioDetailsMutation,
  useDeleteCustomScenarioMutation,
  useUpdateCustomScenarioDefaultMeasuresMutation,
  useUpdateCustomScenarioCustomMeasuresMutation
} = customScenariosApi;

import { retrokitApi } from "common/api/retrokitApi";

import type {
  CreateOrGetHEAApiRequest,
  UpdateHEASection1ApiRequest,
  UpdateHEASection2ApiRequest,
  UpdateHEASection4ApiRequest,
  UpdateHEASection6ApiRequest,
  UpdateHEASection7ApiRequest,
  UpdateHEASection8ApiRequest,
  UpdateHEASection10ApiRequest,
  UpdateHEASection11ApiRequest,
  UpdateHEASection12ApiRequest,
  UpdateHEASection3ApiRequest,
  UpdateHEASection5ApiRequest,
  UpdateHEASection9ApiRequest
} from "./types";
import type { HomeEnergyAssessment } from "../types";

import {
  heaApiTransformer,
  heaApiUpdateTransformer
} from "./transformers";

export const heaApi = retrokitApi.injectEndpoints({
  endpoints: (builder) => ({
    createOrGetHEA: builder.query<
      HomeEnergyAssessment,
      CreateOrGetHEAApiRequest
    >({
      query: (arg) => ({
        url: `integrations/reports/hea`,
        method: "POST",
        body: arg
      }),
      providesTags: [
        "HomeEnergyAssessment"
      ],
      transformResponse: heaApiTransformer
    }),

    updateHEA: builder.mutation<
      HomeEnergyAssessment,
      {
        heaUpdate: UpdateHEASection1ApiRequest |
          UpdateHEASection2ApiRequest |
          UpdateHEASection3ApiRequest |
          UpdateHEASection4ApiRequest |
          UpdateHEASection5ApiRequest |
          UpdateHEASection6ApiRequest |
          UpdateHEASection7ApiRequest |
          UpdateHEASection8ApiRequest |
          UpdateHEASection9ApiRequest |
          UpdateHEASection10ApiRequest |
          UpdateHEASection11ApiRequest |
          UpdateHEASection12ApiRequest,
        existingHEACacheKey: CreateOrGetHEAApiRequest
      }
    >({
      query: (arg) => ({
        url: `integrations/reports/hea`,
        method: "PUT",
        body: heaApiUpdateTransformer(
          arg.heaUpdate
        )
      }),
      transformResponse: heaApiTransformer
    })
  })
});

export const {
  useLazyCreateOrGetHEAQuery,
  useUpdateHEAMutation
} = heaApi;
export function ARCHETYPE_AGE_BAND_MAP(age_band) {

    let AGE_BAND_MAP = {
        "< 1971" : 1,
        "< 1990" : 2,
        "< 2000": 3,
        "< 2010": 4,
        "> 2011" : 5
    }

    AGE_BAND_MAP[
      `< ${new Date().getFullYear() + 1}`
    ] = 0

    return AGE_BAND_MAP[age_band]
}

export const ARCHETYPE_DWELLING_TYPE_MAP = {
    "Any": 0,
    "Apartment": 1,
    "Terraced house": 2,
    "Semi-d house": 3,
    "Detached house": 4
};

export const ARCHETYPE_FUEL_TYPE_MAP = {
    "Any": 0,
    "Solid fuel": 1,
    "Electricity": 2,
    "Heating oil": 3,
    "Gas or LPG": 4,
    "Group Heating": 5
};

export const ARCHETYPE_WALL_TYPE_MAP = {
    "Any": 0,
    "Timber frame": 1,
    "Cavity": 2,
    "Solid or hollow": 3
};



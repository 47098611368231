import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";


type NoScenariosViewProps = {
  onViewClicked: () => void;
};

export function NoCustomScenariosView(
  {
    onViewClicked
  }: NoScenariosViewProps) {

  const theme = useTheme();

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        padding: 0,
        margin: 0,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
      }}
      onClick={
        () => onViewClicked()
      }
    >

      <Typography
        sx={{
          color: theme.palette.text.secondary
        }}
        variant="h5"
      >
        Create or select a scenario to view it here
      </Typography>

    </Box>
  );
}
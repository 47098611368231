import {configureStore} from '@reduxjs/toolkit';
// TODO: Remove this after refactor
// eslint-disable-next-line import/no-extraneous-dependencies
import thunk from 'redux-thunk';

import {retrokitApi} from "common/api/retrokitApi";
import globalLayoutReducer from 'common/slice/globalLayoutSlice';
import {customScenariosApi} from 'features/customScenarios/api/customScenariosApi';
import {dwellingsApi} from 'features/dwellings/api/dwellingsApi';
import {projectsApi} from 'features/projects/api/projectsApi';
import {reportsApi} from 'features/reports/api/reportsApi';
import {tafApi} from "features/technicalAssessmentForm/api/tafApi";
import {uploadsApi} from 'features/uploads/api/uploadsApi';
import authentication from 'v1/reducers/authentication';
import dashboard from 'v1/reducers/dashboard';
import project from 'v1/reducers/project';
import projects from 'v1/reducers/projects';
import uploads from 'v1/reducers/uploads';
import user from 'v1/reducers/user';


export const store = configureStore({
    reducer: {
        // New Slices
        globalLayout: globalLayoutReducer,

        // RTK Query
        [retrokitApi.reducerPath]: retrokitApi.reducer,

        // Old Reducers
        // TODO: Remove this after refactor
        authentication,
        // TODO: Remove this after refactor
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
        dashboard: dashboard as any,
        project,
        projects,
        // TODO: Remove this after refactor
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
        uploads: uploads as any,
        user,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          immutableCheck: false,
          serializableCheck: false
        })
            // New Middlewares
            .concat([
                projectsApi.middleware,
                dwellingsApi.middleware,
                uploadsApi.middleware,
                reportsApi.middleware,
                customScenariosApi.middleware,
                tafApi.middleware,
            ])
            // Old Middlewares
            // TODO: Remove this after refactor
            .concat([thunk]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

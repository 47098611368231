import type {
  ChangeEvent} from "react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";


import MaterialTable, { type Column } from "@material-table/core";

import CloseIcon from "@mui/icons-material/Close";
import EastIcon from "@mui/icons-material/East";
import { FormHelperText ,
  colors,
  FormGroup,
  FormLabel
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { green } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import type { PaperProps } from "@mui/material/Paper";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { BERChevron } from "common/components/BERChevron";
import { DwellingMapImage } from "common/components/DwellingMapImage";
import { TextFieldWithLabel } from "common/components/TextFieldWithLabel";
import {
  PROJECT_COSTS_LOWER_TABLE_COLUMNS,
  PROJECT_COSTS_TABLE_COLUMNS
} from "common/constants/tables";
import { useProjectCosts } from "features/projectCosts/hooks/useProjectCosts";
import {
  useProjectReportConfig
} from "features/projectsReportConfig/hooks/useProjectReportConfig";

import type { Dwelling } from "features/dwellings/types";
import type {
  ProjectCostsLowerTableData,
  ProjectCostsTableData
} from "features/projectCosts/types";
import type { ProjectScenario } from "features/projectScenarios/types";



interface ProjectCostsDialogProps {
  dwelling: Dwelling;
  onClose: () => void;
  open: boolean;
  projectId: number;
  scenario: ProjectScenario;
}


export function ProjectCostsDialog(
  {
    dwelling,
    onClose,
    open,
    projectId,
    scenario
  }: ProjectCostsDialogProps
) {

  // HOOKS
  const {
    isProjectCostsLoading,
    projectCosts,
    updateProjectCosts,
    // getProjectCosts
  } = useProjectCosts({
    project_id: projectId,
    dwelling_id: dwelling.id,
    scenario_id: Number(scenario.root_scenario),
    scenario_type: scenario.type,
    dwelling_type: dwelling.type
  });

  const {
    isProjectReportConfigLoading,
    reportConfig,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateProjectReportConfig
  } = useProjectReportConfig(projectId);

  const [
    roundCostsToNearestHundred,
    setRoundCostsToNearestHundred
  ] = useState<boolean>(false);

  const [
    showCosts,
    setShowCosts
  ] = useState<boolean>(true);

  const [
    showCredits,
    setShowCredits
  ] = useState<boolean>(false);

  const [
    showGrants,
    setShowGrants
  ] = useState<boolean>(true);

  const [
    scenarioRating,
    setScenarioRating
  ] = useState<string>("?");

  const [
    ossAhnEligible,
    setOssAhnEligible
  ] = useState<string>("?");

  const [
    tableData,
    setTableData
  ] = useState<ProjectCostsTableData[]>([]);

  const [
    lowerTableData,
    setLowerTableData
  ] = useState<ProjectCostsLowerTableData[]>([]);

  const theme = useTheme();

  const fullScreen = useMediaQuery(
    theme.breakpoints.down("md")
  );

  const ref = useRef(true);

  useEffect(
    () => {

      const roundToNearestHundred = (someNumber: number) => Math.round(
        someNumber / 100
      ) * 100;

      // TODO: Figure out why the round costs is causing a double render
      if (reportConfig !== undefined) {
        if (reportConfig.round_costs !== roundCostsToNearestHundred) {
          setRoundCostsToNearestHundred(reportConfig.round_costs);
        }
        if (reportConfig.show_costs !== showCosts) {
          setShowCosts(reportConfig.show_costs);
        }
        if (reportConfig.show_credits !== showCredits) {
          setShowCredits(reportConfig.show_credits);
        }
        if (reportConfig.show_grants !== showGrants) {
          setShowGrants(reportConfig.show_grants);
        }
      }

      if (projectCosts !== undefined && reportConfig !== undefined) {

        setOssAhnEligible(
          projectCosts.oss_ahn_eligible ? "Yes" : "No"
        );

        if (roundCostsToNearestHundred) {
          const tableDataCopy = [
            ...projectCosts.table_data
          ];

          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < tableDataCopy.length; i++) {
            const newRowData = {
              ...tableDataCopy[i]
            };

            newRowData.cost = roundToNearestHundred(
              tableDataCopy[i].cost
            );

            newRowData.grant = roundToNearestHundred(
              tableDataCopy[i].grant
            );

            tableDataCopy[i] = newRowData;
          }
          setTableData(tableDataCopy);
        } else {
          setTableData(projectCosts.table_data);
        }

        const tableDataLower: any[] = [];

        let berAndHeatPumpBonus =
          projectCosts.ber_and_heat_pump_bonus;

        let centralHeatingAndHeatPumpBonus =
          projectCosts.central_heating_and_heat_pump_bonus;

        let totalCost = projectCosts.table_data.reduce(
          (
            previousValue,
            currentValue
          ) => previousValue + currentValue.cost, 0
        );

        let totalCostToHomeowner = projectCosts.table_data.reduce(
          (
            previousValue,
            currentValue
          ) => previousValue + currentValue.cost, 0
        );

        let totalCredits = projectCosts.total_credits;

        let totalGrants = projectCosts.table_data.reduce(
          (
            previousValue,
            currentValue
          ) => previousValue + currentValue.grant, 0
        );

        if (roundCostsToNearestHundred) {
          berAndHeatPumpBonus = roundToNearestHundred(
            berAndHeatPumpBonus
          );
          centralHeatingAndHeatPumpBonus = roundToNearestHundred(
            centralHeatingAndHeatPumpBonus
          );
          totalCost = roundToNearestHundred(
            totalCost
          );
          totalCostToHomeowner = roundToNearestHundred(
            totalCostToHomeowner
          );
          totalCredits = roundToNearestHundred(
            totalCredits
          );
          totalGrants = roundToNearestHundred(
            totalGrants
          );
        }

        totalGrants = totalGrants +
          berAndHeatPumpBonus +
          centralHeatingAndHeatPumpBonus

        if (reportConfig.show_costs) {
          tableDataLower.push({
            id: 0,
            grant: 0,
            cost: totalCost,
            title: "Total cost",
            variableName: "totalCost"
          });
        }

        if (reportConfig.show_grants) {
          tableDataLower.push({
            id: 1,
            grant: berAndHeatPumpBonus.toFixed(0),
            cost: 0,
            title: "BER and heat pump bonus",
            variableName: "berAndHeatPumpBonus"
          });
          tableDataLower.push({
            id: 2,
            grant: centralHeatingAndHeatPumpBonus.toFixed(0),
            cost: 0,
            title: "Central heating and heat pump bonus",
            variableName: "centralHeatingAndHeatPumpBonus"
          });
        }

        if (reportConfig.show_credits && reportConfig.show_grants) {
          tableDataLower.push({
            id: 3,
            grant: totalCredits.toFixed(0),
            cost: 0,
            title: "Total credits",
            variableName: "totalCredits"
          });
        } else if (reportConfig.show_credits && reportConfig.show_costs) {
          tableDataLower.push({
            id: 3,
            grant: 0,
            cost: totalCredits.toFixed(0),
            title: "Total credits",
            variableName: "totalCredits"
          });
        }

        if (reportConfig.show_grants) {
          tableDataLower.push({
            id: 4,
            grant: totalGrants,
            cost: 0,
            title: "Total grants",
            variableName: "totalGrants"
          });
        }

        if (reportConfig.show_credits) {
          totalCostToHomeowner -= totalCredits;
        }

        if (reportConfig.show_grants) {
          totalCostToHomeowner -= totalGrants;
        }

        if (reportConfig.show_costs) {
          tableDataLower.push({
            id: 5,
            grant: 0,
            cost: totalCostToHomeowner,
            title: "Total cost to the homeowner",
            variableName: "totalCostToHomeowner"
          });
        }

        setLowerTableData(tableDataLower);
      }

      if (ref.current) {
        // First render only
        ref.current = false;
        const dwellingPerformanceInScenario =
          scenario.related_performances.find(
            (scenarioPerformance) =>
              scenarioPerformance.dwelling === dwelling.id
          );
        if (dwellingPerformanceInScenario !== undefined) {
          setScenarioRating(
            dwellingPerformanceInScenario.rating
          );
        }
      }
    },
    [
      dwelling.area,
      dwelling.city,
      dwelling.county,
      dwelling.id,
      dwelling.postcode,
      dwelling.street,
      projectCosts,
      reportConfig,
      roundCostsToNearestHundred,
      scenario.related_performances,
      showCosts,
      showCredits,
      showGrants
    ]
  );

  const handleClose = useCallback(() => {
      onClose();
    },
    [onClose]
  );

   const handleRoundCosts = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      updateProjectReportConfig({
        project_id: projectId,
        round_costs: checked
      });
    },
    [
      projectId,
      updateProjectReportConfig
    ]
  );

  const handleShowCosts = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      updateProjectReportConfig({
        project_id: projectId,
        show_costs: checked
      });
    },
    [
      projectId,
      updateProjectReportConfig
    ]
  );

  const handleShowCredits = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      updateProjectReportConfig({
        project_id: projectId,
        show_credits: checked
      });
    },
    [
      projectId,
      updateProjectReportConfig
    ]
  );

  const handleShowGrants = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      updateProjectReportConfig({
        project_id: projectId,
        show_grants: checked
      });
    },
    [
      projectId,
      updateProjectReportConfig
    ]
  );

  const DialogPaperComponent = useCallback(
    (
      props: JSX.IntrinsicAttributes & PaperProps
    ) => <Paper
      elevation={0}
      sx={{
        borderRadius: "20px",
        backgroundColor: colors.grey["100"],
      }}
      variant="outlined"
      {...props}
    />,
    []
  );

  const TablePaperComponent = useCallback(
    (
      props: JSX.IntrinsicAttributes & PaperProps
    ) => <Paper
      sx={{
        borderRadius: "20px 20px 0 0",
        backgroundColor: colors.grey["200"]
      }}
      variant="outlined"
      {...props}
    />,
    []
  );

  const LowerTablePaperComponent = useCallback(
    (
      props: JSX.IntrinsicAttributes & PaperProps
    ) => <Paper
      sx={{
        borderRadius: "0 0 20px 20px",
        width: "100%"
      }}
      variant="outlined"
      {...props}
    />,
    []
  );

  const filteredColumns =
    PROJECT_COSTS_TABLE_COLUMNS.filter(
      (tableColumn) => {

        if (!showGrants && !showCosts) {
          return tableColumn.field !== "grant" && tableColumn.field !== "cost";
        }

        if (!showGrants) {
          return tableColumn.field !== "grant";
        }

        if (!showCosts) {
          return tableColumn.field !== "cost";
        }

        return true;
      }
    );

  const tableColumns: Array<Column<ProjectCostsTableData>> = useMemo(
    () => [
      ...filteredColumns
    ],
    [
      filteredColumns
    ]
  );

  const filteredLowerTableColumns =
    PROJECT_COSTS_LOWER_TABLE_COLUMNS.filter(
      (tableColumn) => {

        if (!showGrants && !showCosts) {
          return tableColumn.field !== "grant" && tableColumn.field !== "cost";
        }

        if (!showGrants) {
          return tableColumn.field !== "grant";
        }

        if (!showCosts) {
          return tableColumn.field !== "cost";
        }

        return true;
      }
    );

  const lowerTableColumns: Array<Column<ProjectCostsLowerTableData>> = useMemo(
    () => [
      ...filteredLowerTableColumns
    ],
    [
      filteredLowerTableColumns
    ]
  );

  return (
    <Dialog
      fullWidth={fullScreen}
      maxWidth={fullScreen ? "md" : "xl"}
      open={open}
      onClose={handleClose}
      scroll="body"
      PaperComponent={DialogPaperComponent}
    >

      <Box
        sx={{
          display: "flex",
          flexDirection: "row"
        }}
      >

        <DialogTitle
          sx={{
            flex: 1
          }}
        >
          Edit Scenario Costs and Grants
        </DialogTitle>

        <Box
          sx={{
            marginLeft: "1rem",
            paddingTop: "4px",
            paddingRight: "4px"
          }}
        >

          <IconButton
            onClick={handleClose}
          >

            <CloseIcon />

          </IconButton>

        </Box>

      </Box>

      <DialogContent
        sx={{
          width: "100%",
          flex: 1,
          overflowX: "hidden",
          overflowY: "auto",
          padding: "0px 8px 0px 8px",
        }}
      >

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "column",
              lg: "row",
              xl: "row"
            },
            paddingLeft: "16px",
            paddingRight: "16px",
            gap: "1.125rem"
          }}
        >

          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "row",
                sm: "row",
                md: "row",
                lg: "column",
                xl: "column"
              },
              gap: "1.125rem",
              flex: {
                lg: 0.3,
                xl: 0.3
              }
            }}
          >

            <FormGroup
              sx={{
                flex: 1,
                border: "1px",
                borderRadius: "20px",
                backgroundColor: theme.palette.common.white,
                padding: "16px"
              }}
            >

              <FormLabel
                component="legend"
              >
                Details
              </FormLabel>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: {
                    xs: "1.125rem",
                    sm: "1.125rem",
                    md: "1.125rem",
                    lg: "0.6rem",
                    xl: "0.6rem"
                  },
                  marginTop: "20px"
                }}
              >

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%"
                  }}
                >
                  <Box
                    sx={{
                      flex: 1
                    }}
                  >
                    <TextFieldWithLabel
                      label="MPRN"
                      text={
                        String(dwelling.mprn)
                      }
                    />
                  </Box>

                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row"
                  }}
                >

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      flex: 1
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 500,
                        fontSize: "0.75rem",
                        color: colors.grey["800"]
                      }}
                    >
                      Address
                    </Typography>

                    <Box
                      sx={{
                        width: "100%"
                      }}
                    >

                      {
                        dwelling.street !== undefined &&
                        dwelling.street !== "" &&
                        dwelling.street !== "None" ? <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 400,
                            fontSize: "0.875rem",
                            color: colors.grey["800"]
                          }}
                        >
                          {dwelling.street}
                        </Typography> : null
                      }

                      {
                        dwelling.area !== undefined &&
                        dwelling.area !== "" &&
                        dwelling.area !== "None" ? <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 400,
                            fontSize: "0.875rem",
                            color: colors.grey["800"]
                          }}
                        >
                          {dwelling.area}
                        </Typography> : null
                      }

                      {
                        dwelling.city !== undefined &&
                        dwelling.city !== "" &&
                        dwelling.city !== "None" ? <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 400,
                            fontSize: "0.875rem",
                            color: colors.grey["800"]
                          }}
                        >
                          {dwelling.city}
                        </Typography> : null
                      }

                      {
                        dwelling.county !== undefined &&
                        dwelling.county !== "" &&
                        dwelling.county !== "None" ? <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 400,
                            fontSize: "0.875rem",
                            color: colors.grey["800"]
                          }}
                        >
                          {dwelling.county}
                        </Typography> : null
                      }

                      {
                        dwelling.postcode !== undefined &&
                        dwelling.postcode !== "" &&
                        dwelling.postcode !== "None" ? <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 400,
                            fontSize: "0.875rem",
                            color: colors.grey["800"]
                          }}
                        >
                          {dwelling.postcode}
                        </Typography> : null
                      }

                    </Box>

                  </Box>

                  <Box
                    sx={{
                      flex: 1
                    }}
                  >

                    <DwellingMapImage
                      dwelling={dwelling}
                      width={150}
                      height={116}
                    />

                  </Box>

                </Box>



                <TextFieldWithLabel
                  label="Type"
                  text={dwelling.archetype.dwelling_type}
                />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%"
                  }}
                >
                  <Box
                    sx={{
                      flex: 1
                    }}
                  >

                    <TextFieldWithLabel
                      label="Scenario"
                      text={scenario.title}
                    />

                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                      gap: "1.125rem"
                    }}
                  >

                    <BERChevron
                      rating={dwelling.performance.rating}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >

                      <EastIcon
                        sx={{
                          color: green[500],
                        }}
                      />

                    </Box>


                    <BERChevron
                      rating={scenarioRating}
                    />

                  </Box>

                </Box>


                <TextFieldWithLabel
                  label="OSS criteria achieved"
                  text={ossAhnEligible}
                  textColour={
                    ossAhnEligible ? colors.green["800"] : colors.red["800"]
                  }
                  textWeight={800}
                />

              </Box>

            </FormGroup>

            <Box
              sx={{
                flex: 1
              }}
            />

            {/*
            TODO: Include a different layout for extra small screens,
              the current layout breaks at that size
            */}

          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: {
                lg: 0.7,
                xl: 0.7
              }
            }}
          >

            {
              projectCosts !== undefined ? <MaterialTable
                columns={tableColumns}
                components={{
                  Container: TablePaperComponent,
                  Toolbar: () => null
                }}
                data={tableData}
                cellEditable={{
                  isCellEditable: () => true,
                  onCellEditApproved: (
                    newValue,
                    oldValue,
                    rowData,
                    columnDef
                  ) => new Promise<void>((resolve, reject) => {

                    const requestBody = {
                      project_id: projectId,
                      dwelling_id: dwelling.id,
                      scenario_id: Number(scenario.root_scenario),
                      scenario_type: scenario.type,
                      measure_id: rowData.measure_id,
                      measure_type: rowData.measure_type,
                      dwelling_type: dwelling.type
                    };

                    const newNumber = Number(newValue);
                    if (!Number.isNaN(newNumber)) {
                      if (columnDef.field === "cost") {
                        // @ts-expect-error cost does not already exist on requestBody
                        requestBody.cost = newNumber;
                      } else {
                        // @ts-expect-error grant does not already exist on requestBody
                        requestBody.grant = newNumber;
                      }
                      updateProjectCosts(requestBody)
                        .then(() => {
                          resolve();
                        })
                        .catch(() => {
                          reject();
                        });
                    } else {
                      reject();
                    }
                  })
                }}
                isLoading={isProjectCostsLoading}
                localization={{
                  body: {
                    emptyDataSourceMessage: "No measures to display",
                    filterRow: {
                      filterTooltip: "Filter"
                    }
                  }
                }}
                options={{
                  actionsColumnIndex: -1,
                  idSynonym: "step",
                  headerStyle: {
                    color: theme.palette.common.black,
                    padding: "6px 12px 6px 12px",
                    height: "56px",
                    fontWeight: 500,
                    fontSize: "0.875rem"
                  },
                  padding: "default",
                  paging: false,
                  rowStyle: () => ({
                    fontWeight: 400,
                    fontSize: "0.875rem",
                    backgroundColor: theme.palette.common.white
                  }),
                  search: false,
                  sorting: false,
                  showTitle: false,
                  tableLayout: "fixed",
                  thirdSortClick: false
                }}
              /> : <Box
                sx={{
                  width: "100%"
                }}
              >
                <LinearProgress />
              </Box>
            }

            {
              lowerTableData.length > 0 ? <MaterialTable
                columns={lowerTableColumns}
                components={{
                  Container: LowerTablePaperComponent,
                  Header: () => null,
                  Toolbar: () => null
                }}
                cellEditable={{
                  isCellEditable: (
                    rowData,
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    columnDef
                  ) => rowData.variableName === 'berAndHeatPumpBonus' ||
                    rowData.variableName === 'centralHeatingAndHeatPumpBonus',
                    // NOTE: Including the following line enables editing of the credit total
                    // || rowData.variableName === 'totalCredits',
                  onCellEditApproved: (
                    newValue,
                    oldValue,
                    rowData,
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    columnDef
                  ) => new Promise<void>((resolve, reject) => {

                    const requestBody = {
                      project_id: projectId,
                      dwelling_id: dwelling.id,
                      scenario_id: Number(scenario.root_scenario),
                      scenario_type: scenario.type,
                      dwelling_type: dwelling.type
                    };

                    const newNumber = Number(newValue);
                    if (!Number.isNaN(newNumber)) {
                      if (rowData.variableName === "berAndHeatPumpBonus") {
                        // @ts-expect-error ber_and_heat_pump_bonus does not already exist on requestBody
                        requestBody.ber_and_heat_pump_bonus = newNumber;
                      } else if (rowData.variableName === "centralHeatingAndHeatPumpBonus") {
                        // @ts-expect-error central_heating_and_heat_pump_bonus does not already exist on requestBody
                        requestBody.central_heating_and_heat_pump_bonus = newNumber;
                      }
                      else if (rowData.variableName === "totalCredits") {
                        // @ts-expect-error total_credits does not already exist on requestBody
                        requestBody.total_credits = newNumber;
                      }
                      updateProjectCosts(requestBody)
                        .then(() => {
                          resolve();
                        })
                        .catch(() => {
                          reject();
                        });
                    } else {
                      reject();
                    }
                  })
                }}
                data={lowerTableData}
                isLoading={isProjectCostsLoading || isProjectReportConfigLoading}
                options={{
                  actionsColumnIndex: -1,
                  // idSynonym: "step",
                  padding: "default",
                  paging: false,
                  rowStyle: () => ({
                    fontWeight: 400,
                    fontSize: "0.875rem"
                  }),
                  search: false,
                  sorting: false,
                  showTitle: false,
                  tableLayout: "fixed",
                  thirdSortClick: false
                }}
              /> : null
            }

          </Box>

        </Box>

      </DialogContent>

      <DialogActions
        sx={{
          paddingRight: "24px"
        }}
      >

        <Button
          variant="contained"
          color="secondary"
          onClick={handleClose}
        >
          Close
        </Button>

      </DialogActions>

    </Dialog>
  );
}
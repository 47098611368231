import React from "react";

import { Person as PersonIcon } from "@mui/icons-material";
import { styled, Zoom } from "@mui/material";
import type { Theme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import type { TooltipProps } from "@mui/material/Tooltip";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import { RetroKitIcon } from "common/components/RetroKitIcon";
import { isDefaultMeasure } from "common/utils/types";

import type { CustomMeasure } from "features/customMeasures/types";
import type { DefaultMeasure } from "features/defaultMeasures/types";


export const renderMeasureIcon = (
  rowData: CustomMeasure | DefaultMeasure
) => <RetroKitIcon
  iconLink={rowData.icon_link}
/>;

export const renderTitleColumn = (
  rowData: CustomMeasure | DefaultMeasure,
  theme: Theme
) => <Tooltip
  title={rowData.description}
  arrow
  TransitionComponent={Zoom}
  sx={{
    arrow: {
      "&::before": {
        backgroundColor: theme.palette.primary.main
      }
    },
    tooltip: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      boxShadow: theme.shadows[1],
      fontSize: "0.875rem",
      padding: "8px"
    }
  }}
>

  {
    Number(rowData.target_u_value) > 0.00 ?
      <div>
        <span>{rowData.title}</span> <span style={{
        color: theme.palette.primary.dark
      }}
      >
        {rowData.target_u_value} W/m²K
      </span>
      </div>

      : <div>
        {rowData.title}
      </div>
  }

</Tooltip>;

export const renderTitleColumnWithErrorTooltip = (
  rowData: CustomMeasure | DefaultMeasure,
  customMeasures: Array<CustomMeasure>,
  defaultMeasures: Array<DefaultMeasure>,
  theme: Theme
) => {

  const existingCustomMeasureWithSameRKId = customMeasures.some(
    customMeasure => customMeasure.retrokit_identifier === rowData.retrokit_identifier
  );

  const existingDefaultMeasureWithSameRKId = defaultMeasures.some(
    defaultMeasure => defaultMeasure.retrokit_identifier === rowData.retrokit_identifier
  );

  const customMeasureIncompatible = customMeasures.some(
    customMeasure => customMeasure.incompatible_with.includes(
      rowData.retrokit_identifier
    )
  );

  const defaultMeasureIncompatible = defaultMeasures.some(
    defaultMeasure => defaultMeasure.incompatible_with.includes(
      rowData.retrokit_identifier
    )
  );

  let existingMeasure: CustomMeasure | DefaultMeasure | undefined;
  if (
    existingCustomMeasureWithSameRKId ||
    customMeasureIncompatible
  ) {
    existingMeasure = customMeasures.find(
      customMeasure => customMeasure.retrokit_identifier === rowData.retrokit_identifier ||
        rowData.incompatible_with.includes(
          customMeasure.retrokit_identifier
        )
    );
  }

  if (
    existingDefaultMeasureWithSameRKId ||
    defaultMeasureIncompatible
  ) {
    existingMeasure = defaultMeasures.find(
      defaultMeasure => defaultMeasure.retrokit_identifier === rowData.retrokit_identifier ||
        rowData.incompatible_with.includes(
          defaultMeasure.retrokit_identifier
        )
    );
  }

  let tooltipTitle = rowData.description;

  if (existingMeasure !== undefined) {
    if (
      existingCustomMeasureWithSameRKId ||
      existingDefaultMeasureWithSameRKId
    ) {
      tooltipTitle = `Measure type already present (${existingMeasure.title})`;
    } else {
      tooltipTitle = rowData.incompatible_reason;
    }
  }

  const RetroKitTooltip = styled((
    { className, ...props }: TooltipProps
  ) => (
    <Tooltip
      {...props}
      classes={{
        popper: className
      }}
    />
  ))(() => ({
    [`& .${tooltipClasses.arrow}`]: existingMeasure !== undefined ?
      {
        "&::before": {
          backgroundColor: theme.retrokitPalette.red.dark
        }
      } : {
        "&::before": {
          backgroundColor: theme.palette.primary.main
        }
      },
    [`& .${tooltipClasses.tooltip}`]: existingMeasure !== undefined ?
      {
        backgroundColor: theme.retrokitPalette.red.dark,
        color: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        fontSize: "0.875rem",
        padding: "8px"
      } : {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        fontSize: "0.875rem",
        padding: "8px"
      }
  }));

  return <RetroKitTooltip
    title={tooltipTitle}
    arrow
    TransitionComponent={Zoom}
  >
    {
      Number(rowData.target_u_value) > 0.00 ?
        <Box>
          <span>
            {rowData.title}
          </span> <span
          style={{
            color: theme.palette.primary.dark
          }}
        >
          {rowData.target_u_value} W/m²K
        </span>
        </Box>

        : <Box>
          {rowData.title}
        </Box>
    }
  </RetroKitTooltip>;
};

export const renderFixedCostsColumn = (
  rowData: CustomMeasure | DefaultMeasure
) => {
  if (Number(rowData.fixed_costs) === 0 && (
    Number(rowData.fixed_costs_up_to_50) > 0 ||
    Number(rowData.fixed_costs_up_to_100) > 0 ||
    Number(rowData.fixed_costs_up_to_150) > 0 ||
    Number(rowData.fixed_costs_over_150) > 0
  )) {
    return <div style={{
      display: "flex",
      flexDirection: "column"
    }}
    >
      <span>€{rowData.fixed_costs_up_to_50}</span>
      <span>€{rowData.fixed_costs_up_to_100}</span>
      <span>€{rowData.fixed_costs_up_to_150}</span>
      <span>€{rowData.fixed_costs_over_150}</span>
    </div>;
  }
  if (Number(rowData.fixed_costs) === 0) {
    return "";
  }
  return <span>€{rowData.fixed_costs}</span>;
};

export const renderVariableCostsColumn = (
  rowData: CustomMeasure | DefaultMeasure
) => {
  if (Number(rowData.variable_costs) === 0) {
    return "";
  }
  return <span>€{rowData.variable_costs}</span>;
};

export const renderVariableUnitColumn = (
  rowData: CustomMeasure | DefaultMeasure
) => {
  if (Number(rowData.fixed_costs) === 0 && (
    Number(rowData.fixed_costs_up_to_50) > 0 ||
    Number(rowData.fixed_costs_up_to_100) > 0 ||
    Number(rowData.fixed_costs_up_to_150) > 0 ||
    Number(rowData.fixed_costs_over_150) > 0
  )) {
    return <Box
      sx={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <span>{"< 50"}{rowData.variable_unit}</span>
      <span>50 - 100{rowData.variable_unit}</span>
      <span>100 - 150{rowData.variable_unit}</span>
      <span>{"> 150"}{rowData.variable_unit}</span>
    </Box>;
  }
  return <span>{rowData.variable_unit}</span>;
};

export const renderTypeColumn = (
  rowData: CustomMeasure | DefaultMeasure,
  avatarClicked: (
    title: string,
    description: string,
    open: boolean
  ) => void,
  theme: Theme
) => {

  if (isDefaultMeasure(rowData)) {
    return <Tooltip
      title="RetroKit measure"
      arrow
      TransitionComponent={Zoom}
    >

      <Avatar
        sx={{
          backgroundColor: "#FFFFFF",
          width: "36px",
          height: "36px"
        }}
        aria-label="avatar-retrokit"
        onClick={() => {
          avatarClicked(
            "RetroKit Measures",
            "These are default measures provided by RetroKit. " +
            "They use sensible values that are updated regularly. " +
            "They can not be edited.",
            true
          );
        }}
      >

        <RetroKitIcon
          iconLink="static/public/images/logos/rk-logo-transparent.svg"
        />

      </Avatar>

    </Tooltip>;
  }
  return <Tooltip
    title="Custom measure"
    arrow
    TransitionComponent={Zoom}
  >

    <Avatar
      sx={{
        backgroundColor: theme.retrokitPalette.indigo.main,
        width: "36px",
        height: "36px"
      }}
      aria-label="avatar-user"
      onClick={() => {
        avatarClicked(
          "Custom Measures",
          "These are custom measures that are created by a user. " +
          "They can be edited in the *upcoming* Measures section. " +
          "They enable far more detailed scenario customization.",
          true
        );
      }}
    >
      <PersonIcon
        sx={{ color: "#FFFFFF" }}
      />

    </Avatar>

  </Tooltip>;
};
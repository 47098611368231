import React from "react";

import Box from "@mui/material/Box";

import type { Dwelling } from "features/dwellings/types";


interface DwellingMapImageProps {
  dwelling: Dwelling;
  height?: number;
  width?: number;
  borderRadius?: string;
}

// @ts-expect-error dwelling prop is undefined
const defaultDwellingMapImageProps: DwellingMapImageProps = {
  height: 144,
  width: 359,
  borderRadius: "0px"
};


export function DwellingMapImage(
  {
    dwelling,
    height,
    width,
    borderRadius
  }: DwellingMapImageProps) {
  
  return <Box
    style={{
      width,
      height
    }}
  >

    <img
      src={
        `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/geojson(%7B%22type%22%3A%22Point%22%2C%22coordinates%22%3A%5B${dwelling.latitude_longitude.coordinates[0]}%2C${dwelling.latitude_longitude.coordinates[1]}%5D%7D)/${dwelling.latitude_longitude.coordinates[0]},${dwelling.latitude_longitude.coordinates[1]},12/${width}x${height}?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "Not set"}`
      }
      alt="Static Mapbox map of the dwelling location"
      style={{
        borderRadius: borderRadius
      }}
    />

  </Box>;
}

DwellingMapImage.defaultProps = defaultDwellingMapImageProps;
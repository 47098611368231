export const floorInsulationLocationOptions = [
  {
    "label": "Not set",
    "value": 0
  },
  {
    "label": "On slab",
    "value": 1
  },
  {
    "label": "Between joists",
    "value": 2
  }
];

export const floorTypeOptions = [
  {
    "label": "Not set",
    "value": 0
  },
  {
    "label": "Ground Floor Solid",
    "value": 1
  },
  {
    "label": "Ground Floor Suspended",
    "value": 2
  },
  {
    "label": "Ground Floor Above Unheated Basement",
    "value": 3
  },
  {
    "label": "Heated Basement",
    "value": 4
  },
  {
    "label": "Partially Heated Below",
    "value": 5
  },
  {
    "label": "Exposed/Semi-Exposed",
    "value": 6
  }
];

export const frameTypeOptions = [
  {
    "label": "Not set",
    "value": 0
  },
  {
    "label": "Wood/PVC",
    "value": 1
  },
  {
    "label": "Metal, no thermal break",
    "value": 2
  },
  {
    "label": "Metal, thermal break 4 mm",
    "value": 3
  },
  {
    "label": "Metal, thermal break 8 mm",
    "value": 4
  },
  {
    "label": "Metal, thermal break 12 mm",
    "value": 5
  },
  {
    "label": "Metal, thermal break 20 mm",
    "value": 6
  },
  {
    "label": "Metal, thermal break 32 mm",
    "value": 7
  },
  {
    "label": "Other (Specify)",
    "value": 8
  }
];

export const glazingGapOptions = [
  {
    "label": "Not set",
    "value": 0
  },
  {
    "label": "6mm",
    "value": 1
  },
  {
    "label": "12mm",
    "value": 2
  },
  {
    "label": ">=16mm",
    "value": 3
  }
];

export const glazingTypeOptions = [
  {
    "label": "Not set",
    "value": 0
  },
  {
    "label": "Single-glazed",
    "value": 1
  },
  {
    "label": "Double-glazed, air filled",
    "value": 2
  },
  {
    "label": "Double-glazed, air filled (low-E, εn = 0.2, hard coat)",
    "value": 3
  },
  {
    "label": "Double-glazed, air filled (low-E, εn = 0.15, hard coat)",
    "value": 4
  },
  {
    "label": "Double-glazed, air filled (low-E, εn = 0.1, soft coat)",
    "value": 5
  },
  {
    "label": "Double-glazed, air filled (low-E, εn = 0.05, soft coat)",
    "value": 6
  },
  {
    "label": "Double-glazed, argon filled",
    "value": 7
  },
  {
    "label": "Double-glazed, argon filled (low-E, εn = 0.2, hard coat)",
    "value": 8
  },
  {
    "label": "Double-glazed, argon filled (low-E, εn = 0.15, hard coat)",
    "value": 9
  },
  {
    "label": "Double-glazed, argon filled (low-E, εn = 0.1, soft coat)",
    "value": 10
  },
  {
    "label": "Double-glazed, argon filled (low-E, εn = 0.05, soft coat)",
    "value": 11
  },
  {
    "label": "Triple glazed, air filled",
    "value": 12
  },
  {
    "label": "Triple-glazed, air filled (low-E, εn = 0.2, hard coat)",
    "value": 13
  },
  {
    "label": "Triple-glazed, air filled (low-E, εn = 0.15, hard coat)",
    "value": 14
  },
  {
    "label": "Triple-glazed, air filled (low-E, εn = 0.15, hard coat)",
    "value": 15
  },
  {
    "label": "Triple-glazed, air filled (low-E, εn = 0.05, soft coat)",
    "value": 16
  },
  {
    "label": "Triple-glazed, argon filled",
    "value": 17
  },
  {
    "label": "Triple-glazed, argon filled (low-E, εn = 0.2, hard coat)",
    "value": 18
  },
  {
    "label": "Triple-glazed, argon filled (low-E, εn = 0.15, hard coat)",
    "value": 19
  },
  {
    "label": "Triple-glazed, argon filled (low-E, εn = 0.1, soft coat)",
    "value": 20
  },
  {
    "label": "Triple-glazed, argon filled (low-E, εn = 0.05, soft coat)",
    "value": 21
  },
  {
    "label": "Single with secondary glazing",
    "value": 22
  }
];

export const insulationTypeOptions = [
  {
    "label": "Not set",
    "value": 0
  },
  {
    "label": "Expanded polystyrene (EPS) slab (HD)",
    "value": 1
  },
  {
    "label": "Expanded polystyrene (EPS) slab (SD)",
    "value": 2
  },
  {
    "label": "Extruded polystyrene",
    "value": 3
  },
  {
    "label": "Mineral fibre / wool quilt",
    "value": 4
  },
  {
    "label": "Mineral fibre / wool batt",
    "value": 5
  },
  {
    "label": "Phenolic foam (rigid)",
    "value": 6
  },
  {
    "label": "Polyurethane board (unfaced)",
    "value": 7
  },
  {
    "label": "Polyurethane foam",
    "value": 8
  },
  {
    "label": "Polyisocyanate",
    "value": 9
  },
  {
    "label": "Polyisocyanurate board",
    "value": 10
  },
  {
    "label": "Polystyrene beads",
    "value": 11
  },
  {
    "label": "Cellulosic insulation",
    "value": 12
  },
  {
    "label": "None",
    "value": 13
  }
];

export const orientationOptions = [
  {
    "label": "Not set",
    "value": 0
  },
  {
    "label": "North",
    "value": 1
  },
  {
    "label": "North-East",
    "value": 2
  },
  {
    "label": "East",
    "value": 3
  },
  {
    "label": "South-East",
    "value": 4
  },
  {
    "label": "South",
    "value": 5
  },
  {
    "label": "South-West",
    "value": 6
  },
  {
    "label": "West",
    "value": 7
  },
  {
    "label": "North-West",
    "value": 8
  },
  {
    "label": "H",
    "value": 9
  }
];

export const roofInsulationLocationOptions = [
  {
    "label": "Not set",
    "value": 0
  },
  {
    "label": "Between and below rafters",
    "value": 1
  },
  {
    "label": "Flat ceiling in attic",
    "value": 2
  },
  {
    "label": "Flat roof",
    "value": 3
  }
];

export const roofTypeOptions = [
  {
    "label": "Not set",
    "value": 0
  },
  {
    "label": "Pitched roof insulated on rafter",
    "value": 1
  },
  {
    "label": "Pitched roof insulated on ceiling",
    "value": 2
  },
  {
    "label": "Flat roof",
    "value": 3
  },
  {
    "label": "Room in roof",
    "value": 4
  }
];

export const structureTypeOptions = [
  {
    "label": "Not set",
    "value": 0
  },
  {
    "label": "Masonry (Assumed)",
    "value": 4
  },
  {
    "label": "Masonry",
    "value": 5
  },
  {
    "label": "Timber or Steel Frame",
    "value": 6
  },
  {
    "label": "ICF",
    "value": 7
  }
];

export const thermalConductivityOptions = [
  {
    "label": "0",
    "value": 0
  },
  {
    "label": "0.035",
    "value": 1
  },
  {
    "label": "0.038",
    "value": 2
  },
  {
    "label": "0.029",
    "value": 3
  },
  {
    "label": "0.044",
    "value": 4
  },
  {
    "label": "0.037",
    "value": 5
  },
  {
    "label": "0.025",
    "value": 6
  },
  {
    "label": "0.023",
    "value": 7
  },
  {
    "label": "0.028",
    "value": 8
  },
  {
    "label": "0.03",
    "value": 9
  },
  {
    "label": "0.025",
    "value": 10
  },
  {
    "label": "0.036",
    "value": 11
  },
  {
    "label": "0.042",
    "value": 12
  },
  {
    "label": "0",
    "value": 13
  }
];

export const ventilationDuctingOptions = [
  {
    "label": "N/A",
    "value": "0"
  },
  {
    "label": "Rigid",
    "value": "1"
  },
  {
    "label": "Flexible",
    "value": "2"
  }
];

export const ventilationMethodOptions = [
  {
    "label": "Not set",
    "value": 0
  },
  {
    "label": "Natural ventilation",
    "value": 1
  },
  {
    "label": "Positive input ventilation from loft",
    "value": 2
  },
  {
    "label": "Positive input ventilation from outside",
    "value": 3
  },
  {
    "label": "Whole-house extract ventilation",
    "value": 4
  },
  {
    "label": "Balanced whole-house mechanical ventilation, no heat recovery",
    "value": 5
  },
  {
    "label": "Balanced whole-house mechanical ventilation with heat recovery",
    "value": 6
  },
  {
    "label": "Exhaust air heat pump",
    "value": 7
  }
];

export const wallTypeOptions = [
  {
    "label": "Not set",
    "value": 0
  },
  {
    "label": "Stone",
    "value": 1
  },
  {
    "label": "225mm Solid brick",
    "value": 2
  },
  {
    "label": "325mm Solid Brick",
    "value": 3
  },
  {
    "label": "300mm Cavity",
    "value": 4
  },
  {
    "label": "300mm Filled Cavity",
    "value": 5
  },
  {
    "label": "Solid Mass Concrete",
    "value": 6
  },
  {
    "label": "Concrete Hollow Block",
    "value": 7
  },
  {
    "label": "Timber Frame",
    "value": 8
  },
  {
    "label": "Other",
    "value": 9
  },
  {
    "label": "Unknown",
    "value": 10
  },
  {
    "label": "425mm Cavity",
    "value": 11
  },
  {
    "label": "425mm Filled Cavity",
    "value": 12
  },
  {
    "label": "Semi exposed 100mm block",
    "value": 13
  },
  {
    "label": "Semi exposed 215mm block",
    "value": 14
  },
  {
    "label": "Unknown",
    "value": 15
  }
];

export const wallInsulationLocationOptions = [
  {
    "label": "Cavity",
    "value": 0
  },
  {
    "label": "External",
    "value": 1
  },
  {
    "label": "Internal",
    "value": 2
  }
];

export const woodenGroundFloorOptions = [
  {
    "label": "Not set",
    "value": 0
  },
  {
    "label": "N/A",
    "value": 1
  },
  {
    "label": "Yes",
    "value": 2
  },
  {
    "label": "No",
    "value": 3
  }
];
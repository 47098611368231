import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

let withMaterialBreakpointQuery = () => Component => props => {

    let keys = [...props.theme.breakpoints.keys].reverse();
    let result = (
        keys.reduce((output, key) => {
            const matches = useMediaQuery(props.theme.breakpoints.up(key));
            return !output && matches ? key : output;
        }, null) || 'xs'
    );

    return <Component breakpoint={result} {...props} />;
}

export default withMaterialBreakpointQuery;
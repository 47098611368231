import { USER_LOADING, USER_LOADED } from '../actions/user'
import { LOGOUT_SUCCESSFUL, LOGIN_FAILED, REGISTRATION_FAILED, AUTHENTICATION_ERROR } from '../actions/authentication'

const initialState = {
    id: 0,
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    last_login: "",
    can_view_uploads: false,
    can_view_seai_api: false,
    can_view_projects: false,
    can_view_superhomes_export: false,
    is_staff: false,
    first_use: true,
    assets: [],
    plans: [],
    projects: [],
    summaries: [],
    loaded: false
};

export default function authReducer(state=initialState, action) {
    switch (action.type) {

        case USER_LOADING:
            return {...state, loaded: false};
        case USER_LOADED:
            return {...state, loaded: true,
                id: action.user.id,
                username: action.user.username,
                first_name: action.user.first_name,
                last_name: action.user.last_name,
                email: action.user.email,
                address: action.user.address,
                last_login: action.user.last_login,
                can_view_uploads: action.user.can_view_uploads,
                can_view_seai_api: action.user.can_view_seai_api,
                can_view_projects: action.user.can_view_projects,
                can_view_superhomes_export: action.user.can_view_superhomes_export,
                is_staff: action.user.is_staff,
                first_use: action.user.first_use,
                assets: action.user.assets,
                plans: action.user.plans,
                projects: action.user.projects,
                summaries: action.user.summaries
            };

        case AUTHENTICATION_ERROR:
        case LOGIN_FAILED:
        case REGISTRATION_FAILED:
        case LOGOUT_SUCCESSFUL:
            return {
                ...state,
                errors: action.data,
                id: 0,
                username: "",
                first_name: "",
                last_name: "",
                email: "",
                address: "",
                last_login: "",
                can_view_uploads: false,
                can_view_seai_api: false,
                can_view_projects: false,
                can_view_superhomes_export: false,
                first_use: true,
                assets: [],
                plans: [],
                projects: [],
                loaded: false,
                is_staff: false
            };
        default:
            return state;
    }
}
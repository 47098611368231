import { retrokitApi } from "common/api/retrokitApi";

import type {
  GetProjectCostsRequest,
  UpdateProjectCostsRequest
} from "./types";
import type { ProjectCosts } from "../types";


const projectCostsApiEndpoint = `integrations/project/costs`

export const projectCostsApi = retrokitApi.injectEndpoints({
  endpoints: (builder) => ({

    // See documentation here: https://documentation.retrokit.eu/books/retrokit-api/page/get-project-costs-and-grants
    getProjectCosts: builder.query<ProjectCosts, GetProjectCostsRequest>({
      query: (arg) => ({
        url: projectCostsApiEndpoint,
        method: "POST",
        body: arg
      }),
      providesTags: [
        "ProjectCosts"
      ]
    }),

    // See documentation here: https://documentation.retrokit.eu/books/retrokit-api/page/update-project-costs-and-grants
    updateProjectCosts: builder.mutation<ProjectCosts, UpdateProjectCostsRequest>({
      query: (arg) => ({
        url: projectCostsApiEndpoint,
        method: "PUT",
        body: arg
      }),
      invalidatesTags: [
        "ProjectCosts"
      ]
    }),
    
  })
});

export const {
  useGetProjectCostsQuery,
  useUpdateProjectCostsMutation
} = projectCostsApi;
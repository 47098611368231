import React, {type ChangeEvent, useCallback, useEffect, useState} from "react";

import { format } from "date-fns";

import { Edit as EditIcon } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { colors, TextField, Zoom } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Paper, { type PaperProps } from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { ConfirmationDialog } from "common/components/ConfirmationDialog";
import {
  ProjectScenarioMeasureCategorySection
} from "features/projectScenarios/components/ProjectScenarioMeasureCategorySection";
import {
  AddMeasuresToCustomScenarioDialog
} from "features/projectScenarios/dialogs/AddMeasuresToProjectScenarioDialog";
import {
  useEditProjectScenarioDialog
} from "features/projectScenarios/hooks/useEditProjectScenarioDialog";


interface EditProjectScenarioDialogProps {
  onClose: () => void;
  open: boolean;
  projectScenarioId: number;
}

export function EditProjectScenarioDialog(
  {
    onClose,
    open,
    projectScenarioId
  }: EditProjectScenarioDialogProps) {

  const theme = useTheme();

  const {
    projectScenario,
    customMeasuresNotInProjectScenario,
    defaultMeasuresNotInProjectScenario,
    isProjectScenarioLoading,
    updateProjectScenarioDetails,
    updateProjectScenarioDefaultMeasures,
    updateProjectScenarioCustomMeasures
  } = useEditProjectScenarioDialog(projectScenarioId);

  const handleClose = useCallback(() => {
      onClose();
    },
    [onClose]
  );

  const handleUpdateProjectScenarioTitle = useCallback(
    (newTitle: string) => {
      if (projectScenario) {
        updateProjectScenarioDetails({
          id: projectScenario.id,
          title: newTitle
        });
      }
    },
    [
      projectScenario,
      updateProjectScenarioDetails
    ]
  );

  const handleUpdateProjectScenarioDescription = useCallback(
    (newDescription: string) => {
      if (projectScenario) {
        updateProjectScenarioDetails({
          id: projectScenario.id,
          description: newDescription
        });
      }
    },
    [
      projectScenario,
      updateProjectScenarioDetails
    ]
  );

  const handleOnUpdateProjectScenarioCustomMeasures = useCallback(
    (data: {
      add: boolean;
      custom_measures: Array<number>;
    }) => {
      if (projectScenario) {
        updateProjectScenarioCustomMeasures({
          id: projectScenario.id,
          add: data.add,
          custom_measures: data.custom_measures
        });
      }
    },
    [
      projectScenario,
      updateProjectScenarioCustomMeasures
    ]
  );

  const handleOnUpdateProjectScenarioDefaultMeasures = useCallback(
    (data: {
      add: boolean;
      measures: Array<number>;
    }) => {
      if (projectScenario) {
        updateProjectScenarioDefaultMeasures({
          id: projectScenario.id,
          add: data.add,
          measures: data.measures
        });
      }
    },
    [
      projectScenario,
      updateProjectScenarioDefaultMeasures
    ]
  );

  const [
    editingDescription, setEditingDescription
  ] = useState<boolean>(false);

  const [
    editingTitle, setEditingTitle
  ] = useState<boolean>(false);

  const [
    confirmationDialogTitle, setConfirmationDialogTitle
  ] = useState<string>("");

  const [
    confirmationDialogMessage, setConfirmationDialogMessage
  ] = useState<string>("");

  const [
    confirmationDialogOpen, setConfirmationDialogOpen
  ] = useState<boolean>(false);

  const [
    addMeasuresDialogCategory, setAddMeasuresDialogCategory
  ] = useState<string>("");

  const [
    addMeasuresDialogTableHeaderColor, setAddMeasuresDialogTableHeaderColor
  ] = useState<string>(theme.palette.primary.light);

  const [
    addMeasuresDialogTableEvenRowColor, setAddMeasuresDialogTableEvenRowColor
  ] = useState<string>("#EEEEEE");

  const [
    addMeasuresDialogTableOddRowColor, setAddMeasuresDialogTableOddRowColor
  ] = useState<string>("#FFFFFF");

  useEffect(
    () => {
      if (addMeasuresDialogCategory === "Ventilation") {
        setAddMeasuresDialogTableHeaderColor(theme.retrokitPalette.red.main);
        setAddMeasuresDialogTableEvenRowColor(colors.red["100"]);
        setAddMeasuresDialogTableOddRowColor(colors.red["50"]);
      } else if (addMeasuresDialogCategory === "Fabric") {
        setAddMeasuresDialogTableHeaderColor(theme.retrokitPalette.green.main);
        setAddMeasuresDialogTableEvenRowColor(colors.green["100"]);
        setAddMeasuresDialogTableOddRowColor(colors.green["50"]);
      } else if (addMeasuresDialogCategory === "Heating") {
        setAddMeasuresDialogTableHeaderColor(theme.retrokitPalette.blue.main);
        setAddMeasuresDialogTableEvenRowColor(colors.blue["100"]);
        setAddMeasuresDialogTableOddRowColor(colors.blue["50"]);
      } else if (addMeasuresDialogCategory === "Renewables") {
        setAddMeasuresDialogTableHeaderColor(theme.retrokitPalette.orange.main);
        setAddMeasuresDialogTableEvenRowColor(colors.orange["100"]);
        setAddMeasuresDialogTableOddRowColor(colors.orange["50"]);
      } else if (addMeasuresDialogCategory === "Other") {
        setAddMeasuresDialogTableHeaderColor(theme.retrokitPalette.teal.main);
        setAddMeasuresDialogTableEvenRowColor(colors.teal["100"]);
        setAddMeasuresDialogTableOddRowColor(colors.teal["50"]);
      } else {
        setAddMeasuresDialogTableHeaderColor(theme.palette.primary.light);
        setAddMeasuresDialogTableEvenRowColor("#EEEEEE");
        setAddMeasuresDialogTableOddRowColor("#FFFFFF");
      }

    },
    [
      addMeasuresDialogCategory,
      theme.retrokitPalette.red.main,
      theme.retrokitPalette.green.main,
      theme.retrokitPalette.blue.main,
      theme.retrokitPalette.orange.main,
      theme.retrokitPalette.teal.main,
      theme.palette.primary.light
    ]
  );

  const [
    addMeasuresDialogOpen, setAddMeasuresDialogOpen
  ] = useState<boolean>(false);

  const handleCloseAddMeasuresDialog = () => {
    setAddMeasuresDialogOpen(false);
    setAddMeasuresDialogCategory("");
  };

  const handleCloseConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
  };

    const [
        ossEligible,
        setOSSEligible
    ] = useState<boolean>(true);

    useEffect(
        () => {
            if (projectScenario !== undefined) {
                if (projectScenario.oss_eligible !== ossEligible) {
                    setOSSEligible(projectScenario.oss_eligible);
                }
            }
        },
        [ossEligible, projectScenario]
    );

    const handleUpdateOSSEligible = useCallback(
        (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
            if (projectScenario) {
                updateProjectScenarioDetails({
                    id: projectScenario.id,
                    oss_eligible: checked
                });
            }
        },
        [
            projectScenario,
            updateProjectScenarioDetails
        ]
    );


  const DialogPaperComponent = useCallback(
    (
      props: JSX.IntrinsicAttributes & PaperProps
    ) => <Paper
      elevation={0}
      sx={{
        borderWidth: "0px",
        borderRadius: "20px"
      }}
      variant="outlined"
      {...props}
    />,
    []
  );

  return projectScenario ? (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={handleClose}
      scroll="body"
      PaperComponent={DialogPaperComponent}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          paddingTop: "8px",
          paddingBottom: "8px",
          paddingLeft: "24px",
          paddingRight: "8px"
        }}
      >
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
          variant="h5"
        >
          Edit Scenario in Project
        </Typography>

        <Box
          sx={{
            flex: 1
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row"
          }}
        >
          {
            handleClose ? (
              <Tooltip title="Close" aria-label="edit-project-close-modal">
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            ) : null
          }
        </Box>
      </Box>

      <DialogContent
        sx={{
          width: "100%",
          flex: 1,
          overflowX: "hidden",
          overflowY: "auto"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "16px"
          }}
        >

          <Paper
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              padding: "16px",
              borderRadius: "20px",
              gap: "8px"
            }}
            elevation={0}
            variant="outlined"
          >
            {
              editingTitle ? <Box
                sx={{
                  paddingTop: "16px",
                  flex: 1
                }}
              >
                <TextField
                  autoFocus
                  defaultValue={projectScenario.title}
                  fullWidth
                  id="scenario-title"
                  label="TITLE"
                  onBlur={(event) => {
                    handleUpdateProjectScenarioTitle(event.target.value);
                    setEditingTitle(false);
                  }}
                  variant="outlined"
                />
              </Box> : <Box
                sx={{
                  flex: 1,
                  paddingTop: "16px",
                  display: "flex",
                  flexDirection: "row",
                  borderTopWidth: "0px",
                  borderLeftWidth: "0px",
                  borderRightWidth: "0px",
                  borderBottomWidth: "1px",
                  borderBottomStyle: "solid",
                  borderBottomColor: colors.grey["300"]
                }}
              >

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >

                  <Typography
                    variant="subtitle1"
                    style={{
                      fontSize: "11px",
                      fontWeight: 500,
                      textTransform: "uppercase",
                      color: colors.grey["700"]
                    }}
                  >
                    Title
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    style={{
                      fontSize: "1rem",
                      fontWeight: 400
                    }}
                  >
                    {projectScenario.title}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "right"
                  }}
                >
                  <Tooltip
                    title="Edit scenario title"
                    arrow
                    TransitionComponent={Zoom}
                  >
                    <IconButton
                      aria-label="edit scenario title"
                      onClick={() => {
                        setEditingTitle(true);
                      }}
                    >
                      <EditIcon
                        sx={{
                          color: theme.retrokitPalette.green.dark
                        }}
                      />
                    </IconButton>
                  </Tooltip>

                </Box>
              </Box>
            }

            {
              editingDescription ? <Box
                sx={{
                  paddingTop: "16px",
                  flex: 1
                }}
              >
                <TextField
                  autoFocus
                  defaultValue={projectScenario.description}
                  fullWidth
                  id="project-description"
                  label="DESCRIPTION"
                  onBlur={(event) => {
                    handleUpdateProjectScenarioDescription(event.target.value);
                    setEditingDescription(false);
                  }}
                  variant="outlined"
                />
              </Box> : <Box
                sx={{
                  flex: 1,
                  paddingTop: "16px",
                  display: "flex",
                  flexDirection: "row",
                  borderTopWidth: "0px",
                  borderLeftWidth: "0px",
                  borderRightWidth: "0px",
                  borderBottomWidth: "1px",
                  borderBottomStyle: "solid",
                  borderBottomColor: colors.grey["300"]
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >

                  <Typography
                    variant="subtitle1"
                    style={{
                      fontSize: "11px",
                      fontWeight: 500,
                      textTransform: "uppercase",
                      color: colors.grey["700"]
                    }}
                  >
                    Description
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    style={{
                      fontSize: "1rem",
                      fontWeight: 400
                    }}
                  >
                    {projectScenario.description}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "right"
                  }}
                >
                  <Tooltip
                    title="Edit scenario description"
                    arrow
                    TransitionComponent={Zoom}
                  >
                    <IconButton
                      aria-label="edit scenario description"
                      onClick={() => setEditingDescription(true)}
                    >
                      <EditIcon sx={{
                        color: theme.retrokitPalette.green.dark
                      }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            }

              <Tooltip
                  title="OSS eligible"
              >
                  <FormControlLabel
                      control={
                          <Checkbox
                              checked={ossEligible}
                              onChange={handleUpdateOSSEligible}
                          />
                      }
                      label="OSS eligible"
                  />
              </Tooltip>

          </Paper>

          <Paper
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              padding: "16px",
              borderRadius: "20px"
            }}
            elevation={0}
            variant="outlined"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width:" 100%"
              }}
            >

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  alignItems: "center"
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    fontSize: "1rem",
                    fontWeight: 500,
                    textTransform: "uppercase",
                    color: colors.grey["700"],
                    textAlign: "right",
                    flex: 1
                  }}
                >
                  Measures
                </Typography>

                <Typography
                  variant="subtitle1"
                  style={{
                    fontSize: "0.875rem",
                    fontWeight: 400,
                    textAlign: "center",
                    flex: 1
                  }}
                >
                  {
                    projectScenario.measures.length + projectScenario.custom_measures.length
                  }
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  alignItems: "center",
                  borderTop: `1px solid ${colors.grey["300"]}`
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    fontSize: "1rem",
                    fontWeight: 500,
                    textTransform: "uppercase",
                    color: colors.grey["700"],
                    textAlign: "right",
                    flex: 1
                  }}
                >
                  Created
                </Typography>

                <Typography
                  variant="subtitle1"
                  style={{
                    fontSize: "0.875rem",
                    fontWeight: 400,
                    textAlign: "center",
                    flex: 1,
                  }}
                >
                  {
                    String(format(
                      projectScenario.created_on,
                      "do MMM yyyy"
                    ))
                  }
                </Typography>
              </Box>

            </Box>

          </Paper>

        </Box>



        <ProjectScenarioMeasureCategorySection
          avatarColor={theme.retrokitPalette.red.dark}
          category="Ventilation"
          customMeasures={projectScenario.custom_measures}
          defaultMeasures={projectScenario.measures}
          handleSetAddMeasuresDialogCategory={setAddMeasuresDialogCategory}
          handleSetAddMeasuresDialogOpen={setAddMeasuresDialogOpen}
          isLoading={isProjectScenarioLoading}
          onUpdateProjectScenarioDefaultMeasures={
            handleOnUpdateProjectScenarioDefaultMeasures
          }
          onUpdateProjectScenarioCustomMeasures={
            handleOnUpdateProjectScenarioCustomMeasures
          }
          scenarioTitle={projectScenario.title}
          setConfirmationDialogTitle={setConfirmationDialogTitle}
          setConfirmationDialogMessage={setConfirmationDialogMessage}
          setConfirmationDialogOpen={setConfirmationDialogOpen}
          subHeaderText="Mechanical ventilation and air tightness"
          tableHeaderColor={theme.retrokitPalette.red.main}
          tableEvenRowColor={colors.red["100"]}
          tableOddRowColor={colors.red["50"]}
        />

        <ProjectScenarioMeasureCategorySection
          avatarColor={theme.retrokitPalette.green.dark}
          category="Fabric"
          customMeasures={projectScenario.custom_measures}
          defaultMeasures={projectScenario.measures}
          handleSetAddMeasuresDialogCategory={setAddMeasuresDialogCategory}
          handleSetAddMeasuresDialogOpen={setAddMeasuresDialogOpen}
          isLoading={isProjectScenarioLoading}
          onUpdateProjectScenarioDefaultMeasures={
            handleOnUpdateProjectScenarioDefaultMeasures
          }
          onUpdateProjectScenarioCustomMeasures={
            handleOnUpdateProjectScenarioCustomMeasures
          }
          scenarioTitle={projectScenario.title}
          setConfirmationDialogTitle={setConfirmationDialogTitle}
          setConfirmationDialogMessage={setConfirmationDialogMessage}
          setConfirmationDialogOpen={setConfirmationDialogOpen}
          subHeaderText="Insulation for floors, roofs and walls. Replacement of doors and windows"
          tableHeaderColor={theme.retrokitPalette.green.main}
          tableEvenRowColor={colors.green["100"]}
          tableOddRowColor={colors.green["50"]}
        />

        <ProjectScenarioMeasureCategorySection
          avatarColor={theme.retrokitPalette.blue.dark}
          category="Heating"
          customMeasures={projectScenario.custom_measures}
          defaultMeasures={projectScenario.measures}
          handleSetAddMeasuresDialogCategory={setAddMeasuresDialogCategory}
          handleSetAddMeasuresDialogOpen={setAddMeasuresDialogOpen}
          isLoading={isProjectScenarioLoading}
          onUpdateProjectScenarioDefaultMeasures={
            handleOnUpdateProjectScenarioDefaultMeasures
          }
          onUpdateProjectScenarioCustomMeasures={
            handleOnUpdateProjectScenarioCustomMeasures
          }
          scenarioTitle={projectScenario.title}
          setConfirmationDialogTitle={setConfirmationDialogTitle}
          setConfirmationDialogMessage={setConfirmationDialogMessage}
          setConfirmationDialogOpen={setConfirmationDialogOpen}
          subHeaderText="Heating controls, heat emission and distribution systems, and boiler upgrades"
          tableHeaderColor={theme.retrokitPalette.blue.main}
          tableEvenRowColor={colors.blue["100"]}
          tableOddRowColor={colors.blue["50"]}
        />

        <ProjectScenarioMeasureCategorySection
          avatarColor={theme.retrokitPalette.orange.dark}
          category="Renewables"
          customMeasures={projectScenario.custom_measures}
          defaultMeasures={projectScenario.measures}
          handleSetAddMeasuresDialogCategory={setAddMeasuresDialogCategory}
          handleSetAddMeasuresDialogOpen={setAddMeasuresDialogOpen}
          isLoading={isProjectScenarioLoading}
          onUpdateProjectScenarioDefaultMeasures={
            handleOnUpdateProjectScenarioDefaultMeasures
          }
          onUpdateProjectScenarioCustomMeasures={
            handleOnUpdateProjectScenarioCustomMeasures
          }
          scenarioTitle={projectScenario.title}
          setConfirmationDialogTitle={setConfirmationDialogTitle}
          setConfirmationDialogMessage={setConfirmationDialogMessage}
          setConfirmationDialogOpen={setConfirmationDialogOpen}
          subHeaderText="Heat pumps, solar PV, solar thermal, and wood stove"
          tableHeaderColor={theme.retrokitPalette.orange.main}
          tableEvenRowColor={colors.orange["100"]}
          tableOddRowColor={colors.orange["50"]}
        />

        <ProjectScenarioMeasureCategorySection
          avatarColor={theme.retrokitPalette.teal.dark}
          category="Other"
          customMeasures={projectScenario.custom_measures}
          defaultMeasures={projectScenario.measures}
          handleSetAddMeasuresDialogCategory={setAddMeasuresDialogCategory}
          handleSetAddMeasuresDialogOpen={setAddMeasuresDialogOpen}
          isLoading={isProjectScenarioLoading}
          onUpdateProjectScenarioDefaultMeasures={
            handleOnUpdateProjectScenarioDefaultMeasures
          }
          onUpdateProjectScenarioCustomMeasures={
            handleOnUpdateProjectScenarioCustomMeasures
          }
          scenarioTitle={projectScenario.title}
          setConfirmationDialogTitle={setConfirmationDialogTitle}
          setConfirmationDialogMessage={setConfirmationDialogMessage}
          setConfirmationDialogOpen={setConfirmationDialogOpen}
          subHeaderText="Lighting, monitoring, and miscellaneous"
          tableHeaderColor={theme.retrokitPalette.teal.main}
          tableEvenRowColor={colors.teal["100"]}
          tableOddRowColor={colors.teal["50"]}
        />

      </DialogContent>

      <DialogActions
        sx={{
          paddingRight: "24px"
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClose}
        >
          Close
        </Button>
      </DialogActions>

      {
        customMeasuresNotInProjectScenario &&
        defaultMeasuresNotInProjectScenario ?
          <AddMeasuresToCustomScenarioDialog
            category={addMeasuresDialogCategory}
            customMeasures={projectScenario.custom_measures}
            customMeasuresNotInProjectScenario={
              customMeasuresNotInProjectScenario.filter(
                (measure) => measure.retrokit_category === addMeasuresDialogCategory
              )
            }
            defaultMeasures={projectScenario.measures}
            defaultMeasuresNotInProjectScenario={
              defaultMeasuresNotInProjectScenario.filter(
                (measure) => measure.retrokit_category === addMeasuresDialogCategory
              )
            }
            isLoading={isProjectScenarioLoading}
            onClose={handleCloseAddMeasuresDialog}
            onUpdateProjectScenarioDefaultMeasures={
              handleOnUpdateProjectScenarioDefaultMeasures
            }
            onUpdateProjectScenarioCustomMeasures={
              handleOnUpdateProjectScenarioCustomMeasures
            }
            open={addMeasuresDialogOpen}
            scenarioTitle={projectScenario.title}
            setConfirmationDialogTitle={setConfirmationDialogTitle}
            setConfirmationDialogMessage={setConfirmationDialogMessage}
            setConfirmationDialogOpen={setConfirmationDialogOpen}
            tableHeaderColor={addMeasuresDialogTableHeaderColor}
            tableEvenRowColor={addMeasuresDialogTableEvenRowColor}
            tableOddRowColor={addMeasuresDialogTableOddRowColor}
          /> : null
      }

      <ConfirmationDialog
        buttonText="Okay"
        message={confirmationDialogMessage}
        title={confirmationDialogTitle}
        onClose={handleCloseConfirmationDialog}
        onSubmit={() => {
          handleCloseConfirmationDialog();
        }}
        open={confirmationDialogOpen}
      />
    </Dialog>
  ) : null;
}
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";


import MaterialTable, { type Column } from "@material-table/core";

import {
  colors
} from "@mui/material";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import type { PaperProps } from "@mui/material/Paper";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

import { BEREdited } from "common/components/BEREdited";
import { HEA_RENOVATION_TABLE_COLUMNS } from "common/constants/tables";
import iconTitleHomeBlank from "common/icons/icon-title-home-blank.png";
import {
  useHomeEnergyAssessment
} from "features/homeEnergyAssessment/hooks/useHomeEnergyAssessment";

import type { Dwelling } from "features/dwellings/types";
import type {
  HomeEnergyAssessmentRenovationTableData
} from "features/homeEnergyAssessment/types";


interface HomeEnergyAssessmentDialogContent6Props {
  dwelling: Dwelling;
  contentBackgroundColour: string;
  pageBackgroundColour: string;
  inputError: boolean;
  setInputError: React.Dispatch<React.SetStateAction<boolean>>;
  handleHeaFieldBlur: (
    value: string | number | File,
    field: string
  ) => void;
}


export function HomeEnergyAssessmentDialogContent6(
  {
    dwelling,
    contentBackgroundColour,
    pageBackgroundColour,
    inputError,
    setInputError,
    handleHeaFieldBlur
  }: HomeEnergyAssessmentDialogContent6Props
) {

  const {
    homeEnergyAssessment
  } = useHomeEnergyAssessment();

  const [
    sectionHeadingRenovationPathway,
    setSectionHeadingRenovationPathway
  ] = useState<string>("");

  const [
    sectionHeadingRenovationPathwayError,
    setSectionHeadingRenovationPathwayError
  ] = useState<boolean>(false);

  const [
    sectionHeadingRenovationPathwayLength,
    setSectionHeadingRenovationPathwayLength
  ] = useState<number>(0);

  const sectionHeadingRenovationPathwayLengthLimit = 100;

  const sectionHeadingRenovationPathwayInputRef = useRef<HTMLInputElement>();

  const [
    renovationPathwayParagraph1,
    setRenovationPathwayParagraph1
  ] = useState<string>("");

  const [
    renovationPathwayParagraph1Error,
    setRenovationPathwayParagraph1Error
  ] = useState<boolean>(false);

  const [
    renovationPathwayParagraph1Length,
    setRenovationPathwayParagraph1Length
  ] = useState<number>(0);

  const renovationPathwayParagraph1LengthLimit = 600;

  const renovationPathwayParagraph1InputRef = useRef<HTMLInputElement>();

  const [
    renovationTableData,
    setRenovationTableData
  ] = useState<HomeEnergyAssessmentRenovationTableData[]>([]);

  useEffect(
    () => {

      if (
        renovationPathwayParagraph1Error ||
        sectionHeadingRenovationPathwayError
      ) {
        setInputError(true);
      }

      if (inputError) {
        if (
          !renovationPathwayParagraph1Error &&
          !sectionHeadingRenovationPathwayError
        ) {
          setInputError(false);
        }
      }

    },
    [
      renovationPathwayParagraph1Error,
      sectionHeadingRenovationPathwayError,
      inputError,
      setInputError
    ]
  );

  useEffect(
    () => {

      if (homeEnergyAssessment !== undefined) {
        setSectionHeadingRenovationPathway(
          homeEnergyAssessment.section_heading_renovation_pathway
        );

        setSectionHeadingRenovationPathwayLength(
          homeEnergyAssessment.section_heading_renovation_pathway !== null ?
            homeEnergyAssessment.section_heading_renovation_pathway.length : 0
        );

        setRenovationPathwayParagraph1(
          homeEnergyAssessment.renovation_pathway_paragraph_1
        );

        setRenovationPathwayParagraph1Length(
          homeEnergyAssessment.renovation_pathway_paragraph_1 !== null ?
            homeEnergyAssessment.renovation_pathway_paragraph_1.length : 0
        );

        setRenovationTableData(
          homeEnergyAssessment.renovation_table_data
        );
      }
    },
    [
      homeEnergyAssessment
    ]
  );

  const handleFieldUpdated = useCallback(
    (
      value: string | number | File,
      field: string
    ) => {
      handleHeaFieldBlur(value, field);
    },
    [
      handleHeaFieldBlur
    ]
  );

  const tableColumns: Array<Column<HomeEnergyAssessmentRenovationTableData>> = useMemo(
    () => [
      ...HEA_RENOVATION_TABLE_COLUMNS
    ],
    []
  );

  const TablePaperComponent = useCallback(
    (
      props: JSX.IntrinsicAttributes & PaperProps
    ) => <Paper
      sx={{
        borderRadius: "20px 20px 0 0",
        backgroundColor: colors.grey["200"]
      }}
      variant="outlined"
      {...props}
    />,
    []
  );

  const theme = useTheme();

  return (
    <DialogContent
      sx={{
        width: "100%",
        flex: 1,
        overflowX: "hidden",
        overflowY: "hidden",
        padding: "0px 8px 0px 8px",
        gap: "2rem"
      }}
    >

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            borderRadius: "20px",
            backgroundColor: contentBackgroundColour,
            padding: "16px"
          }}
        >
          <img
            src={iconTitleHomeBlank}
            alt="Icon"
            style={{
              maxHeight: "48px"
            }}
          />

          <TextField
            error={sectionHeadingRenovationPathwayError}
            variant="standard"
            fullWidth
            inputProps={{
              style: {
                fontSize: "1.4rem",
                backgroundColor: theme.palette.common.white
              }
            }}
            InputLabelProps={{
              style: {
                fontSize: "1.4rem"
              }
            }}
            FormHelperTextProps={{
              sx: {
                textAlign: "right",
                width: "100%"
              }
            }}
            value={sectionHeadingRenovationPathway}
            sx={{
              display: "flex",
              alignItems: "center",
              marginLeft: "16px",
              color: colors.grey["800"]
            }}
            onBlur={(event) => {
              if (!inputError) {
                handleFieldUpdated(
                  event.target.value,
                  "section_heading_renovation_pathway"
                );
              }
            }}
            onChange={(event) => {
              const {
                value
              } = event.target;
              setSectionHeadingRenovationPathwayLength(value.length);
              if (value.length > sectionHeadingRenovationPathwayLengthLimit) {
                setSectionHeadingRenovationPathwayError(true);
              } else if (sectionHeadingRenovationPathwayError) {
                setSectionHeadingRenovationPathwayError(false);
              }
              setSectionHeadingRenovationPathway(value);
            }}
            helperText={
              sectionHeadingRenovationPathwayLengthLimit - sectionHeadingRenovationPathwayLength <= (sectionHeadingRenovationPathwayLengthLimit / 2) ?
                `${sectionHeadingRenovationPathwayLength}/${sectionHeadingRenovationPathwayLengthLimit}` : ""
            }
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                if (!inputError) {
                  if (
                    renovationPathwayParagraph1InputRef !== undefined &&
                    renovationPathwayParagraph1InputRef.current !== undefined
                  ) {
                    renovationPathwayParagraph1InputRef.current.focus();
                  }
                }
              }
            }}
            inputRef={sectionHeadingRenovationPathwayInputRef}
          />

        </Box>

      </Box>


      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >
        <TextField
          error={renovationPathwayParagraph1Error}
          label="Renovation pathway table"
          sx={{
            backgroundColor: pageBackgroundColour,
            width: "100%"
          }}
          multiline
          size="small"
          variant="outlined"
          InputProps={{
            sx: {
              backgroundColor: theme.palette.common.white
            }
          }}
          FormHelperTextProps={{
            sx: {
              textAlign: "right"
            }
          }}
          value={renovationPathwayParagraph1}
          onBlur={(event) => {
            if (!inputError) {
              handleFieldUpdated(
                event.target.value,
                "renovation_pathway_paragraph_1"
              );
            }
          }}
          onChange={(event) => {
            const {
              value
            } = event.target;
            setRenovationPathwayParagraph1Length(value.length);
            if (value.length > renovationPathwayParagraph1LengthLimit) {
              setRenovationPathwayParagraph1Error(true);
            } else if (renovationPathwayParagraph1Error) {
              setRenovationPathwayParagraph1Error(false);
            }
            setRenovationPathwayParagraph1(value);
          }}
          helperText={
            renovationPathwayParagraph1LengthLimit - renovationPathwayParagraph1Length <= (renovationPathwayParagraph1LengthLimit / 2) ?
              `${renovationPathwayParagraph1Length}/${renovationPathwayParagraph1LengthLimit}` : ""
          }
          inputRef={renovationPathwayParagraph1InputRef}
        />

      </Box>

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >

        <MaterialTable
          columns={tableColumns}
          components={{
            Container: TablePaperComponent,
            Toolbar: () => null
          }}
          data={renovationTableData}
          localization={{
            body: {
              emptyDataSourceMessage: "No measures to display",
              filterRow: {
                filterTooltip: "Filter"
              }
            }
          }}
          options={{
            idSynonym: "step",
            headerStyle: {
              color: theme.palette.common.black,
              padding: "6px 12px 6px 12px",
              height: "56px",
              fontWeight: 500,
              fontSize: "0.875rem"
            },
            padding: "dense",
            paging: false,
            rowStyle: () => ({
              fontWeight: 400,
              fontSize: "0.875rem",
              backgroundColor: theme.palette.common.white
            }),
            search: false,
            sorting: false,
            showTitle: false,
            tableLayout: "fixed",
            thirdSortClick: false
          }}
        />

      </Box>

      {
        dwelling.performance.ber_edited ? <BEREdited/> : null
      }

    </DialogContent>
  );
}
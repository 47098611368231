import type { ChangeEvent} from "react";
import React, {
  useCallback,
  useEffect,
  useState
} from "react";


import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import type { PaperProps } from "@mui/material/Paper";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

import type {
  UpdateProjectDetailsRequest
} from "features/projects/api/types";


interface EditProjectDetailsDialogProps {
  existingValue: string;
  open: boolean;
  onClose: () => void;
  onUpdateProjectDetails: (requestArgs: UpdateProjectDetailsRequest) => void;
  projectId: number;
  title: string;
}

export function EditProjectDetailsDialog(
  {
    existingValue,
    open,
    onClose,
    onUpdateProjectDetails,
    projectId,
    title
  }: EditProjectDetailsDialogProps
) {

  const handleOnClose = useCallback(
    () => {
      onClose();
    }, [
      onClose
    ]
  );

  const [
    projectDetail,
    setProjectDetail
  ] = useState(existingValue);

  const handleUpdateProjectDetail = useCallback(
    () => {
      if (projectDetail !== "") {
        if (title === "Title") {
          onUpdateProjectDetails({
            id: projectId,
            title: projectDetail
          });
        } else {
          onUpdateProjectDetails({
            id: projectId,
            description: projectDetail
          });
        }
        handleOnClose();
      }
    },
    [
      handleOnClose,
      onUpdateProjectDetails,
      projectDetail,
      projectId,
      title
    ]
  );

  // This ensures that the projectDetail
  // variable is current
  useEffect(
    () => {
      setProjectDetail(existingValue)
    },
    [
      existingValue
    ]
  );

  const handleFocus = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    event.target.focus();
    event.target.setSelectionRange(
      0,
      event.target.value.length
    );
  };

  const DialogPaperComponent = useCallback(
    (
      props: JSX.IntrinsicAttributes & PaperProps
    ) => <Paper
      elevation={0}
      sx={{
        borderWidth: "0px",
        borderRadius: "20px"
      }}
      variant="outlined"
      {...props}
    />,
    []
  );

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      PaperComponent={DialogPaperComponent}
    >
      <DialogTitle>
        Edit Project {title}
      </DialogTitle>

      <DialogContent
        sx={{
          paddingBottom: "8px"
        }}
      >

        <TextField
          autoFocus
          maxRows={title === "Title" ? 1 : 10}
          minRows={title === "Title" ? 1 : 5}
          defaultValue={projectDetail}
          error={projectDetail.length === 0}
          fullWidth
          helperText={
            projectDetail.length === 0 ? `${title} can't be blank` : ""
          }
          label={title}
          margin="dense"
          onChange={
            (event) => {
              setProjectDetail(event.target.value)
            }
          }
          onFocus={
            (event) => {
              handleFocus(event)
            }
          }
          required
          variant="outlined"
        />

      </DialogContent>

      <DialogActions
        sx={{
          paddingRight: "24px"
        }}
      >

        <Button
          onClick={handleOnClose}
          color="primary"
          variant="contained"
        >
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={
            projectDetail.length === 0
          }
          onClick={
            () => handleUpdateProjectDetail()
          }
          variant="contained"
        >
          Save
        </Button>

      </DialogActions>
    </Dialog>
  );
}

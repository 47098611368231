import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useState
} from "react";

import Paper from "@mui/material/Paper";

import {
  ProjectDwellingsTable
} from "features/projects/components/tables/ProjectDwellingsTable";
import {
  AddDwellingsToProjectDialog
} from "features/projects/dialogs/AddDwellingsToProjectDialog";
import {
  DwellingScenarioComparisonDialog
} from "features/projects/dialogs/DwellingScenarioPerformanceDialog";


import type {
  Dwelling
} from "features/dwellings/types";
import type {
  CreateOrGetHEAApiRequest
} from "features/homeEnergyAssessment/api/types";
import type {
  UpdateProjectDwellingsRequest,
  UpdateProjectScenarioRecommendationRequest
} from "features/projects/api/types";
import type {
  ProjectScenarioRecommendation,
  ProjectThresholds
} from "features/projects/types";
import type {
  ProjectScenario
} from "features/projectScenarios/types";
import type {
  CreateTAFApiRequest
} from "features/technicalAssessmentForm/api/types";


interface ProjectDwellingsSectionsProps {
  generatePDFReport: (dwellings: Dwelling) => void;
  isLoading: boolean;
  isProjectRunning: boolean;
  onCreateOrGetHeaClicked: (
    request: CreateOrGetHEAApiRequest,
    dwelling: Dwelling,
    recommendedScenario: ProjectScenario
  ) => void;
  onCreateTafClicked: (request: CreateTAFApiRequest) => void;
  onUpdateDwellings: (
    data: Pick<UpdateProjectDwellingsRequest, "add" | "remove">
  ) => void;
  onUpdateProjectScenarioRecommendation: (
    requestArgs: UpdateProjectScenarioRecommendationRequest
  ) => void;
  otherDwellings: Array<Dwelling>;
  projectDwellings: Array<Dwelling>;
  projectScenarios: Array<ProjectScenario>;
  projectId: number;
  projectTitle: string;
  projectThresholds: ProjectThresholds;
  projectScenarioRecommendations: Array<ProjectScenarioRecommendation>;
  selectedDwellings: Dwelling[];
  setSelectedDwellings: Dispatch<SetStateAction<Dwelling[]>>;
}

export function ProjectDwellings(
  {
    generatePDFReport,
    isLoading,
    isProjectRunning,
    onCreateOrGetHeaClicked,
    onCreateTafClicked,
    onUpdateDwellings,
    onUpdateProjectScenarioRecommendation,
    otherDwellings,
    projectDwellings,
    projectScenarios,
    projectId,
    projectTitle,
    projectThresholds,
    projectScenarioRecommendations,
    selectedDwellings,
    setSelectedDwellings
  }: ProjectDwellingsSectionsProps
) {

  const [
    showAddDwellingsModal, setShowAddDwellingsModal
  ] = useState<boolean>(false);

  const handleCloseAddDwellingsModal = () => {
    setShowAddDwellingsModal(false);
  };

  const [
    showCompareScenariosModal, setShowCompareScenariosModal
  ] = useState<boolean>(false);

  const [
    dwellingForScenarioComparison, setDwellingForScenarioComparison
  ] = useState<Dwelling | undefined>();

  const handleCloseCompareScenariosModal = () => {
    setShowCompareScenariosModal(false);
    setDwellingForScenarioComparison(undefined);
  };

  const handleClickScenarioComparison = (dwelling: Dwelling) => {
    setDwellingForScenarioComparison(dwelling);
    setShowCompareScenariosModal(true);
  };

  const handleOnAddDwellings = useCallback(
    (dwellings: Array<Dwelling>) => {
      onUpdateDwellings({
        add: dwellings.map((dwelling) => ({
                id: dwelling.id,
                type: dwelling.type
            })),
        remove: []
      });
    },
    [onUpdateDwellings]
  );

  const handleOnRemoveDwellings = useCallback(
    (dwellings: Array<Dwelling>) => {
      onUpdateDwellings({
        add: [],
        remove: dwellings.map((dwelling) => ({
            id: dwelling.id,
            type: dwelling.type
        })),
      });
    },
    [onUpdateDwellings]
  );

  const handleClickGeneratePDFReport = useCallback(
    (dwelling: Dwelling) => {
      generatePDFReport(dwelling);
    },
    [generatePDFReport]
  );

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "2em",
        marginBottom: "3em",
        "& > *": {
          margin: "0"
        },
        "& .MuiPaper-root": {
          border: "0px",
          boxShadow: "none"
        },
        borderWidth: "0px",
        borderRadius: "20px"
      }}
      elevation={0}
      variant="outlined"
    >

      <ProjectDwellingsTable
        dwellings={projectDwellings}
        isLoading={isLoading}
        isProjectRunning={isProjectRunning}
        onRemoveDwellings={handleOnRemoveDwellings}
        handleUpdateProjectScenarioRecommendation={
          onUpdateProjectScenarioRecommendation
        }
        onClickScenarioComparison={handleClickScenarioComparison}
        onClickGeneratePDFReport={handleClickGeneratePDFReport}
        projectId={projectId}
        projectScenarios={projectScenarios}
        scenarioRecommendations={projectScenarioRecommendations}
        selectedDwellings={selectedDwellings}
        setSelectedDwellings={setSelectedDwellings}
        setShowAddDwellingsModal={setShowAddDwellingsModal}
        setShowCompareScenariosModal={setShowCompareScenariosModal}
        onCreateOrGetHeaClicked={onCreateOrGetHeaClicked}
        onCreateTafClicked={onCreateTafClicked}
      />

      <AddDwellingsToProjectDialog
        projectTitle={projectTitle}
        open={showAddDwellingsModal}
        isLoading={isLoading}
        onClose={handleCloseAddDwellingsModal}
        dwellings={otherDwellings}
        onAddDwellings={handleOnAddDwellings}
      />

      {
        dwellingForScenarioComparison !== undefined ? <DwellingScenarioComparisonDialog
          dwelling={dwellingForScenarioComparison}
          isLoading={isLoading}
          onClose={handleCloseCompareScenariosModal}
          onGeneratePdf={handleClickGeneratePDFReport}
          open={showCompareScenariosModal}
          projectTitle={projectTitle}
          projectScenarios={projectScenarios}
          thresholds={projectThresholds}
        /> : null
      }

    </Paper>
  );
}

import React from "react";

import { colors } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";


interface TextFieldWithLabelProps {
  label: string;
  labelColour?: string;
  labelSize?: string;
  labelWeight?: number;
  text: string;
  textColour?: string;
  textSize?: string;
  textWeight?: number;
}

const defaultTextFieldWithLabelProps: TextFieldWithLabelProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  label: 'Not set',
  labelColour: colors.grey["800"],
  labelSize: "0.75rem",
  labelWeight: 500,
  // eslint-disable-next-line react/default-props-match-prop-types
  text: 'Not set',
  textColour: colors.grey["800"],
  textSize: "0.875rem",
  textWeight: 400
}


export function TextFieldWithLabel(
  {

    label,
    labelColour,
    labelSize,
    labelWeight,
    text,
    textColour,
    textSize,
    textWeight
  }: TextFieldWithLabelProps) {

  return <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      width: "100%"
    }}
  >
    <Typography
      variant="h5"
      sx={{
        fontWeight: labelWeight,
        fontSize: labelSize,
        color: labelColour
      }}
    >
      {label}
    </Typography>

    <Box
      sx={{
        width: "100%"
      }}
    >

      <Typography
        variant="body2"
        sx={{
          fontWeight: textWeight,
          fontSize: textSize,
          color: textColour
        }}
      >
        {text}
      </Typography>

    </Box>

  </Box>
}

TextFieldWithLabel.defaultProps = defaultTextFieldWithLabelProps;
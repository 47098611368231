import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import {
  DwellingAndScenarioBerGrades
} from "common/components/DwellingAndScenarioBerGrades";
import { 
  WhiteBackgroundTextField 
} from "common/components/WhiteBackgroundTextField";
import {
  integerKeyCheck,
  integerRegex
} from "common/utils/numbers";
import {
  TechnicalAssessmentFormHeader
} from "features/technicalAssessmentForm/dialogs/common/TechnicalAssessmentFormHeader";

import type {
  UpdateTAFApiRequest
} from "features/technicalAssessmentForm/api/types";
import type {
  TechnicalAssessmentForm
} from "features/technicalAssessmentForm/types";



interface TechnicalAssessmentFormModal8Props {
  isTafUpdating: boolean;
  setShowModal: (show: boolean) => void;
  setTafStep: (newStep: number) => void;
  showModal: boolean;
  taf: TechnicalAssessmentForm;
  tafStep: number;
  updateTAF: (variable: UpdateTAFApiRequest) => Promise<TechnicalAssessmentForm>;
}


export function TechnicalAssessmentFormModal8(
  {
    isTafUpdating,
    showModal,
    setShowModal,
    setTafStep,
    taf,
    tafStep,
    updateTAF
  }: TechnicalAssessmentFormModal8Props
) {

  const [
    noOfChimneys,
    setNoOfChimneys
  ] = useState(0);

  const [
    noOfFluelessFixedHeaters,
    setNoOfFluelessFixedHeaters
  ] = useState(0);

  const [
    noOfOpenFlues,
    setNoOfOpenFlues
  ] = useState(0);

  const [
    noOfFansAndVents,
    setNoOfFansAndVents
  ] = useState(0);

  const [
    otherDetailsComments,
    setOtherDetailsComments
  ] = useState('');

  const [
    draftLobby,
    setDraftLobby
  ] = useState(false);

  const [
    proposedNoOfChimneys,
    setProposedNoOfChimneys
  ] = useState(0);

  const [
    proposedNoOfFluelessFixedHeaters,
    setProposedNoOfFluelessFixedHeaters
  ] = useState(0);

  const [
    proposedNoOfOpenFlues, 
    setProposedNoOfOpenFlues
  ] = useState(0);

  const [
    proposedNoOfFansAndVents,
    setProposedNoOfFansAndVents
  ] = useState(0);

  const [
    proposedDraftLobby,
    setProposedDraftLobby
  ] = useState(false);

  useEffect(
    () => {
      setNoOfChimneys(
        taf.system.ventilation_no_of_chimneys
      )
      setNoOfFluelessFixedHeaters(
        taf.system.ventilation_no_of_flueless_combustion_room_heaters
      )
      setNoOfOpenFlues(
        taf.system.ventilation_no_of_open_flues
      )
      setNoOfFansAndVents(
        taf.system.ventilation_no_of_fans_and_vents
      )
      setOtherDetailsComments(
        taf.other_details_comments_openings
      )
      setDraftLobby(
        taf.system.ventilation_draft_lobby
      )
      setProposedNoOfChimneys(
        taf.system.proposed_ventilation_no_of_chimneys
      )
      setProposedNoOfFluelessFixedHeaters(
        taf.system.proposed_ventilation_no_of_flueless_combustion_room_heaters
      )
      setProposedNoOfOpenFlues(
        taf.system.proposed_ventilation_no_of_open_flues
      )
      setProposedNoOfFansAndVents(
        taf.system.proposed_ventilation_no_of_fans_and_vents
      )
      setProposedDraftLobby(
        taf.system.proposed_ventilation_draft_lobby
      )
    },
    [
      
    ]
  );
  
  
  const closeModal = () => {
    setShowModal(false);
  };

  const handleUpdateTAF = (dialogNumber: number) => {
    const requestBody: UpdateTAFApiRequest = {
      technical_assessment_form_id: taf.id,
      other_details_comments_openings: otherDetailsComments,
      system: {
        ventilation_no_of_flueless_combustion_room_heaters: noOfFluelessFixedHeaters,
        ventilation_no_of_open_flues: noOfOpenFlues,
        ventilation_no_of_fans_and_vents: noOfFansAndVents,
        ventilation_no_of_chimneys: noOfChimneys,
        ventilation_draft_lobby: draftLobby,
        proposed_ventilation_draft_lobby: proposedDraftLobby,
        proposed_ventilation_no_of_open_flues: proposedNoOfOpenFlues,
        proposed_ventilation_no_of_fans_and_vents: proposedNoOfFansAndVents,
        proposed_ventilation_no_of_chimneys: proposedNoOfChimneys,
        proposed_ventilation_no_of_flueless_combustion_room_heaters: proposedNoOfFluelessFixedHeaters
      }
    };

    updateTAF(
      requestBody
    ).then(
      (response) => {
        if (response) {
          setTafStep(dialogNumber);
        }
      }
    ).catch(
      (error) => console.log("Error while updating TAF:", error)
    );
  };

  const handleBackButtonClick = () => {
    handleUpdateTAF(7);
  };

  const handleNextButtonClick = () => {
    handleUpdateTAF(9);
  };

  const handleSliderChanged = (dialogNumber: number) => {
    handleUpdateTAF(dialogNumber);
  };
  
  const contentSectionHeaderText = {
    color: "#71717a"
  };

  const inputLabelFixedWidth = {
    display: "flex",
    alignItems: "center",
    width: "255px"
  };

  const labelAndInputBox = {
    display: "flex"
  };

  const inputLabelFixedWiderWidth = {
    display: "flex",
    alignItems: "center",
    width: "280px"
  };

  const sectionTitle = {
    marginTop: "8px"
  };

  return (
    <Dialog
      open={showModal}
      onClose={closeModal}
      maxWidth="lg"
      fullWidth
      scroll="paper"
      sx={{
        padding: "16px"
      }}
    >
      <TechnicalAssessmentFormHeader
        changeTafStep={handleSliderChanged}
        closeModal={closeModal}
        tafStep={tafStep}
      />

      <DialogContent
        sx={{
          padding: "0px 8px 0px 8px"
        }}
      >

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "5px",
            backgroundColor: "#e0dede"
          }}
        >

          {/* SECTION TITLE */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "24px",
              paddingTop: "24px",
              paddingRight: "24px",
              paddingBottom: "16px"
            }}
          >

            <img
              alt="section title"
              style={{
                width: "60px",
                paddingRight: "16px"
              }}
              src="https://rk-staging-static.s3.eu-west-1.amazonaws.com/static/public/images/housing_upgrade_plans/icon-draughts.svg"
            />

            <Typography
              sx={{
                display: "flex",
                alignItems: "center"
              }}
              variant="h5"
            >
              Openings Upgrades
            </Typography>

          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "8px",
              paddingRight: "8px",
              paddingBottom: "8px",
              gap: "8px"
            }}
          >

            {/* SECTION CURRENT */}
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                borderRadius: "5px",
                border: 1,
                borderColor: "#71717a",
                padding: "0px 16px 16px 16px",
                gap: "8px"
              }}
            >

              <Box
                sx={sectionTitle}
              >
                <Typography
                  align="center"
                  variant="h5"
                  sx={contentSectionHeaderText}
                >
                  Current (as per published BER)
                </Typography>

              </Box>

              <Box
                sx={labelAndInputBox}
              >

                <Typography
                  sx={inputLabelFixedWidth}
                >
                  No. of chimneys
                </Typography>

                <WhiteBackgroundTextField
                  type="number"
                  variant="outlined"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      step: 1
                    }
                  }}
                  onChange={
                    (event) => {
                      if (
                        event.target.value === "" ||
                        integerRegex.test(event.target.value)
                      ) {
                        setNoOfChimneys(
                          Number(
                            event.target.value
                          )
                        );
                      }
                    }
                  }
                  onKeyDown={integerKeyCheck}
                  value={noOfChimneys}
                  sx={{
                    flex: 1,
                    marginLeft: "24px"
                  }}
                />

              </Box>

              <Box
                sx={labelAndInputBox}
              >

                <Typography
                  sx={inputLabelFixedWidth}
                >
                  No. of flueless fixed heaters
                </Typography>

                <WhiteBackgroundTextField
                  type="number"
                  variant="outlined"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      step: 1
                    }
                  }}
                  onChange={
                    (event) => {
                      if (
                        event.target.value === "" ||
                        integerRegex.test(event.target.value)
                      ) {
                        setNoOfFluelessFixedHeaters(
                          Number(
                            event.target.value
                          )
                        );
                      }
                    }
                  }
                  onKeyDown={integerKeyCheck}
                  value={noOfFluelessFixedHeaters}
                  sx={{
                    flex: 1,
                    marginLeft: "24px"
                  }}
                />

              </Box>

              <Box
                sx={labelAndInputBox}
              >
                <Typography
                  sx={inputLabelFixedWidth}
                >
                  No. of open flues
                </Typography>

                <WhiteBackgroundTextField
                  type="number"
                  variant="outlined"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      step: 1
                    }
                  }}
                  onChange={
                    (event) => {
                      if (
                        event.target.value === "" ||
                        integerRegex.test(event.target.value)
                      ) {
                        setNoOfOpenFlues(
                          Number(
                            event.target.value
                          )
                        );
                      }
                    }
                  }
                  onKeyDown={integerKeyCheck}
                  value={noOfOpenFlues}
                  sx={{
                    flex: 1,
                    marginLeft: "24px"
                  }}
                />

              </Box>

              <Box
                sx={labelAndInputBox}
              >
                <Typography
                  sx={inputLabelFixedWidth}
                >
                  No. of intermittent fans and passive vents
                </Typography>

                <WhiteBackgroundTextField
                  type="number"
                  variant="outlined"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      step: 1
                    }
                  }}
                  onChange={
                    (event) => {
                      if (
                        event.target.value === "" ||
                        integerRegex.test(event.target.value)
                      ) {
                        setNoOfFansAndVents(
                          Number(
                            event.target.value
                          )
                        );
                      }
                    }
                  }
                  onKeyDown={integerKeyCheck}
                  value={noOfFansAndVents}
                  sx={{
                    flex: 1,
                    marginLeft: "24px"
                  }}
                />

              </Box>

              <Box
                sx={labelAndInputBox}
              >
                <Typography
                  sx={inputLabelFixedWiderWidth}
                >
                  Draught lobby on main entrance?
                </Typography>

                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={String(draftLobby)}
                  onChange={(e) => {
                    setDraftLobby(e.target.value === "true");
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={
                      <Radio />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={
                      <Radio />
                    }
                    label="No"
                  />
                </RadioGroup>

              </Box>

            </Box>

            {/* SECTION PROPOSED */}
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                borderRadius: "5px",
                border: 1,
                borderColor: "#71717a",
                padding: "0px 16px 16px 16px",
                gap: "8px"
              }}
            >
              <Box
                sx={sectionTitle}
              >
                <Typography
                  align="center"
                  variant="h5"
                  sx={contentSectionHeaderText}
                >
                  Proposed upgrade
                </Typography>

              </Box>

              <Box sx={labelAndInputBox}>

                <Typography
                  sx={inputLabelFixedWidth}
                >
                  No. of chimneys
                </Typography>

                <WhiteBackgroundTextField
                  type="number"
                  variant="outlined"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      step: 1
                    }
                  }}
                  onChange={
                    (event) => {
                      if (
                        event.target.value === "" ||
                        integerRegex.test(event.target.value)
                      ) {
                        setProposedNoOfChimneys(
                          Number(
                            event.target.value
                          )
                        );
                      }
                    }
                  }
                  onKeyDown={integerKeyCheck}
                  value={proposedNoOfChimneys}
                  sx={{
                    flex: 1,
                    marginLeft: "24px"
                  }}
                />

              </Box>

              <Box
                sx={labelAndInputBox}
              >
                <Typography
                  sx={inputLabelFixedWidth}
                >
                  No. of flueless fixed heaters
                </Typography>

                <WhiteBackgroundTextField
                  type="number"
                  variant="outlined"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      step: 1
                    }
                  }}
                  onChange={
                    (event) => {
                      if (
                        event.target.value === "" ||
                        integerRegex.test(event.target.value)
                      ) {
                        setProposedNoOfFluelessFixedHeaters(
                          Number(
                            event.target.value
                          )
                        );
                      }
                    }
                  }
                  onKeyDown={integerKeyCheck}
                  value={proposedNoOfFluelessFixedHeaters}
                  sx={{
                    flex: 1,
                    marginLeft: "24px"
                  }}
                />

              </Box>

              <Box
                sx={labelAndInputBox}
              >
                <Typography
                  sx={inputLabelFixedWidth}
                >
                  No. of open flues
                </Typography>

                <WhiteBackgroundTextField
                  type="number"
                  variant="outlined"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      step: 1
                    }
                  }}
                  onChange={
                    (event) => {
                      if (
                        event.target.value === "" ||
                        integerRegex.test(event.target.value)
                      ) {
                        setProposedNoOfOpenFlues(
                          Number(
                            event.target.value
                          )
                        );
                      }
                    }
                  }
                  onKeyDown={integerKeyCheck}
                  value={proposedNoOfOpenFlues}
                  sx={{
                    flex: 1,
                    marginLeft: "24px"
                  }}
                />

              </Box>

              <Box
                sx={labelAndInputBox}
              >
                <Typography
                  sx={inputLabelFixedWidth}
                >
                  No. of intermittent fans and passive vents
                </Typography>

                <WhiteBackgroundTextField
                  type="number"
                  variant="outlined"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      step: 1
                    }
                  }}
                  onChange={
                    (event) => {
                      if (
                        event.target.value === "" ||
                        integerRegex.test(event.target.value)
                      ) {
                        setProposedNoOfFansAndVents(
                          Number(
                            event.target.value
                          )
                        );
                      }
                    }
                  }
                  onKeyDown={integerKeyCheck}
                  value={proposedNoOfFansAndVents}
                  sx={{
                    flex: 1,
                    marginLeft: "24px"
                  }}
                />

              </Box>

              <Box
                sx={labelAndInputBox}
              >
                <Typography
                  sx={inputLabelFixedWiderWidth}
                >
                  Draught lobby on main entrance?
                </Typography>

                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={String(proposedDraftLobby)}
                  onChange={
                    (e) => {
                      setProposedDraftLobby(e.target.value === "true");
                    }
                  }
                >
                  <FormControlLabel
                    value="true"
                    control={
                      <Radio />
                    }
                    label="Yes"
                  />

                  <FormControlLabel
                    value="false"
                    control={
                      <Radio />
                    }
                    label="No"
                  />
                </RadioGroup>

              </Box>

            </Box>

          </Box>

          {/* SECTION OTHER */}
          <Box
            sx={{
              paddingLeft: "8px",
              paddingRight: "8px",
              paddingBottom: "8px"
            }}
          >

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "5px",
                border: 1,
                borderColor: "#71717a",
                padding: "16px",
                gap: "8px"
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                Other details/comments (include other info for contractor here)
              </Typography>

              <TextField
                sx={{
                  flex: 1,
                  backgroundColor: "white",
                  width: "100%"
                }}
                multiline
                value={otherDetailsComments}
                onChange={
                  event => setOtherDetailsComments(
                    event.target.value
                  )
                }
              />
            </Box>

          </Box>

        </Box>

      </DialogContent>

      <DialogActions>

        <DwellingAndScenarioBerGrades
          dwellingDetail={
            String(taf.dwelling_mprn_reference)
          }
          dwellingRating={taf.dwelling_rating}
          scenarioDetail={taf.scenario_title}
          scenarioRating={taf.scenario_rating}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleBackButtonClick}
        >
          Back
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleNextButtonClick}
        >
          Next
        </Button>

      </DialogActions>
    </Dialog>
  );
}
import { AUTHENTICATION_ERROR } from './authentication';

import { toast } from 'react-toastify';

export const FETCH_ALL_UPLOADS = '@@uploads/FETCH_ALL_UPLOADS';
export const ADD_UPLOAD_TASK_ID_LOADED = '@@uploads/ADD_UPLOAD_TASK_ID_LOADED';
export const ADD_UPLOAD_TASK_SUCCESS = '@@dash/ADD_UPLOAD_TASK_SUCCESS';
export const ADD_UPLOAD_TASK_EXECUTING = '@@dash/ADD_UPLOAD_TASK_EXECUTING';
export const ADD_UPLOAD_TASK_FAILED = '@@dash/ADD_UPLOAD_TASK_FAILED';
export const ADD_UPLOAD_TASK_QUEUED = '@@dash/ADD_UPLOAD_TASK_QUEUED';
export const CLEAR_UPLOAD_TASKS = '@@dash/CLEAR_UPLOAD_TASKS';
export const UPLOAD_ERROR = '@@uploads/UPLOAD_ERROR';

export const fetchUploads = () => {
  return (dispatch, getState) => {
    let headers = { 'Content-Type': 'application/json' };
    let { token } = getState().authentication;

    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }

    return fetch(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/v1/uploads/`,
      { headers, method: 'GET' }
    )
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          console.log('Server Error!');
          throw res;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          const response = res.data.map((item, id) => ({
            id,
            ...item,
          }));
          return dispatch({ type: FETCH_ALL_UPLOADS, uploadList: response });
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: AUTHENTICATION_ERROR, data: res.data });
          throw res.data;
        }
      });
  };
};

export const fetchBerFileFromMPRN = (mprn, consent_file) => {
  return (dispatch, getState) => {
    let headers = {};
    let { token } = getState().authentication;

    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }

    const formData = new FormData();
    formData.append('mprn', mprn);
    formData.append('file', consent_file);

    return fetch(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/v1/integrations/seai`,
      { headers, method: 'POST', body: formData }
    ).then((res) => {
      if (res.status === 400) {
        toast.error('Invalid MPRN number!', {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored"
        });
      } else if (res.status === 201) {
        toast.success('Fetched BER file!', {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored"
        });
      } else {
        console.log('Server Error!');
        throw res;
      }
    });
  };
};

export const addUpload = (formData) => {
  return (dispatch, getState) => {
    let headers = {
      'Access-Control-Allow-Origin': `${process.env.REACT_APP_API_BASE_URL || '*'}`,
    };
    let { token } = getState().authentication;

    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }

    return fetch(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/v1/uploads/`,
      {
        headers,
        method: 'POST',
        body: formData,
      }
    )
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          return {
            status: res.status,
            data: {
              file: formData.get('file').name,
              text: res.statusText,
            },
          };
        }
      })
      .then((res) => {
        if (res.status === 201) {
          return dispatch({
            type: ADD_UPLOAD_TASK_ID_LOADED,
            upload: {
              filename: res.data.filename,
              uploaded_on: res.data.uploaded_on,
            },
            uploadTask: {
              filename: res.data.filename,
              task_id: res.data.task_id,
              task_status: res.data.task_status,
            },
          });
        } else if (res.status === 401 || res.status === 403) {
          dispatch({
            type: AUTHENTICATION_ERROR,
            data: res.data,
          });
          throw res.data;
        } else {
          return dispatch({
            type: UPLOAD_ERROR,
            error: {
              file: formData.get('file').name,
              text: res.data.detail,
            },
          });
        }
      });
  };
};

export const getAddUploadTaskResult = (uploadTaskId) => {
  return (dispatch, getState) => {
    let headers = { 'Content-Type': 'application/json' };
    let { token } = getState().authentication;

    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }

    return fetch(
      `${
        process.env.REACT_APP_API_BASE_URL || ''
      }/api/v1/task-progress/${uploadTaskId}/`,
      { headers }
    )
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          console.log(
            'Server error when attempting to retrieve upload task result!'
          );
          throw res;
        }
      })
      .then(async (res) => {
        if (res.status === 200) {
          if (res.data.task_status === 'SUCCESS') {
            return dispatch({
              type: ADD_UPLOAD_TASK_SUCCESS,
              uploadTask: {
                task_id: res.data.task_id,
                task_status: res.data.task_status,
                task_result: res.data.task_result,
              },
            });
          } else if (res.data.task_status === 'PENDING') {
            return dispatch({
              type: ADD_UPLOAD_TASK_QUEUED,
              uploadTask: {
                task_id: res.data.task_id,
                task_status: res.data.task_status,
                task_result: res.data.task_result,
              },
            });
          } else if (
            res.data.task_status === 'FAILURE' ||
            res.data.task_status === 'REVOKED'
          ) {
            return dispatch({
              type: ADD_UPLOAD_TASK_FAILED,
              uploadTask: {
                task_id: res.data.task_id,
                task_status: res.data.task_status,
                task_result: res.data.task_result,
              },
            });
          }
          // res.data.task_status could also be STARTED, RETRY
          return dispatch({
            type: ADD_UPLOAD_TASK_EXECUTING,
            uploadTask: {
              task_id: res.data.task_id,
              task_status: res.data.task_status,
              task_result: res.data.task_result,
            },
          });
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: AUTHENTICATION_ERROR, data: res.data });
          throw res.data;
        }
      });
  };
};

export const clearUploadTasks = () => {
  return (dispatch, getState) => {
    dispatch({ type: CLEAR_UPLOAD_TASKS });
  };
};

import React from "react";

import MapboxDraw from "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw";
import turfArea from "@turf/area";
import turfbbox from "@turf/bbox";
import turfCentroid from "@turf/centroid";
import dateFormat from "dateformat";
import mapboxgl from "mapbox-gl";
import RulerControl from "mapbox-gl-controls/lib/RulerControl/RulerControl";
import PropTypes from "prop-types";

import "./DashboardMap.scss";

import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";

import {
  MAP_STYLE_URI_DICTIONARY
} from "common/assets/maps/extras";
import {
  BER_COLOUR_CODE_MAP,
  BER_SCORE_MAP
} from "common/assets/rk_type_mappings/ber";
import {
  withMaterialUserInterfaceTheme
} from "common/hocs/theme";

import BERLegendControl from "v1/components/shared/map/BERLegendControl";
import MapStyleSwitcherControl from "v1/components/shared/map/MapStyleSwitcherControl";
import ZoomExtentsControl from "v1/components/shared/map/ZoomExtentsControl";


class DashboardMap extends React.Component {
  constructor(props) {
    super(props);

    this.mapRef = React.createRef();

    this.state = {
      boundingBox: null,
      boundingBoxStart: null,
      mapboxDraw: null,
      dwellingPopUp: null,
      electoralDivisionPopUp: null,
      map: null,
      polygonPopUp: null,
      showElectoralDivisionsLayer: true
    };

    // MAP
    this.onMapStyleSwitcherButtonClicked =
      this.onMapStyleSwitcherButtonClicked.bind(this);
    this.onMouseEnterPolygon = this.onMouseEnterPolygon.bind(this);
    this.onMouseLeavePolygon = this.onMouseLeavePolygon.bind(this);
    this.updatePolygonArea = this.updatePolygonArea.bind(this);

    this.getMousePositionOnMap = this.getMousePositionOnMap.bind(this);
    this.onBoundingBoxMouseDown = this.onBoundingBoxMouseDown.bind(this);
    this.onBoundingBoxMouseMove = this.onBoundingBoxMouseMove.bind(this);
    this.onBoundingBoxMouseUp = this.onBoundingBoxMouseUp.bind(this);
    this.onBoundingBoxKeyDown = this.onBoundingBoxKeyDown.bind(this);
    this.onBoundingBoxFinish = this.onBoundingBoxFinish.bind(this);

    // DWELLINGS
    this.onClickDwellingClusters = this.onClickDwellingClusters.bind(this);
    this.onClickUnclusteredDwellingPoint =
      this.onClickUnclusteredDwellingPoint.bind(this);
    this.onMouseEnterUnclusteredDwellingPoint =
      this.onMouseEnterUnclusteredDwellingPoint.bind(this);
    this.onMouseLeaveUnclusteredDwellingPoint =
      this.onMouseLeaveUnclusteredDwellingPoint.bind(this);

    // ELECTORAL DIVISIONS
    this.onClickElectoralDivision = this.onClickElectoralDivision.bind(this);
    this.onMouseEnterElectoralDivision =
      this.onMouseEnterElectoralDivision.bind(this);
    this.onMouseLeaveElectoralDivision =
      this.onMouseLeaveElectoralDivision.bind(this);
  }

  addRetroKitMapLayers(map) {
    this.setupDwellingClustersLayer(map);

    this.setupElectoralDivisionsLayer(map);

    this.setupSelectedDwellingsLayer(map);

    this.setup3DBuildingsLayer(map);
  }

  // MAP CONTROLS
  addControls(map) {
    map.addControl(new mapboxgl.ScaleControl(), "bottom-right");
    map.addControl(new mapboxgl.NavigationControl(), "bottom-right");

    this.addMapStyleControl(map);

    const extentsControl = new ZoomExtentsControl();
    map.addControl(extentsControl, "top-left");
    extentsControl.button.addEventListener("click", () =>
      this.setZoomToStockExtents()
    );

    map.addControl(new RulerControl(), "top-left");
    map.on("ruler.on", () => {
      map.getCanvas().style.cursor = "crosshair";
    });
    map.on("ruler.off", () => {
      map.getCanvas().style.cursor = "";
    });

    this.addPolygonDrawingControl(map);

    this.addBERControl(map);

    this.addBoxSelectionControl(map);
  }

  addBERControl(map) {
    const {
      onChangeElectoralDivisionsLayerOpacity,
      electoralDivisionsLayerOpacity
    } = this.props;
    const legend = new BERLegendControl(electoralDivisionsLayerOpacity);
    map.addControl(legend, "top-right");

    const slider = document.getElementById("uploads-opacity-slider");
    slider.addEventListener("input", (event) => {
      const opacity = parseInt(event.target.value, 10) / 100;

      map.setPaintProperty("electoral-divisions", "fill-opacity", opacity);

      map.setPaintProperty(
        "electoral-divisions-outline",
        "line-opacity",
        opacity
      );

      onChangeElectoralDivisionsLayerOpacity(opacity);
    });
  }

  getMousePositionOnMap(event) {

    if (this.state.map !== null) {
      const canvas = this.state.map.getCanvasContainer();
      const rect = canvas.getBoundingClientRect();
      return new mapboxgl.Point(
        event.clientX - rect.left - canvas.clientLeft,
        event.clientY - rect.top - canvas.clientTop
      );
    }
  }

  onBoundingBoxMouseDown(event) {
    // Continue the rest of the function if the shift key is pressed.
    if (!(event.shiftKey && event.button === 0)) return;


    if (this.state.map !== null) {
      // Disable default drag zooming when the shift key is held down.
      this.state.map.dragPan.disable();

      // Call functions for the following events
      document.addEventListener("mousemove", this.onBoundingBoxMouseMove);
      document.addEventListener("mouseup", this.onBoundingBoxMouseUp);
      document.addEventListener("keydown", this.onBoundingBoxKeyDown);

      // Capture the first xy coordinates
      this.setState(
        {
          boundingBoxStart: this.getMousePositionOnMap(event)
        }
      );
    }
  }

  onBoundingBoxMouseMove(event) {
    const current = this.getMousePositionOnMap(event);

    // Append the box element if it doesn't exist
    if (this.state.boundingBox === null && this.state.map !== null) {
      const box = document.createElement("div");
      box.classList.add("boxdraw");
      this.state.map.getCanvasContainer().appendChild(box);
      this.setState(
        {
          boundingBox: box
        }
      );
    }

    const minX = Math.min(
      this.state.boundingBoxStart.x,
      current.x
    );
    const maxX = Math.max(
      this.state.boundingBoxStart.x,
      current.x
    );
    const minY = Math.min(
      this.state.boundingBoxStart.y,
      current.y
    );
    const maxY = Math.max(
      this.state.boundingBoxStart.y,
      current.y
    );

    // Adjust width and xy position of the box element ongoing
    const pos = `translate(${minX}px,${minY}px)`;

    if (this.state.boundingBox !== null) {
      this.state.boundingBox.style.transform = pos;
      this.state.boundingBox.style.WebkitTransform = pos;
      this.state.boundingBox.style.width = `${maxX - minX}px`;
      this.state.boundingBox.style.height = `${maxY - minY}px`;
    }
  }

  onBoundingBoxMouseUp(event) {
    // Capture xy coordinates
    this.onBoundingBoxFinish([
      this.state.boundingBoxStart,
      this.getMousePositionOnMap(event)
    ]);
  }

  onBoundingBoxKeyDown(event) {
    // If the ESC key is pressed
    if (event.keyCode === 27) this.onBoundingBoxFinish();
  }

  onBoundingBoxFinish(boundingBox) {
    // Remove these events now that finish has been called.
    document.removeEventListener("mousemove", this.onBoundingBoxMouseMove);
    document.removeEventListener("keydown", this.onBoundingBoxKeyDown);
    document.removeEventListener("mouseup", this.onBoundingBoxMouseUp);

    if (this.state.boundingBox !== null) {
      this.state.boundingBox.parentNode.removeChild(
        this.state.boundingBox
      );
      this.setState(
        {
          boundingBox: null
        }
      );
    }

    // If boundingBox exists. use this value as the argument for `queryRenderedFeatures`
    if (boundingBox && this.state.map !== null) {
      let northEast;
      let southWest;

      // Check the bounding box co-ordinates arrangement.
      // Ensure that the points are referring to the NE and SW of the bounding box
      if (
        (boundingBox[1].x > boundingBox[0].x &&
          boundingBox[1].y < boundingBox[0].y) ||
        (boundingBox[1].x < boundingBox[0].x &&
          boundingBox[0].y < boundingBox[1].y)
      ) {
        northEast = this.state.map.unproject({
          x: boundingBox[0].x,
          y: boundingBox[1].y
        });
        southWest = this.state.map.unproject({
          x: boundingBox[1].x,
          y: boundingBox[0].y
        });
      } else {
        northEast = this.state.map.unproject(boundingBox[0]);
        southWest = this.state.map.unproject(boundingBox[1]);
      }

      let lngLatBounds;
      if (northEast.lng > southWest.lng) {
        lngLatBounds = new mapboxgl.LngLatBounds(northEast, southWest);
      } else {
        lngLatBounds = new mapboxgl.LngLatBounds(southWest, northEast);
      }

      const selectedDwellings = this.props.geostock.features.filter(
        ({ geometry }) => lngLatBounds.contains(geometry.coordinates)
      );
      this.props.onSelectionChange(selectedDwellings);
    }

    if (this.state.map !== null) {
      this.state.map.dragPan.enable();
    }
  }

  addBoxSelectionControl(map) {
    map.boxZoom.disable();
    const canvas = map.getCanvasContainer();
    /* let start;
            let current;
            let box; */

    // canvas.addEventListener('mousedown', mouseDown, true);
    canvas.addEventListener("mousedown", this.onBoundingBoxMouseDown, true);

    // Return the xy coordinates of the mouse position
    /* function mousePos(event) {
                let rect = canvas.getBoundingClientRect();
                return new mapboxgl.Point(
                    event.clientX - rect.left - canvas.clientLeft,
                    event.clientY - rect.top - canvas.clientTop,
                );
            }
    
            function mouseDown(event) {
                // Continue the rest of the function if the shift key is pressed.
                if (!(event.shiftKey && event.button === 0)) return;
    
                // Disable default drag zooming when the shift key is held down.
                map.dragPan.disable();
    
                // Call functions for the following events
                document.addEventListener('mousemove', onMouseMove);
                document.addEventListener('mouseup', onMouseUp);
                document.addEventListener('keydown', onKeyDown);
    
                // Capture the first xy coordinates
                start = mousePos(event);
            }
    
            function onMouseMove(event) {
                // Capture the ongoing xy coordinates
                current = mousePos(event);
    
                // Append the box element if it doesnt exist
                if (!box) {
                    box = document.createElement('div');
                    box.classList.add('boxdraw');
                    canvas.appendChild(box);
                }
    
                let minX = Math.min(start.x, current.x),
                    maxX = Math.max(start.x, current.x),
                    minY = Math.min(start.y, current.y),
                    maxY = Math.max(start.y, current.y);
    
                // Adjust width and xy position of the box element ongoing
                let pos = `translate(${minX}px,${minY}px)`;
                box.style.transform = pos;
                box.style.WebkitTransform = pos;
                box.style.width = `${maxX - minX}px`;
                box.style.height = `${maxY - minY}px`;
            }
    
            function onMouseUp(event) {
                // Capture xy coordinates
                finish([start, mousePos(event)]);
            }
    
            function onKeyDown(event) {
                // If the ESC key is pressed
                if (event.keyCode === 27) finish();
            }
    
            let {geostock, onSelectionChange} = this.props;
    
            function finish(boundingBox) {
                // Remove these events now that finish has been called.
                document.removeEventListener('mousemove', onMouseMove);
                document.removeEventListener('keydown', onKeyDown);
                document.removeEventListener('mouseup', onMouseUp);
    
                if (box) {
                    box.parentNode.removeChild(box);
                    box = null;
                }
    
                // If boundingBox exists. use this value as the argument for `queryRenderedFeatures`
                if (boundingBox) {
    
                    let northEast, southWest;
    
                    // Check the bounding box co-ordinates arrangement.
                    // Ensure that the points are referring to the NE and SW of the bounding box
                    if (((boundingBox[1].x > boundingBox[0].x) && (boundingBox[1].y < boundingBox[0].y)) ||
                        ((boundingBox[1].x < boundingBox[0].x) && (boundingBox[0].y < boundingBox[1].y))) {
                        northEast = map.unproject({x: boundingBox[0].x, y: boundingBox[1].y})
                        southWest = map.unproject({x: boundingBox[1].x, y: boundingBox[0].y})
                    } else {
                        northEast = map.unproject(boundingBox[0])
                        southWest = map.unproject(boundingBox[1])
                    }
    
                    let lngLatBounds;
                    if (northEast.lng > southWest.lng) {
                        lngLatBounds = new mapboxgl.LngLatBounds(northEast, southWest);
                    } else {
                        lngLatBounds = new mapboxgl.LngLatBounds(southWest, northEast);
                    }
    
                    console.log("geostock.features.length =", geostock.features.length)
    
                    let selectedDwellings = geostock.features.filter(
                        ({geometry}) => lngLatBounds.contains(geometry.coordinates),
                    );
                    onSelectionChange(selectedDwellings);
                }
                map.dragPan.enable();
            } */

    const { setPosition, setZoom } = this.props;

    map.on("moveend", () => {
      setPosition(map.getCenter());
    });

    map.on("zoomend", () => {
      setZoom(map.getZoom());
    });

    /* map.on('mousedown', (event) => {
                if (!(event.originalEvent.shiftKey && event.originalEvent.button === 0)) return;
                startLngLat = event.lngLat;
            });
    
            map.on('mouseup', (event) => {
                if (!(event.originalEvent.shiftKey && event.originalEvent.button === 0)) return;
                endLngLat = event.lngLat;
            }); */
  }

  addMapStyleControl(map) {
    const mapStyleSwitcher = new MapStyleSwitcherControl(this.props.style);
    map.addControl(mapStyleSwitcher, "top-left");

    for (const key in MAP_STYLE_URI_DICTIONARY) {
      if (MAP_STYLE_URI_DICTIONARY.hasOwnProperty(key)) {
        const button = document.getElementById(`${key}-button`);
        button.addEventListener("click", (event) => {
          this.onMapStyleSwitcherButtonClicked(event, key, mapStyleSwitcher);
        });
      }
    }
  }

  addPolygonDrawingControl(map) {
    const mapboxDraw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        line_string: false,
        trash: true
      }
    });
    map.addControl(mapboxDraw, "top-left");
    this.setState({ mapboxDraw });

    map.on("draw.create", this.updatePolygonArea);
    map.on("draw.delete", this.updatePolygonArea);
    map.on("draw.update", this.updatePolygonArea);
  }

  updatePolygonArea(event) {
    if (this.state.mapboxDraw.getAll().features.length > 0 && this.state.map !== null) {
      let polygonLayer = this.state.map.getLayer(
        "gl-draw-polygon-fill-active.cold"
      );
      if (typeof polygonLayer === "undefined") {
        polygonLayer = this.state.map.getLayer(
          "gl-draw-polygon-fill-inactive.cold"
        );
      }

      if (typeof polygonLayer !== "undefined") {
        this.state.map.on(
          "mouseenter",
          polygonLayer.id,
          this.onMouseEnterPolygon
        );
        this.state.map.on(
          "mouseleave",
          polygonLayer.id,
          this.onMouseLeavePolygon
        );
      }
    } else if (event.type !== "draw.delete") {
      console.log("draw.delete");
      // TODO: This is not working
      alert("Use the draw tools to draw a polygon!");

      // polygonPopUp.remove()
    } else {

      if (this.state.map !== null) {
        this.state.map.off(
          "mouseenter",
          "gl-draw-polygon-fill-active.cold",
          this.onMouseEnterPolygon
        );
        this.state.map.off(
          "mouseleave",
          "gl-draw-polygon-fill-active.cold",
          this.onMouseLeavePolygon
        );
        this.state.map.off(
          "mouseenter",
          "gl-draw-polygon-fill-inactive.cold",
          this.onMouseEnterPolygon
        );
        this.state.map.off(
          "mouseleave",
          "gl-draw-polygon-fill-inactive.cold",
          this.onMouseLeavePolygon
        );
      }

    }
  }

  onMouseEnterPolygon() {

    if (this.state.map !== null) {
      this.state.map.getCanvas().style.cursor = "pointer";

      if (this.state.polygonPopUp !== null) {
        this.state.polygonPopUp.remove();
        this.setState({ polygonPopUp: null });
      }

      const data = this.state.mapboxDraw.getAll();
      if (data.features.length > 0) {
        console.log("data.features = ", data.features[0].id_area);
        console.log("this.state.map.getStyle().layers = ", this.state.map.getStyle().layers);

        const polygonPopUp = new mapboxgl.Popup({
          className: "polygon-popup",
          closeButton: false,
          closeOnClick: true
        });
        const areaInMetersSquared = turfArea(data);
        const roundedAreaInMetersSquared = Math.round(areaInMetersSquared);
        const centroid = turfCentroid(data);
        const kilometersSquared = `${(roundedAreaInMetersSquared / 1000000).toFixed(
          2
        )} km²`;

        const description =
          `<div id="map-area-popup-label"
                        style="background: #353551; color: #ffffff;">Polygon Area</div>` +
          // + `<div id=\"map-uploads-popup-province\">${division.province}</div>`
          `<div id="map-area-popup-details"> 
                    <table id="map-area-popup-details-table">
                        <tr>
                            <td>${kilometersSquared.replace(
            /(\\d)(?=(\\d{3})+(?!\\d))/g,
            "$1,"
          )}</td>
                        </tr>
                    </table>
                </div>`;

        polygonPopUp.setLngLat(
          centroid.geometry.coordinates
        );
        polygonPopUp.setHTML(
          description
        );
        polygonPopUp.addTo(
          this.state.map
        );

        this.setState(
          { polygonPopUp }
        );
      }
    }

  }

  onMouseLeavePolygon() {

    if (this.state.map !== null) {
      this.state.map.getCanvas().style.cursor = "";

      if (this.state.polygonPopUp !== null) {
        this.state.polygonPopUp.remove();
        this.setState(
          {
            polygonPopUp: null
          }
        );
      }
    }
  }

  // MAP FUNCTIONS
  onMapPopUpButtonClicked() {
    console.log("button clicked");
  }

  onMapStyleSwitcherButtonClicked(event, key, mapStyleSwitcher) {
    const { setStyle } = this.props;

    const srcElement = event.srcElement || event.originalTarget;

    if (srcElement.classList.contains("active")) {
      return;
    }

    if (this.state.map !== null) {
      if (srcElement.dataset.uri) {
        this.state.map.off(
          "click",
          "clusters",
          this.onClickDwellingClusters
        );

        this.state.map.off(
          "click",
          "unclustered-point",
          this.onClickUnclusteredDwellingPoint
        );
        this.state.map.off(
          "mouseenter",
          "unclustered-point",
          this.onMouseEnterUnclusteredDwellingPoint
        );
        this.state.map.off(
          "mouseleave",
          "unclustered-point",
          this.onMouseLeaveUnclusteredDwellingPoint
        );

        this.state.map.off(
          "click",
          "electoral-divisions",
          this.onClickElectoralDivision
        );
        this.state.map.off(
          "mouseenter",
          "electoral-divisions",
          this.onMouseEnterElectoralDivision
        );
        this.state.map.off(
          "mouseleave",
          "electoral-divisions",
          this.onMouseLeaveElectoralDivision
        );

        this.state.map.setStyle(
          JSON.parse(srcElement.dataset.uri)
        );
      }

      if (mapStyleSwitcher.styleButton) {
        mapStyleSwitcher.styleButton.style.display = "block";
      }

      if (mapStyleSwitcher.mapStyleContainer) {
        mapStyleSwitcher.mapStyleContainer.style.display = "none";

        const elements =
          mapStyleSwitcher.mapStyleContainer.getElementsByClassName("active");
        while (elements[0]) {
          elements[0].classList.remove("active");
        }
        srcElement.classList.add("active");
      }

      setStyle(key);

      setTimeout(
        () => {
          this.addRetroKitMapLayers(this.state.map);
        },
        1000
      );
    }
  }

  setZoomToStockExtents() {
    if (!this.props.geostock) return;


    if (this.state.map !== null) {
      const boundingBox = turfbbox(
        this.props.geostock
      );

      this.state.map.fitBounds(
        boundingBox,
        {
          padding: {
            top: 50,
            bottom: 30,
            left: 30,
            right: 30
          }
        }
      );
    }
  }

  // 3D BUILDINGS LAYER
  setup3DBuildingsLayer(map) {
    const threeDBuildingsLayer = map.getLayer("3d-buildings");
    if (typeof threeDBuildingsLayer === "undefined") {
      map.addLayer(
        {
          id: "3d-buildings",
          source: "composite",
          "source-layer": "building",
          filter: ["==", "extrude", "true"],
          type: "fill-extrusion",
          minzoom: 15,
          paint: {
            "fill-extrusion-color": "#aaa",
            // use an 'interpolate' expression to add a smooth transition effect to the buildings as the user
            // zooms in
            "fill-extrusion-height": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "height"]
            ],
            "fill-extrusion-base": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "min_height"]
            ],
            "fill-extrusion-opacity": 0.6
          }
        },
        "electoral-divisions"
      );
    }
  }

  // DWELLINGS LAYERS
  onClickDwellingClusters(event) {

    if (this.state.map !== null) {
      const features = this.state.map.queryRenderedFeatures(event.point, {
        layers: ["clusters"]
      });
      const clusterId = features[0].properties.cluster_id;

      this.state.map
        .getSource("dwellings")
        .getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          this.state.map.easeTo({
            center: features[0].geometry.coordinates,
            zoom
          });
        });
    }
  }

  onClickUnclusteredDwellingPoint(event) {
    const dwelling = this.props.geostock.features.find(
      ({ id }) => id === event.features[0].id
    );
    if (dwelling.tableData) {
      if (dwelling.tableData.checked === true) {
        this.props.onSelectionCleared([dwelling]);
      } else {
        this.props.onSelectionChange([dwelling]);
      }
    } else {
      this.props.onSelectionChange([dwelling]);
    }
    event.originalEvent.cancelClick = true;
  }

  onMouseEnterUnclusteredDwellingPoint(event) {

    if (this.state.map !== null) {
      if (this.state.electoralDivisionPopUp !== null) {
        this.state.electoralDivisionPopUp.remove();
      }

      this.state.map.getCanvas().style.cursor = "pointer";

      const feature = event.features[0].properties;

      let dateParts = ["1900", "01", "01"];
      if (feature.date_of_construction) {
        dateParts = feature.date_of_construction.split("-");
      }

      let address = "";
      if (
        feature.address_line1 !== null &&
        feature.address_line1 !== "Unknown" &&
        feature.address_line1 !== "None"
      ) {
        address += feature.address_line1;
      }

      if (
        feature.address_line2 !== null &&
        feature.address_line2 !== "Unknown" &&
        feature.address_line2 !== "None"
      ) {
        address += `, ${feature.address_line2}`;
      }

      if (
        feature.address_line3 !== null &&
        feature.address_line3 !== "Unknown" &&
        feature.address_line3 !== "None" &&
        !feature.county.includes(feature.address_line3) &&
        !feature.address_line2.includes(feature.address_line3)
      ) {
        address += `, ${feature.address_line3}`;
      }

      let postcode = "No Eircode Provided";
      if (feature.postcode !== "None" && feature.postcode !== null) {
        postcode = feature.postcode;
      }

      const performance = JSON.parse(feature.latest_performance);
      const archetype = JSON.parse(feature.latest_archetype);

      const backgroundColour = BER_COLOUR_CODE_MAP[performance.rating];
      let fontColour = "#ffffff";
      if (
        backgroundColour === BER_COLOUR_CODE_MAP.C ||
        backgroundColour === BER_COLOUR_CODE_MAP.D
      ) {
        fontColour = "#000000";
      }

      const description =
        `<div id="map-dwelling-popup-address" 
                                    style="background: ${backgroundColour}; color: ${fontColour};">
                                        ${address}, ${feature.county}, ${postcode}
                               </div>` +
        `<div id="map-dwelling-popup-kpis">
                    <table id="map-dwelling-popup-kpi-table">
                        <tr>
                            <td><b>Type</b></td>
                            <td class="map-dwelling-popup-kpi-table-column">${
          archetype.dwelling_type
        }</td>
                        </tr>
                        <tr>
                            <td><b>Constructed</b></td>
                            <td class="map-dwelling-popup-kpi-table-column">${dateFormat(
          new Date(
            Number(dateParts[0]),
            Number(dateParts[1]) - 1,
            Number(dateParts[2])
          ),
          "yyyy"
        )}</td>
                        </tr>
                        <tr>
                            <td><b>Main Fuel</b></td>
                            <td class="map-dwelling-popup-kpi-table-column">${
          archetype.main_fuel
        }</td>
                        </tr>
                        <tr>
                            <td><b>Wall Type</b></td>
                            <td class="map-dwelling-popup-kpi-table-column">${
          archetype.main_wall_type
        }</td>
                        </tr>
                        <tr>
                            <td><b>BER</b></td>
                            <td class="map-dwelling-popup-kpi-table-column">${
          performance.rating
        }</td>
                        </tr>
                    </table>
                </div>`;

      const dwellingPopUp = new mapboxgl.Popup({
        className: "dwelling-popup",
        closeButton: false,
        closeOnClick: true
      });
      dwellingPopUp.setLngLat(
        event.features[0].geometry.coordinates
      );
      dwellingPopUp.setHTML(
        description
      );
      dwellingPopUp.addTo(
        this.state.map
      );
      this.setState(
        { dwellingPopUp }
      );
    }
  }

  onMouseLeaveUnclusteredDwellingPoint() {
    if (this.state.map !== null) {
      this.state.map.getCanvas().style.cursor = "";

      if (this.state.dwellingPopUp !== null) {
        this.state.dwellingPopUp.remove();
        this.setState({ dwellingPopUp: null });
      }

      if (this.state.electoralDivisionPopUp !== null) {
        this.state.electoralDivisionPopUp.addTo(
          this.state.map
        );
      }
    }
  }

  setupDwellingClustersLayer(map) {
    const dwellingsSource = map.getSource("dwellings");
    if (typeof dwellingsSource === "undefined") {
      map.addSource("dwellings", {
        type: "geojson",
        data: this.props.geostock,
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50
      });
    }

    const clustersLayer = map.getLayer("clusters");
    if (typeof clustersLayer === "undefined") {
      map.addLayer({
        id: "clusters",
        type: "circle",
        source: "dwellings",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#7986cb",
            20,
            "#3949ab",
            50,
            "#1a237e"
          ],
          "circle-radius": ["step", ["get", "point_count"], 20, 20, 30, 50, 40]
        }
      });
      map.on("click", "clusters", this.onClickDwellingClusters);
    }

    const clusterCountLayer = map.getLayer("cluster-count");
    if (typeof clusterCountLayer === "undefined") {
      map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "dwellings",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12
        }
      });
    }

    const unclusteredPointLayer = map.getLayer("unclustered-point");
    if (typeof unclusteredPointLayer === "undefined") {
      map.addLayer({
        id: "unclustered-point",
        type: "circle",
        source: "dwellings",
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": "#7986cb",
          "circle-radius": 4,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#fff"
        }
      });
      map.on(
        "click",
        "unclustered-point",
        this.onClickUnclusteredDwellingPoint
      );
      map.on(
        "mouseenter",
        "unclustered-point",
        this.onMouseEnterUnclusteredDwellingPoint
      );
      map.on(
        "mouseleave",
        "unclustered-point",
        this.onMouseLeaveUnclusteredDwellingPoint
      );
    }
  }

  // ELECTORAL DIVISIONS LAYER
  onClickElectoralDivision(event) {
    // This prevents the click event being passed through from the clusters layer
    if (event.originalEvent.cancelClick) {
      return;
    }

    if (this.state.map !== null) {
      const features = this.state.map.queryRenderedFeatures(event.point, {
        layers: ["electoral-divisions"]
      });

      // Select behaviour
      const dwellingsInElectoralDivision = this.props.geostock.features.filter(
        ({ properties }) => properties.electoral_division === features[0].id
      );

      const unselectedDwellings = dwellingsInElectoralDivision.filter(
        (dwelling) => {
          if (dwelling.tableData) {
            return dwelling.tableData.checked === false;
          }
          return false;
        }
      );

      if (unselectedDwellings.length > 0) {
        this.props.onSelectionChange(dwellingsInElectoralDivision);
      } else {
        this.props.onSelectionCleared(dwellingsInElectoralDivision);
      }
    }
  }

  onMouseEnterElectoralDivision(event) {

    if (this.state.map !== null) {
      this.state.map.getCanvas().style.cursor = "pointer";

      if (this.state.dwellingPopUp !== null) {
        this.state.dwellingPopUp.remove();
        this.setState({ dwellingPopUp: null });
      }

      const electoralDivision = event.features[0].properties;

      let rating = "?";

      Object.keys(BER_SCORE_MAP).forEach((grade) => {
        const averageBER = parseInt(electoralDivision.average_ber);
        if (
          BER_SCORE_MAP[grade][0] <= averageBER &&
          averageBER <= BER_SCORE_MAP[grade][1]
        ) {
          rating = grade;
        }
      });

      const county = electoralDivision.county.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

      const backgroundColour = BER_COLOUR_CODE_MAP[rating];
      let fontColour = "#ffffff";
      if (
        backgroundColour === BER_COLOUR_CODE_MAP.C ||
        backgroundColour === BER_COLOUR_CODE_MAP.D
      ) {
        fontColour = "#000000";
      }

      const description =
        `<div id="map-ed-popup-label"
                        style="background: ${backgroundColour}; color: ${fontColour};">${electoralDivision.csoed_3409_label}, ${county}</div>` +
        // + `<div id=\"map-uploads-popup-province\">${division.province}</div>`
        `<div id="map-ed-popup-details"> 
                    <table id="map-ed-popup-details-table">
                        <tr>
                            <td><b>Average BER</b></td>
                            <td>${rating}</td>
                        </tr>
                        <tr>
                            <td><b>Dwellings</b></td>
                            <td>${electoralDivision.dwelling_count}</td>
                        </tr>
                    </table>
                </div>`;
      /* + `<div id=\"map-ed-popup-select-button-containers\" class=\"mdc-touch-target-wrapper\"`
                  + "<button class=\"mdc-button mdc-button--touch\" onclick=this.popUpButtonClicked()>"
                  + `<div class=\"mdc-button__ripple\"></div>
                              <span class=\"mdc-button__label\">Select dwelling(s)</span>
                              <div class=\"mdc-button__touch\"></div>
                          </button>
                      </div>` */
      const electoralDivisionPopUp = new mapboxgl.Popup({
        className: "ed-popup",
        closeButton: false,
        closeOnClick: true
      });

      electoralDivisionPopUp.setLngLat(
        event.lngLat
      );
      electoralDivisionPopUp.setHTML(
        description
      );
      electoralDivisionPopUp.addTo(
        this.state.map
      );

      this.setState(
        { electoralDivisionPopUp }
      );
    }
  }

  onMouseLeaveElectoralDivision() {

    if (this.state.map !== null) {
      this.state.map.getCanvas().style.cursor = "";

      if (this.state.electoralDivisionPopUp !== null) {
        this.state.electoralDivisionPopUp.remove();
        this.setState({ electoralDivisionPopUp: null });
      }
    }
  }

  setupElectoralDivisionsLayer(map) {
    const electoralDivisionsSource = map.getSource("electoral-divisions");
    if (typeof electoralDivisionsSource === "undefined") {
      map.addSource("electoral-divisions", {
        type: "geojson",
        data: this.props.electoralDivisions
      });
    }

    const { electoralDivisionsLayerOpacity } = this.props;

    const electoralDivisionsLayer = map.getLayer("electoral-divisions");
    if (typeof electoralDivisionsLayer === "undefined") {
      map.addLayer(
        {
          id: "electoral-divisions",
          type: "fill",
          source: "electoral-divisions",
          paint: {
            "fill-color": [
              "case",
              ["<=", ["to-number", ["get", "average_ber"]], 25],
              BER_COLOUR_CODE_MAP.A1,
              ["<=", ["to-number", ["get", "average_ber"]], 50],
              BER_COLOUR_CODE_MAP.A2,
              ["<=", ["to-number", ["get", "average_ber"]], 75],
              BER_COLOUR_CODE_MAP.A3,
              ["<=", ["to-number", ["get", "average_ber"]], 100],
              BER_COLOUR_CODE_MAP.B1,
              ["<=", ["to-number", ["get", "average_ber"]], 125],
              BER_COLOUR_CODE_MAP.B2,
              ["<=", ["to-number", ["get", "average_ber"]], 150],
              BER_COLOUR_CODE_MAP.B3,
              ["<=", ["to-number", ["get", "average_ber"]], 175],
              BER_COLOUR_CODE_MAP.C1,
              ["<=", ["to-number", ["get", "average_ber"]], 200],
              BER_COLOUR_CODE_MAP.C2,
              ["<=", ["to-number", ["get", "average_ber"]], 225],
              BER_COLOUR_CODE_MAP.C3,
              ["<=", ["to-number", ["get", "average_ber"]], 260],
              BER_COLOUR_CODE_MAP.D1,
              ["<=", ["to-number", ["get", "average_ber"]], 300],
              BER_COLOUR_CODE_MAP.D2,
              ["<=", ["to-number", ["get", "average_ber"]], 340],
              BER_COLOUR_CODE_MAP.E1,
              ["<=", ["to-number", ["get", "average_ber"]], 380],
              BER_COLOUR_CODE_MAP.E2,
              ["<=", ["to-number", ["get", "average_ber"]], 450],
              BER_COLOUR_CODE_MAP.F,
              ["<=", ["to-number", ["get", "average_ber"]], 10000],
              BER_COLOUR_CODE_MAP.G,
              "grey"
            ],
            "fill-opacity": electoralDivisionsLayerOpacity
          }
        },
        "clusters"
      );

      map.on("click", "electoral-divisions", this.onClickElectoralDivision);
      map.on(
        "mouseenter",
        "electoral-divisions",
        this.onMouseEnterElectoralDivision
      );
      map.on(
        "mouseleave",
        "electoral-divisions",
        this.onMouseLeaveElectoralDivision
      );
    }

    const electoralDivisionsOutlineLayer = map.getLayer(
      "electoral-divisions-outline"
    );
    if (typeof electoralDivisionsOutlineLayer === "undefined") {
      map.addLayer(
        {
          id: "electoral-divisions-outline",
          type: "line",
          source: "electoral-divisions",
          paint: {
            "line-color": "#353551",
            "line-dasharray": [2, 1],
            // 'line-gap-width': 4,
            "line-opacity": electoralDivisionsLayerOpacity
          }
        },
        "clusters"
      );
    }
  }

  // SELECTED DWELLINGS LAYER
  setupSelectedDwellingsLayer(map) {
    const selectedDwellingsSource = map.getSource("selected-dwellings");
    if (typeof selectedDwellingsSource === "undefined") {
      map.addSource("selected-dwellings", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: this.props.selectedDwellings
        },
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50
      });
    }

    const selectedLayerColour = "#e91e63";

    const selectedDwellingsClustersLayer = map.getLayer(
      "selected-dwellings-clusters"
    );
    if (typeof selectedDwellingsClustersLayer === "undefined") {
      map.addLayer({
        id: "selected-dwellings-clusters",
        type: "circle",
        source: "selected-dwellings",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": selectedLayerColour,
          "circle-radius": ["step", ["get", "point_count"], 23, 20, 33, 50, 43]
        }
      });
    }

    const selectedDwellingsClusterCountLayer = map.getLayer(
      "selected-dwellings-cluster-count"
    );
    if (typeof selectedDwellingsClusterCountLayer === "undefined") {
      map.addLayer({
        id: "selected-dwellings-cluster-count",
        type: "symbol",
        source: "selected-dwellings",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 16
        },
        paint: {
          "text-color": "#ffffff"
        }
      });
    }

    const unclusteredSelectedDwellingLayer = map.getLayer(
      "unclustered-selected-dwelling"
    );
    if (typeof unclusteredSelectedDwellingLayer === "undefined") {
      map.addLayer({
        id: "unclustered-selected-dwelling",
        type: "circle",
        source: "selected-dwellings",
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": selectedLayerColour,
          "circle-radius": 7
        }
      });
    }
  }

  // LIFECYCLE
  componentDidMount() {
    if (this.state.map === null) {

      const map = new mapboxgl.Map(
        {
          container: this.mapRef.current
        }
      );

      map.setZoom(
        this.props.mapZoom || 7 // Show entire country on 720p or higher
      );

      map.setCenter(
        this.props.position || [
          -8.4728,
          51.8984
        ] // Ireland
      );

      map.setMaxBounds(
        [
          // Ireland only
          // [-10.996501958307562, 51.04205649874885], // [west, south]
          // [-5.139279479507707, 55.60358029514097]  // [east, north]

          // Ireland + some of UK and atlantic (More liberal for easier zooming)
          [
            -18.92689724507204,
            51.04205649874885
          ], // [west, south]
          [
            3.6124619054417337,
            55.60358029514097
          ] // [east, north]
        ]
      );

      map.setStyle(
        MAP_STYLE_URI_DICTIONARY[
          this.props.style
          ]
      );

      this.addControls(map);

      map.once("load", () => {
        this.addRetroKitMapLayers(map);

        if (!this.props.position) {
          this.setZoomToStockExtents();
        }
      });

      this.setState(
        {
          map: map
        }
      );

      /* map.on('load', () => {
          this.addRetroKitMapLayers(map);
          if (!this.props.position) {
              this.setZoomToStockExtents()
          }
      }); */
    }
  }


  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      this.props.geostock !== nextProps.geostock ||
      this.props.showSelectionTip !== nextProps.showSelectionTip ||
      this.props.selectedDwellings !== nextProps.selectedDwellings
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (this.state.map !== null) {
      if (this.state.map.getSource("selected-dwellings")) {
        this.state.map.getSource("selected-dwellings").setData({
          type: "FeatureCollection",
          features: this.props.selectedDwellings
        });
      }

      if (this.props.geostock !== prevProps.geostock) {
        if (this.state.map.getSource("dwellings")) {
          this.state.map.getSource("dwellings").setData(this.props.geostock);
        }
      }
    }
  }

  render() {

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "calc(100vh - 64px - 48px - 64px)",
          flex: 1
        }}
      >
        <div
          id="map"
          style={{
            height: this.props.height,
            margin: 0,
            padding: 0
          }}
          ref={this.mapRef}
        />

        <Snackbar
          open={this.props.showSelectionTip}
          autoHideDuration={30000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          onClose={() => {
            this.props.setShowSelectionTip(false);
          }}
        >
          <SnackbarContent
            message="Hold the Shift key, then click and drag to make a selection"
            action={
              <Button
                color="secondary"
                size="small"
                onClick={() => {
                  this.props.setShowSelectionTip(false);
                }}
              >
                Dismiss
              </Button>
            }
          />
        </Snackbar>
      </div>
    );
  }
}

DashboardMap.propTypes = {
  geostock: PropTypes.object.isRequired,

  zoom: PropTypes.number,
  position: PropTypes.object,
  style: PropTypes.string.isRequired,
  setPosition: PropTypes.func.isRequired,
  setStyle: PropTypes.func.isRequired,
  setZoom: PropTypes.func.isRequired,

  electoralDivisions: PropTypes.object.isRequired,
  electoralDivisionsLayerOpacity: PropTypes.number.isRequired,
  onChangeElectoralDivisionsLayerOpacity: PropTypes.func.isRequired,

  showSelectionTip: PropTypes.bool.isRequired,
  setShowSelectionTip: PropTypes.func.isRequired,

  selectedDwellings: PropTypes.array.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  onSelectionCleared: PropTypes.func.isRequired,

  height: PropTypes.number.isRequired,

  theme: PropTypes.object.isRequired
};

export default withMaterialUserInterfaceTheme(DashboardMap);

import React from "react";

import PropTypes from "prop-types";
import { CSVLink } from "react-csv";
import Draggable from "react-draggable";
import { connect } from "react-redux";

import ClearIcon from "@mui/icons-material/Clear";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Grid } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";


import { withMaterialUserInterfaceTheme } from "common/hocs/theme";
import { clearUploadTasks } from "v1/actions/uploads";


class UploadProgressTracker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorPopOverAnchorEl: null,
      errorPopOverShowingIndex: null
    };

    this.csvLinkRef = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      this.props.uploadTaskList !== nextProps.uploadTaskList ||
      this.props.errorsList !== nextProps.errorsList ||
      this.props.uploadProgress !== nextProps.uploadProgress
    );
  }

  render() {
    const {
      theme,
      uploadProgress,
      uploadProgressBuffer
    } = this.props;

    return (
      <Draggable>
        <Paper
          square={false}
          elevation={8}
          variant="elevation"
          sx={{
            position: "absolute",
            bottom: 24,
            right: 24,
            width: 500,
            zIndex: 2000
          }}
        >
          <Accordion
            defaultExpanded={false}
          >

            <AccordionSummary
              expandIcon={
                <ExpandLessIcon />
              }
              aria-controls="panel1c-content"
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%"
                }}
              >
                <Typography
                  sx={{
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    fontSize: theme.typography.pxToRem(16),
                    paddingRight: "16px"
                  }}
                >

                  {uploadProgress === 100 ? "Done" : "Processing"}

                </Typography>

                {
                  this.props.uploads.errorsList.length > 0 && (
                    <ErrorOutlineIcon
                      sx={{
                        marginRight: "16px",
                        color: theme.palette.error.main
                      }}
                    />
                  )
                }

                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <LinearProgress
                    sx={{
                      width: "100%"
                    }}
                    color="secondary"
                    variant="buffer"
                    value={uploadProgress}
                    valueBuffer={uploadProgressBuffer}
                  />
                </Box>

                <Typography
                  sx={{
                    fontSize: theme.typography.pxToRem(15),
                    color: theme.palette.text.secondary,
                    paddingLeft: "16px"
                  }}
                >

                  {Math.round(uploadProgress)}%

                </Typography>

              </Box>

            </AccordionSummary>

            {
              this.props.uploads.errorsList.length > 0 && (
                <AccordionDetails>
                  <Box
                    sx={{
                      maxHeight: "200px",
                      overflow: "auto",
                      width: "100%"
                    }}
                  >
                    <List>
                      {
                        this.props.uploads.errorsList.map(
                          (error, index) => (
                            <ListItem
                              disablePadding
                              key={`error-popover-${index}`}
                              aria-owns={
                                this.state.errorPopOverAnchorEl
                                  ? `error-mouse-over-popover-${index}`
                                  : undefined
                              }
                              aria-haspopup="true"
                              onMouseEnter={(event) => {
                                this.setState({
                                  errorPopOverAnchorEl: event.currentTarget,
                                  errorPopOverShowingIndex: index
                                });
                              }}
                              onMouseLeave={() => {
                                this.setState({
                                  errorPopOverAnchorEl: null,
                                  errorPopOverShowingIndex: null
                                });
                              }}
                            >

                              <ListItemButton
                                disabled={false}
                              >

                                <ListItemText
                                  primary={error.file}
                                />

                                <ListItemSecondaryAction>

                                  <ClearIcon
                                    style={{
                                      color: theme.palette.error.main
                                    }}
                                  />

                                </ListItemSecondaryAction>

                                <Popover
                                  id={`error-mouse-over-popover-${index}`}
                                  sx={{
                                    pointerEvents: "none",
                                    zIndex: 2001
                                  }}
                                  classes={{
                                    paper: {
                                      padding: theme.spacing(2),
                                      backgroundColor: theme.palette.primary.dark,
                                      color: "white"
                                    }
                                  }}
                                  open={Boolean(
                                    this.state.errorPopOverShowingIndex === index
                                  )}
                                  anchorEl={this.state.errorPopOverAnchorEl}
                                  anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "center"
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center"
                                  }}
                                  onClose={() => {
                                    this.setState({
                                      errorPopOverAnchorEl: null
                                    });
                                  }}
                                  disableRestoreFocus
                                >

                                  <Typography>{error.text}</Typography>

                                </Popover>

                              </ListItemButton>

                            </ListItem>
                          )
                        )
                      }
                    </List>

                  </Box>

                </AccordionDetails>

              )
            }

            <AccordionActions>
              {
                uploadProgress === 100 &&
                this.props.uploads.errorsList.length > 0 && (
                  <div>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => this.csvLinkRef.current.link.click()}
                    >
                      Export errors as CSV
                    </Button>
                    <CSVLink
                      data={this.props.uploads.errorsList}
                      filename="RetroKit_XML_Errors_During_Upload.csv"
                      className="hidden"
                      ref={this.csvLinkRef}
                      target="_blank"
                    />
                  </div>
                )
              }

              <Button
                size="small"
                color="primary"
                disabled={Boolean(uploadProgress < 100)}
                onClick={() => this.props.clearUploadTasks()}
              >
                Close
              </Button>

            </AccordionActions>

          </Accordion>

        </Paper>

      </Draggable>
    );
  }
}

const mapStateToProps = (state) => ({
  uploads: state.uploads
});

const mapDispatchToProps = (dispatch) => ({
  clearUploadTasks: () => dispatch(clearUploadTasks())
});

UploadProgressTracker.propTypes = {
  uploadingDescription: PropTypes.string.isRequired,
  uploadProgress: PropTypes.number.isRequired,
  uploadProgressBuffer: PropTypes.number.isRequired,
  uploadTaskCompleted: PropTypes.bool.isRequired,
  uploadTaskCancelled: PropTypes.bool.isRequired,

  theme: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withMaterialUserInterfaceTheme(UploadProgressTracker)
);

import type { Dispatch, SetStateAction } from "react";
import React, { useCallback, useMemo, useState } from "react";

import MaterialTable from "@material-table/core";
import { toast } from "react-toastify";

import {
  Add as AddIcon,
  Assessment as AssessmentIcon,
  Delete as DeleteIcon,
  Euro as EuroIcon,
  Compare as CompareIcon,
  TableView as TableViewIcon,
  PictureAsPdf as PdfIcon, Person as PersonIcon,
    Groups as GroupsIcon,
} from "@mui/icons-material";
import { colors, Select, Zoom } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import type { PaperProps } from "@mui/material/Paper";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import {
  BERChevron
} from "common/components/BERChevron";
import {
  ConfirmationDialog
} from "common/components/ConfirmationDialog";
import {
  DWELLING_TABLE_COLUMNS
} from "common/constants/tables";
import {
  ProjectCostsDialog
} from "features/projectCosts/dialogs/ProjectCostsDialog";

import type {
  Action,
  Column
} from "@material-table/core";
import type { Dwelling } from "features/dwellings/types";
import type {
  CreateOrGetHEAApiRequest
} from "features/homeEnergyAssessment/api/types";
import type {
  ProjectScenarioRecommendation
} from "features/projects/types";
import type {
  ProjectScenario
} from "features/projectScenarios/types";
import type {
  CreateTAFApiRequest
} from "features/technicalAssessmentForm/api/types";


interface ProjectDwellingsTableProps {
  dwellings: Array<Dwelling>;
  handleUpdateProjectScenarioRecommendation: (
      requestArgs: { id: number; dwelling_type: string; scenario_id: number; dwelling_id: number }
  ) => void;
  isLoading: boolean;
  isProjectRunning: boolean;
  onClickGeneratePDFReport: (dwelling: Dwelling) => void;
  onClickScenarioComparison: (dwelling: Dwelling) => void;
  onRemoveDwellings: (dwellings: Array<Dwelling>) => void;
  projectId: number;
  projectScenarios: Array<ProjectScenario>;
  scenarioRecommendations: Array<ProjectScenarioRecommendation>;
  selectedDwellings: Dwelling[];
  setSelectedDwellings: Dispatch<SetStateAction<Dwelling[]>>;
  setShowAddDwellingsModal: Dispatch<SetStateAction<boolean>>;
  setShowCompareScenariosModal: Dispatch<SetStateAction<boolean>>;
  onCreateOrGetHeaClicked: (
    request: CreateOrGetHEAApiRequest,
    dwelling: Dwelling,
    recommendedScenario: ProjectScenario
  ) => void;
  onCreateTafClicked: (request: CreateTAFApiRequest) => void;
}

export function ProjectDwellingsTable(
  {
    dwellings,
    handleUpdateProjectScenarioRecommendation,
    isLoading,
    isProjectRunning,
    onRemoveDwellings,
    onClickGeneratePDFReport,
    onClickScenarioComparison,
    projectId,
    projectScenarios,
    scenarioRecommendations,
    selectedDwellings,
    setSelectedDwellings,
    setShowAddDwellingsModal,
    setShowCompareScenariosModal,
    onCreateOrGetHeaClicked,
    onCreateTafClicked
  }: ProjectDwellingsTableProps) {

  const theme = useTheme();

  const [
    resultsExistForSelectedDwelling,
    setResultsExistForSelectedDwelling
  ] = useState<boolean>(false);

  const [
    confirmationDialogOpen,
    setConfirmationDialogOpen
  ] = useState<boolean>(false);

  const [
    projectCostsDialogOpen,
    setEditProjectCostsDialogOpen
  ] = useState<boolean>(false);

  const [
    recommendedScenarioForDwelling,
    setRecommendedScenarioForDwelling
  ] = useState<ProjectScenario>();

  const handleCloseConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
  };

  const handleCloseProjectCostsDialog = () => {
    setEditProjectCostsDialogOpen(false);
  };

  const handleUpdateScenarioRecommendation = useCallback(
    (scenarioIdentifier: number, dwellingIdentifier: number, dwellingType: string) => {
      handleUpdateProjectScenarioRecommendation({
        id: projectId,
        scenario_id: scenarioIdentifier,
        dwelling_id: dwellingIdentifier,
        dwelling_type: dwellingType,
      });
    },
    [
      projectId,
      handleUpdateProjectScenarioRecommendation
    ]
  );

  const handleCreateOrGetHomeEnergyAssessment = useCallback(
    (dwelling: Dwelling) => {
      if (selectedDwellings.length === 1 && projectScenarios.length > 0) {
        const recommended = scenarioRecommendations.find(
          recommendation => recommendation.dwelling === dwelling.id
        );
        if (recommended !== undefined) {

          const recommendedScenario = projectScenarios.find(
            projectScenario => projectScenario.id === recommended.scenario
          )

          if (recommendedScenario !== undefined) {

            onCreateOrGetHeaClicked(
              {
                project_id: projectId,
                dwelling_id: dwelling.id,
                scenario_id: recommendedScenario.id,
                dwelling_type: dwelling.type
              },
              dwelling,
              recommendedScenario
            );
          }
          else {
            toast.error("No recommended scenario for this dwelling", {
              position: toast.POSITION.TOP_RIGHT,
              theme: "colored"
            });
          }
        }
        else {
          toast.error("No recommended scenario for this dwelling", {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored"
          });
        }
      }
    },
    [
      onCreateOrGetHeaClicked,
      projectId,
      projectScenarios,
      scenarioRecommendations,
      selectedDwellings
    ]
  );

  const handleCreateTechnicalAssessmentForm = useCallback(
    () => {
      if (selectedDwellings.length === 1 && projectScenarios.length > 0) {
        const dwelling = selectedDwellings[0];
        const recommended = scenarioRecommendations.find(
          recommendation => recommendation.dwelling === dwelling.id
        );
        if (recommended !== undefined) {
          onCreateTafClicked({
            project_id: projectId,
            dwelling_id: dwelling.id,
            scenario_id: recommended.scenario,
            type: dwelling.type
          });
        }
      }
    },
    [
      onCreateTafClicked,
      projectId,
      projectScenarios.length,
      scenarioRecommendations,
      selectedDwellings
    ]
  );

  const tableActions: Array<Action<Dwelling>> = useMemo(
    () => {
      const addDwellingIcon = () => {
        if (isProjectRunning) {
          return <AddIcon />;
        }
        return <AddIcon
          sx={{
            color: theme.retrokitPalette.green.dark
          }}
        />;
      };

      const compareScenariosIcon = () => {
        if (isProjectRunning || !resultsExistForSelectedDwelling) {
          return <CompareIcon />;
        }
        return <CompareIcon
          color="secondary"
        />;
      };

      const costsIcon = () => {
        if (isProjectRunning || !resultsExistForSelectedDwelling) {
          return <EuroIcon />;
        }
        return <EuroIcon
          color="secondary"
        />;
      };

      const pdfReportIcon = () => {
        if (isProjectRunning || !resultsExistForSelectedDwelling) {
          return <PdfIcon />;
        }
        return <PdfIcon
          color="secondary"
        />;
      };


      const removeDwellingIcon = () => {
        if (isProjectRunning) {
          return <DeleteIcon />;
        }
        return <DeleteIcon
          color="secondary"
        />;
      };

      const heaIcon = () => {
        if (isProjectRunning || !resultsExistForSelectedDwelling) {
          return <AssessmentIcon />;
        }
        return <AssessmentIcon
          color="secondary"
        />;
      };

      const tafIcon = () => {
        if (isProjectRunning || !resultsExistForSelectedDwelling) {
          return <TableViewIcon />;
        }
        return <TableViewIcon
          color="secondary"
        />;
      };

      return [
        {
          icon: addDwellingIcon,
          disabled: isProjectRunning,
          hidden: selectedDwellings.length > 0,
          isFreeAction: true,
          tooltip: (() => {
            if (isProjectRunning) {
              return "Can't add dwellings while results are being generated";
            }
            return "Add dwelling(s) to the project";
          })(),
          onClick: () => {
            setShowAddDwellingsModal(true);
          }
        },
        {
          icon: compareScenariosIcon,
          disabled: isProjectRunning || !resultsExistForSelectedDwelling,
          hidden: selectedDwellings.length > 1,
          tooltip: (() => {
            if (isProjectRunning) {
              return "Can't compare scenarios while results are being generated";
            }
            if (!resultsExistForSelectedDwelling) {
              return "Scenario results are missing for your selection, run the project to generate results";
            }
            return "Compare dwelling performance across scenarios in this project";
          })(),
          onClick: (_event: Event, data: Array<Dwelling> | Dwelling) => {
            setShowCompareScenariosModal(true);
            onClickScenarioComparison(Array.isArray(data) ? data[0] : data);
          }
        },
        {
          icon: costsIcon,
          disabled: isProjectRunning || !resultsExistForSelectedDwelling,
          hidden: selectedDwellings.length > 1,
          tooltip: (() => {
            if (isProjectRunning) {
              return "Can't edit costs while results are being generated";
            }
            if (!resultsExistForSelectedDwelling) {
              return "Costs are missing for your selection, run the project to generate results";
            }
            return "Edit scenario measure costs and grants for the selected dwelling";
          })(),
          onClick: () => {

            const projectScenario = projectScenarios.find(
              (scenario) => {
                const recommended = scenarioRecommendations.find(
                  recommendation => recommendation.dwelling === selectedDwellings[0].id
                );

                if (recommended !== undefined) {
                  return scenario.id === recommended.scenario;
                }
                return false;
              }
            );

            if (projectScenario !== undefined) {
              setRecommendedScenarioForDwelling(projectScenario);
              setEditProjectCostsDialogOpen(true);
            } else {
              toast.info("No recommended scenario for this dwelling", {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored"
              });
            }
          }
        },
        {
          icon: pdfReportIcon,
          disabled: isProjectRunning || !resultsExistForSelectedDwelling,
          hidden: selectedDwellings.length > 1,
          tooltip: (() => {
            if (isProjectRunning) {
              return "Can't generate PDF reports while results are being generated";
            }
            if (!resultsExistForSelectedDwelling) {
              return "Scenario results are missing for your selection, run the project to generate results";
            }
            return "Generate PDF report";
          })(),
          onClick: (_event: Event, data: Array<Dwelling> | Dwelling) => {
            onClickGeneratePDFReport(Array.isArray(data) ? data[0] : data);
          }
        },
        {
          icon: heaIcon,
          disabled: isProjectRunning || !resultsExistForSelectedDwelling,
          hidden: selectedDwellings.length > 1,
          tooltip: (() => {
            if (isProjectRunning) {
              return "Can't generate Home Energy Assessment while results are being generated";
            }
            if (!resultsExistForSelectedDwelling) {
              return "Scenario results are missing for your selection, run the project to generate results";
            }
            return "Generate Home Energy Assessment";
          })(),
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          onClick: (_event: Event, data: Array<Dwelling> | Dwelling) => {
            handleCreateOrGetHomeEnergyAssessment(
              Array.isArray(data) ? data[0] : data
            );
          }
        },
        {
          icon: tafIcon,
          disabled: isProjectRunning || !resultsExistForSelectedDwelling,
          hidden: selectedDwellings.length > 1,
          tooltip: (() => {
            if (isProjectRunning) {
              return "Can't generate Technical Assessment Form while results are being generated";
            }
            if (!resultsExistForSelectedDwelling) {
              return "Scenario results are missing for your selection, run the project to generate results";
            }
            return "Generate Technical Assessment Form";
          })(),
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          onClick: (_event: Event, _data: Array<Dwelling> | Dwelling) => {
            handleCreateTechnicalAssessmentForm();
          }
        },
        {
          icon: removeDwellingIcon,
          disabled: isProjectRunning,
          tooltip: (() => {
            if (isProjectRunning) {
              return "Can't remove dwellings while results are being generated";
            }
            return "Remove dwelling(s) from project";
          })(),
          onClick: () => {
            setConfirmationDialogOpen(true);
          }
        }
      ];
    },
    [
      handleCreateOrGetHomeEnergyAssessment,
      handleCreateTechnicalAssessmentForm,
      isProjectRunning,
      onClickGeneratePDFReport,
      onClickScenarioComparison,
      projectScenarios,
      resultsExistForSelectedDwelling,
      scenarioRecommendations,
      selectedDwellings,
      setShowAddDwellingsModal,
      setShowCompareScenariosModal,
      theme.retrokitPalette.green.dark
    ]
  );

  const tableColumns: Array<Column<Dwelling>> = useMemo(
    () => [
      ...DWELLING_TABLE_COLUMNS,
      {
        title: "Type",
        field: "type",
        align: "center",
        width: 50,
        cellStyle: {
          padding: "0 16px 0 16px"
        },
        render: (rowData) => {
          if (rowData.type === "individual") {
            return <Tooltip
                title="Individual heating scheme"
                arrow
                TransitionComponent={Zoom}
            >
              <Avatar
                  sx={{
                    backgroundColor: "#FFFFFF",
                    width: "36px",
                    height: "36px"
                  }}
                  aria-label="avatar-individual"
              >
                <PersonIcon style={{ color: theme.retrokitPalette.indigo.main }} />
              </Avatar>
            </Tooltip>;
          }
          return <Tooltip
              title="Group heating scheme"
              arrow
              TransitionComponent={Zoom}
          >
            <Avatar
                sx={{
                  backgroundColor: theme.retrokitPalette.indigo.main,
                  width: "36px",
                  height: "36px"
                }}
                aria-label="avatar-group"
            >
              <GroupsIcon style={{ color: "#FFFFFF" }} />
            </Avatar>
          </Tooltip>;
        }
      },
      {
        title: "Rating",
        field: "id",
        width: 60,
        cellStyle: {
          textAlign: "center",
          padding: 0
        },
        render: (rowData) => <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <BERChevron
            rating={rowData.performance.rating}
          />
        </Box>
      },
      {
        title: "Scenario",
        field: "id",
        width: 220,
        cellStyle: {
          textAlign: "center",
          padding: 0
        },
        render: (rowData) => {

          // NOTE: DO NOT REMOVE THE typeof CHECK FROM HERE OR BAD THINGS HAPPEN!
          if (typeof scenarioRecommendations !== "string" && scenarioRecommendations.length > 0) {
            const recommendedScenario = scenarioRecommendations.find(
              recommendation => recommendation.dwelling === rowData.id && recommendation.dwelling_type === rowData.type
            );

            return <Tooltip
              title="Recommended scenario"
              arrow
              TransitionComponent={Zoom}
            >
              <Select
                labelId="recommended-scenario-select-label"
                id="recommended-scenario-select"
                value={
                  recommendedScenario?.scenario
                }
                label=""
                onChange={(event) => {
                  handleUpdateScenarioRecommendation(
                    parseInt(event.target.value as string, 10),
                    rowData.id,
                      rowData.type
                  );
                }}
                inputProps={{
                  sx: {
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    paddingLeft: "14px",
                    paddingRight: "14px",
                    display: "flex"
                  }
                }}
                variant="outlined"
                sx={{
                  width: "200px",
                  backgroundColor: "#FFFFFF"
                }}
              >
                {
                  projectScenarios.map((projectScenario) => {

                    const scenarioPerformance = projectScenario.related_performances.find(
                      performance => performance.dwelling === rowData.id
                    );
                    if (scenarioPerformance !== undefined) {
                      return <MenuItem
                        key={projectScenario.id}
                        value={projectScenario.id}
                        sx={{
                          display: "flex",
                          flexDirection: "row"
                        }}
                      >
                        <Typography
                          noWrap
                          variant="body1"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flex: 1,
                            marginRight: "8px",
                            maxWidth: "82px"
                          }}
                        >
                          {projectScenario.title}
                        </Typography>

                        <BERChevron
                          rating={scenarioPerformance.rating}
                        />

                      </MenuItem>;
                    }
                    return <MenuItem
                      key={recommendedScenario?.scenario}
                      value={recommendedScenario?.scenario}
                    >

                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: 14
                        }}
                      >
                        Run the project
                      </Typography>
                    </MenuItem>;
                  })
                }
              </Select>
            </Tooltip>;
          }
          return <Tooltip
            title="Recommended scenario"
            arrow
            TransitionComponent={Zoom}
          >
            <Select
              labelId="recommended-scenario-select-label"
              id="recommended-scenario-select"
              label=""
              onChange={(event) => {
                handleUpdateScenarioRecommendation(
                  parseInt(event.target.value as string, 10),
                  rowData.id,
                    rowData.type
                );
              }}
              variant="outlined"
              sx={{
                width: "200px",
                backgroundColor: "#FFFFFF"
              }}
            >
              <MenuItem
                key={0}
                value={0}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: 14
                  }}
                >
                  Run the project
                </Typography>
              </MenuItem>
            </Select>
          </Tooltip>;
        }
      }
    ],
    [
      handleUpdateScenarioRecommendation,
      projectScenarios,
      scenarioRecommendations
    ]
  );

  const TablePaperComponent = useCallback(
    (
      props: JSX.IntrinsicAttributes & PaperProps
    ) => <Paper
      elevation={0}
      sx={{
        borderWidth: "0px",
        borderRadius: "20px"
      }}
      {...props}
    />,
    []
  );

  return <Box
    sx={{
      height: "100%",
      width: "100%",
      padding: 0,
      margin: 0
    }}
  >
    <MaterialTable
      title={`Dwellings (${dwellings.length})`}
      actions={tableActions}
      columns={tableColumns}
      components={{
        Container: TablePaperComponent
      }}
      data={dwellings}
      isLoading={isLoading}
      localization={{
        toolbar: {
          nRowsSelected: "{0} dwelling(s) selected"
        },
        body: {
          emptyDataSourceMessage: "No dwellings to display",
          filterRow: {
            filterTooltip: "Filter"
          }
        }
      }}
      options={{
        actionsColumnIndex: -1,
        // fixedColumns: {
        //   //left: 2
        //   right: 1
        // },
        headerStyle: {
          backgroundColor: colors.blue["500"],
          color: theme.palette.common.white,
          fontWeight: "bold",
          padding: "0 16px 0 16px"
        },
        padding: "dense",
        paging: true,
        pageSize: dwellings.length > 0 && dwellings.length < 5 ?
          dwellings.length : 5,
        pageSizeOptions: dwellings.length > 0 && dwellings.length < 5 ?
          [dwellings.length] : [5, 10, 20, 50],
        rowStyle: (rowData, index) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (rowData.tableData.checked === true) {
            return {
              backgroundColor: "#fce4ec",
              color: theme.palette.secondary.main,
              fontWeight: "bold",
              fontSize: 14
            };
          }
          if (index % 2) {
            return {
              backgroundColor: colors.indigo["100"],
              fontSize: 14
            };
          }
          return {
            backgroundColor: colors.indigo["50"],
            fontSize: 14
          };
        },
        selection: true,
        showTitle: true,
        tableLayout: "fixed"
      }}
      onSelectionChange={
        (rows) => {
          setSelectedDwellings(rows);
          if (rows.length === 1) {

            const scenarioResultsExistForSelectedDwelling = projectScenarios.every(
              (scenario) => {
                const relatedPerformanceIndex = scenario.related_performances.findIndex(

                  (scenarioPerformance) => scenarioPerformance.dwelling === rows[0].id && scenarioPerformance.dwelling_type === rows[0].type);
                return relatedPerformanceIndex !== -1;
              }
            );

            setResultsExistForSelectedDwelling(scenarioResultsExistForSelectedDwelling);
          } else {
            setResultsExistForSelectedDwelling(false);
          }

        }
      }
    />

    {
      selectedDwellings.length === 1 &&
      recommendedScenarioForDwelling !== undefined &&
      projectCostsDialogOpen ?
        <ProjectCostsDialog
          onClose={handleCloseProjectCostsDialog}
          open={projectCostsDialogOpen}
          projectId={projectId}
          dwelling={selectedDwellings[0]}
          scenario={recommendedScenarioForDwelling}
        /> : null
    }


    <ConfirmationDialog
      message={
        selectedDwellings.length > 1 ?
          "This will delete all results for the selected dwellings" :
          "This will delete all results for this dwelling"
      }
      title={
        selectedDwellings.length > 1 ?
          "Confirm remove dwellings" :
          "Confirm remove dwelling"
      }
      onClose={handleCloseConfirmationDialog}
      onSubmit={
        () => {
          onRemoveDwellings(selectedDwellings);
          setSelectedDwellings([]);
          handleCloseConfirmationDialog();
        }
      }
      open={confirmationDialogOpen}
    />
  </Box>;
}
import { useEffect, useState } from "react";

import {
  useGetProjectReportConfigQuery,
  useUpdateProjectReportConfigMutation
} from "../api/projectReportConfigApi";

export function useProjectReportConfig(projectId: number) {

  const [
    isProjectReportConfigLoading,
    setIsProjectReportConfigLoading
  ] = useState(false);

  const {
    isLoading: isGetReportConfigLoading,
    data: reportConfig
  } = useGetProjectReportConfigQuery({
    project_id: projectId
  });

  const [
    updateProjectReportConfig
  ] = useUpdateProjectReportConfigMutation();

  useEffect(() => {
    setIsProjectReportConfigLoading(
      isGetReportConfigLoading
    );
  }, [
    isGetReportConfigLoading
  ]);

  return {
    isProjectReportConfigLoading,
    reportConfig,
    updateProjectReportConfig
  };
}

import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import type { RootState } from 'store';

export const appBaseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_BASE_URL ?? ''}/api/v1/`,
  prepareHeaders: (headers, { getState }) => {
    // TODO: Proper typing after refactoring authenticate state
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const token = (getState() as RootState).authentication.token as
      | string
      | undefined;

    if (token) {
      headers.set('Authorization', `Token ${token}`);
    }

    return headers;
  },
});

import {
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useGetProjectsQuery
} from "features/projects/api/projectsApi";

export function useProjectsPage() {
  // TODO: Change this after UI supports pagination
  const {
    isLoading: isProjectsLoading,
    data
  } = useGetProjectsQuery({ size: 9999 });

  const [ createProject ] = useCreateProjectMutation();

  const [ deleteProject ] = useDeleteProjectMutation();

  return {
    isProjectsLoading,
    projects: data?.results,
    createProject,
    deleteProject
  };
}

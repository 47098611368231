import React from 'react';

import dateFormat from "dateformat";

import Checkbox from '@mui/material/Checkbox';
import { green, red } from '@mui/material/colors';


export const DWELLING_FILTER_CHECKBOXES = [
  {
    id: 0,
    name: 'check-box-pre-1971',
    key: 'checkBoxPre1971',
    label: '< 1971',
    category: 'age',
    checked: false,
  },
  {
    id: 1,
    name: 'check-box-1971-1990',
    key: 'checkBox1971To1990',
    label: '1971 - 1990',
    category: 'age',
    checked: false,
  },
  {
    id: 2,
    name: 'check-box-1991-2000',
    key: 'checkBox1991To2000',
    label: '1991 - 2000',
    category: 'age',
    checked: false,
  },
  {
    id: 3,
    name: 'check-box-2001-2010',
    key: 'checkBox2001To2010',
    label: '2001 - 2010',
    category: 'age',
    checked: false,
  },
  {
    id: 4,
    name: 'check-box-post-2011',
    key: 'checkBoxPost2011',
    label: '> 2011',
    category: 'age',
    checked: false,
  },
  {
    id: 5,
    name: 'check-box-a1',
    key: 'checkBoxA1',
    label: 'A1',
    category: 'ber',
    checked: false,
  },
  {
    id: 6,
    name: 'check-box-a2',
    key: 'checkBoxA2',
    label: 'A2',
    category: 'ber',
    checked: false,
  },
  {
    id: 7,
    name: 'check-box-a3',
    key: 'checkBoxA3',
    label: 'A3',
    category: 'ber',
    checked: false,
  },
  {
    id: 8,
    name: 'check-box-b1',
    key: 'checkBoxB1',
    label: 'B1',
    category: 'ber',
    checked: false,
  },
  {
    id: 9,
    name: 'check-box-b2',
    key: 'checkBoxB2',
    label: 'B2',
    category: 'ber',
    checked: false,
  },
  {
    id: 10,
    name: 'check-box-b3',
    key: 'checkBoxB3',
    label: 'B3',
    category: 'ber',
    checked: false,
  },
  {
    id: 11,
    name: 'check-box-c1',
    key: 'checkBoxC1',
    label: 'C1',
    category: 'ber',
    checked: false,
  },
  {
    id: 12,
    name: 'check-box-c2',
    key: 'checkBoxC2',
    label: 'C2',
    category: 'ber',
    checked: false,
  },
  {
    id: 13,
    name: 'check-box-c3',
    key: 'checkBoxC3',
    label: 'C3',
    category: 'ber',
    checked: false,
  },
  {
    id: 14,
    name: 'check-box-d1',
    key: 'checkBoxD1',
    label: 'D1',
    category: 'ber',
    checked: false,
  },
  {
    id: 15,
    name: 'check-box-d2',
    key: 'checkBoxD2',
    label: 'D2',
    category: 'ber',
    checked: false,
  },
  {
    id: 16,
    name: 'check-box-e1',
    key: 'checkBoxE1',
    label: 'E1',
    category: 'ber',
    checked: false,
  },
  {
    id: 17,
    name: 'check-box-e2',
    key: 'checkBoxE2',
    label: 'E2',
    category: 'ber',
    checked: false,
  },
  {
    id: 18,
    name: 'check-box-f',
    key: 'checkBoxF',
    label: 'F',
    category: 'ber',
    checked: false,
  },
  {
    id: 19,
    name: 'check-box-g',
    key: 'checkBoxG',
    label: 'G',
    category: 'ber',
    checked: false,
  },
  {
    id: 20,
    name: 'check-box-timber',
    key: 'checkBoxTimber',
    label: 'Timber',
    category: 'construction',
    checked: false,
  },
  {
    id: 21,
    name: 'check-box-solid-or-hollow',
    key: 'checkBoxSolidOrHollow',
    label: 'Solid/Hollow',
    category: 'construction',
    checked: false,
  },
  {
    id: 22,
    name: 'check-box-cavity',
    key: 'checkBoxCavity',
    label: 'Cavity',
    category: 'construction',
    checked: false,
  },
  {
    id: 23,
    name: 'check-box-electricity',
    key: 'checkBoxElectricity',
    label: 'Electricity',
    category: 'fuel',
    checked: false,
  },
  {
    id: 24,
    name: 'check-box-gas',
    key: 'checkBoxGas',
    label: 'Gas',
    category: 'fuel',
    checked: false,
  },
  {
    id: 25,
    name: 'check-box-group-heating',
    key: 'checkBoxGroupHeating',
    label: 'Group heating',
    category: 'fuel',
    checked: false,
  },
  {
    id: 26,
    name: 'check-box-heating-oil',
    key: 'checkBoxHeatingOil',
    label: 'Heating oil',
    category: 'fuel',
    checked: false,
  },
  {
    id: 27,
    name: 'check-box-solid',
    key: 'checkBoxSolid',
    label: 'Solid',
    category: 'fuel',
    checked: false,
  },
  {
    id: 28,
    name: 'check-box-apartment',
    key: 'checkBoxApartment',
    label: 'Apartment',
    category: 'type',
    checked: false,
  },
  {
    id: 29,
    name: 'check-box-detached',
    key: 'checkBoxDetached',
    label: 'Detached',
    category: 'type',
    checked: false,
  },
  {
    id: 30,
    name: 'check-box-semi-detached',
    key: 'checkBoxSemiDetached',
    label: 'Semi-detached',
    category: 'type',
    checked: false,
  },
  {
    id: 31,
    name: 'check-box-terraced',
    key: 'checkBoxTerraced',
    label: 'Terraced',
    category: 'type',
    checked: false,
  },
];

export const DWELLING_VIEW_CHECKBOXES = [
  {
    id: 0,
    name: 'check-box-average',
    key: 'checkBoxAverage',
    label: 'Average',
    category: 'global',
    checked: false,
  },
  {
    id: 1,
    name: 'check-box-total',
    key: 'checkBoxTotal',
    label: 'Total',
    category: 'global',
    checked: true,
  },
  {
    id: 2,
    name: 'check-box-age-group',
    key: 'checkBoxAgeGroup',
    label: 'Age group',
    category: 'kpi',
    checked: true,
  },
  {
    id: 3,
    name: 'check-box-dwelling-type',
    key: 'checkBoxDwellingType',
    label: 'Dwelling type',
    category: 'kpi',
    checked: false,
  },
  {
    id: 4,
    name: 'check-box-fuel-type',
    key: 'checkBoxFuelType',
    label: 'Fuel type',
    category: 'kpi',
    checked: false,
  },
];

/*
function checkSelected(dwelling) {
    if (dwelling.tableData) {
        dwelling.tableData.checked = true;
    } else {
        dwelling.tableData = {checked: true}
    }
}



const sortByAddressLine1 = {
    ascending: (dwellingA, dwellingB) => {
        console.log("sortByAddressLine1: dwellingA, dwellingB = ", dwellingA, dwellingB)
        return dwellingA.properties.address_line1.toLowerCase() > dwellingB.properties.address_line1.toLowerCase()
    },
    descending: (dwellingA, dwellingB) => {
        console.log("sortByAddressLine1: dwellingB, dwellingA = ", dwellingA, dwellingB)
        return dwellingB.properties.address_line1.toLowerCase() < dwellingA.properties.address_line1.toLowerCase()
    }
}
*/

export const DASHBOARD_TABLE_COLUMNS = [
  // {title: "id", field: "id", hidden: true},
  {
    id: 0,
    title: 'Street',
    // customSort: sortByAddressLine1.ascending,
    field: 'properties.address_line1',
    removable: true,
    hidden: false,
    hiddenByColumnsButton: true,
    align: 'left',
    render: (rowData) => rowData.properties.address_line1 !== 'Unknown' &&
        rowData.properties.address_line1 !== 'None'
        ? rowData.properties.address_line1
        : '',
  },
  {
    id: 1,
    title: 'Area',
    field: 'properties.address_line2',
    removable: true,
    hidden: false,
    hiddenByColumnsButton: true,
    align: 'left',
    render: (rowData) => rowData.properties.address_line2 !== 'Unknown' &&
        rowData.properties.address_line2 !== 'None'
        ? rowData.properties.address_line2
        : '',
  },
  {
    id: 2,
    title: 'Town/City',
    field: 'properties.address_line3',
    removable: true,
    hidden: false,
    hiddenByColumnsButton: true,
    align: 'left',
    render: (rowData) => rowData.properties.address_line3 !== 'Unknown' &&
        rowData.properties.address_line3 !== 'None'
        ? rowData.properties.address_line3
        : '',
  },
  {
    id: 3,
    title: 'County',
    field: 'properties.county',
    removable: true,
    hidden: false,
    hiddenByColumnsButton: true,
    align: 'left',
  },
  {
    id: 4,
    title: 'Eircode',
    field: 'properties.postcode',
    removable: true,
    hidden: false,
    hiddenByColumnsButton: true,
    align: 'left',
    render: (rowData) => rowData.properties.postcode !== 'Unknown' &&
        rowData.properties.postcode !== 'None'
        ? rowData.properties.postcode
        : '',
  },
  {
    id: 5,
    title: 'BER #',
    field: 'properties.ber_number',
    removable: true,
    hidden: false,
    hiddenByColumnsButton: true,
    align: 'right',
  },
  {
    id: 6,
    title: 'MPRN',
    field: 'properties.mprn_reference',
    removable: true,
    hidden: false,
    hiddenByColumnsButton: true,
    align: 'right',
  },
  {
    id: 7,
    title: 'Constructed',
    field: 'properties.date_of_construction',
    removable: true,
    hidden: false,
    hiddenByColumnsButton: true,
    align: 'right',
    render: (rowData) => {
      let dateParts = ['1900', '01', '01'];
      if (rowData.properties.date_of_construction) {
        dateParts = rowData.properties.date_of_construction.split('-');
      }
      return rowData
        ? dateFormat(
            new Date(
              Number(dateParts[0]),
              Number(dateParts[1]) - 1,
              Number(dateParts[2])
            ),
            'yyyy'
          )
        : '';
    },
  },
  {
    id: 8,
    title: 'Living Area (m²)',
    field: 'properties.dimension.living_area',
    removable: true,
    hidden: false,
    hiddenByColumnsButton: true,
    align: 'right',
    render: (rowData) => `${parseFloat(rowData.properties.dimension.living_area).toFixed(
        1
      )}`,
  },
  {
    id: 9,
    title: 'Total Floor Area (m²)',
    field: 'properties.dimension.total_floor_area',
    removable: true,
    hidden: false,
    hiddenByColumnsButton: true,
    align: 'right',
    render: (rowData) => `${parseFloat(
        rowData.properties.dimension.total_floor_area
      ).toFixed(1)}`,
  },
  {
    id: 10,
    title: 'Storeys',
    field: 'properties.dimension.no_of_storeys',
    removable: true,
    hidden: false,
    hiddenByColumnsButton: true,
    align: 'right',
  },
  {
    id: 11,
    title: 'Age Band',
    field: 'properties.latest_archetype.age_band',
    hidden: false,
    hiddenByColumnsButton: true,
    align: 'left',
  },
  {
    id: 12,
    title: 'Wall Type',
    field: 'properties.latest_archetype.main_wall_type',
    hidden: false,
    hiddenByColumnsButton: true,
    align: 'center',
  },
  {
    id: 13,
    title: 'Dwelling Type',
    field: 'properties.latest_archetype.dwelling_type',
    hidden: false,
    hiddenByColumnsButton: true,
    align: 'center',
  },
  {
    id: 14,
    title: 'Main Fuel',
    field: 'properties.latest_archetype.main_fuel',
    hidden: false,
    hiddenByColumnsButton: true,
    align: 'center',
  },
  {
    id: 15,
    title: 'Energy Rating',
    field: 'properties.latest_performance.rating',
    hidden: false,
    hiddenByColumnsButton: true,
    align: 'center',
  },
  {
    id: 16,
    title: 'Energy Cost (€)',
    field: 'properties.latest_performance.energy_cost',
    align: 'center',
    hidden: false,
    hiddenByColumnsButton: true,
    render: (rowData) => `€${parseFloat(
        rowData.properties.latest_performance.energy_cost
      ).toFixed(2)}`,
  },
  {
    id: 17,
    title: 'Energy Delivered (kWh)',
    field: 'properties.latest_performance.total_de',
    align: 'center',
    hidden: false,
    hiddenByColumnsButton: true,
    render: (rowData) => `${parseFloat(
        rowData.properties.latest_performance.total_de
      ).toFixed(2)}`,
  },
  {
    id: 18,
    title: 'CO₂ (kg)',
    field: 'properties.latest_performance.total_co2',
    align: 'center',
    hidden: false,
    hiddenByColumnsButton: true,
    render: (rowData) => `${parseFloat(
        rowData.properties.latest_performance.total_co2
      ).toFixed(2)}`,
  },
  {
    id: 19,
    title: 'HLI',
    field: 'properties.latest_performance.heat_loss_parameter',
    align: 'center',
    hidden: false,
    hiddenByColumnsButton: true,
    render: (rowData) => `${parseFloat(
        rowData.properties.latest_performance.heat_loss_parameter
      ).toFixed(2)}`,
  },
  {
    id: 20,
    title: 'HP Ready',
    field: 'properties.latest_performance.heat_pump_readiness',
    align: 'center',
    hidden: false,
    hiddenByColumnsButton: true,
    render: (rowData) => (
      <Checkbox
        color="default"
        sx={{
          color: red[400],
          '&$checked': {
            color: green[600],
          },
        }}
        checked={rowData.properties.latest_performance.heat_pump_readiness}
      />
    ),
  },
];

export const UPLOADS_TABLE_COLUMNS = [
  {
    title: 'Filename',
    field: 'filename',
    removable: false,
    align: 'left',
  },
  {
    title: 'Uploaded',
    field: 'uploaded_on',
    removable: false,
    align: 'right',
    render: (rowData) => rowData.uploaded_on
        ? dateFormat(
            new Date(rowData.uploaded_on * 1000),
            'dS mmmm yyyy, h:MM:ss TT'
          )
        : 'Unknown date format',
  },
];

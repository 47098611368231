import Cookies from 'js-cookie';

let csrf_token = Cookies.get('csrftoken');

export const REGISTRATION_SUCCESSFUL = '@@auth/REGISTRATION_SUCCESSFUL';
export const REGISTRATION_FAILED = '@@auth/REGISTRATION_FAILED';

export const LOGIN_SUCCESSFUL = '@@auth/LOGIN_SUCCESSFUL';
export const LOGIN_FAILED = '@@auth/LOGIN_FAILED';
export const LOGOUT_SUCCESSFUL = '@@auth/LOGOUT_SUCCESSFUL';

export const AUTHENTICATION_ERROR = '@@auth/AUTHENTICATION_ERROR';

export const logUserIn = (username, password) => {
  return (dispatch, getState) => {
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': csrf_token,
    };
    let body = JSON.stringify({ username, password });

    return fetch(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/v1/auth/login/`,
      { headers, body, method: 'POST' }
    )
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          console.log('Server Error!');
          throw res;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: LOGIN_SUCCESSFUL, data: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({ type: AUTHENTICATION_ERROR, data: res.data });
          throw res.data;
        } else {
          dispatch({ type: LOGIN_FAILED, data: res.data });
          throw res.data;
        }
      });
  };
};

export const logUserOut = () => {
  return (dispatch, getState) => {
    const token = getState().authentication.token;

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }

    return fetch(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/v1/auth/logout/`,
      { headers, body: '', method: 'POST' }
    )
      .then((res) => {
        if (res.status === 200 || res.status === 204) {
          return { status: res.status, data: {} };
        } else if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          console.log('Server Error!');
          throw res;
        }
      })
      .then((res) => {
        if (res.status === 200 || res.status === 204) {
          dispatch({ type: LOGOUT_SUCCESSFUL });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({ type: AUTHENTICATION_ERROR, data: res.data });
          throw res.data;
        }
      });
  };
};

export const registerUser = (username, password) => {
  return (dispatch, getState) => {
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': csrf_token,
    };

    let body = JSON.stringify({ username, password });

    return fetch(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/v1/auth/register/`,
      { headers, body, method: 'POST' }
    )
      .then((res) => {
        if (res.status === 204) {
          return { status: res.status, data: {} };
        } else if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          console.log('Server Error!');
          throw res;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: REGISTRATION_SUCCESSFUL, data: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({ type: AUTHENTICATION_ERROR, data: res.data });
          throw res.data;
        } else {
          dispatch({ type: REGISTRATION_FAILED, data: res.data });
          throw res.data;
        }
      });
  };
};

export const getAuthToken = () => {
  return (dispatch, getState) => {
    if (getState) {
      const token = getState().authentication.token;

      if (token) {
        return dispatch({
          type: LOGIN_SUCCESSFUL,
          data: getState().authentication,
        });
      } else {
        return dispatch({ type: LOGOUT_SUCCESSFUL });
      }
    } else {
      return dispatch({ type: LOGOUT_SUCCESSFUL });
    }
  };
};

import type {
  ChangeEvent
} from "react";
import React, {
  useRef,
  useState,
  useEffect
} from "react";

import { enGB } from 'date-fns/locale/en-GB';

import InfoIcon from "@mui/icons-material/Info";
import { Select } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { blue, orange } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {
  DatePicker,
  LocalizationProvider
} from "@mui/x-date-pickers";
import {
  AdapterDateFns
} from "@mui/x-date-pickers/AdapterDateFnsV3";

import {
  CustomWidthTooltip
} from "common/components/CustomWidthTooltip";
import {
  DwellingAndScenarioBerGrades
} from "common/components/DwellingAndScenarioBerGrades";
import { 
  WhiteBackgroundTextField 
} from "common/components/WhiteBackgroundTextField";
import {
  floatKeyCheck,
  floatRegex
} from "common/utils/numbers";
import {
  TechnicalAssessmentFormHeader
} from "features/technicalAssessmentForm/dialogs/common/TechnicalAssessmentFormHeader";

import type {
  UpdateTAFApiRequest
} from "features/technicalAssessmentForm/api/types";
import type {
  TechnicalAssessmentForm
} from "features/technicalAssessmentForm/types";



interface TechnicalAssessmentFormModal2Props {
  isTafUpdating: boolean;
  setShowModal: (show: boolean) => void;
  setTafStep: (newStep: number) => void;
  showModal: boolean;
  taf: TechnicalAssessmentForm;
  tafStep: number;
  updateTAF: (variable: UpdateTAFApiRequest) => Promise<TechnicalAssessmentForm>;
}


export function TechnicalAssessmentFormModal2(
  {
    isTafUpdating,
    setShowModal,
    setTafStep,
    showModal,
    taf,
    tafStep,
    updateTAF
  }: TechnicalAssessmentFormModal2Props
) {

  // STATE VARIABLES
  const [
    technicalAdvisorConfirmation,
    setTechnicalAdvisorConfirmation
  ] = useState<boolean>(false);

  const [
    technicalAdvisorRecommendations,
    setTechnicalAdvisorRecommendations
  ] = useState<string>("");

  const [
    currentHli,
    setCurrentHli
  ] = useState<number>(0);

  const [
    projectedHli,
    setProjectedHli
  ] = useState<number>(0);

  const [
    technicalAdvisorSignatureFile,
    setTechnicalAdvisorSignatureFile
  ] = useState<File>();

  const [
    technicalAdvisorSignatureFileURL,
    setTechnicalAdvisorSignatureFileURL
  ] = useState<string | ArrayBuffer>();

  const [
    technicalAdvisorSignatureDate,
    setTechnicalAdvisorSignatureDate
  ] = useState<Date | null>(null);

  const [
    berReferenced,
    setBerReferenced
  ] = useState<string>("");

  useEffect(
    () => {
      setTechnicalAdvisorConfirmation(
        taf.technical_advisor_confirmation
      );
      setTechnicalAdvisorRecommendations(
        taf.technical_advisor_recommendations
      );
      setCurrentHli(
        Number(
          taf.dwelling_heat_loss_parameter.toFixed(2)
        )
      );
      setProjectedHli(
        Number(
          taf.scenario_heat_loss_parameter.toFixed(2)
        )
      );
      setTechnicalAdvisorSignatureDate(
        taf.technical_advisor_signature_date
      );
      setBerReferenced(
        String(
          taf.technical_advisor_ber_referenced
        )
      );
    },
    [
      taf.dwelling_heat_loss_parameter,
      taf.scenario_heat_loss_parameter,
      taf.technical_advisor_ber_referenced,
      taf.technical_advisor_confirmation,
      taf.technical_advisor_recommendations,
      taf.technical_advisor_signature_date
    ]
  );

  const closeModal = () => {
    setShowModal(false);
  };

  const handleUpdateTAF = (dialogNumber: number) => {
    let berRef = false;
    if (berReferenced === "true") {
      berRef = true;
    }

    const requestBody: UpdateTAFApiRequest = {
      technical_assessment_form_id: taf.id,
      technical_advisor_confirmation: technicalAdvisorConfirmation,
      technical_advisor_ber_referenced: berRef,
      dwelling_heat_loss_parameter: currentHli,
      scenario_heat_loss_parameter: projectedHli,
      technical_advisor_signature: technicalAdvisorSignatureFile,
      technical_advisor_recommendations: technicalAdvisorRecommendations
    };

    if (technicalAdvisorSignatureDate !== null) {
      // eslint-disable-next-line prefer-destructuring
      requestBody.technical_advisor_signature_date = technicalAdvisorSignatureDate.toISOString().split("T")[0];
    }

    updateTAF(
      requestBody
    ).then(
      (response) => {
        if (response) {
          setTafStep(dialogNumber);
        }
      }
    ).catch(
      (error) => console.log("Error while updating TAF:", error)
    );
  };

  const handleBackButtonClick = () => {
    handleUpdateTAF(1);
  };

  const handleNextButtonClick = () => {
    handleUpdateTAF(3);
  };

  const handleSliderChanged = (dialogNumber: number) => {
    handleUpdateTAF(dialogNumber);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  /* const handleSignatureClick = () => {
    if ("current" in inputRef) {
      inputRef.current?.click();
    }
  }; */

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    let fileObj = null;
    if ("files" in event.target) {
      fileObj = event.target.files && event.target?.files[0];
    }

    if (fileObj) {
      setTechnicalAdvisorSignatureFile(fileObj);
      const reader = new FileReader();
      reader.onload = e => {
        if (e.target) {
          if (e.target.result) {
            setTechnicalAdvisorSignatureFileURL(e.target.result);
          }
        }
      };
      reader.readAsDataURL(fileObj);
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTechnicalAdvisorConfirmation(event.target.checked);
  };

  const berReferenceOptions = [
    {
      "label": "Not set",
      "value": "null"
    },
    {
      "label": "a",
      "value": "false"
    },
    {
      "label": "b",
      "value": "true"
    }
  ];

  const contentTextBox = {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    gap: "8px"
  };

  const contentSectionHeaderText = {
    color: "#71717a"
  };

  const hliInputLabel = {
    display: "flex",
    alignItems: "center",
    width: "100px"
  };

  /* const homeOwnerSignatureImage = {
    height: "40px"
  }; */

  const iconSpacer = {
    width: "48px",
    paddingLeft: "12px"
  };

  const iconButton = {
    padding: "0px",
    paddingLeft: "12px"
  };

  const inputLabelHomeownerSignature = {
    display: "flex",
    alignItems: "center"
  };

  const labelAndInputBox = {
    display: "flex"
  };

  const sectionTitle = {
    marginTop: "8px"
  };

  return <LocalizationProvider
    dateAdapter={AdapterDateFns}
    adapterLocale={enGB}
  >
    <Dialog
      open={showModal}
      onClose={closeModal}
      maxWidth="lg"
      fullWidth
      scroll="paper"
      sx={{
        padding: "16px"
      }}
    >
      <TechnicalAssessmentFormHeader
        changeTafStep={handleSliderChanged}
        closeModal={closeModal}
        tafStep={tafStep}
      />

      <DialogContent
        sx={{
          padding: "0px 8px 0px 8px"
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "5px",
            backgroundColor: "#e0dede"
          }}
        >

          {/* SECTION TITLE */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "24px",
              paddingTop: "24px",
              paddingRight: "24px",
              paddingBottom: "16px"
            }}
          >
            <img
              alt="Icon title home"
              style={{
                width: "60px",
                paddingRight: "16px"
              }}
              src={`${process.env.REACT_APP_API_BASE_URL || ""}/static/public/images/housing_upgrade_plans/draw.svg`}
            />
            <Typography
              sx={{
                display: "flex",
                alignItems: "center"
              }}
              variant="h5"
            >
              Technical Advisor Declaration
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "8px",
              paddingRight: "8px",
              paddingBottom: "8px",
              gap: "8px"
            }}
          >

            {/* SECTION PUBLISHED BER */}

            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                borderRadius: "5px",
                border: 1,
                borderColor: "#71717a",
                padding: "0px 16px 16px 16px",
                gap: "8px"
              }}
            >
              <Box
                sx={contentTextBox}
              >
                <Box
                  sx={sectionTitle}
                >
                  <Typography
                    align="center"
                    variant="h5"
                    sx={contentSectionHeaderText}
                  >
                    Published BER
                  </Typography>

                </Box>

                <Box
                  sx={labelAndInputBox}
                >
                  <Typography
                    sx={inputLabelHomeownerSignature}
                  >
                    I confirm that the published BER
                    referenced on page 1 of this form
                    accurately reflects the current
                    condition of this dwelling*
                  </Typography>

                  <Checkbox
                    onChange={handleCheckboxChange}
                    checked={technicalAdvisorConfirmation}
                  />

                  <Box
                    sx={iconSpacer}
                  />

                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    BER referenced on page 1 of this form was
                    published by (a) me, or (b) another BER
                    Assessor*
                  </Typography>

                  <Select
                    sx={{
                      height: "fit-content",
                      marginLeft: "24px",
                      backgroundColor: "white",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      overflowWrap: "break-word",
                      width: "10rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}
                    inputProps={{
                      sx: {
                        padding: "8px"
                      }
                    }}
                    value={berReferenced}
                    label=""
                    onChange={
                      (e) => {
                        setBerReferenced(e.target.value);
                      }
                    }
                    required
                    variant="outlined"
                  >
                    {
                      berReferenceOptions.map(
                        (heatingOption) => <MenuItem
                          value={heatingOption.value}
                          key={heatingOption.label}
                        >
                          {heatingOption.label}
                        </MenuItem>
                      )
                    }
                  </Select>

                  <Box
                    sx={iconSpacer}
                  />

                </Box>

                {/* SECTION RESPONSIBILITIES */}
                <Box
                  sx={{
                    display: "flex",
                    marginTop: "8px",
                    justifyContent: "center"
                  }}
                >
                  <Typography
                    align="center"
                    variant="h5"
                    sx={contentSectionHeaderText}
                  >
                    Responsibilities
                  </Typography>

                  <CustomWidthTooltip
                    title={
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: 12
                        }}
                      >
                        As part of this Technical Assessment, I have:<br />
                        &#8226; ensured all information in this form is correct and
                        have adhered to SEAI&#39;s guidance and requirements
                        for Technical Advisors.<br />
                        &#8226; advised the homeowner on the current level of
                        thermal performance of the dwelling and on the
                        level required to qualify for the heat pump system
                        grants offered by the SEAI Better Energy Programme.<br />
                        &#8226; advised the homeowner that the heat pump system
                        may need a different heat distribution system
                        than the current one and that the heat pump
                        contractor must carry out a whole system design.<br />
                        &#8226; advised the homeowner in relation to the energy
                        performance of their home and discussed possible
                        improvement to the fabric, ventilation, heating
                        and hot water systems, lighting and operational
                        aspects.<br />
                        &#8226; adhered to the independence requirements for
                        Technical Advisors in carrying out a Technical
                        Assessment of this dwelling<br />
                        <br />
                        If the current building Heat Loss Indicator is higher
                        than 2.00, I have:<br />
                        &#8226; discussed possible fabric improvement options
                        with the homeowner and taking into account
                        technical aspects, cost and homeowner&#39;s preference,
                        I have recommended the most suitable solution
                        as proposed works. I have provided the homeowner
                        with a report explaining different options to
                        help them select the best approach to meeting the HLI.<br />
                        &#8226; ensured the proposed works are compatible with
                        the requirements for wall and roof insulation grants if
                        applicable, or otherwise clarified this with the homeowner.<br />
                        &#8226; specified and detailed the fabric performance
                        corresponding to the proposed works and calculated the
                        corresponding Heat Loss Indicator<br />
                        &#8226; advised the homeowner on how to clarify
                        the recommended works with the relevant contractors<br />
                        &#8226; advised the homeowner that they need to ask
                        contractors to provide suitable evidence of the works
                        carried out and that they must keep a record of it in
                        order for the works to be reflected in the post-BER<br />
                        &#8226; advised the homeowner that in order to qualify
                        for the heat pump grant:<br />
                        &emsp;&#8226; The insulation and window works must
                        achieve the required improvement in the level of
                        thermal performance<br />
                        &emsp;&#8226; The post-BER must reflect all the works
                        carried out<br />
                      </Typography>
                    }
                  >
                    <IconButton
                      sx={iconButton}
                    >
                      <InfoIcon
                        sx={{
                          color: blue[500]
                        }}
                      />
                    </IconButton>
                  </CustomWidthTooltip>
                </Box>
                <Box
                  sx={labelAndInputBox}
                >
                  <Typography
                    sx={inputLabelHomeownerSignature}
                  >
                    Signature
                  </Typography>

                  <input
                    style={{ display: "none" }}
                    ref={inputRef}
                    type="file"
                    onChange={handleFileChange}
                  />

                  {/* TEMPORARY */}
                  <Tooltip
                    title={
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: 12
                        }}
                      >
                        Add the advisor signature to the Technical
                        Assessment Form Excel file downloaded at the
                        end of this wizard
                      </Typography>
                    }
                  >

                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        marginLeft: "24px"
                      }}
                    >

                      <IconButton>
                        <InfoIcon
                          sx={{
                            color: orange[500]
                          }}
                        />
                      </IconButton>

                      {/* <img
                        src={SignaturePlaceholder}
                        alt="Signature Placeholder"
                        style={{
                        height: "40px",
                        width: "60px"
                      }}
                        /> */}

                    </Box>

                  </Tooltip>

                  {/* {
                    taf.home_owner_signature || technicalAdvisorSignatureFileURL ?
                      <Box
                        sx={{
                          flex: 1,
                          marginLeft: "16px",
                          display: "flex",
                          justifyContent: "center"
                        }}
                        onClick={handleSignatureClick}
                      >
                        {technicalAdvisorSignatureFileURL ?
                          <img
                            alt="home owner signature file"
                            style={homeOwnerSignatureImage}
                            src={typeof technicalAdvisorSignatureFileURL === "string" ? technicalAdvisorSignatureFileURL : ""}
                          />
                          : <img
                            alt="home owner signature"
                            style={homeOwnerSignatureImage}
                            src={taf.home_owner_signature}
                          />
                        }

                      </Box> :
                      <Button
                        onClick={handleSignatureClick}
                        variant="contained"
                        color="primary"
                        sx={{
                          marginLeft: "24px"
                        }}
                      >
                        Select signature file
                      </Button>
                  } */}

                  <Box
                    sx={iconSpacer}
                  />

                </Box>

                <Box
                  sx={labelAndInputBox}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "70px"
                    }}
                  >
                    Date
                  </Typography>

                  <DatePicker
                    sx={{
                      flex: 1,
                      marginLeft: "24px",
                      ".MuiInputBase-root": {
                        backgroundColor: "#FFFFFF"
                      }
                    }}
                    value={technicalAdvisorSignatureDate}
                    onChange={
                      (value) => {
                        setTechnicalAdvisorSignatureDate(
                          value
                        );
                      }
                    }
                  />

                  <Tooltip
                    title={
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: 12
                        }}
                      >
                        I confirm that the above information is true
                        and correct. As a Technical Advisor,
                        registered with SEAI, I abide by SEAI&#39;s
                        requirements for Technical Advisors as
                        detailed on www.seai.ie
                      </Typography>
                    }
                  >
                    <IconButton
                      sx={iconButton}
                    >
                      <InfoIcon
                        sx={{
                          color: blue[500]
                        }}
                      />

                    </IconButton>

                  </Tooltip>

                </Box>

              </Box>
            </Box>


            {/* SECTION HEAT LOSS INDICATOR */}
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                borderRadius: "5px",
                border: 1,
                borderColor: "#71717a",
                padding: "16px",
                gap: "8px"
              }}
            >
              <Box
                sx={contentTextBox}
              >

                <Box
                  sx={sectionTitle}
                >

                  <Typography
                    align="center"
                    variant="h5"
                    sx={contentSectionHeaderText}
                  >
                    Heat Loss Indicator
                  </Typography>

                </Box>

                <Box
                  sx={labelAndInputBox}
                >

                  <Typography
                    sx={hliInputLabel}
                  >
                    Current HLI
                  </Typography>


                  <WhiteBackgroundTextField
                    type="number"
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment
                        position="start"
                      >
                        Watts/m&#178;/K
                      </InputAdornment>,
                      inputProps: {
                        min: 0,
                        step: "0.01"
                      }
                    }}
                    onChange={
                      (event) => {
                        if (
                          event.target.value === "" ||
                          floatRegex.test(event.target.value)
                        ) {
                          setCurrentHli(
                            Number(
                              event.target.value
                            )
                          );
                        }
                      }
                    }
                    onKeyDown={floatKeyCheck}
                    value={currentHli}
                    sx={{
                      flex: 1,
                      marginLeft: "24px"
                    }}
                  />

                </Box>

                <Box
                  sx={labelAndInputBox}
                >
                  <Typography
                    sx={hliInputLabel}
                  >
                    Projected HLI
                  </Typography>

                  <WhiteBackgroundTextField
                    type="number"
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment
                        position="start"
                      >
                        Watts/m&#178;/K
                      </InputAdornment>,
                      inputProps: {
                        min: 0,
                        step: "0.01"
                      }
                    }}
                    onChange={
                      (event) => {
                        if (
                          event.target.value === "" ||
                          floatRegex.test(event.target.value)
                        ) {
                          setProjectedHli(
                            Number(
                              event.target.value
                            )
                          );
                        }
                      }
                    }
                    onKeyDown={floatKeyCheck}
                    value={projectedHli}
                    sx={{
                      flex: 1,
                      marginLeft: "24px"
                    }}
                  />

                </Box>

              </Box>
            </Box>

          </Box>

          {/* SECTION GENERAL COMMENTARY */}

          <Box
            sx={{
              paddingLeft: "8px",
              paddingRight: "8px",
              paddingBottom: "8px"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "5px",
                border: 1,
                borderColor: "#71717a",
                padding: "0px 16px 16px 16px",
                gap: "8px"
              }}
            >
              <Box
                sx={sectionTitle}
              >

                <Typography
                  align="center"
                  variant="h5"
                  sx={contentSectionHeaderText}
                >
                  General Commentary and Recommendations
                </Typography>

              </Box>

              <Typography>
                Please include an outline of the Technical
                Assessment and resulting recommendations
                here. Any additional useful notes for the
                homeowners or contractors should be
                included here and/or in space for
                additional comments elsewhere in the form.
              </Typography>

              <TextField
                sx={{
                  flex: 1,
                  backgroundColor: "white",
                  width: "100%"
                }}
                multiline
                value={technicalAdvisorRecommendations}
                onChange={
                  event => setTechnicalAdvisorRecommendations(
                    event.target.value
                  )
                }
              />
            </Box>
          </Box>


        </Box>

      </DialogContent>

      <DialogActions>

        <DwellingAndScenarioBerGrades
          dwellingDetail={
            String(taf.dwelling_mprn_reference)
          }
          dwellingRating={taf.dwelling_rating}
          scenarioDetail={taf.scenario_title}
          scenarioRating={taf.scenario_rating}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleBackButtonClick}
        >
          Back
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleNextButtonClick}
        >
          Next
        </Button>

      </DialogActions>

    </Dialog>

  </LocalizationProvider>;
}
import { retrokitApi } from "common/api/retrokitApi";

import type { SeaiApiFetchRequest } from 'features/uploads/api/types';


export const uploadsApi = retrokitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchDwellingBerData: builder.mutation<void, SeaiApiFetchRequest>({
      query: (arg) => {
        const formData = new FormData();

        Object.entries(arg).forEach(
          ([key, value]: [
            string,
            SeaiApiFetchRequest[keyof SeaiApiFetchRequest]
          ]) => {
            if (typeof value === 'number') {
              formData.append(key, value.toString(10));
            } else {
              formData.append(key, value);
            }
          }
        );

        return {
          url: 'integrations/seai',
          method: 'POST',
          body: formData,
        };
      },
    }),
  }),
});

export const { useFetchDwellingBerDataMutation } = uploadsApi;

import React, { ChangeEvent } from "react";
import { useCallback, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

import type {
  CreateCustomScenarioApiRequest
} from "features/customScenarios/api/types";
import Paper, { PaperProps } from "@mui/material/Paper";


interface AddCustomModalProps {
  open: boolean;
  onClose: () => void;
  onCreateCustomScenario: (data: CreateCustomScenarioApiRequest) => void;
}

export function CreateCustomScenarioDialog(
  {
    open,
    onClose,
    onCreateCustomScenario
  }: AddCustomModalProps) {

  const handleOnClose = () => {
    onClose();
  };

  const [
    customScenarioTitle, setCustomScenarioTitle
  ] = useState<string>("Title");

  const [
    customScenarioDescription, setCustomScenarioDescription
  ] = useState<string>("Description");

  const handleCreateCustomScenarioClicked = useCallback(
    () => {
      if (customScenarioTitle !== "" && customScenarioDescription !== "") {
        onCreateCustomScenario({
          title: customScenarioTitle,
          description: customScenarioDescription
        });
      }
    },
    [
      customScenarioDescription,
      customScenarioTitle,
      onCreateCustomScenario
    ]
  );

  const handleFocus = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    event.target.focus();
    event.target.setSelectionRange(
      0,
      event.target.value.length
    );
  };

  const DialogPaperComponent = useCallback(
    (
      props: JSX.IntrinsicAttributes & PaperProps
    ) => <Paper
      elevation={0}
      sx={{
        borderWidth: "0px",
        borderRadius: "20px"
      }}
      variant="outlined"
      {...props}
    />,
    []
  );

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleOnClose}
      PaperComponent={DialogPaperComponent}
    >
      <DialogTitle>
        Add Scenario
      </DialogTitle>

      <DialogContent
        sx={{
          paddingBottom: "8px"
        }}
      >
        <TextField
          autoFocus
          defaultValue={customScenarioTitle}
          error={customScenarioTitle.length === 0}
          fullWidth
          helperText={
            customScenarioTitle.length === 0 ? "Title can't be blank" : ""
          }
          label="TITLE"
          margin="dense"
          onChange={
            (event) => setCustomScenarioTitle(event.target.value)
          }
          onFocus={
            (event) => handleFocus(event)
          }
          required
          variant="outlined"
        />

        <TextField
          fullWidth
          defaultValue={customScenarioDescription}
          error={customScenarioDescription.length === 0}
          helperText={
            customScenarioDescription.length === 0 ? "Description can't be blank" : ""
          }
          label="DESCRIPTION"
          multiline
          margin="dense"
          onChange={
            (event) => setCustomScenarioDescription(event.target.value)
          }
          onFocus={
            (event) => handleFocus(event)
          }
          required
          variant="outlined"
        />
      </DialogContent>

      <DialogActions
        sx={{
          paddingRight: "24px"
        }}
      >

        <Button
          onClick={handleOnClose}
          color="primary"
          variant="contained"
        >
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={
            customScenarioDescription.length === 0 ||
            customScenarioTitle.length === 0
          }
          onClick={
            () => handleCreateCustomScenarioClicked()
          }
          variant="contained"
        >
          Create
        </Button>

      </DialogActions>
    </Dialog>
  );
}

import { createApi } from '@reduxjs/toolkit/query/react'

import { appBaseQuery } from "common/api/appBaseQuery";

export const retroKitApiTags = [
  "CustomMeasures",
  "CustomMeasuresNotInCustomScenario",
  "CustomMeasuresNotInProjectScenario",
  'CustomScenario',
  "CustomScenarios",
  "CustomScenariosNotInProject",
  "DefaultMeasures",
  "DefaultMeasuresNotInCustomScenario",
  "DefaultMeasuresNotInProjectScenario",
  "DefaultScenario",
  "DefaultScenarios",
  "DefaultScenariosNotInProject",
  'Dwellings',
  'HomeEnergyAssessment',
  'HUP1',
  'HUP2',
  "ListHUP1",
  "ListHUP2",
  "MinimalProject",
  "Project",
  "ProjectDwellings",
  "Projects",
  "ProjectCosts",
  "ProjectReportConfig",
  "ProjectScenario",
  "ProjectScenarios",
  "ProjectScenariosNotInProject",
  "RunProject",
  "TechnicalAssessmentForm"
];

export const retrokitApi = createApi({
  baseQuery: appBaseQuery,
  reducerPath: 'retrokitApi',
  tagTypes: retroKitApiTags,
  endpoints: () => ({}),
})
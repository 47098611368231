import React from "react";

import { useDashboard } from "features/dashboard/hooks/useDashboard";


// eslint-disable-next-line react/display-name
export const withUseDashboard = (Component: any) => (props: any) => {
  const {
    createProject,
    creatingNewProject,
    newProject
  } = useDashboard();

  return <Component
    onCreateProject={createProject}
    isCreatingProject={creatingNewProject}
    newProject={newProject}
    {...props}
  />;
};
import { useEffect, useState } from "react";

import { useLazyRunProjectQuery } from "features/projects/api/projectsApi";


export function useRunProjectWebSocket() {

  const [
    isRunProjectFetching,
    setIsFetching
  ] = useState(false);

  const [
    runProjectResult,
    setRunProjectResult
  ] = useState<boolean | undefined>();

  const [
    runProjectTrigger,
    {
      data: runProjectQueryResult,
      isFetching: isRunProjectQueryFetching
    }
  ] = useLazyRunProjectQuery();

  useEffect(() => {
    setIsFetching(isRunProjectQueryFetching);
  }, [
    isRunProjectQueryFetching
  ]);

  // Updated project dwellings
  useEffect(() => {
    setRunProjectResult(
      runProjectQueryResult?.success ?? undefined
    );
  }, [
    runProjectQueryResult
  ]);

  return {
    isRunProjectFetching,
    runProjectResult,
    runProjectTrigger
  };
}

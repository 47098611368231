import React, { useEffect, useState } from "react";

import { Select } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import {
  DwellingAndScenarioBerGrades
} from "common/components/DwellingAndScenarioBerGrades";
import { 
  WhiteBackgroundTextField 
} from "common/components/WhiteBackgroundTextField";
import {
  structureTypeOptions,
  woodenGroundFloorOptions
} from "common/maps/dwelling";
import {
  floatKeyCheck,
  floatRegex,
  integerKeyCheck,
  integerRegex
} from "common/utils/numbers";
import {
  TechnicalAssessmentFormHeader
} from "features/technicalAssessmentForm/dialogs/common/TechnicalAssessmentFormHeader";

import type {
  UpdateTAFApiRequest
} from "features/technicalAssessmentForm/api/types";
import type {
  TechnicalAssessmentForm
} from "features/technicalAssessmentForm/types";



interface TechnicalAssessmentFormModal9Props {
  isTafUpdating: boolean;
  setShowModal: (show: boolean) => void;
  setTafStep: (newStep: number) => void;
  showModal: boolean;
  taf: TechnicalAssessmentForm;
  tafStep: number;
  updateTAF: (variable: UpdateTAFApiRequest) => Promise<TechnicalAssessmentForm>;
}


export function TechnicalAssessmentFormModal9(
  {
    isTafUpdating,
    setShowModal,
    setTafStep,
    showModal,
    taf,
    tafStep,
    updateTAF
  }: TechnicalAssessmentFormModal9Props
) {

  const [
    proposedPercentageWindowsDoorsDraughtStripped,
    setProposedPercentageWindowsDoorsDraughtStripped
  ] = useState<number>(0);

  const [
    percentageWindowsDoorsDraughtStripped,
    setPercentageWindowsDoorsDraughtStripped
  ] = useState<number>(0);

  const [
    measuresToImproveStructuralAirTightness,
    setMeasuresToImproveStructuralAirTightness
  ] = useState<string>('');

  const [
    airtightnessTestResult,
    setAirtightnessTestResult
  ] = useState<number>(0);

  const [
    noOfShelteredSides,
    setNoOfShelteredSides
  ] = useState<number>(0);

  const [
    requiredAirtightnessTestResult,
    setRequiredAirtightnessTestResult
  ] = useState<number>(0);

  const [
    structureType,
    setStructureType
  ] = useState<number>(0);

  const [
    woodenGroundFloor,
    setWoodenGroundFloor
  ] = useState<number>(0);

  const [
    proposedWoodenGroundFloor,
    setProposedWoodenGroundFloor
  ] = useState<number>(0);

  useEffect(
    () => {

      setProposedPercentageWindowsDoorsDraughtStripped(
        taf.system.proposed_ventilation_percentage_draught_stripped
      )

      setPercentageWindowsDoorsDraughtStripped(
        taf.system.ventilation_percentage_draught_stripped
      )

      setMeasuresToImproveStructuralAirTightness(
        taf.measures_to_improve_structural_air_tightness_upgrades
      )

      setAirtightnessTestResult(
        Number(
          taf.system.ventilation_permeability_test_result.toFixed(2)
        )
      )

      setNoOfShelteredSides(
        taf.system.ventilation_no_of_sides_sheltered
      )

      setRequiredAirtightnessTestResult(
        Number(
          taf.system.proposed_ventilation_permeability_test_result.toFixed(2)
        )
      )

      setStructureType(
        taf.system.ventilation_structure_type
      )

      setWoodenGroundFloor(
        taf.system.ventilation_suspended_wooden_floor
      )

      setProposedWoodenGroundFloor(
        taf.system.proposed_ventilation_suspended_wooden_floor
      );
    },
    [
      taf.measures_to_improve_structural_air_tightness_upgrades,
      taf.system.proposed_ventilation_percentage_draught_stripped,
      taf.system.proposed_ventilation_permeability_test_result,
      taf.system.proposed_ventilation_suspended_wooden_floor,
      taf.system.ventilation_no_of_sides_sheltered,
      taf.system.ventilation_percentage_draught_stripped,
      taf.system.ventilation_permeability_test_result,
      taf.system.ventilation_structure_type,
      taf.system.ventilation_suspended_wooden_floor
    ]
  );

  const closeModal = () => {
    setShowModal(false);
  };

  const handleUpdateTAF = (dialogNumber: number) => {
    const requestBody: UpdateTAFApiRequest = {
      technical_assessment_form_id: taf.id,
      measures_to_improve_structural_air_tightness_upgrades: measuresToImproveStructuralAirTightness,
      system: {
        ventilation_structure_type: structureType,
        ventilation_percentage_draught_stripped: percentageWindowsDoorsDraughtStripped,
        ventilation_permeability_test_result: airtightnessTestResult,
        ventilation_no_of_sides_sheltered: noOfShelteredSides,
        ventilation_suspended_wooden_floor: woodenGroundFloor,
        proposed_ventilation_permeability_test_result: requiredAirtightnessTestResult,
        proposed_ventilation_percentage_draught_stripped: proposedPercentageWindowsDoorsDraughtStripped,
        proposed_ventilation_suspended_wooden_floor: proposedWoodenGroundFloor
      }
    };

    updateTAF(
      requestBody
    ).then(
      (response) => {
        if (response) {
          setTafStep(dialogNumber);
        }
      }
    ).catch(
      (error) => console.log("Error while updating TAF:", error)
    );
  };

  const handleBackButtonClick = () => {
    handleUpdateTAF(8);
  };

  const handleNextButtonClick = () => {
    handleUpdateTAF(10);
  };

  const handleSliderChanged = (dialogNumber: number) => {
    handleUpdateTAF(dialogNumber);
  };

  const contentSectionHeaderText = {
    color: "#71717a"
  };

  const inputLabelVariableWidth = {
    display: "flex",
    alignItems: "center"
  };

  const labelAndInputBox = {
    display: "flex"
  };

  const outlinedInputStyleDropdown = {
    flex: 1,
    marginLeft: "24px",
    backgroundColor: "white",
    paddingLeft: "8px",
    paddingRight: "8px",
    overflowWrap: "break-word",
    width: "10rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  };

  const sectionTitle = {
    marginTop: "8px"
  };

  return (
    <Dialog
      open={showModal}
      onClose={closeModal}
      maxWidth="lg"
      fullWidth
      scroll="paper"
      sx={{
        padding: "16px"
      }}
    >
      <TechnicalAssessmentFormHeader
        changeTafStep={handleSliderChanged}
        closeModal={closeModal}
        tafStep={tafStep}
      />

      <DialogContent
        sx={{
          padding: "0px 8px 0px 8px"
        }}
      >

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "5px",
            backgroundColor: "#e0dede"
          }}
        >

          {/* SECTION TITLE */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "24px",
              paddingTop: "24px",
              paddingRight: "24px",
              paddingBottom: "16px"
            }}
          >
            <img
              alt="section title"
              style={{
                width: "60px",
                paddingRight: "16px"
              }}
              src="https://rk-staging-static.s3.eu-west-1.amazonaws.com/static/public/images/housing_upgrade_plans/icon-heat-loss.svg"
            />
            <Typography
              sx={{
                display: "flex",
                alignItems: "center"
              }}
              variant="h5"

            >
              Structural Airtightness Upgrades
            </Typography>

          </Box>

          <Box
            sx={{
              paddingLeft: "8px",
              paddingRight: "8px",
              paddingBottom: "8px"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                borderRadius: "5px",
                border: 1,
                borderColor: "#71717a",
                gap: "8px",
                padding: "16px"
              }}
            >
              {/* SECTION STRUCTURE TYPE */}
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  paddingRight: "16px"
                }}
              >
                <Typography
                  sx={inputLabelVariableWidth}
                >
                  Structure Type
                </Typography>

                <Select
                  sx={outlinedInputStyleDropdown}
                  inputProps={{
                    sx: {
                      padding: "8px"
                    }
                  }}
                  value={structureType}
                  label=""
                  onChange={
                    e => setStructureType(
                      Number(e.target.value)
                    )
                  }
                  variant="outlined"
                >
                  {
                    structureTypeOptions.map(
                      (structureOption) => <MenuItem
                        value={structureOption.value}
                        key={structureOption.value}
                      >
                        {
                          structureOption.label
                        }
                      </MenuItem>
                    )
                  }
                </Select>

              </Box>

              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  paddingLeft: "16px"
                }}
              >
                <Typography
                  sx={inputLabelVariableWidth}>
                  No. of sheltered sides
                </Typography>

                <WhiteBackgroundTextField
                  type="number"
                  variant="outlined"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      step: 1
                    }
                  }}
                  onChange={
                    (event) => {
                      if (
                        event.target.value === "" ||
                        integerRegex.test(event.target.value)
                      ) {
                        setNoOfShelteredSides(
                          Number(
                            event.target.value
                          )
                        );
                      }
                    }
                  }
                  onKeyDown={integerKeyCheck}
                  value={noOfShelteredSides}
                  sx={{
                    flex: 1,
                    marginLeft: "24px"
                  }}
                />

              </Box>

            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "8px",
              paddingRight: "8px",
              paddingBottom: "8px",
              gap: "8px"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "5px",
                border: 1,
                borderColor: "#71717a",
                padding: "0px 16px 16px 16px",
                gap: "8px",
                flex: 1
              }}
            >

              {/* SECTION CURRENT */}
              <Box
                sx={sectionTitle}
              >
                <Typography
                  align="center"
                  variant="h6"
                  sx={contentSectionHeaderText}
                >
                  Current (as per published BER)
                </Typography>
              </Box>

              <Box
                sx={labelAndInputBox}
              >
                <Typography
                  sx={inputLabelVariableWidth}
                >
                  Air tightness test result (q50/20)
                </Typography>

                <WhiteBackgroundTextField
                  type="number"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment
                      position="start"
                    >
                      ac/h
                    </InputAdornment>,
                    inputProps: {
                      min: 0,
                      step: "0.01"
                    }
                  }}
                  onChange={
                    (event) => {
                      if (
                        event.target.value === "" ||
                        floatRegex.test(event.target.value)
                      ) {
                        setAirtightnessTestResult(
                          Number(
                            event.target.value
                          )
                        );
                      }
                    }
                  }
                  onKeyDown={floatKeyCheck}
                  value={
                    airtightnessTestResult
                  }
                  sx={{
                    flex: 1,
                    marginLeft: "24px"
                  }}
                />

              </Box>

              <Box
                sx={labelAndInputBox}
              >
                <Typography
                  sx={inputLabelVariableWidth}
                >
                  Windows/doors draught stripped
                </Typography>

                <WhiteBackgroundTextField
                  type="number"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment
                      position="start"
                    >
                      %
                    </InputAdornment>,
                    inputProps: {
                      min: 0,
                      max: 100,
                      step: 1
                    }
                  }}
                  onChange={
                    (event) => {
                      if (
                        event.target.value === "" ||
                        integerRegex.test(event.target.value)
                      ) {
                        setPercentageWindowsDoorsDraughtStripped(
                          Number(
                            event.target.value
                          )
                        );
                      }
                    }
                  }
                  onKeyDown={integerKeyCheck}
                  value={percentageWindowsDoorsDraughtStripped}
                  sx={{
                    flex: 1,
                    marginLeft: "24px"
                  }}
                />

              </Box>

              <Box
                sx={labelAndInputBox}
              >
                <Typography
                  sx={inputLabelVariableWidth}
                >
                  Wooden ground floors sealed
                </Typography>
                <Select
                  sx={outlinedInputStyleDropdown}
                  inputProps={{
                    sx: {
                      padding: "8px"
                    }
                  }}
                  value={woodenGroundFloor}
                  label=""
                  onChange={
                    e => setWoodenGroundFloor(
                      Number(e.target.value)
                    )
                  }
                  variant="outlined"
                >
                  {
                    woodenGroundFloorOptions.map(
                      (floorOption) => <MenuItem
                        value={floorOption.value}
                        key={floorOption.label}
                      >
                        {floorOption.label}
                      </MenuItem>
                    )
                  }
                </Select>
              </Box>

            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "5px",
                border: 1,
                borderColor: "#71717a",
                padding: "0px 16px 16px 16px",
                gap: "8px",
                flex: 1
              }}
            >

              <Box
                sx={sectionTitle}
              >
                <Typography
                  align="center"
                  variant="h5"
                  sx={contentSectionHeaderText}
                >
                  Proposed upgrade
                </Typography>

              </Box>

              <Box
                sx={labelAndInputBox}
              >
                <Typography
                  sx={inputLabelVariableWidth}
                >
                  Air tightness test result (q50/20)
                </Typography>

                <WhiteBackgroundTextField
                  type="number"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment
                      position="start"
                    >
                      ac/h
                    </InputAdornment>,
                    inputProps: {
                      min: 0,
                      step: "0.01"
                    }
                  }}
                  onChange={
                    (event) => {
                      if (
                        event.target.value === "" ||
                        floatRegex.test(event.target.value)
                      ) {
                        setRequiredAirtightnessTestResult(
                          Number(
                            event.target.value
                          )
                        );
                      }
                    }
                  }
                  onKeyDown={floatKeyCheck}
                  value={
                    requiredAirtightnessTestResult
                  }
                  sx={{
                    flex: 1,
                    marginLeft: "24px"
                  }}
                />

              </Box>

              <Box
                sx={labelAndInputBox}
              >
                <Typography
                  sx={inputLabelVariableWidth}
                >
                  Windows/doors draught stripped
                </Typography>

                <WhiteBackgroundTextField
                  type="number"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment
                      position="start"
                    >
                      %
                    </InputAdornment>,
                    inputProps: {
                      min: 0,
                      max: 100,
                      step: 1
                    }
                  }}
                  onChange={
                    (event) => {
                      if (
                        event.target.value === "" ||
                        integerRegex.test(event.target.value)
                      ) {
                        setProposedPercentageWindowsDoorsDraughtStripped(
                          Number(
                            event.target.value
                          )
                        );
                      }
                    }
                  }
                  onKeyDown={integerKeyCheck}
                  value={proposedPercentageWindowsDoorsDraughtStripped}
                  sx={{
                    flex: 1,
                    marginLeft: "24px"
                  }}
                />

              </Box>

              <Box
                sx={labelAndInputBox}
              >
                <Typography
                  sx={inputLabelVariableWidth}
                >
                  Wooden ground floors sealed
                </Typography>

                <Select
                  sx={outlinedInputStyleDropdown}
                  inputProps={{
                    sx: {
                      padding: "8px"
                    }
                  }}
                  value={String(proposedWoodenGroundFloor)}
                  label=""
                  onChange={
                    e => setProposedWoodenGroundFloor(
                      Number(e.target.value)
                    )
                  }
                  variant="outlined"
                >
                  {
                    woodenGroundFloorOptions.map(
                      (heatingOption) => <MenuItem
                        value={heatingOption.value}
                        key={heatingOption.label}
                      >
                        {heatingOption.label}
                      </MenuItem>
                    )
                  }
                </Select>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              paddingLeft: "8px",
              paddingRight: "8px",
              paddingBottom: "8px"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "5px",
                border: 1,
                borderColor: "#71717a",
                padding: "16px",
                gap: "8px"
              }}
            >
              <Typography>
                Measures to improve air tightness and achieve proposed q50/20 (e.g. taping around junctions etc.) (include
                other info for contractor here)
              </Typography>

              <TextField
                sx={{
                  flex: 1,
                  backgroundColor: "white",
                  width: "100%"
                }}
                multiline
                value={measuresToImproveStructuralAirTightness}
                onChange={
                  event => setMeasuresToImproveStructuralAirTightness(
                    event.target.value
                  )
                }
              />

            </Box>
          </Box>

        </Box>

      </DialogContent>

      <DialogActions>

        <DwellingAndScenarioBerGrades
          dwellingDetail={
            String(taf.dwelling_mprn_reference)
          }
          dwellingRating={taf.dwelling_rating}
          scenarioDetail={taf.scenario_title}
          scenarioRating={taf.scenario_rating}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleBackButtonClick}
        >
          Back
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleNextButtonClick}
        >
          Next
        </Button>

      </DialogActions>

    </Dialog>
  );
}
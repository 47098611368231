import React, {
  type ChangeEvent,
  useCallback,
  useState
} from "react";

import { format } from "date-fns";
import { useStore } from "react-redux";

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  TableChart as TableIcon,
  InfoOutlined as InfoOutlinedIcon,
  Settings as SettingsIcon
} from "@mui/icons-material";
import {
  colors, Drawer,
  TextField
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { ConfirmationDialog } from "common/components/ConfirmationDialog";
import { LoadingButton } from "common/components/LoadingButton";
import {
  EditProjectDetailsDialog
} from "features/projects/dialogs/EditProjectDetailsDialog";
import {
  useProjectReportConfig
} from "features/projectsReportConfig/hooks/useProjectReportConfig";

import type {
  DeleteProjectApiRequest,
  RunProjectWebSocketRequest,
  UpdateProjectDetailsRequest,
  ExportSuperhomesRequest
} from "features/projects/api/types";
import type { Project } from "features/projects/types";


interface ProjectDetailProps {
  isProjectRunning: boolean;
  onDeleteProject: (requestArgs: DeleteProjectApiRequest) => void;
  onUpdateProjectDetails: (requestArgs: UpdateProjectDetailsRequest) => void;
  project: Project;
  runProject: (requestArgs: RunProjectWebSocketRequest) => void;
  superhomesExport: (requestArgs: ExportSuperhomesRequest) => void;
}

export function ProjectDetails(
  {
    isProjectRunning,
    onDeleteProject,
    onUpdateProjectDetails,
    project,
    runProject,
    superhomesExport
  }: ProjectDetailProps
) {

  const theme = useTheme();

  const store = useStore();

  const [
    showProjectSettingsDrawer,
    setShowProjectSettingsDrawer
  ] = useState(false);

  const [
    showEditTitleIcon,
    setShowEditTitleIcon
  ] = useState(false);

  const [
    showEditDescriptionIcon,
    setShowEditDescriptionIcon
  ] = useState(false);

  const [
    editProjectTitleDialogOpen,
    setEditProjectTitleDialogOpen
  ] = useState<boolean>(false);

  const [
    editProjectDescriptionDialogOpen,
    setEditProjectDescriptionDialogOpen
  ] = useState<boolean>(false);

  const handleCloseEditProjectDetailsDialog = () => {
    setEditProjectTitleDialogOpen(false);
    setEditProjectDescriptionDialogOpen(false);
  };

  const handleDeleteProject = useCallback(
    (projectToDelete: Project) => {
      onDeleteProject({
        id: projectToDelete.id
      });
    },
    [
      onDeleteProject
    ]
  );

  const handleRunProject = useCallback(
    () => {

      runProject({
        project,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
        token: store.getState().authentication.token
      });
    },
    [
      project,
      runProject,
      store
    ]
  );

  const handleExportProject = useCallback(
    () => {
      superhomesExport(project);
    },
    [
      project,
      superhomesExport
    ]
  );

  const [
    confirmationDialogTitle, setConfirmationDialogTitle
  ] = useState<string>("");

  const [
    confirmationDialogMessage, setConfirmationDialogMessage
  ] = useState<string>("");

  const {
    updateProjectReportConfig
  } = useProjectReportConfig(project.id);

  const handleUpdateRoundCosts = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      updateProjectReportConfig({
        project_id: project.id,
        round_costs: checked
      });
    },
    [
      project.id,
      updateProjectReportConfig
    ]
  );

  const handleUpdateShowCredits = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      updateProjectReportConfig({
        project_id: project.id,
        show_credits: checked
      });
    },
    [project.id, updateProjectReportConfig]
  );

  const handleCreditsMultiplierChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onUpdateProjectDetails({
        id: project.id,
        credits_multiplier: Number(event.target.value)
      });
    },
    [project.id, onUpdateProjectDetails]
  );

  const handleUpdateShowCosts = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      updateProjectReportConfig({
        project_id: project.id,
        show_costs: checked
      });
    },
    [project.id, updateProjectReportConfig]
  );

  const [
    confirmationDialogOpen,
    setConfirmationDialogOpen
  ] = useState<boolean>(false);

  const handleCloseConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
  };

  const [
    deleteProjectConfirmationDialogOpen, setDeleteProjectConfirmationDialogOpen
  ] = useState<boolean>(false);

  const handleCloseDeleteProjectConfirmationDialog = () => {
    setDeleteProjectConfirmationDialogOpen(false);
  };

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 10
      }}
    >
      <CssBaseline />

      <AppBar
        elevation={1}
        sx={{
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: theme.palette.common.white,
          color: theme.palette.text.primary,
          position: "sticky"
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >

            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "column",
                  lg: "row",
                  xl: "row"
                }
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px"
                }}
                onClick={() => {
                  setEditProjectTitleDialogOpen(true);
                }}
              >

                <Typography
                  variant="h6"
                  style={{
                    fontSize: "18px",
                    fontWeight: 400,
                    textOverflow: "ellipsis"
                  }}
                  onMouseEnter={
                    () => setShowEditTitleIcon(true)
                  }
                  onMouseLeave={
                    () => setShowEditTitleIcon(false)
                  }
                >
                  {project.title}
                </Typography>

                {
                  showEditTitleIcon ? <EditIcon
                    sx={{
                      color: colors.green["500"],
                      marginRight: "8px"
                    }}
                  /> : <Box
                    sx={{
                      width: "24px",
                      marginRight: "8px"
                    }}
                  />
                }

              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                  alignItems: "center"
                }}
                onClick={() => {
                  setEditProjectDescriptionDialogOpen(true);
                }}
              >

                <Typography
                  variant="subtitle2"
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    height: "24px",
                    color: colors.grey["500"]
                  }}
                  onMouseEnter={
                    () => setShowEditDescriptionIcon(true)
                  }
                  onMouseLeave={
                    () => setShowEditDescriptionIcon(false)
                  }
                >
                  {project.description}
                </Typography>

                {
                  showEditDescriptionIcon ? <EditIcon
                    sx={{
                      color: colors.green["500"]
                    }}
                  /> : null
                }

              </Box>

            </Box>

            <Box
              sx={{
                flex: 1
              }}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
                padding: "8px"
              }}
            >

              <Tooltip
                title={
                  `Created on ${
                    format(project.created_on, "do MMM yyyy")
                  }`
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px"
                  }}
                >

                  <Typography
                    variant="subtitle1"
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: 400,
                      textAlign: "center",
                      flex: 1,
                      color: colors.grey["500"]
                    }}
                  >
                    {`${format(project.created_on, "do MMM")}`}
                  </Typography>

                </Box>

              </Tooltip>

              <Tooltip
                title="Information"
              >
                <IconButton
                  aria-label="project-info"
                  onClick={() => {
                    setConfirmationDialogTitle(
                      "Projects"
                    );
                    setConfirmationDialogMessage(
                      "A range of scenarios are modelled on collections of dwellings in projects. Click the \"Run\" button to generate results and analyse how each scenario performs for each dwelling."
                    );
                    setConfirmationDialogOpen(
                      true
                    );
                  }}
                >
                  <InfoOutlinedIcon
                    sx={{
                      color: theme.palette.primary.main
                    }}
                  />
                </IconButton>
              </Tooltip>

              <Tooltip
                title="Settings"
              >
                <IconButton
                  aria-label="project-settings"
                  onClick={
                    () => {
                      setShowProjectSettingsDrawer(true);
                    }
                  }
                >
                  <SettingsIcon
                    sx={{
                      color: theme.palette.primary.main
                    }}
                  />
                </IconButton>
              </Tooltip>

              <Tooltip
                title="Generate results for every dwelling and scenario in this project"
              >
                <Box sx={{
                  paddingLeft: "12px",
                  paddingRight: "12px",
                  display: "flex",
                  alignItems: "center"
                }}
                >
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    loading={isProjectRunning}
                    onClick={handleRunProject}
                  >
                    {isProjectRunning ? "Running" : "Run"}
                  </LoadingButton>
                </Box>
              </Tooltip>
            </Box>

          </Box>

        </Toolbar>

      </AppBar>

      <Drawer
        anchor="right"
        open={showProjectSettingsDrawer}
        onClose={
          () => setShowProjectSettingsDrawer(false)
        }
        sx={{
          width: 300,
          flexShrink: 0,
          height: "auto",
          [`& .MuiDrawer-paper`]: {
            width: 300,
            boxSizing: "border-box"
          }
        }}
      >
        <Toolbar />

        <Box
          sx={{
            padding: "16px"
          }}
        >
          <Typography
            variant="h6"
            style={{
              fontSize: "18px",
              fontWeight: 400,
              color: theme.palette.secondary.main
            }}
          >
            Credits
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "8px",
              gap: "8px"
            }}
          >

            <TextField
              id="credits-multiplier"
              label="Value per kWh"
              type="number"
              name="credits_multiplier"
              defaultValue={project.thresholds.credits_multiplier}
              onChange={handleCreditsMultiplierChange}
              inputProps={{
                step: ".01"
              }}
            />

          </Box>

        </Box>

        <Divider />

        <Box
          sx={{
            padding: "16px"
          }}
        >
          <Typography
            variant="h6"
            style={{
              fontSize: "18px",
              fontWeight: 400,
              color: theme.palette.secondary.main
            }}
          >
            Reports
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "8px",
              gap: "8px"
            }}
          >

            <Tooltip
              title="Round costs"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={project.report_config.round_costs}
                    onChange={handleUpdateRoundCosts}
                  />
                }
                label="Round Costs"
              />
            </Tooltip>

            <Tooltip
              title="Show costs"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={project.report_config.show_costs}
                    onChange={handleUpdateShowCosts}
                  />
                }
                label="Show Costs"
              />
            </Tooltip>

            <Tooltip
              title="Show credits"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={project.report_config.show_credits}
                    onChange={handleUpdateShowCredits}
                  />
                }
                label="Show Credits"
              />
            </Tooltip>

          </Box>

        </Box>

        <Divider />

        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          store.getState().user.can_view_superhomes_export === true || store.getState().user.is_staff === true ?
            <Box>
              <Box
                sx={{
                  padding: "16px"
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    fontSize: "18px",
                    fontWeight: 400,
                    color: theme.palette.secondary.main,
                    paddingBottom: "8px"
                  }}
                >
                  Export Data
                </Typography>

                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={
                    <TableIcon />
                  }
                  sx={{
                    width: "100%"
                  }}
                  onClick={handleExportProject}
                >
                  XLSX
                </Button>
              </Box>

              <Divider />
            </Box> : null
        }

        <Box
          sx={{
            flex: 1
          }}
        />

        <Box
          sx={{
            padding: "16px"
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            startIcon={
              <DeleteIcon />
            }
            sx={{
              width: "100%"
            }}
            onClick={
              () => {
                setDeleteProjectConfirmationDialogOpen(true);
              }
            }
          >
            Delete project
          </Button>
        </Box>
      </Drawer>


      <EditProjectDetailsDialog
        existingValue={project.title}
        open={editProjectTitleDialogOpen}
        onClose={handleCloseEditProjectDetailsDialog}
        onUpdateProjectDetails={onUpdateProjectDetails}
        projectId={project.id}
        title="Title"
      />

      <EditProjectDetailsDialog
        existingValue={project.description}
        open={editProjectDescriptionDialogOpen}
        onClose={handleCloseEditProjectDetailsDialog}
        onUpdateProjectDetails={onUpdateProjectDetails}
        projectId={project.id}
        title="Description"
      />

      <ConfirmationDialog
        buttonText="Okay"
        message={confirmationDialogMessage}
        title={confirmationDialogTitle}
        onClose={handleCloseConfirmationDialog}
        onSubmit={() => {
          handleCloseConfirmationDialog();
        }}
        open={confirmationDialogOpen}
      />

      <ConfirmationDialog
        message="This will also delete all generated results for dwellings and scenarios in this project"
        title="Delete Project"
        onClose={handleCloseDeleteProjectConfirmationDialog}
        onSubmit={() => {
          handleCloseDeleteProjectConfirmationDialog();
          handleDeleteProject(project);
        }}
        open={deleteProjectConfirmationDialogOpen}
      />
    </Box>
  );
}

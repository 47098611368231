import type {
  CustomMeasureApiResponse
} from "features/customMeasures/api/types";
import type { CustomMeasure } from "features/customMeasures/types";


export function customMeasuresApiTransformer(
  response: Array<CustomMeasureApiResponse>
): Array<CustomMeasure> {

  return response.map(
    (customMeasureApiResponse): CustomMeasure => ({
      id: customMeasureApiResponse.id,
      retrokit_identifier: customMeasureApiResponse.retrokit_identifier,
      category_code: customMeasureApiResponse.category_code,
      retrokit_category: customMeasureApiResponse.retrokit_category,
      title: customMeasureApiResponse.title,
      description: customMeasureApiResponse.description,
      bench_value: customMeasureApiResponse.bench_value,
      retro_value: customMeasureApiResponse.retro_value,
      energy_credit_apartment: customMeasureApiResponse.energy_credit_apartment,
      energy_credit_house: customMeasureApiResponse.energy_credit_house,
      variable_multiplier: customMeasureApiResponse.variable_multiplier,
      variable_unit: customMeasureApiResponse.variable_unit,
      variable_costs: customMeasureApiResponse.variable_costs,
      fixed_costs: customMeasureApiResponse.fixed_costs,
      fixed_costs_up_to_50: customMeasureApiResponse.fixed_costs_up_to_50,
      fixed_costs_up_to_100: customMeasureApiResponse.fixed_costs_up_to_100,
      fixed_costs_up_to_150: customMeasureApiResponse.fixed_costs_up_to_150,
      fixed_costs_over_150: customMeasureApiResponse.fixed_costs_over_150,
      maintenance_costs: customMeasureApiResponse.maintenance_costs,
      target_u_value: customMeasureApiResponse.target_u_value,
      notes: customMeasureApiResponse.notes,
      icon_link: customMeasureApiResponse.icon_link,
      incompatible_with: customMeasureApiResponse.incompatible_with,
      incompatible_reason: customMeasureApiResponse.incompatible_reason
    })
  );
}
import React from "react";
import "./App.scss";

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ThemeProvider } from "@mui/material/styles";

import { store } from "store";

import { theme } from "./styles/theme";
import RootContainer from "./v1/components/RootContainer";

// Using direct loading as a workaround with transpiling issue with mapbox
// https://docs.mapbox.com/mapbox-gl-js/guides/install/#transpiling
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
mapboxgl.workerClass =
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  // eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-var-requires
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

mapboxgl.accessToken = `${
  process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "Not set"
}`;

export default function App() {
  return (
    <Provider
      store={
        store
      }
    >
      <ThemeProvider
        theme={
          theme
        }
      >
        <RootContainer />
        <ToastContainer />
      </ThemeProvider>
    </Provider>
  );
}
import React from "react";

import {
  Label as LabelIcon
} from "@mui/icons-material";
import { Icon } from "@mui/material";
import { useTheme } from "@mui/material/styles";


interface RetroKitIconProps {
  iconLink: string;
}

export function RetroKitIcon(
  {
    iconLink
  }: RetroKitIconProps) {

  const theme = useTheme();

  return (
    <Icon
      sx={{
        textAlign: "center",
        width: "24px"
      }}
      color="secondary"
    >
      {
        iconLink === "" ? <LabelIcon
          sx={{
            color: theme.palette.common.black
          }}
        /> : <img
          style={{
            display: "flex",
            height: "inherit",
            width: "inherit"
          }}
          src={`${process.env.REACT_APP_API_BASE_URL || ""}/${iconLink}`}
          alt="A RetroKit icon"
        />
      }
    </Icon>
  );
}



import type { ButtonProps } from "@mui/material/Button";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";

interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
}

export function LoadingButton(props: LoadingButtonProps) {
  const {
    loading = false,
    ...buttonProps
  } = props;

  const theme = useTheme();

  return (
    <Button
      {...buttonProps}
      disabled={loading || buttonProps.disabled}
    >
      {
        loading && (
          <CircularProgress
            size="1em"
            sx={{
              marginRight: "0.5em",
              color: theme.palette.action.disabled
            }}
          />
        )
      }
      {
        buttonProps.children
      }
    </Button>
  );
}

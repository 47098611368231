import type {
  HomeEnergyAssessmentApiResponse,
  UpdateHEASection1ApiRequest,
  UpdateHEASection2ApiRequest,
  UpdateHEASection3ApiRequest,
  UpdateHEASection4ApiRequest,
  UpdateHEASection5ApiRequest,
  UpdateHEASection6ApiRequest,
  UpdateHEASection7ApiRequest,
  UpdateHEASection8ApiRequest,
  UpdateHEASection9ApiRequest,
  UpdateHEASection10ApiRequest,
  UpdateHEASection11ApiRequest,
  UpdateHEASection12ApiRequest
} from "./types";
import type { HomeEnergyAssessment } from "../types";

export function heaApiTransformer(
  response: HomeEnergyAssessmentApiResponse
): HomeEnergyAssessment {
  return {
    ...response,
    logoFile: undefined,
    dwellingImageFile: undefined,
    annexAdditionalFiles: undefined
  };
}

export function heaApiUpdateTransformer(
  variable: UpdateHEASection1ApiRequest |
    UpdateHEASection2ApiRequest |
    UpdateHEASection3ApiRequest |
    UpdateHEASection4ApiRequest |
    UpdateHEASection5ApiRequest |
    UpdateHEASection6ApiRequest |
    UpdateHEASection7ApiRequest |
    UpdateHEASection8ApiRequest |
    UpdateHEASection9ApiRequest |
    UpdateHEASection10ApiRequest |
    UpdateHEASection11ApiRequest |
    UpdateHEASection12ApiRequest
): FormData {
  const formData = new FormData();
  Object.entries(
    variable
  ).forEach(
    (entry) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const [key, value] = entry;
      if (key === 'additional_annex_files') {
        if (value instanceof Array) {
          for (let i = 0; i < value.length; i += 1) {
            formData.append(
              `${key}${i}`,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              value[i]
            );

          }
        }
      }
      else {
        formData.append(
          key,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          value
        );
      }
    }
  );
  return formData;
}
import type { ReactNode } from "react";
import { useEffect, useState } from "react";

import Dropzone from "react-dropzone";

import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DescriptionIcon from "@mui/icons-material/Description";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import type { Accept } from "react-dropzone";

interface FileInputProps {
  accept: Accept;
  error?: boolean;
  label?: ReactNode;
  value?: Array<File> | File;
  multiple?: boolean;
  helperText?: string;
  onDrop?: (acceptedFiles: Array<File>) => void;
}

export function FileInput(
  {
    onDrop,
    accept,
    helperText,
    value = [],
    error = false,
    multiple = false,
    label = (
      <Typography>
        Drag n drop file here, or click to select file
      </Typography>
    )
  }: FileInputProps) {

  const theme = useTheme();

  const [
    files,
    setFiles
  ] = useState<Array<File>>([
    ...(Array.isArray(value) ? value : [value])
  ]);

  // TODO: This effect is called on every re-render (Mouse Move). Fix this after fixing overall app re-render
  useEffect(
    () => {
      const arrayValue = [
        ...(Array.isArray(value) ? value : [value])
      ];

      if (
        // Update state only if new value is different and value is defined
        (files.length !== arrayValue.length ||
          !files.every((item, index) => item && arrayValue[index] === item)) &&
        arrayValue.every((item) => item != null)
      ) {
        setFiles(arrayValue);
      }
    },
    [
      value,
      files
    ]
  );

  const handleOnDrop = (acceptedFiles: Array<File>) => {
    const updatedFiles = (
      multiple ? [...files, ...acceptedFiles] : [acceptedFiles[0]]
    ).filter((file) => file != null);

    setFiles(updatedFiles);
    if (onDrop) {
      onDrop(updatedFiles);
    }
  };

  const removeFile = (fileToRemove: File) => {
    const updatedFiles = files.filter((file) => file !== fileToRemove);

    setFiles(updatedFiles);

    if (onDrop) {
      onDrop(updatedFiles);
    }
  };

  return (
    <Dropzone
      multiple={multiple}
      accept={accept}
      onDrop={handleOnDrop}
    >
      {({ getRootProps, getInputProps }) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            "& > * + *": {
              marginTop: "0.5em"
            }
          }}
        >
          <Box
            {...getRootProps()}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "1em",
              color: theme.palette.text.disabled,
              background: theme.palette.grey[200],
              border: () =>
                `1px dashed ${
                  error ? theme.palette.error.main : theme.palette.grey[600]
                }`,
              cursor: "pointer"
            }}
          >
            <input
              {...getInputProps()}
            />
            {label}
            <CloudUploadIcon
              sx={{
                marginTop: "0.5em"
              }}
            />
          </Box>

          {files.length > 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                "& > * + *": {
                  marginLeft: "1em"
                }
              }}
            >
              {files.map((file, index) => (
                <span
                  key={index}
                  style={{
                    display: "inline-flex",
                    flexDirection: "row",
                    alignItems: "center",
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    "& > * + *": {
                      marginLeft: "1em"
                    }
                  }}
                >
                  <DescriptionIcon
                    sx={{
                      color: theme.palette.text.disabled
                    }}
                  />
                  {file.name}
                  <Tooltip title="Remove file">
                    <CloseIcon
                      sx={{
                        color: theme.palette.error.main,
                        marginLeft: "0.2em",
                        cursor: "pointer"
                      }}
                      onClick={() => removeFile(file)}
                    />
                  </Tooltip>
                </span>
              ))}
            </Box>
          )}

          {
            helperText && (
              <Typography
                sx={{
                  color: () =>
                    error ? theme.palette.error.main : theme.palette.text.disabled
                }}
              >
                {helperText}
              </Typography>
            )
          }
        </Box>
      )}
    </Dropzone>
  );
}

export const RETRY_STATUSES = [
    "UNKNOWN",
    "JUST_RECEIVED",
    "PENDING",
    "STARTED",
    "RETRY"
]

export const FINISHED_STATUSES = [
    "FAILURE",
    "REVOKED",
    "DOWNLOADING",
    "SUCCESS"
]
import { useEffect, useState } from 'react';

import {
  useCreateCustomScenarioMutation,
  useDeleteCustomScenarioMutation,
  useGetCustomScenariosQuery
} from "features/customScenarios/api/customScenariosApi";

import type { CustomScenario } from 'features/customScenarios/types';


export function useCustomScenariosPage() {
  
  const [
    customScenarios, setCustomScenarios
  ] = useState<Array<CustomScenario>>([]);
  
  const {
    isLoading: isGetCustomScenariosLoading,
    data: customScenariosResponse
  } = useGetCustomScenariosQuery();

  const [ createCustomScenario ] = useCreateCustomScenarioMutation();

  const [ deleteCustomScenario ] = useDeleteCustomScenarioMutation();

  useEffect(() => {
    setCustomScenarios(customScenariosResponse ?? []);
  }, [customScenariosResponse]);

  return {
    createCustomScenario,
    customScenarios,
    deleteCustomScenario,
    isGetCustomScenariosLoading
  };
}

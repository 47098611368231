import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { useTheme } from "@mui/material/styles";


export default function TransitionAlerts(props) {

    const theme = useTheme();

    const {open, setOpen, title, content, severity} = props;

    return (
        <div style={{
          width: '100%',
          '& > * + *': {
            marginTop: theme.spacing(2),
          },
        }}
        >
            <Collapse in={open}>
                <Alert
                    severity={severity}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    <AlertTitle>{title}</AlertTitle>
                    {content}
                </Alert>
            </Collapse>
        </div>
    );
}
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import MaterialTable, { type Column } from "@material-table/core";

import {
  colors
} from "@mui/material";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import type { PaperProps } from "@mui/material/Paper";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

import { BEREdited } from "common/components/BEREdited";
import {
  DwellingAndScenarioPerformanceBanner
} from "common/components/DwellingAndScenarioPerformanceBanner";
import {
  HEA_COST_ESTIMATES_BOTTOM_TABLE_COLUMNS,
  HEA_COST_ESTIMATES_TABLE_COLUMNS
} from "common/constants/tables";
import iconTitleHomeBlank from "common/icons/icon-title-home-blank.png";
import {
  useHomeEnergyAssessment
} from "features/homeEnergyAssessment/hooks/useHomeEnergyAssessment";

import type { Dwelling } from "features/dwellings/types";
import type {
  HomeEnergyAssessmentCostEstimatesTableData,
  HomeEnergyAssessmentCostEstimatesBottomTableData
} from "features/homeEnergyAssessment/types";
import type { ProjectReportConfig } from "features/projects/types";


interface HomeEnergyAssessmentDialogContent11Props {
  contentBackgroundColour: string;
  pageBackgroundColour: string;
  dwelling: Dwelling;
  reportConfig: ProjectReportConfig;
  scenarioDetail: string;
  scenarioRating: string;
  inputError: boolean;
  setInputError: React.Dispatch<React.SetStateAction<boolean>>;
  handleHeaFieldBlur: (
    value: string | number | File,
    field: string
  ) => void;
}


export function HomeEnergyAssessmentDialogContent11(
  {
    contentBackgroundColour,
    pageBackgroundColour,
    dwelling,
    reportConfig,
    scenarioDetail,
    scenarioRating,
    inputError,
    setInputError,
    handleHeaFieldBlur
  }: HomeEnergyAssessmentDialogContent11Props
) {

  const {
    homeEnergyAssessment
  } = useHomeEnergyAssessment();

  const [
    sectionHeadingCostEstimates,
    setSectionHeadingCostEstimates
  ] = useState<string>("");

  const [
    sectionHeadingCostEstimatesError,
    setSectionHeadingCostEstimatesError
  ] = useState<boolean>(false);

  const [
    sectionHeadingCostEstimatesLength,
    setSectionHeadingCostEstimatesLength
  ] = useState<number>(0);

  const sectionHeadingCostEstimatesLengthLimit = 100;

  const sectionHeadingCostEstimatesInputRef = useRef<HTMLInputElement>();

  const [
    scheduleOfPaymentsParagraph,
    setScheduleOfPaymentsParagraph
  ] = useState<string>("");

  const [
    scheduleOfPaymentsParagraphError,
    setScheduleOfPaymentsParagraphError
  ] = useState<boolean>(false);

  const [
    scheduleOfPaymentsParagraphLength,
    setScheduleOfPaymentsParagraphLength
  ] = useState<number>(0);

  const scheduleOfPaymentsParagraphLengthLimit = 500;

  const scheduleOfPaymentsParagraphInputRef = useRef<HTMLInputElement>();

  const [
    sectionHeadingScheduleOfPayments,
    setSectionHeadingScheduleOfPayments
  ] = useState<string>("");

  const [
    financingOptionParagraph,
    setFinancingOptionParagraph
  ] = useState<string>("");

  const [
    financingOptionParagraphError,
    setFinancingOptionParagraphError
  ] = useState<boolean>(false);

  const [
    financingOptionParagraphLength,
    setFinancingOptionParagraphLength
  ] = useState<number>(0);

  const financingOptionParagraphLengthLimit = 500;

  const [
    sectionHeadingFinancingOptionParagraph,
    setSectionHeadingFinancingOptionParagraph
  ] = useState<string>("");

  const [
    costEstimatesTableData,
    setCostEstimatesTableData
  ] = useState<HomeEnergyAssessmentCostEstimatesTableData[]>([]);

  const [
    costEstimatesBottomTableData,
    setCostEstimatesBottomTableData
  ] = useState<HomeEnergyAssessmentCostEstimatesBottomTableData[]>([]);

  const filteredColumns =
    HEA_COST_ESTIMATES_TABLE_COLUMNS.filter(
      (tableColumn) => {

        if (!reportConfig.show_costs) {
          return tableColumn.field !== "cost";
        }

        return true;
      }
    );

  const [
    roundCostsToNearestHundred,
    setRoundCostsToNearestHundred
  ] = useState<boolean>(false);

  const [
    showCosts,
    setShowCosts
  ] = useState<boolean>(true);

  const [
    showCredits,
    setShowCredits
  ] = useState<boolean>(false);

  const [
    tableData,
    setTableData
  ] = useState<HomeEnergyAssessmentCostEstimatesTableData[]>([]);

  useEffect(
    () => {

      if (
        scheduleOfPaymentsParagraphError ||
        financingOptionParagraphError ||
        sectionHeadingCostEstimatesError
      ) {
        setInputError(true);
      }

      if (inputError) {
        if (
          !scheduleOfPaymentsParagraphError &&
          !financingOptionParagraphError &&
          !sectionHeadingCostEstimatesError
        ) {
          setInputError(false);
        }
      }

      const roundToNearestHundred = (someNumber: number) => Math.round(
        someNumber / 100
      ) * 100;

      // TODO: Figure out why the round costs is causing a double render
      if (reportConfig !== undefined) {
        if (reportConfig.round_costs !== roundCostsToNearestHundred) {
          setRoundCostsToNearestHundred(reportConfig.round_costs);
        }
        if (reportConfig.show_costs !== showCosts) {
          setShowCosts(reportConfig.show_costs);
        }
        if (reportConfig.show_credits !== showCredits) {
          setShowCredits(reportConfig.show_credits);
        }
      }
      if (roundCostsToNearestHundred) {
        const tableDataCopy = [
          ...costEstimatesTableData
        ];

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableDataCopy.length; i++) {
          const newRowData = {
            ...tableDataCopy[i]
          };

          newRowData.cost = roundToNearestHundred(
            tableDataCopy[i].cost
          );

          tableDataCopy[i] = newRowData;
        }
        setTableData(tableDataCopy);
      } else {
        setTableData(costEstimatesTableData);
      }

    },
    [
      scheduleOfPaymentsParagraphError,
      financingOptionParagraphError,
      sectionHeadingCostEstimatesError,
      inputError,
      setInputError,
      reportConfig,
      roundCostsToNearestHundred,
      showCosts,
      showCredits,
      costEstimatesTableData
    ]
  );

  useEffect(
    () => {
      if (homeEnergyAssessment !== undefined) {
        setSectionHeadingCostEstimates(
          homeEnergyAssessment.section_heading_cost_estimates
        );

        setSectionHeadingCostEstimatesLength(
          homeEnergyAssessment.section_heading_cost_estimates !== null ?
            homeEnergyAssessment.section_heading_cost_estimates.length : 0
        );

        setScheduleOfPaymentsParagraph(
          homeEnergyAssessment.schedule_of_payments_paragraph
        );

        setScheduleOfPaymentsParagraphLength(
          homeEnergyAssessment.schedule_of_payments_paragraph !== null ?
            homeEnergyAssessment.schedule_of_payments_paragraph.length : 0
        );

        setFinancingOptionParagraph(
          homeEnergyAssessment.financing_option_paragraph
        );

        setFinancingOptionParagraphLength(
          homeEnergyAssessment.financing_option_paragraph !== null ?
            homeEnergyAssessment.financing_option_paragraph.length : 0
        );

        setCostEstimatesTableData(
          homeEnergyAssessment.cost_estimates_table_data
        );

        setSectionHeadingScheduleOfPayments(
          homeEnergyAssessment.section_heading_schedule_of_payments
        );

        setSectionHeadingFinancingOptionParagraph(
          homeEnergyAssessment.section_heading_financing_option
        );

        const totalCosts = homeEnergyAssessment.cost_estimates_table_data.reduce(
          (partialSum, currentObject) =>
            partialSum + currentObject.cost, 0
        );

        let totalGrants = homeEnergyAssessment.cost_estimates_table_data.reduce(
          (partialSum, currentObject) =>
            partialSum + currentObject.grant, 0
        );

        totalGrants += homeEnergyAssessment.ber_and_heat_pump_bonus +
          homeEnergyAssessment.central_heating_and_heat_pump_bonus;

        const energyCredits = homeEnergyAssessment.credit_total;

        setCostEstimatesBottomTableData(
          [
            {
              id: 0,
              title: "Total",
              cost: totalCosts,
              grant: 0
            },
            {
              id: 1,
              title: "BER and Heat Pump Bonus",
              cost: 0,
              grant: homeEnergyAssessment.ber_and_heat_pump_bonus
            },
            {
              id: 2,
              title: "Central Heating and Heat Pump Bonus",
              cost: 0,
              grant: homeEnergyAssessment.central_heating_and_heat_pump_bonus
            },
            {
              id: 3,
              title: "Total SEAI Grant",
              cost: 0,
              grant: totalGrants
            },
            {
              id: 4,
              title: "Energy Credits from Energy Efficiency Obligation Scheme",
              cost: 0,
              grant: energyCredits
            },
            {
              id: 5,
              title: "Total Cost to Homeowner",
              cost: totalCosts - totalGrants - energyCredits,
              grant: 0
            }
          ]
        );
      }
    },
    [
      homeEnergyAssessment
    ]
  );

  const handleFieldUpdated = useCallback(
    (
      value: string | number | File,
      field: string
    ) => {
      handleHeaFieldBlur(value, field);
    },
    [
      handleHeaFieldBlur
    ]
  );

  // const tableColumns: Array<Column<HomeEnergyAssessmentCostEstimatesTableData>> = useMemo(
  //   () => [
  //     ...HEA_COST_ESTIMATES_TABLE_COLUMNS
  //   ],
  //   []
  // );
  const tableColumns: Array<Column<HomeEnergyAssessmentCostEstimatesTableData>> = useMemo(
    () => [
      ...filteredColumns
    ],
    [
      filteredColumns
    ]
  );

  const bottomTableColumns: Array<Column<HomeEnergyAssessmentCostEstimatesBottomTableData>> = useMemo(
    () => [
      ...HEA_COST_ESTIMATES_BOTTOM_TABLE_COLUMNS
    ],
    []
  );

  const TablePaperComponent = useCallback(
    (
      props: JSX.IntrinsicAttributes & PaperProps
    ) => <Paper
      sx={{
        borderRadius: "20px 20px 0 0",
        backgroundColor: colors.grey["200"]
      }}
      variant="outlined"
      {...props}
    />,
    []
  );

  const BottomTablePaperComponent = useCallback(
    (
      props: JSX.IntrinsicAttributes & PaperProps
    ) => <Paper
      sx={{
        "& tbody tr td": {
          border: 0,
          backgroundColor: colors.grey["100"]
        },
        border: 0
      }}
      variant="outlined"
      elevation={0}
      {...props}
    />,
    []
  );

  const theme = useTheme();

  return (
    <DialogContent
      sx={{
        width: "100%",
        flex: 1,
        overflowX: "hidden",
        overflowY: "hidden",
        padding: "0px 8px 0px 8px",
        gap: "2rem"
      }}
    >

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            borderRadius: "20px",
            backgroundColor: contentBackgroundColour,
            padding: "16px"
          }}
        >
          <img
            src={iconTitleHomeBlank}
            alt="Icon"
            style={{
              maxHeight: "48px"
            }}
          />

          <TextField
            error={sectionHeadingCostEstimatesError}
            variant="standard"
            fullWidth
            inputProps={{
              style: {
                fontSize: "1.4rem",
                backgroundColor: theme.palette.common.white
              }
            }}
            InputLabelProps={{
              style: {
                fontSize: "1.4rem"
              }
            }}
            FormHelperTextProps={{
              sx: {
                textAlign: "right",
                width: "100%"
              }
            }}
            value={sectionHeadingCostEstimates}
            sx={{
              display: "flex",
              alignItems: "center",
              marginLeft: "16px",
              color: colors.grey["800"]
            }}
            onBlur={(event) => {
              if (!inputError) {
                handleFieldUpdated(
                  event.target.value,
                  "section_heading_cost_estimates"
                );
              }
            }}
            onChange={(event) => {
              const {
                value
              } = event.target;
              setSectionHeadingCostEstimatesLength(value.length);
              if (value.length > sectionHeadingCostEstimatesLengthLimit) {
                setSectionHeadingCostEstimatesError(true);
              } else if (sectionHeadingCostEstimatesError) {
                setSectionHeadingCostEstimatesError(false);
              }
              setSectionHeadingCostEstimates(value);
            }}
            helperText={
              sectionHeadingCostEstimatesLengthLimit - sectionHeadingCostEstimatesLength <= (sectionHeadingCostEstimatesLengthLimit / 2) ?
                `${sectionHeadingCostEstimatesLength}/${sectionHeadingCostEstimatesLengthLimit}` : ""
            }
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                if (!inputError) {
                  if (
                    scheduleOfPaymentsParagraphInputRef !== undefined &&
                    scheduleOfPaymentsParagraphInputRef.current !== undefined
                  ) {
                    scheduleOfPaymentsParagraphInputRef.current.focus();
                  }
                }
              }
            }}
            inputRef={sectionHeadingCostEstimatesInputRef}
          />

        </Box>

      </Box>

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >
        <DwellingAndScenarioPerformanceBanner
          dwellingDetail={String(dwelling.mprn)}
          dwellingRating={dwelling.performance.rating}
          scenarioDetail={scenarioDetail}
          scenarioRating={scenarioRating}
        />

      </Box>

      {
        dwelling.performance.ber_edited ? <BEREdited /> : null
      }

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >

        <MaterialTable
          columns={tableColumns}
          components={{
            Container: TablePaperComponent,
            Toolbar: () => null
          }}
          data={tableData}
          localization={{
            body: {
              emptyDataSourceMessage: "No measures to display",
              filterRow: {
                filterTooltip: "Filter"
              }
            }
          }}
          options={{
            idSynonym: "step",
            headerStyle: {
              color: theme.palette.common.black,
              padding: "6px 12px 6px 12px",
              height: "56px",
              fontWeight: 500,
              fontSize: "0.875rem"
            },
            padding: "dense",
            paging: false,
            rowStyle: () => ({
              fontWeight: 400,
              fontSize: "0.875rem",
              backgroundColor: theme.palette.common.white
            }),
            search: false,
            sorting: false,
            showTitle: false,
            tableLayout: "fixed",
            thirdSortClick: false
          }}
        />

        <MaterialTable
          columns={bottomTableColumns}
          components={{
            Container: BottomTablePaperComponent,
            Header: () => null,
            Toolbar: () => null
          }}
          data={costEstimatesBottomTableData}
          localization={{
            body: {
              emptyDataSourceMessage: "No costs to display",
              filterRow: {
                filterTooltip: "Filter"
              }
            }
          }}
          options={{
            padding: "dense",
            paging: false,
            rowStyle: () => ({
              fontWeight: "bold",
              fontSize: "0.875rem",
              backgroundColor: theme.palette.common.white,
              border: 0
            }),
            search: false,
            sorting: false,
            showTitle: false,
            tableLayout: "fixed",
            thirdSortClick: false
          }}
        />

      </Box>

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >
        <TextField
          error={scheduleOfPaymentsParagraphError}
          label={sectionHeadingScheduleOfPayments}
          sx={{
            backgroundColor: pageBackgroundColour,
            width: "100%"
          }}
          multiline
          size="small"
          variant="outlined"
          InputProps={{
            sx: {
              backgroundColor: theme.palette.common.white
            }
          }}
          FormHelperTextProps={{
            sx: {
              textAlign: "right"
            }
          }}
          value={scheduleOfPaymentsParagraph}
          onBlur={(event) => {
            if (!inputError) {
              handleFieldUpdated(
                event.target.value,
                "schedule_of_payments_paragraph"
              );
            }
          }}
          onChange={(event) => {
            const {
              value
            } = event.target;
            setScheduleOfPaymentsParagraphLength(value.length);
            if (value.length > scheduleOfPaymentsParagraphLengthLimit) {
              setScheduleOfPaymentsParagraphError(true);
            } else if (scheduleOfPaymentsParagraphError) {
              setScheduleOfPaymentsParagraphError(false);
            }
            setScheduleOfPaymentsParagraph(value);
          }}
          helperText={
            scheduleOfPaymentsParagraphLengthLimit - scheduleOfPaymentsParagraphLength <= (scheduleOfPaymentsParagraphLengthLimit / 2) ?
              `${scheduleOfPaymentsParagraphLength}/${scheduleOfPaymentsParagraphLengthLimit}` : ""
          }
          inputRef={scheduleOfPaymentsParagraphInputRef}
        />

      </Box>

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >
        <TextField
          error={financingOptionParagraphError}
          label={sectionHeadingFinancingOptionParagraph}
          sx={{
            backgroundColor: pageBackgroundColour,
            width: "100%"
          }}
          multiline
          size="small"
          variant="outlined"
          InputProps={{
            sx: {
              backgroundColor: theme.palette.common.white
            }
          }}
          FormHelperTextProps={{
            sx: {
              textAlign: "right"
            }
          }}
          value={financingOptionParagraph}
          onBlur={(event) => {
            if (!inputError) {
              handleFieldUpdated(
                event.target.value,
                "financing_option_paragraph"
              );
            }
          }}
          onChange={(event) => {
            const {
              value
            } = event.target;
            setFinancingOptionParagraphLength(value.length);
            if (value.length > financingOptionParagraphLengthLimit) {
              setFinancingOptionParagraphError(true);
            } else if (financingOptionParagraphError) {
              setFinancingOptionParagraphError(false);
            }
            setFinancingOptionParagraph(value);
          }}
          helperText={
            financingOptionParagraphLengthLimit - financingOptionParagraphLength <= (financingOptionParagraphLengthLimit / 2) ?
              `${financingOptionParagraphLength}/${financingOptionParagraphLengthLimit}` : ""
          }
        />

      </Box>

    </DialogContent>
  );
}
import React from "react";

import clsx from "clsx";
import PropTypes from "prop-types";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Typography from "@mui/material/Typography";
import { withMaterialUserInterfaceTheme } from "common/hocs/theme";
import Box from "@mui/material/Box";



class DropZone extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      highlight: false
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  onFilesAdded(event) {
    const {files} = event.target;
    if (this.props.filesAdded) {
      const array = this.fileListToArray(files);
      this.props.filesAdded(array);
    }
  }

  onDragOver(event) {
    event.preventDefault();
    this.setState({ highlight: true });
  }

  onDragLeave(event) {
    this.setState({ highlight: false });
  }

  onDrop(event) {
    event.preventDefault();
    const {files} = event.dataTransfer;
    if (this.props.filesAdded) {
      const array = this.fileListToArray(files);
      this.props.filesAdded(array);
    }
    this.setState({ highlight: false });
  }

  fileListToArray(list) {
    const array = [];
    for (let i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  render() {

    const {theme} = this.props;

    return (
      <Box
        sx={{
          height: "150px",
          width: "100%",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          border: `2px dashed ${theme.palette.primary.main}`,
          background: this.state.highlight ?
            theme.palette.secondary.main : theme.palette.primary.light
        }}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.props.openFileDialog}
      >

        <input
          ref={this.props.fileInputRef}
          style={{
            display: "none"
          }}
          multiple
          type="file"
          onChange={this.onFilesAdded}
        />

        <CloudUploadIcon 
          color="primary" 
        />

        <Typography 
          variant="body1" 
          color="inherit" 
          noWrap
        >
          Select files or drag them here
        </Typography>

      </Box>
    );
  }
}

DropZone.propTypes = {
  theme: PropTypes.object.isRequired
};

export default withMaterialUserInterfaceTheme(
  DropZone
);

import type { Dispatch, SetStateAction } from "react";
import React, { useCallback, useMemo } from "react";

import MaterialTable from "@material-table/core";

import {
  Remove as RemoveIcon
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { CardContent, CardHeader, colors, Zoom } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import type { PaperProps } from "@mui/material/Paper";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";

import {
  renderFixedCostsColumn,
  renderMeasureIcon,
  renderTitleColumn,
  renderTypeColumn,
  renderVariableCostsColumn,
  renderVariableUnitColumn
} from "common/utils/tables/scenarioTable";
import {
  isCustomMeasure,
  isDefaultMeasure
} from "common/utils/types";

import type { Column, Action } from "@material-table/core";
import type { CustomMeasure } from "features/customMeasures/types";
import type { DefaultMeasure } from "features/defaultMeasures/types";


interface CustomScenarioMeasureCategorySectionProps {
  avatarColor: string;
  category: string;
  customMeasures: Array<CustomMeasure>;
  defaultMeasures: Array<DefaultMeasure>;
  handleSetAddMeasuresDialogCategory: Dispatch<SetStateAction<string>>;
  handleSetAddMeasuresDialogOpen: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  onUpdateCustomScenarioDefaultMeasures: (data: {
    add: boolean;
    measures: Array<number>;
  }) => void;
  onUpdateCustomScenarioCustomMeasures: (data: {
    add: boolean;
    custom_measures: Array<number>;
  }) => void;
  scenarioTitle: string;
  setConfirmationDialogTitle: Dispatch<SetStateAction<string>>;
  setConfirmationDialogMessage: Dispatch<SetStateAction<string>>;
  setConfirmationDialogOpen: Dispatch<SetStateAction<boolean>>;
  subHeaderText: string;
  tableHeaderColor: string;
  tableEvenRowColor: string;
  tableOddRowColor: string;
}

export function CustomScenarioMeasureCategorySection(
  {
    avatarColor,
    category,
    customMeasures,
    defaultMeasures,
    handleSetAddMeasuresDialogCategory,
    handleSetAddMeasuresDialogOpen,
    isLoading,
    onUpdateCustomScenarioDefaultMeasures,
    onUpdateCustomScenarioCustomMeasures,
    scenarioTitle,
    setConfirmationDialogTitle,
    setConfirmationDialogMessage,
    setConfirmationDialogOpen,
    subHeaderText,
    tableHeaderColor,
    tableEvenRowColor,
    tableOddRowColor
  }: CustomScenarioMeasureCategorySectionProps) {

  const theme = useTheme();

  const handleAvatarClick = useCallback(
    (title: string, message: string, show: boolean) => {
      setConfirmationDialogTitle(title);
      setConfirmationDialogMessage(message);
      setConfirmationDialogOpen(show);
    },
    [
      setConfirmationDialogTitle,
      setConfirmationDialogMessage,
      setConfirmationDialogOpen
    ]
  );

  const tableActions: Array<Action<DefaultMeasure | CustomMeasure>> = useMemo(
    () => {
      const removeIcon = () => {
        if (isLoading) {
          return <RemoveIcon
            sx={{
              color: theme.retrokitPalette.grey.main
            }}
          />;
        }
        return <RemoveIcon
          sx={{
            color: theme.retrokitPalette.red.dark
          }}
        />;
      };

      return [
        {
          icon: removeIcon,
          tooltip: `Remove from ${scenarioTitle} scenario`,
          onClick: (event: any, rowData: DefaultMeasure | CustomMeasure | DefaultMeasure[] | CustomMeasure[]) => {

            let measure;
            if (Array.isArray(rowData)) {
              if (rowData.length > 0) {
                const [firstMeasure] = rowData;
                measure = firstMeasure;
              }
            } else {
              measure = rowData;
            }

            if (measure !== undefined) {
              if (isDefaultMeasure(measure)) {
                onUpdateCustomScenarioDefaultMeasures({
                  add: false,
                  measures: [measure.id]
                });
              }

              if (isCustomMeasure(measure)) {
                onUpdateCustomScenarioCustomMeasures({
                  add: false,
                  custom_measures: [measure.id]
                });
              }
            }
          }
        }
      ];
    },
    [
      isLoading,
      onUpdateCustomScenarioCustomMeasures,
      onUpdateCustomScenarioDefaultMeasures,
      scenarioTitle,
      theme.retrokitPalette.grey.main,
      theme.retrokitPalette.red.dark
    ]
  );

  const tableColumns: Array<Column<DefaultMeasure | CustomMeasure>> = [
    {
      title: "",
      field: "icon_link",
      align: "center",
      width: 50,
      cellStyle: {
        padding: "0 8px 0 8px"
      },
      render: (rowData) => renderMeasureIcon(rowData),
      searchable: false
    },
    {
      title: "Name",
      field: "title",
      type: "string",
      cellStyle: {
        textAlign: "left",
        padding: "8px 16px 8px 16px"
      },
      defaultSort: "asc",
      render: (rowData) => renderTitleColumn(
        rowData,
        theme
      )
    },
    {
      title: "Fixed",
      field: "fixed_costs",
      type: "numeric",
      cellStyle: {
        textAlign: "right",
        padding: "0 8px 0 8px"
      },
      render: (rowData) => renderFixedCostsColumn(
        rowData
      )
    },
    {
      title: "Variable",
      field: "variable_costs",
      type: "numeric",
      cellStyle: {
        textAlign: "right",
        padding: "0 8px 0 8px"
      },
      render: (rowData) => renderVariableCostsColumn(
        rowData
      )
    },
    {
      title: "Multiplier",
      field: "variable_multiplier",
      type: "string",
      cellStyle: {
        textAlign: "left",
        padding: "0 8px 0 8px"
      }
    },
    {
      title: "Unit",
      field: "variable_unit",
      type: "string",
      cellStyle: {
        textAlign: "left",
        padding: "0 8px 0 8px"
      },
      render: (rowData) => renderVariableUnitColumn(
        rowData
      )
    },
    {
      title: "Type",
      field: "type",
      align: "center",
      width: 50,
      cellStyle: {
        padding: "0 16px 0 16px"
      },
      render: (rowData) => renderTypeColumn(
        rowData,
        handleAvatarClick,
        theme
      )
    }
  ];

  const tableData: Array<DefaultMeasure | CustomMeasure> = useMemo(
    () => [
      ...defaultMeasures.filter(
        (measure) => measure.retrokit_category === category
      ),
      ...customMeasures.filter(
        (measure) => measure.retrokit_category === category
      )
    ],
    [
      category,
      customMeasures,
      defaultMeasures
    ]
  );

  const handleOnAddMeasureCategoryClicked = useCallback(
    () => {
      handleSetAddMeasuresDialogCategory(category);
      handleSetAddMeasuresDialogOpen(true);
    },
    [
      category,
      handleSetAddMeasuresDialogCategory,
      handleSetAddMeasuresDialogOpen
    ]
  );

  const TablePaperComponent = useCallback(
    (
      props: JSX.IntrinsicAttributes & PaperProps
    ) => <Paper
      elevation={0}
      {...props}
    />,
    []
  );

  return (
    <Card
      variant="outlined"
      sx={{
        borderWidth: "0px",
        borderRadius: "20px"
      }}
    >
      <CardHeader
        avatar={
          <Avatar
            style={{
              backgroundColor: avatarColor
            }}
            aria-label={`avatar-${category}`}
          >
            {category.charAt(0).toUpperCase()}
          </Avatar>
        }
        action={
          <Tooltip
            title={`Add ${category} measures to ${scenarioTitle}`}
            arrow
            TransitionComponent={Zoom}
          >
            <IconButton
              aria-label={`add-${category}`}
              onClick={() => handleOnAddMeasureCategoryClicked()}
            >
              <AddIcon
                sx={{
                  color: theme.retrokitPalette.green.dark
                }}
              />
            </IconButton>
          </Tooltip>
        }
        title={category}
        subheader={subHeaderText}
      />

      <CardContent
        sx={{
          padding: 0
        }}
      >
        <MaterialTable
          actions={tableActions}
          columns={tableColumns}
          components={{
            Container: TablePaperComponent
          }}
          data={tableData}
          isLoading={isLoading}
          localization={{
            body: {
              emptyDataSourceMessage: `No ${category.toLowerCase()} measures`
            }
          }}
          options={{
            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: tableHeaderColor,
              color: "#ffffff",
              fontWeight: "bold",
              whiteSpace: "nowrap",
              padding: "0 16px 0 16px"
            },
            padding: "dense",
            paging: false,
            rowStyle: (rowData, index) => {

              if (isDefaultMeasure(rowData)) {
                if (index % 2) {
                  return {
                    backgroundColor: colors.indigo["100"],
                    fontSize: 14
                  };
                }
                return {
                  backgroundColor: colors.indigo["50"],
                  fontSize: 14
                };
              }

              if (index % 2) {
                return {
                  backgroundColor: tableEvenRowColor,
                  fontSize: 14
                };
              }
              return {
                backgroundColor: tableOddRowColor,
                fontSize: 14
              };

            },
            selection: false,
            showTitle: false,
            toolbar: false
          }}
        />
      </CardContent>

    </Card>
  );
}
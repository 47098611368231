import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";


export function BEREdited() {
  return <Box
    sx={{
      paddingLeft: "16px",
      paddingRight: "16px",
      paddingBottom: "16px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    }}
  >

    <Typography
      variant="subtitle2"
      style={{
        color: "red"
      }}
    >
      IMPORTANT NOTE ON DWELLING BER
    </Typography>

    <Typography
      variant="body1"
    >
      The BER value has been edited and may not reflect the original BER.
    </Typography>

  </Box>;
}


import React, { useCallback, useMemo, useState } from "react";

import MaterialTable from "@material-table/core";


import {
  Add as AddIcon,
  Close as CloseIcon, Person as PersonIcon
} from "@mui/icons-material";
import { colors, Zoom } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import type { PaperProps } from "@mui/material/Paper";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";

import { ConfirmationDialog } from "common/components/ConfirmationDialog";
import { RetroKitIcon } from "common/components/RetroKitIcon";
import {
  ADD_SCENARIO_TO_PROJECT_TABLE_COLUMNS
} from "common/constants/tables";

import type { Action, Column } from "@material-table/core";
import type { CustomScenario } from "features/customScenarios/types";
import type { DefaultScenario } from "features/defaultScenarios/types";


interface AddProjectScenariosDialogProps {
  customScenarios: Array<CustomScenario>;
  defaultScenarios: Array<DefaultScenario>;
  isLoading: boolean;
  onAddCustomScenario: (scenario: CustomScenario) => void;
  onAddDefaultScenario: (scenario: DefaultScenario) => void;
  onClose: () => void;
  open: boolean;
  projectTitle: string;
}

export function AddScenariosToProjectDialog(
  {
    customScenarios,
    defaultScenarios,
    isLoading,
    onAddCustomScenario,
    onAddDefaultScenario,
    onClose,
    open,
    projectTitle
  }: AddProjectScenariosDialogProps) {

  const theme = useTheme();

  const [
    confirmationDialogTitle, setConfirmationDialogTitle
  ] = useState<string>("");

  const [
    confirmationDialogMessage, setConfirmationDialogMessage
  ] = useState<string>("");

  const [
    confirmationDialogOpen, setConfirmationDialogOpen
  ] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    onClose();
  }, [
    onClose
  ]);

  const handleCloseConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
  };

  const handleAvatarClick = useCallback(
    (title: string, message: string, show: boolean) => {
      setConfirmationDialogTitle(title);
      setConfirmationDialogMessage(message);
      setConfirmationDialogOpen(show);
    },
    [
      setConfirmationDialogTitle,
      setConfirmationDialogMessage,
      setConfirmationDialogOpen
    ]
  );

  const handleAddCustomScenarioClicked = useCallback(
    (scenario: CustomScenario) => {
      onAddCustomScenario(scenario);
    },
    [
      onAddCustomScenario
    ]
  );

  const handleAddDefaultScenarioClicked = useCallback(
    (scenario: DefaultScenario) => {
      onAddDefaultScenario(scenario);
    },
    [
      onAddDefaultScenario
    ]
  );

  const actions: Array<(
    rowData: CustomScenario | DefaultScenario
  ) => Action<CustomScenario | DefaultScenario>> = useMemo(
    () => {
      const addIcon = () => <AddIcon sx={{
        color: theme.retrokitPalette.green.dark
      }}
      />;

      return [
        (rowData: CustomScenario | DefaultScenario) => ({
          icon: addIcon,
          tooltip: `Add to ${projectTitle} project`,
          onClick: () => {

            if ("custom_measures" in rowData) {
              handleAddCustomScenarioClicked(rowData);
            } else {
              handleAddDefaultScenarioClicked(rowData);
            }
          }
        })
      ];
    },
    [
      handleAddCustomScenarioClicked,
      handleAddDefaultScenarioClicked,
      projectTitle,
      theme.retrokitPalette.green.dark
    ]
  );

  const freeActions: Array<Action<CustomScenario | DefaultScenario>> = useMemo(
    () => {

      const closeIcon = () => <CloseIcon />;

      return [
        {
          icon: closeIcon,
          isFreeAction: true,
          tooltip: "Close",
          onClick: handleClose
        }
      ];
    },
    [
      handleClose
    ]
  );

  const tableColumns: Array<Column<CustomScenario | DefaultScenario>> = [
    ...ADD_SCENARIO_TO_PROJECT_TABLE_COLUMNS,
    {
      title: "Type",
      field: "custom_measures",
      align: "center",
      width: 50,
      cellStyle: {
        padding: "0 16px 0 16px"
      },
      render: (rowData) => {
        if ("custom_measures" in rowData) {
          return <Tooltip
            title="Custom scenario"
            arrow
            TransitionComponent={Zoom}
          >
            <Avatar
              sx={{
                backgroundColor: theme.retrokitPalette.indigo.main,
                width: "36px",
                height: "36px"
              }}
              aria-label="avatar-user"
              onClick={() => {
                handleAvatarClick(
                  "Custom Scenarios",
                  "These are custom scenarios that are " +
                  "created by a user. They can be edited in " +
                  "the Scenarios section.",
                  true
                );
              }}
            >
              <PersonIcon style={{ color: "#FFFFFF" }} />
            </Avatar>
          </Tooltip>;
        }
        return <Tooltip
          title="RetroKit scenario"
          arrow
          TransitionComponent={Zoom}
        >
          <Avatar
            sx={{
              backgroundColor: theme.palette.primary.main,
              width: "36px",
              height: "36px"
            }}
            aria-label="avatar-retrokit"
            onClick={() => {
              handleAvatarClick(
                "RetroKit Scenarios",
                "These are default scenarios provided by " +
                "RetroKit. You can only edit them " +
                "within the scope of this project.",
                true
              );
            }}
          >
            <RetroKitIcon
              iconLink="static/public/images/logos/rk-logo-transparent-dark-background.svg"
            />
          </Avatar>
        </Tooltip>;
      }
    }
  ];

  const tableData: Array<CustomScenario | DefaultScenario> = useMemo(
    () => [
      ...defaultScenarios,
      ...customScenarios
    ],
    [
      defaultScenarios,
      customScenarios
    ]
  );

  const TablePaperComponent = useCallback(
    (
      props: JSX.IntrinsicAttributes & PaperProps
    ) => <Paper
      elevation={0}
      {...props}
    />,
    []
  );

  const DialogPaperComponent = useCallback(
    (
      props: JSX.IntrinsicAttributes & PaperProps
    ) => <Paper
      elevation={0}
      sx={{
        borderWidth: "0px",
        borderRadius: "20px"
      }}
      variant="outlined"
      {...props}
    />,
    []
  );

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={handleClose}
      scroll="body"
      PaperComponent={DialogPaperComponent}
    >

      <Box
        sx={{
          width: "100%",
          flex: 1,
          overflowX: "hidden",
          overflowY: "auto",
          padding: "0px"
        }}
      >
        <MaterialTable
          actions={[
            ...actions,
            ...freeActions
          ]}
          columns={tableColumns}
          components={{
            Container: TablePaperComponent
          }}
          data={tableData}
          isLoading={isLoading}
          localization={{
            body: {
              emptyDataSourceMessage: "No scenarios to display"
            }
          }}
          options={{
            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: colors.amber["500"],
              color: theme.palette.common.white,
              fontWeight: "bold",
              whiteSpace: "nowrap",
              padding: "0 16px 0 16px"
            },
            maxBodyHeight: "700px",
            padding: "dense",
            paging: true,
            pageSize: tableData.length > 0 && tableData.length < 5 ? tableData.length : 5,
            pageSizeOptions: tableData.length > 0 && tableData.length < 5 ? [tableData.length] : [5, 10, 20],
            rowStyle: (rowData, index) => {

              if ("custom_measures" in rowData) {
                if (index % 2) {
                  return {
                    backgroundColor: colors.amber["100"],
                    fontSize: 14
                  };
                }
                return {
                  backgroundColor: colors.amber["50"],
                  fontSize: 14
                };
              }

              if (index % 2) {
                return {
                  backgroundColor: colors.indigo["100"],
                  fontSize: 14
                };
              }
              return {
                backgroundColor: colors.indigo["50"],
                fontSize: 14
              };
            },
            search: true,
            showTitle: true,
            selection: false,
            toolbar: true
          }}
          title={`Add Scenarios to ${projectTitle} Project`}
        />
      </Box>

      <DialogActions
        sx={{
          paddingRight: "24px"
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClose}
        >
          Close
        </Button>
      </DialogActions>

      <ConfirmationDialog
        buttonText="Okay"
        message={confirmationDialogMessage}
        title={confirmationDialogTitle}
        onClose={handleCloseConfirmationDialog}
        onSubmit={() => {
          handleCloseConfirmationDialog();
        }}
        open={confirmationDialogOpen}
      />

    </Dialog>
  );
}
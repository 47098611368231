import { getDateFromUTCSeconds } from "common/utils/date-utils";
import { 
  customMeasuresApiTransformer 
} from "features/customMeasures/api/transformers";
import { 
  defaultMeasuresApiTransformer 
} from "features/defaultMeasures/api/transformers";

import type {
  CustomScenarioApiResponse 
} from "features/customScenarios/api/types";
import type { CustomScenario } from "features/customScenarios/types";


export function customScenariosApiTransformer(
  response: Array<CustomScenarioApiResponse>
): Array<CustomScenario> {

  return response.map(
    (scenariosApiResponse): CustomScenario => ({
      id: scenariosApiResponse.id,
      title: scenariosApiResponse.title,
      created_on: getDateFromUTCSeconds(
        parseInt(scenariosApiResponse.created_on, 10)
      ),
      description: scenariosApiResponse.description,
      measures: defaultMeasuresApiTransformer(
        scenariosApiResponse.measures
      ),
      custom_measures: customMeasuresApiTransformer(
        scenariosApiResponse.custom_measures
      ),
      is_default: scenariosApiResponse.is_default
    })
  );
}

export function customScenarioApiTransformer(
  response: CustomScenarioApiResponse
): CustomScenario {

  return {
    id: response.id,
    title: response.title,
    created_on: getDateFromUTCSeconds(
      parseInt(response.created_on, 10)
    ),
    description: response.description,
    measures: defaultMeasuresApiTransformer(
      response.measures
    ),
    custom_measures: customMeasuresApiTransformer(
      response.custom_measures
    ),
    is_default: response.is_default
  };
}
import { useFormContext, Controller } from "react-hook-form";

import { FormHelperText } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import type { DialogProps } from "@mui/material/Dialog";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Link from "@mui/material/Link";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import type { Control } from "react-hook-form";

type FetchBerTermsAndConditionModalProps = DialogProps;

function YesAndNoInput(
  control: Control,
  name: string,
) {
  
  const theme = useTheme()
  
  return (
    <Controller
      control={control}
      name={name}
      defaultValue="no"
      rules={{
        required: true,
        pattern: {
          value: /^yes$/,
          message: "You must select \"Yes\" to continue"
        }
      }}
      render={
        (
          {
            field: { value, onChange },
            fieldState: { error }
          }
        ) => (
          <RadioGroup
            value={String(value)}
            onChange={onChange}
          >
            <FormControlLabel
              label="Yes"
              value="yes"
              labelPlacement="end"
              control={
                <Radio
                  color="secondary"
                />
              }
            />
            <FormControlLabel
              label="No"
              value="no"
              labelPlacement="end"
              control={
                <Radio
                  color="secondary"
                />
              }
            />
            {
              error?.message && (
                <FormHelperText
                  sx={{
                    color: theme.palette.error.main
                  }}
                >
                  {error.message}
                </FormHelperText>
              )
            }
          </RadioGroup>
        )
      }
    />
  );
}

export function SeaiApiTermsAndConditionModal(
  props: FetchBerTermsAndConditionModalProps
) {
  const {
    onClose
  } = props;

  const {
    control
  } = useFormContext();

  const handleOnClose = () => {
    if (onClose) {
      onClose({}, "backdropClick");
    }
  };

  return (
    <Dialog
      fullWidth
      scroll="body"
      {...props}
    >
      <DialogTitle>
        Terms and Conditions
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          "& > * + *": {
            marginTop: "1em"
          }
        }}
      >
        <Typography>
          RetroKit will source and analyse the Building Energy Rating (BER) data
          for your home, but first needs you to confirm that you have consent to
          access the BER data for this dwelling from the dwelling owner. Please
          provide us with a recent utility bill (e.g. electricity bill dated
          within the last three months) showing the MPRN (Meter Point Reference
          Number) for this dwelling and enter the MPRN and Eircode in the boxes
          provided.
        </Typography>

        <Typography>
          If you have any questions about this process, please email us on{" "}
          <Link
            href="mailto:info@retrokit.eu"
            underline="hover"
          >
            info@retrokit.eu
          </Link>

        </Typography>

        <Typography>
          RetroKit’s analysis is based on the calculations underpinning the
          Building Energy Rating (BER) for Irish homes. RetroKit is not a
          replacement for a BER assessments by a registered BER Assessor or any
          other form of survey or financial/technical advice that the user may
          wish to avail of. RetroKit Ltd. is not liable for any losses you may
          incur directly or indirectly by use of the RetroKit software.
        </Typography>

        <Typography>
          Please select YES to each of the following so that we can proceed with
          this data request.
        </Typography>

        <Box>

          <Typography>
            1) Do you accept the text above detailing the method and limitations
            of this process?
          </Typography>

          {
            YesAndNoInput(control, "termsAcceptProcess")
          }

        </Box>

        <Box>

          <Typography>
            2) Do you consent for SEAI to release your BER data to RetroKit Ltd.
            for use in RetroKit&apos;s data analysis process?
          </Typography>

          {
            YesAndNoInput(control, "termsReleaseBerData")
          }

        </Box>

        <Box>

          <Typography>
            3) Do you consent for RetroKit Ltd. and SEAI to retain and access
            the file you submit during this data request? This file may need to
            be reviewed for quality review of the data request process.
          </Typography>

          {
            YesAndNoInput(control, "termsAccessFile")
          }

        </Box>

        <Box>

          <Typography>
            4) Do you have consent from the homeowner to access the BER data for
            this dwelling from SEAI via RetroKit?
          </Typography>

          {
            YesAndNoInput(control, "termsConsentHomeowner")
          }

        </Box>

      </DialogContent>

      <DialogActions>

        <Button
          onClick={handleOnClose}
        >
          Ok
        </Button>
        <Button
          onClick={handleOnClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const BER_COLOUR_CODE_MAP = {
    A: "#00a651",
    A1: "#00a651",
    A2: "#00a651",
    A3: "#00a651",
    B: "#51b848",
    B1: "#51b848",
    B2: "#51b848",
    B3: "#51b848",
    C: "#b6d730",
    C1: "#b6d730",
    C2: "#b6d730",
    C3: "#b6d730",
    D: "#fff200",
    D1: "#fff200",
    D2: "#fff200",
    E: "#fdbb13",
    E1: "#fdbb13",
    E2: "#fdbb13",
    F: "#f37221",
    G: "#ed1c23",
    Unknown: "#808080"
};

export const BER_SCORE_MAP = {
    'A1': [-100000, 24],
    'A2': [25, 49],
    'A3': [50, 74],
    'B1': [75, 99],
    'B2': [100, 124],
    'B3': [125, 149],
    'C1': [150, 174],
    'C2': [175, 199],
    'C3': [200, 224],
    'D1': [225, 259],
    'D2': [260, 299],
    'E1': [300, 339],
    'E2': [340, 379],
    'F': [380, 449],
    'G': [450, 100000]
}
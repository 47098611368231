import type { ChangeEvent, MouseEventHandler } from "react";
import { useEffect, useState } from "react";

import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";

import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { FileInput } from "common/components/FileInput";
import { LoadingButton } from "common/components/LoadingButton";
import {
  useFetchDwellingBerDataView
} from "features/uploads/hooks/useFetchDwellingBerDataView";
import {
  SeaiApiTermsAndConditionModal
} from "features/uploads/modals/SeaiApiTermsAndConditionModal";

import type { SubmitHandler } from "react-hook-form";

type YesOrNo = "yes" | "no";

interface FetchBerFormData {
  mprn: string;
  eircode: string;
  consentFile: File;
  termsAcceptProcess: YesOrNo;
  termsReleaseBerData: YesOrNo;
  termsAccessFile: YesOrNo;
  termsConsentHomeowner: YesOrNo;
}

export function FetchDwellingBerDataView() {

  const formMethods = useForm<FetchBerFormData>({
    mode: "all"
  });

  const {
    control,
    handleSubmit,
    formState,
    reset,
    resetField
  } = formMethods;

  const {
    isLoading,
    isFetchBerSuccess,
    fetchBerFile
  } = useFetchDwellingBerDataView();

  const [
    isDialogOpen,
    setIsDialogOpen
  ] = useState(false);
  const [
    hideDialog,
    setHideDialog
  ] = useState(true);

  const mprnOrEircodeWatch = useWatch({
    control,
    name: ["mprn", "eircode"]
  });

  const sanitizeTextInput =
    (formOnChange: (event: ChangeEvent<HTMLInputElement>) => void) =>
      (event: ChangeEvent<HTMLInputElement>) => {
        // Remove all white space
        event.target.value = (event.target.value ?? "").replace(/\s/g, "");

        formOnChange(event);
      };

  // Reset file field on MPRN or Eircode change
  useEffect(() => {
    resetField("consentFile");
  }, [mprnOrEircodeWatch, resetField]);

  // Hack to register react form hook inputs
  useEffect(() => {
    setHideDialog(true);
    setIsDialogOpen(true);
  }, []);

  useEffect(() => {
    if (isFetchBerSuccess === undefined) {
      return;
    }
    if (isFetchBerSuccess) {
      reset();

      // Hack to register react form hook inputs
      setHideDialog(true);
      setIsDialogOpen(true);

      toast.success("Retrieved Dwelling from SEAI", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
      });
    }
  }, [isFetchBerSuccess, reset]);

  const submitHandler: SubmitHandler<FetchBerFormData> = (data) => {
    fetchBerFile({
      mprn: parseInt(data.mprn, 10),
      postcode: data.eircode,
      file: data.consentFile
    }).then((response) => {

      if ("error" in response) {
        if ("data" in response.error) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if ("detail" in response.error.data) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            toast.error(response.error.data.detail, {
              position: toast.POSITION.TOP_RIGHT,
              theme: "colored"
            });
          } else {
            toast.error(String(response.error), {
              position: toast.POSITION.TOP_RIGHT,
              theme: "colored"
            });
          }
        }
      }
    });
  };

  const onTermsAndConditionLinkClicked: MouseEventHandler<HTMLAnchorElement> = (
    event
  ) => {
    if (hideDialog) {
      setHideDialog(false);
    }
    setIsDialogOpen(true);
    event.preventDefault();
  };

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "1em",
        "& > * + *": {
          marginTop: "1em"
        }
      }}
    >
      <FormProvider
        {...formMethods}
      >
        <>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
              columnGap: "1em"
            }}
          >
            <Controller
              name="mprn"
              control={control}
              /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
              defaultValue={"" as any}
              rules={{
                required: "MPRN field is required",
                pattern: {
                  value: /^\d{11}$/,
                  message: "MPRN number must be 11 digit numeric value"
                }
              }}
              render={({
                         field: { onChange, value },
                         fieldState: { error }
                       }) => (
                <TextField
                  required
                  label="MPRN"
                  type="number"
                  error={!!error}
                  value={value}
                  onChange={sanitizeTextInput(onChange)}
                  helperText={error?.message ?? ""}
                  variant="outlined"
                />
              )}
            />
            <Controller
              name="eircode"
              control={control}
              defaultValue=""
              rules={{
                required: "Eircode field is required",
                pattern: {
                  value: /^[\w\d]{7}$/,
                  message:
                    "Eircode must be alphanumeric characters of length 7"
                }
              }}
              render={({
                         field: { onChange, value },
                         fieldState: { error }
                       }) => (
                <TextField
                  required
                  label="Eircode"
                  error={!!error}
                  value={value}
                  onChange={sanitizeTextInput(onChange)}
                  helperText={error?.message ?? ""}
                  variant="outlined"
                />
              )}
            />
          </Box>

          <Controller
            name="consentFile"
            control={control}
            rules={{
              required:
                "Please upload a consent file in *.pdf, *.jpg or *.png format."
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FileInput
                error={!!error}
                helperText={error?.message}
                accept={
                  {
                    'application/pdf': ['.pdf'],
                    'image/jpeg': ['.jpg', '.jpeg'],
                    'image/png': ['.png'],
                  }
                }
                label={
                  <>
                    <Typography>
                      Drag n drop file here, or click to select file
                    </Typography>
                    <Typography>
                      (Only *.pdf, *.jpg and *.png files will be accepted)
                    </Typography>
                  </>
                }
                value={value}
                onDrop={(files) => onChange(files[0] ?? undefined)}
              />
            )}
          />

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="start"
          >
            <Link
              href="features/uploads/components/FetchDwellingBerDataView#"
              underline="hover"
              sx={{
                textDecoration: "underline"
              }}
              onClick={onTermsAndConditionLinkClicked}
            >
              * Click here to review terms and conditions
            </Link>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
          >
            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="secondary"
              disabled={!formState.isValid}
              /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
              onClick={handleSubmit(submitHandler)}
            >
              Fetch Ber File
            </LoadingButton>
          </Box>
          <SeaiApiTermsAndConditionModal
            open={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            style={{ ...(hideDialog ? { display: "none" } : {}) }}
          />
        </>
      </FormProvider>
    </Paper>
  );
}

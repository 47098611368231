import React, { useCallback, useState } from "react";

import { toast } from "react-toastify";

import Box from "@mui/material/Box";

import {
  ConfirmationDialog
} from "common/components/ConfirmationDialog";
import {
  HomeEnergyAssessmentDialog
} from "features/homeEnergyAssessment/dialogs/HomeEnergyAssessmentDialog";
import {
  useHomeEnergyAssessment
} from "features/homeEnergyAssessment/hooks/useHomeEnergyAssessment";
import {
  ProjectDetails
} from "features/projects/components/ProjectDetails";
import {
  ProjectDwellings
} from "features/projects/components/ProjectDwellings";
import {
  ProjectScenarios
} from "features/projects/components/ProjectScenarios";
import {
  useProjectView
} from "features/projects/hooks/useProjectView";
import {
  useRunProjectWebSocket
} from "features/projects/hooks/useRunProjectWebSocket";
import {
  DisplayPdfDialog
} from "features/reports/dialogs/DisplayPdfDialog";
import {
  GenerateReportDialog
} from "features/reports/dialogs/GenerateReportDialog";
import {
  useReportGenerationView
} from "features/reports/hooks/useReportGenerationView";
import {
  TechnicalAssessmentFormModal1
} from "features/technicalAssessmentForm/dialogs/TechnicalAssessmentFormModal1";
import {
  TechnicalAssessmentFormModal10
} from "features/technicalAssessmentForm/dialogs/TechnicalAssessmentFormModal10";
import {
  TechnicalAssessmentFormModal2
} from "features/technicalAssessmentForm/dialogs/TechnicalAssessmentFormModal2";
import {
  TechnicalAssessmentFormModal3
} from "features/technicalAssessmentForm/dialogs/TechnicalAssessmentFormModal3";
import {
  TechnicalAssessmentFormModal4
} from "features/technicalAssessmentForm/dialogs/TechnicalAssessmentFormModal4";
import {
  TechnicalAssessmentFormModal5
} from "features/technicalAssessmentForm/dialogs/TechnicalAssessmentFormModal5";
import {
  TechnicalAssessmentFormModal6
} from "features/technicalAssessmentForm/dialogs/TechnicalAssessmentFormModal6";
import {
  TechnicalAssessmentFormModal7
} from "features/technicalAssessmentForm/dialogs/TechnicalAssessmentFormModal7";
import {
  TechnicalAssessmentFormModal8
} from "features/technicalAssessmentForm/dialogs/TechnicalAssessmentFormModal8";
import {
  TechnicalAssessmentFormModal9
} from "features/technicalAssessmentForm/dialogs/TechnicalAssessmentFormModal9";
import {
  useTafModal
} from "features/technicalAssessmentForm/hooks/useTafModal";

import type { ErrorResponse } from "common/api/types";
import type { CustomScenario } from "features/customScenarios/types";
import type { DefaultScenario } from "features/defaultScenarios/types";
import type { Dwelling } from "features/dwellings/types";
import type {
  CreateOrGetHEAApiRequest
} from "features/homeEnergyAssessment/api/types";
import type {
  UpdateProjectScenarioRecommendationRequest,
  DeleteProjectApiRequest,
  RunProjectWebSocketRequest,
  UpdateProjectDetailsRequest,
  UpdateProjectDwellingsRequest,
  ExportSuperhomesRequest
} from "features/projects/api/types";
import type { MinimalProject } from "features/projects/types";
import type { ProjectScenario } from "features/projectScenarios/types";
import type {
  GenerateHUP1Request,
  GenerateHUP2Request
} from "features/reports/api/types";
import type {
  CreateTAFApiRequest,
  UpdateTAFApiRequest
} from "features/technicalAssessmentForm/api/types";
import type {
  TechnicalAssessmentForm
} from "features/technicalAssessmentForm/types";


type ProjectViewProps = {
  minimalProject: MinimalProject;
  onDeleteProject: (requestArgs: DeleteProjectApiRequest) => void;
  onViewClicked: () => void;
};

export function ProjectView(
  {
    minimalProject,
    onDeleteProject,
    onViewClicked
  }: ProjectViewProps
) {

  // HOOKS
  const {
    customScenariosNotInProject,
    defaultScenariosNotInProject,
    isLoading,
    otherDwellings,
    project,
    projectDwellings,
    projectScenarios,
    superhomesExport,
    updateProjectDetails,
    updateProjectDwelling,
    updateProjectScenarios,
    updateProjectCustomScenarios,
    updateProjectDefaultScenarios,
    updateProjectScenarioRecommendation
  } = useProjectView(minimalProject.id);

  const {
    generateHEAReport,
    generateHUP1Report,
    generateHUP2Report,
    hea1List,
    hea2List
  } = useReportGenerationView();

  const {
    isRunProjectFetching,
    runProjectTrigger
  } = useRunProjectWebSocket();

  // noinspection JSUnusedLocalSymbols
  const {
    createOrGetTAF,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isTafLoading,
    isTafUpdating,
    taf,
    updateTAF
  } = useTafModal();

  const {
    createOrGetHomeEnergyAssessmentTrigger
  } = useHomeEnergyAssessment();

  // STATE VARIABLES
  const [
    showReportPDFModal,
    setShowReportPDFModal
  ] = useState<boolean>(false);

  const [
    reportPDFUrl, setReportPDFUrl
  ] = useState<string>("");

  const [
    showGenerateReportModal,
    setShowGenerateReportModal
  ] = useState<boolean>(false);

  const [
    reportDwelling,
    setReportDwelling
  ] = useState<Dwelling>();

  const [
    reportScenario,
    setReportScenario
  ] = useState<ProjectScenario>();

  // noinspection JSUnusedLocalSymbols
  const [
    confirmationDialogTitle,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setConfirmationDialogTitle
  ] = useState<string>("");

  // noinspection JSUnusedLocalSymbols
  const [
    confirmationDialogMessage,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setConfirmationDialogMessage
  ] = useState<string>("");

  const [
    confirmationDialogOpen,
    setConfirmationDialogOpen
  ] = useState<boolean>(false);

  const [
    showTechnicalAssessmentFormModal,
    setShowTechnicalAssessmentFormDialog
  ] = useState(false);

  const [
    showHomeEnergyAssessmentModal,
    setShowHomeEnergyAssessmentDialog
  ] = useState(false);

  const [
    tafStep,
    setTafStep
  ] = useState(0);

  const [
    heaStep,
    setHeaStep
  ] = useState(1);

  const [
    selectedDwellings,
    setSelectedDwellings
  ] = useState<Dwelling[]>([]);

  // CALLBACKS
  const handleRunProject = useCallback(
    (requestArgs: RunProjectWebSocketRequest) => {
      runProjectTrigger(requestArgs, false);
    },
    [
      runProjectTrigger
    ]
  );

  const handleUpdateProjectDetails = useCallback(
    (requestArgs: UpdateProjectDetailsRequest) => {
      if (project) {
        updateProjectDetails(
          requestArgs
        );
      }
    },
    [
      project,
      updateProjectDetails
    ]
  );

  const handleUpdateProjectScenarioRecommendation = useCallback(
    (requestArgs: UpdateProjectScenarioRecommendationRequest) => {
      if (project) {
        updateProjectScenarioRecommendation(
          requestArgs
        );
      }
    },
    [
      project,
      updateProjectScenarioRecommendation
    ]
  );

  const handleSuperhomesExport = useCallback(
    (requestArgs: ExportSuperhomesRequest) => {
      if (project) {
        superhomesExport(
          requestArgs
        ).unwrap().then(
          (response) => {
            const link = document.createElement(
              "a"
            );
            link.download = response.name;
            link.href = response.url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        ).catch(
          (errorResponse: ErrorResponse) => {
            toast.dismiss();
            toast.error(
              `${String(errorResponse.data)}`,
              {
                autoClose: 10000,
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored"
              }
            );
          }
        );
      }
    },
    [
      project,
      superhomesExport
    ]
  );

  const handleOnUpdateProjectDwellings = useCallback(
    (data: Pick<UpdateProjectDwellingsRequest, "add" | "remove">) => {
      if (project) {
        updateProjectDwelling({
          id: project.id,
          ...data
        });
      }
    },
    [
      project,
      updateProjectDwelling
    ]
  );

  const handleRemoveProjectScenarios = useCallback(
    (scenario: ProjectScenario) => {
      if (project) {
        updateProjectScenarios({
          id: project.id,
          scenario: scenario.id,
          add: false
        });
      }
    },
    [
      project,
      updateProjectScenarios
    ]
  );

  const handleAddCustomScenario = useCallback(
    (scenario: CustomScenario) => {
      if (project) {
        updateProjectCustomScenarios({
          id: project.id,
          customScenarios: [scenario.id],
          add: true
        });
      }
    },
    [
      project,
      updateProjectCustomScenarios
    ]
  );

  const handleAddDefaultScenario = useCallback(
    (scenario: DefaultScenario) => {
      if (project) {
        updateProjectDefaultScenarios({
          id: project.id,
          defaultScenarios: [scenario.id],
          add: true
        });
      }
    },
    [
      project,
      updateProjectDefaultScenarios
    ]
  );

  const handleCreateOrGetHea = useCallback(
    (
      requestArgs: CreateOrGetHEAApiRequest,
      dwelling: Dwelling,
      recommendedScenario: ProjectScenario
    ) => {

      setReportDwelling(dwelling);
      setReportScenario(recommendedScenario);

      createOrGetHomeEnergyAssessmentTrigger(
        requestArgs
      ).then(
        () => {
          setHeaStep(1);
          setShowHomeEnergyAssessmentDialog(true);
        }
      ).catch(
        (errorResponse: ErrorResponse) => {
          toast.dismiss();
          toast.error(
            `${String(errorResponse.data)}`,
            {
              autoClose: 10000,
              position: toast.POSITION.TOP_RIGHT,
              theme: "colored"
            }
          );
        }
      );
    },
    [
      createOrGetHomeEnergyAssessmentTrigger
    ]
  );

  const handleUpdateHeaStep = useCallback(
    (newHeaStep: number) => {
      setHeaStep(newHeaStep);
    },
    []
  );

  const handleCreateOrGetTaf = useCallback(
    (requestArgs: CreateTAFApiRequest) => {

      createOrGetTAF(requestArgs).unwrap().then(
        (response) => {
          if (response) {
            setTafStep(1);
            setShowTechnicalAssessmentFormDialog(true);
          }
        }
      ).catch(
        (errorResponse: ErrorResponse) => {
          toast.dismiss();
          toast.error(
            `${String(errorResponse.data)}`,
            {
              autoClose: 10000,
              position: toast.POSITION.TOP_RIGHT,
              theme: "colored"
            }
          );
        }
      );
    },
    [
      createOrGetTAF
    ]
  );

  const handleUpdateTaf = useCallback(
    (requestArgs: UpdateTAFApiRequest) => new Promise<TechnicalAssessmentForm>((resolve, reject) => {

      let errorFound = false;
      let errorText = "";

      if (project !== undefined) {

        if (selectedDwellings.length > 0) {
          const recommendedScenario = project.scenario_recommendations.find(
            recommendation => recommendation.dwelling === selectedDwellings[0].id
          );

          if (recommendedScenario !== undefined) {


            const existingTafCacheKey = {
              project_id: project.id,
              dwelling_id: selectedDwellings[0].id,
              scenario_id: recommendedScenario.scenario,
                type: selectedDwellings[0].type
            };

            updateTAF({
              tafUpdate: requestArgs,
              existingTAFCacheKey: existingTafCacheKey
            }).unwrap().then(
              (response) => {
                if (response) {
                  resolve(response);
                }
              }
            ).catch(
              (errorResponse: ErrorResponse) => {
                const error = String(errorResponse.data);
                reject(error);
                toast.dismiss();
                toast.error(
                  error,
                  {
                    autoClose: 10000,
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored"
                  }
                );
              }
            );
          } else {
            errorFound = true;
            errorText = "No recommended scenario found";
          }
        } else {
          errorFound = true;
          errorText = "No dwelling selected";
        }
      } else {
        errorFound = true;
        errorText = "Project was undefined";
      }

      if (errorFound) {
        reject(errorText);
        toast.dismiss();
        toast.error(
          errorText,
          {
            autoClose: 10000,
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored"
          }
        );
      }

    }),
    [
      project,
      selectedDwellings,
      updateTAF
    ]
  );

  const handleCloseReportModal = () => {
    setShowReportPDFModal(false);
    setReportPDFUrl("");
  };

  const handleCloseReportGenerationModal = () => {
    setShowGenerateReportModal(false);
  };

  const handleCloseConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
  };

  const handleCloseHomeEnergyAssessmentDialog = () => {
    setShowHomeEnergyAssessmentDialog(false);
  };

  const heaToastDuration = 15000;

  const handleOnGenerateHEAReport = useCallback(
    () => {
      if (project) {

        if (selectedDwellings.length > 0) {
          toast.info(
            "Generating HEA PDF",
            {
              position: "top-right",
              autoClose: heaToastDuration,
              theme: "colored"
            }
          );
          generateHEAReport(
            {
                project_id: project.id,
                dwelling_id: selectedDwellings[0].id,
                dwelling_type: selectedDwellings[0].type
            }
          ).unwrap().then(
            (response) => {
              handleCloseReportGenerationModal();
              setReportPDFUrl(response.url);
              setShowReportPDFModal(true);
              toast.dismiss();
            }
          ).catch(
            (errorResponse: ErrorResponse) => {
              toast.dismiss();
              toast.error(
                `${String(errorResponse.data)}`,
                {
                  autoClose: 10000,
                  position: toast.POSITION.TOP_RIGHT,
                  theme: "colored"
                }
              );
            }
          );
        }
      }
    },
    [
      project,
      selectedDwellings,
      generateHEAReport
    ]
  );

  const handleOnGenerateHUP1Report = useCallback(
    (
      data: Pick<GenerateHUP1Request,
        "dwellingsList" | "scenariosList" | "reportId">
    ) => {
      if (project) {
        toast.info(
          "Generating PDF",
          {
            position: "top-right",
            autoClose: heaToastDuration,
            theme: "colored"
          }
        );
        generateHUP1Report(
          {
            projectId: project.id,
            ...data
          }
        ).unwrap().then(
          (response) => {
            handleCloseReportGenerationModal();
            setReportPDFUrl(response.url);
            setShowReportPDFModal(true);
            toast.dismiss();
          }
        ).catch(
          (errorResponse: ErrorResponse) => {
            toast.dismiss();
            toast.error(
              `${String(errorResponse.data)}`,
              {
                autoClose: 10000,
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored"
              }
            );
          }
        );
      }
    },
    [
      project,
      generateHUP1Report,
      heaToastDuration
    ]
  );

  const handleOnGenerateHUP2Report = useCallback(
    (
      data: Pick<GenerateHUP2Request,
        | "dwellingsList"
        | "scenariosList"
        | "reportId"
        | "recommendedScenario"
        | "showAllScenarios">
    ) => {
      if (project) {
        toast.info(
          "Generating PDF",
          {
            position: "top-right",
            autoClose: heaToastDuration,
            theme: "colored"
          }
        );
        generateHUP2Report(
          {
            projectId: project.id,
            ...data
          }
        ).unwrap().then(
          (response) => {
            handleCloseReportGenerationModal();
            setReportPDFUrl(response.url);
            setShowReportPDFModal(true);
            toast.dismiss();
          }
        ).catch(
          (errorResponse: ErrorResponse) => {
            toast.error(
              `${errorResponse.data}`,
              {
                autoClose: 10000,
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored"
              }
            );
          }
        );
      }
    },
    [
      project,
      generateHUP2Report,
      heaToastDuration
    ]
  );

  const handleOnGeneratePdfReport = (dwelling: Dwelling) => {
    setReportDwelling(dwelling);
    setShowGenerateReportModal(true);
  };

  return project && projectScenarios ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        width: "100%"
      }}
      onClick={
        () => onViewClicked()
      }
    >

      <ProjectDetails
        onDeleteProject={onDeleteProject}
        isProjectRunning={isRunProjectFetching}
        onUpdateProjectDetails={handleUpdateProjectDetails}
        project={project}
        runProject={handleRunProject}
        superhomesExport={handleSuperhomesExport}
      />

      <Box
        sx={{
          paddingLeft: "32px",
          paddingRight: "32px",
          paddingTop: "16px",
          paddingBottom: "24px"
        }}
      >

        <ProjectScenarios
          customScenariosNotInProject={customScenariosNotInProject}
          defaultScenariosNotInProject={defaultScenariosNotInProject}
          isLoading={isLoading}
          isProjectRunning={isRunProjectFetching}
          onAddCustomScenario={handleAddCustomScenario}
          onAddDefaultScenario={handleAddDefaultScenario}
          onRemoveScenarios={handleRemoveProjectScenarios}
          projectScenarios={projectScenarios}
          projectTitle={project.title}
        />

        <ProjectDwellings
          generatePDFReport={handleOnGeneratePdfReport}
          isLoading={isLoading}
          isProjectRunning={isRunProjectFetching}
          onCreateOrGetHeaClicked={handleCreateOrGetHea}
          onCreateTafClicked={handleCreateOrGetTaf}
          onUpdateDwellings={handleOnUpdateProjectDwellings}
          onUpdateProjectScenarioRecommendation={
            handleUpdateProjectScenarioRecommendation
          }
          otherDwellings={otherDwellings}
          projectDwellings={projectDwellings}
          projectScenarios={projectScenarios}
          projectScenarioRecommendations={
            project.scenario_recommendations
          }
          projectId={project.id}
          projectTitle={project.title}
          projectThresholds={project.thresholds}
          selectedDwellings={selectedDwellings}
          setSelectedDwellings={setSelectedDwellings}
        />

      </Box>

      <DisplayPdfDialog
        onClose={handleCloseReportModal}
        open={showReportPDFModal}
        reportUrl={reportPDFUrl}
        title="Preview"
      />

      {
        reportDwelling !== undefined ? <GenerateReportDialog
          dwelling={reportDwelling}
          generateHEAReport={handleOnGenerateHEAReport}
          generateHUP1Report={handleOnGenerateHUP1Report}
          generateHUP2Report={handleOnGenerateHUP2Report}
          hea1Reports={hea1List ?? [{ id: 1 }]}
          hea2Reports={hea2List ?? [{ id: 1 }]}
          onClose={handleCloseReportGenerationModal}
          open={showGenerateReportModal}
          scenarios={projectScenarios}
        /> : null
      }

      <ConfirmationDialog
        buttonText="Okay"
        message={confirmationDialogMessage}
        title={confirmationDialogTitle}
        onClose={handleCloseConfirmationDialog}
        onSubmit={() => {
          setConfirmationDialogOpen(false);
        }}
        open={confirmationDialogOpen}
      />

      {
        reportDwelling !== undefined &&
        reportScenario !== undefined ? <HomeEnergyAssessmentDialog
              dwelling={reportDwelling}
              projectId={project.id}
              reportConfig={project.report_config}
              heaStep={heaStep}
              onClose={handleCloseHomeEnergyAssessmentDialog}
              open={showHomeEnergyAssessmentModal}
              scenario={reportScenario}
              updateHeaStep={handleUpdateHeaStep}
              handleGenerateHeaPdf={handleOnGenerateHEAReport}
            /> : null
      }


      {
        taf !== undefined && tafStep === 1 ?
          <TechnicalAssessmentFormModal1
            isTafUpdating={isTafUpdating}
            setShowModal={setShowTechnicalAssessmentFormDialog}
            setTafStep={setTafStep}
            showModal={
              showTechnicalAssessmentFormModal && tafStep === 1
            }
            taf={taf}
            tafStep={tafStep}
            updateTAF={handleUpdateTaf}
          /> : null
      }

      {
        taf !== undefined && tafStep === 2 ?
          <TechnicalAssessmentFormModal2
            isTafUpdating={isTafUpdating}
            setShowModal={setShowTechnicalAssessmentFormDialog}
            setTafStep={setTafStep}
            showModal={
              showTechnicalAssessmentFormModal && tafStep === 2
            }
            taf={taf}
            tafStep={tafStep}
            updateTAF={handleUpdateTaf}
          /> : null
      }

      {
        taf !== undefined && tafStep === 3 ?
          <TechnicalAssessmentFormModal3
            isTafUpdating={isTafUpdating}
            setShowModal={setShowTechnicalAssessmentFormDialog}
            setTafStep={setTafStep}
            showModal={
              showTechnicalAssessmentFormModal && tafStep === 3
            }
            taf={taf}
            tafStep={tafStep}
            updateTAF={handleUpdateTaf}
          /> : null
      }

      {
        taf !== undefined && tafStep === 4 ?
          <TechnicalAssessmentFormModal4
            isTafUpdating={isTafUpdating}
            setShowModal={setShowTechnicalAssessmentFormDialog}
            setTafStep={setTafStep}
            showModal={
              showTechnicalAssessmentFormModal && tafStep === 4
            }
            taf={taf}
            tafStep={tafStep}
            updateTAF={handleUpdateTaf}
          /> : null
      }

      {
        taf !== undefined && tafStep === 5 ?
          <TechnicalAssessmentFormModal5
            isTafUpdating={isTafUpdating}
            setShowModal={setShowTechnicalAssessmentFormDialog}
            setTafStep={setTafStep}
            showModal={
              showTechnicalAssessmentFormModal && tafStep === 5
            }
            taf={taf}
            tafStep={tafStep}
            updateTAF={handleUpdateTaf}
          /> : null
      }

      {
        taf !== undefined && tafStep === 6 ?
          <TechnicalAssessmentFormModal6
            isTafUpdating={isTafUpdating}
            setShowModal={setShowTechnicalAssessmentFormDialog}
            setTafStep={setTafStep}
            showModal={
              showTechnicalAssessmentFormModal && tafStep === 6
            }
            taf={taf}
            tafStep={tafStep}
            updateTAF={handleUpdateTaf}
          /> : null
      }

      {
        taf !== undefined && tafStep === 7 ?
          <TechnicalAssessmentFormModal7
            isTafUpdating={isTafUpdating}
            setShowModal={setShowTechnicalAssessmentFormDialog}
            setTafStep={setTafStep}
            showModal={
              showTechnicalAssessmentFormModal && tafStep === 7
            }
            taf={taf}
            tafStep={tafStep}
            updateTAF={handleUpdateTaf}
          /> : null
      }

      {
        taf !== undefined && tafStep === 8 ?
          <TechnicalAssessmentFormModal8
            isTafUpdating={isTafUpdating}
            setShowModal={setShowTechnicalAssessmentFormDialog}
            setTafStep={setTafStep}
            showModal={
              showTechnicalAssessmentFormModal && tafStep === 8
            }
            taf={taf}
            tafStep={tafStep}
            updateTAF={handleUpdateTaf}
          /> : null
      }

      {
        taf !== undefined && tafStep === 9 ?
          <TechnicalAssessmentFormModal9
            isTafUpdating={isTafUpdating}
            setShowModal={setShowTechnicalAssessmentFormDialog}
            setTafStep={setTafStep}
            showModal={
              showTechnicalAssessmentFormModal && tafStep === 9
            }
            taf={taf}
            tafStep={tafStep}
            updateTAF={handleUpdateTaf}
          /> : null
      }

      {
        taf !== undefined && tafStep === 10 ?
          <TechnicalAssessmentFormModal10
            isTafUpdating={isTafUpdating}
            setShowModal={setShowTechnicalAssessmentFormDialog}
            setTafStep={setTafStep}
            showModal={
              showTechnicalAssessmentFormModal && tafStep === 10
            }
            taf={taf}
            tafStep={tafStep}
            updateTAF={handleUpdateTaf}
          /> : null
      }

    </Box>
  ) : null;
}

import React from "react";

import { useTheme } from "@mui/material/styles";


export const withMaterialUserInterfaceTheme = (Component: any) => {
  const Wrapper = (props: any) => {
    const theme = useTheme();
    return <Component
      theme={theme}
      {...props}
    />;
  };
  return Wrapper;
};
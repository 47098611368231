import { getDateFromUTCSeconds } from "common/utils/date-utils";
import { 
  defaultMeasuresApiTransformer 
} from "features/defaultMeasures/api/transformers";

import type {
  DefaultScenarioApiResponse
} from "features/defaultScenarios/api/types";
import type { DefaultScenario } from "features/defaultScenarios/types";


export function defaultScenariosApiTransformer(
  response: Array<DefaultScenarioApiResponse>
): Array<DefaultScenario> {

  return response.map(
    (scenariosApiResponse): DefaultScenario => ({
      id: scenariosApiResponse.id,
      title: scenariosApiResponse.title,
      description: scenariosApiResponse.description,
      created_on: getDateFromUTCSeconds(
        parseInt(
          scenariosApiResponse.created_on, 
          10
        )
      ),
      measures: defaultMeasuresApiTransformer(
        scenariosApiResponse.default_measures
      ),
    })
  );
}
import type { Dispatch, SetStateAction } from "react";
import React, { useCallback, useMemo } from "react";

import MaterialTable from "@material-table/core";

import {
  Add as AddIcon,
  Block as BlockIcon,
  Close as CloseIcon
} from "@mui/icons-material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { grey, indigo } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import type { PaperProps } from "@mui/material/Paper";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";

import {
  renderFixedCostsColumn,
  renderMeasureIcon,
  renderTitleColumnWithErrorTooltip,
  renderTypeColumn,
  renderVariableCostsColumn,
  renderVariableUnitColumn
} from "common/utils/tables/scenarioTable";
import {
  isCustomMeasure,
  isDefaultMeasure
} from "common/utils/types";

import type { Action, Column } from "@material-table/core";
import type { CustomMeasure } from "features/customMeasures/types";
import type { DefaultMeasure } from "features/defaultMeasures/types";


const TablePaperComponent = (
  props: JSX.IntrinsicAttributes & PaperProps
) => <Paper
  elevation={0}
            {...props}
/>;

interface AddMeasuresToProjectScenarioModalProps {
  category: string;
  customMeasures: Array<CustomMeasure>;
  customMeasuresNotInProjectScenario: Array<CustomMeasure>;
  defaultMeasures: Array<DefaultMeasure>;
  defaultMeasuresNotInProjectScenario: Array<DefaultMeasure>;
  isLoading: boolean;
  onClose: () => void;
  onUpdateProjectScenarioDefaultMeasures: (data: {
    add: boolean;
    measures: Array<number>;
  }) => void;
  onUpdateProjectScenarioCustomMeasures: (data: {
    add: boolean;
    custom_measures: Array<number>;
  }) => void;
  open: boolean;
  scenarioTitle: string;
  setConfirmationDialogTitle: Dispatch<SetStateAction<string>>;
  setConfirmationDialogMessage: Dispatch<SetStateAction<string>>;
  setConfirmationDialogOpen: Dispatch<SetStateAction<boolean>>;
  tableHeaderColor: string;
  tableEvenRowColor: string;
  tableOddRowColor: string;
}

export function AddMeasuresToCustomScenarioDialog(
  {
    category,
    customMeasures,
    customMeasuresNotInProjectScenario,
    defaultMeasures,
    defaultMeasuresNotInProjectScenario,
    isLoading,
    onClose,
    onUpdateProjectScenarioDefaultMeasures,
    onUpdateProjectScenarioCustomMeasures,
    open,
    scenarioTitle,
    setConfirmationDialogTitle,
    setConfirmationDialogMessage,
    setConfirmationDialogOpen,
    tableHeaderColor,
    tableEvenRowColor,
    tableOddRowColor
  }: AddMeasuresToProjectScenarioModalProps) {

  const theme = useTheme();

  const handleClose = useCallback(() => {
      onClose();
    },
    [onClose]
  );

  const handleAvatarClick = useCallback(
    (title: string, message: string, show: boolean) => {
      setConfirmationDialogTitle(title);
      setConfirmationDialogMessage(message);
      setConfirmationDialogOpen(show);
    },
    [
      setConfirmationDialogTitle,
      setConfirmationDialogMessage,
      setConfirmationDialogOpen
    ]
  );

  const tableActions: Array<(
    rowData: DefaultMeasure | CustomMeasure
  ) => Action<DefaultMeasure | CustomMeasure>> = useMemo(
    () => {

      const addIcon = () => <AddIcon
        sx={{
          color: theme.retrokitPalette.green.dark
        }}
      />;

      const blockIcon = () => <BlockIcon
        sx={{
          color: theme.retrokitPalette.red.dark
        }}
      />;

      return [
        (rowData: DefaultMeasure | CustomMeasure) => {


          const existingCustomMeasureWithSameRKId = customMeasures.some(
            measure => measure.retrokit_identifier === rowData.retrokit_identifier
          );

          const existingDefaultMeasureWithSameRKId = defaultMeasures.some(
            measure => measure.retrokit_identifier === rowData.retrokit_identifier
          );

          const customMeasureIncompatible = customMeasures.some(
            measure => rowData.incompatible_with.includes(measure.retrokit_identifier)
          );

          const defaultMeasureIncompatible = defaultMeasures.some(
            measure => rowData.incompatible_with.includes(measure.retrokit_identifier)
          );

          let existingMeasure: CustomMeasure | DefaultMeasure | undefined;
          if (existingCustomMeasureWithSameRKId || customMeasureIncompatible) {
            existingMeasure = customMeasures.find(
              measure => measure.retrokit_identifier === rowData.retrokit_identifier ||
                rowData.incompatible_with.includes(measure.retrokit_identifier)
            );
          }

          if (existingDefaultMeasureWithSameRKId || defaultMeasureIncompatible) {
            existingMeasure = defaultMeasures.find(
              measure => measure.retrokit_identifier === rowData.retrokit_identifier ||
                rowData.incompatible_with.includes(measure.retrokit_identifier)
            );
          }

          let tooltipMessage = `Add to ${scenarioTitle} scenario`;
          if (existingMeasure !== undefined) {
            if (existingCustomMeasureWithSameRKId || existingDefaultMeasureWithSameRKId) {
              tooltipMessage = `Measure type already present (${existingMeasure.title})`;
            } else {
              tooltipMessage = `${rowData.incompatible_reason}`;
            }
          }

          return {
            icon: existingMeasure !== undefined ? blockIcon : addIcon,
            tooltip: tooltipMessage,
            onClick: () => {

              if (existingMeasure === undefined) {
                if (isDefaultMeasure(rowData)) {
                  onUpdateProjectScenarioDefaultMeasures({
                    add: true,
                    measures: [rowData.id]
                  });
                }

                if (isCustomMeasure(rowData)) {
                  onUpdateProjectScenarioCustomMeasures({
                    add: true,
                    custom_measures: [rowData.id]
                  });
                }
              } else {
                handleAvatarClick(
                  "Measure type already in scenario",
                  `There is already a measure of this type in the scenario (${existingMeasure.title}). Remove it before attempting to add ${rowData.title} again.`,
                  true
                );
              }

            }
          };
        }
      ];
    },
    [
      theme.retrokitPalette.green.dark,
      theme.retrokitPalette.red.dark,
      customMeasures,
      defaultMeasures,
      scenarioTitle,
      onUpdateProjectScenarioDefaultMeasures,
      onUpdateProjectScenarioCustomMeasures,
      handleAvatarClick
    ]
  );

  const freeActions: Array<Action<DefaultMeasure | CustomMeasure>> = useMemo(
    () => {

      const closeIcon = () => <CloseIcon />;

      return [
        {
          icon: closeIcon,
          isFreeAction: true,
          tooltip: "Close",
          onClick: handleClose
        }
      ];
    },
    [
      handleClose
    ]
  );


  const tableColumns: Array<Column<DefaultMeasure | CustomMeasure>> = [
    {
      align: "center",
      cellStyle: {
        padding: "0 8px 0 8px"
      },
      field: "icon_link",
      render: (rowData) => renderMeasureIcon(rowData),
      searchable: false,
      title: "",
      width: 50
    },
    {
      cellStyle: {
        textAlign: "left",
        padding: "8px 16px 8px 16px"
      },
      customFilterAndSearch: (
        term, rowData
      ) => rowData.title.indexOf(String(term)) !== -1 || rowData.description.indexOf(String(term)) !== -1,
      defaultSort: "asc",
      field: "title",
      render: (rowData) => renderTitleColumnWithErrorTooltip(
        rowData,
        customMeasures,
        defaultMeasures,
        theme
      ),
      searchable: true,
      title: "Name",
      type: "string"
    },
    {
      cellStyle: {
        textAlign: "right",
        padding: "0 8px 0 8px"
      },
      field: "fixed_costs",
      render: (rowData) => renderFixedCostsColumn(
        rowData
      ),
      searchable: true,
      title: "Fixed",
      type: "numeric"
    },
    {
      cellStyle: {
        textAlign: "right",
        padding: "0 8px 0 8px"
      },
      field: "variable_costs",
      render: (rowData) => renderVariableCostsColumn(
        rowData
      ),
      searchable: true,
      title: "Variable",
      type: "numeric"
    },
    {
      cellStyle: {
        textAlign: "left",
        padding: "0 8px 0 8px"
      },
      field: "variable_multiplier",
      searchable: true,
      title: "Multiplier",
      type: "string"
    },
    {
      cellStyle: {
        textAlign: "left",
        padding: "0 8px 0 8px"
      },
      field: "variable_unit",
      render: (rowData) => renderVariableUnitColumn(
        rowData
      ),
      searchable: true,
      title: "Unit",
      type: "string"
    },
    {
      align: "center",
      cellStyle: {
        padding: "0 16px 0 16px"
      },
      field: "type",
      render: (rowData) => renderTypeColumn(
        rowData,
        handleAvatarClick,
        theme
      ),
      searchable: false,
      title: "Type",
      width: 50
    }
  ];

  const tableData = useMemo(
    () => [
      ...customMeasuresNotInProjectScenario,
      ...defaultMeasuresNotInProjectScenario
    ],
    [
      customMeasuresNotInProjectScenario,
      defaultMeasuresNotInProjectScenario
    ]
  );

  const DialogPaperComponent = useCallback(
    (
      props: JSX.IntrinsicAttributes & PaperProps
    ) => <Paper
      elevation={0}
      sx={{
        borderWidth: "0px",
        borderRadius: "20px"
      }}
      variant="outlined"
      {...props}
    />,
    []
  );

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={handleClose}
      scroll="body"
      PaperComponent={DialogPaperComponent}
    >

      <Box
        sx={{
          width: "100%",
          flex: 1,
          overflowX: "hidden",
          overflowY: "auto",
          padding: 0,
          display: "flex",
          flexDirection: "column",
          marginTop: 0
        }}
      >
        <MaterialTable
          actions={[
            ...tableActions,
            ...freeActions
          ]}
          columns={tableColumns}
          components={{
            Container: TablePaperComponent
          }}
          data={tableData}
          isLoading={isLoading}
          localization={{
            body: {
              emptyDataSourceMessage: `No ${category.toLowerCase()} measures to display`
            }
          }}
          options={{
            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: tableHeaderColor,
              color: "#ffffff",
              fontWeight: "bold",
              whiteSpace: "nowrap",
              padding: "0 16px 0 16px"
            },
            maxBodyHeight: "700px",
            paging: true,
            pageSize: tableData.length > 0 && tableData.length < 5 ? tableData.length : 5,
            pageSizeOptions: tableData.length > 0 && tableData.length < 5 ? [tableData.length] : [5, 10, 20],
            rowStyle: (rowData, index) => {

              const customMeasureAlreadyPresent = customMeasures.some(
                measure => measure.retrokit_identifier === rowData.retrokit_identifier ||
                  rowData.incompatible_with.includes(measure.retrokit_identifier)
              );

              const defaultMeasureAlreadyPresent = defaultMeasures.some(
                measure => measure.retrokit_identifier === rowData.retrokit_identifier ||
                  rowData.incompatible_with.includes(measure.retrokit_identifier)
              );

              let existingMeasure = false;
              if (customMeasureAlreadyPresent && !defaultMeasureAlreadyPresent) {
                existingMeasure = true;
              }

              if (!customMeasureAlreadyPresent && defaultMeasureAlreadyPresent) {
                existingMeasure = true;
              }

              if (existingMeasure) {
                return {
                  backgroundColor: grey["500"],
                  color: grey["800"],
                  fontWeight: "bold",
                  fontSize: 14
                };
              }

              if (isDefaultMeasure(rowData)) {
                if (index % 2) {
                  return {
                    backgroundColor: indigo["100"],
                    fontSize: 14
                  };
                }
                return {
                  backgroundColor: indigo["50"],
                  fontSize: 14
                };
              }

              if (index % 2) {
                return {
                  backgroundColor: tableEvenRowColor,
                  fontSize: 14
                };
              }
              return {
                backgroundColor: tableOddRowColor,
                fontSize: 14
              };

            },
            search: true,
            selection: false,
            showTitle: true,
            toolbar: true
          }}
          title={
            `Add ${category} Measures to ${scenarioTitle} Scenario`
          }
        />

      </Box>

      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
import React from "react";

import ReactPlayer from "react-player";
import { Link } from "react-router-dom";


import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import { withMaterialUserInterfaceTheme } from "common/hocs/theme";


// eslint-disable-next-line react/display-name
const Transition = React.forwardRef(
  function Transition(props, ref) {
    return <Slide
      direction="up"
      ref={ref}
      {...props}
    />;
  }
);

class WelcomeDialog extends React.Component {

  render() {
    const {
      showWelcome,
      closeWelcome,
      canViewUploads,
      theme
    } = this.props;

    return <Dialog
      open={showWelcome}
      onClose={() => closeWelcome(false)}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography
          variant="h5"
          color="primary"
        >
          Welcome!
        </Typography>
      </DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column"
        }}
      >

        <Typography
          sx={{
            paddingTop: "8px",
            paddingBottom: "8px"
          }}
          variant="body1"
          color="textPrimary"
        >
          Before using RetroKit, we recommend you take a quick look at our overview video.
        </Typography>

        <Box
          sx={{
            position: "relative",
            paddingTop: "56.25%"
          }}
        >
          <ReactPlayer
            style={{
              position: "absolute",
              top: 0,
              left: 0
            }}
            // url="https://www.youtube.com/watch?v=1RLfAJQWWKk&feature=youtu.be"
            url="https://retrokit.eu/wp-content/uploads/2019/02/2019-02-13_Retrokit_Video_Draft_02-converted-with-Clipchamp.mp4"
            // url="/static/public/video/instructions.mp4"
            width="100%"
            height="100%"
            controls
          />
        </Box>

        <Typography
          sx={{
            paddingTop: "8px",
            paddingBottom: "8px"
          }}
          variant="body1"
          color="textPrimary"
        >
          When you would like to begin using RetroKit, you will first need to provide our system with
          some data in the form of XML files. If you don’t have XML files, you, as owner of the stock
          can obtain them <a
          href="v1/components/dialogs/welcome/WelcomeDialog"
        >
          from SEAI via a registered BER Assessor
        </a>.
        </Typography>

        <Typography
          sx={{
            paddingTop: "8px",
            paddingBottom: "8px"
          }}
          variant="body1"
          color="textPrimary"
        >
          Don&#39;t hesitate to <a
          href="v1/components/dialogs/welcome/WelcomeDialog"
        >
          contact us at RetroKit
        </a> for more details.
        </Typography>

        <Paper
          variant="outlined"
          sx={{
            borderColor: theme.palette.primary.main,
            marginTop: "16px",
            marginBottom: "16px"
          }}
        >
          <Typography
            variant="subtitle1"
            color="textPrimary"
            sx={{
              background: theme.palette.primary.main,
              color: "#ffffff",
              display: "flex",
              paddingLeft: "16px",
              paddingRight: "16px",
              paddingTop: "8px",
              paddingBottom: "8px"
            }}
          >
            Our template
          </Typography>

          <Typography
            variant="body1"
            color="textPrimary"
            sx={{
              paddingLeft: "16px",
              paddingRight: "16px",
              paddingTop: "8px",
              paddingBottom: "8px"
            }}
          >
            Our template helps to format your data into an asset list before
            uploading.
          </Typography>

          <Typography
            sx={{
              paddingLeft: "16px",
              paddingRight: "16px",
              paddingTop: "8px",
              paddingBottom: "8px"
            }}
            variant="body1"
            color="textPrimary"
          >
            This is very useful if you have a large number of assets to analyze.
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              paddingRight: "16px",
              paddingBottom: "8px"
            }}
          >
            <Box
              sx={{
                flex: 1
              }}
            />

            <Button
              color="primary"
              href="/static/public/asset_template.csv"
            >
              Download Template
            </Button>
          </Box>
        </Paper>

        {
          canViewUploads ? <Paper
            variant="outlined"
            sx={{
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              borderColor: theme.palette.primary.main,
              marginTop: "16px",
              marginBottom: "16px"
            }}
          >
            <Typography
              variant="subtitle1"
              color="textPrimary"
              sx={{
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                background: theme.palette.primary.main,
                color: "#ffffff",
                display: "flex",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px"
              }}
            >
              Adding your data
            </Typography>

            <Typography
              sx={{
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px"
              }}
              variant="body1"
              color="textPrimary"
            >
              If you have already placed your data into our template, or only have "XML" files,
              then you are ready to begin uploading.
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                paddingRight: "16px",
                paddingBottom: "8px"
              }}
            >
              <Box
                sx={{
                  flex: 1
                }}
              />

              <Link to="/uploads">
                <Button
                  color="primary"
                  sx={{
                    textDecoration: "none"
                  }}
                >
                  Upload Files
                </Button>
              </Link>
            </Box>
          </Paper> : null
        }
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            closeWelcome(false);
          }}
        >
          Okay
        </Button>
      </DialogActions>
    </Dialog>;
  }
}

export default withMaterialUserInterfaceTheme(WelcomeDialog);
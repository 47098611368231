import React, { useCallback, useEffect, useRef, useState } from "react";


import {
  Attachment as AttachmentIcon,
  Clear as ClearIcon
} from "@mui/icons-material";
import {
  colors, FormGroup
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { BEREdited } from "common/components/BEREdited";
import {
  DwellingAndScenarioPerformanceBanner
} from "common/components/DwellingAndScenarioPerformanceBanner";
import iconTitleHomeBlank from "common/icons/icon-title-home-blank.png"
import { 
  useHomeEnergyAssessment
} from "features/homeEnergyAssessment/hooks/useHomeEnergyAssessment";

import type { Dwelling } from "features/dwellings/types";
import type { 
  HomeEnergyAnnexItem 
} from "features/homeEnergyAssessment/types";


interface HomeEnergyAssessmentDialogContent12Props {
  contentBackgroundColour: string;
  pageBackgroundColour: string;
  dwelling: Dwelling;
  scenarioDetail: string;
  scenarioRating: string;
  inputError: boolean;
  setInputError: React.Dispatch<React.SetStateAction<boolean>>;
  handleHeaFieldBlur: (
    value: string | number | File | Array<File> | Array<HomeEnergyAnnexItem>,
    field: string
  ) => void;
}


export function HomeEnergyAssessmentDialogContent12(
  {
    contentBackgroundColour,
    pageBackgroundColour,
    dwelling,
    scenarioDetail,
    scenarioRating,
    inputError,
    setInputError,
    handleHeaFieldBlur
  }: HomeEnergyAssessmentDialogContent12Props
) {

  const {
    homeEnergyAssessment
  } = useHomeEnergyAssessment();

  const [
    sectionHeadingAnnexes,
    setSectionHeadingAnnexes
  ] = useState<string>('');

  const [
    sectionHeadingAnnexesError,
    setSectionHeadingAnnexesError
  ] = useState<boolean>(false);

  const [
    sectionHeadingAnnexesLength,
    setSectionHeadingAnnexesLength
  ] = useState<number>(0);

  const sectionHeadingAnnexesLengthLimit = 100;

  const sectionHeadingAnnexesInputRef = useRef<HTMLInputElement>();

  const [
    annexesParagraph,
    setAnnexesParagraph
  ] = useState<string>('');

  const [
    annexesParagraphError,
    setAnnexesParagraphError
  ] = useState<boolean>(false);

  const [
    annexesParagraphLength,
    setAnnexesParagraphLength
  ] = useState<number>(0);

  const annexesParagraphLengthLimit = 2000;

  const annexesParagraphInputRef = useRef<HTMLInputElement>();

  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    includeTaf,
    setIncludeTaf
  ] = useState<boolean>(false);

  const [
    includeOtherFiles,
    setIncludeOtherFiles
  ] = useState<boolean>(false);

  const [
    annexFiles,
    setAnnexFiles
  ] = useState<HomeEnergyAnnexItem[]>([])

  const [
    additionalAnnexFiles,
    setAdditionalAnnexFiles
  ] = useState<File[] | undefined>(undefined)

  const addAnnexFilesInputRef = useRef<HTMLInputElement>(null);

  useEffect(
    () => {

      if (
        annexesParagraphError ||
        sectionHeadingAnnexesError
      ) {
        setInputError(true)
      }

      if (inputError) {
        if (
          !annexesParagraphError &&
          !sectionHeadingAnnexesError
        ) {
          setInputError(false)
        }
      }

    },
    [
      annexesParagraphError,
      sectionHeadingAnnexesError,
      inputError,
      setInputError
    ]
  );

  useEffect(
    () => {

      if (homeEnergyAssessment !== undefined) {
        setSectionHeadingAnnexes(
          homeEnergyAssessment.section_heading_annexes
        )

        setSectionHeadingAnnexesLength(
          homeEnergyAssessment.section_heading_annexes !== null ?
            homeEnergyAssessment.section_heading_annexes.length : 0
        )

        setAnnexesParagraph(
          homeEnergyAssessment.annexes_paragraph
        )

        setAnnexesParagraphLength(
          homeEnergyAssessment.annexes_paragraph !== null ?
            homeEnergyAssessment.annexes_paragraph.length : 0
        )

        setIncludeOtherFiles(
          homeEnergyAssessment.include_other_files
        )

        setIncludeTaf(
          homeEnergyAssessment.include_taf
        )

        setAnnexFiles(
          homeEnergyAssessment.annex_files
        )

        setAdditionalAnnexFiles(
          homeEnergyAssessment.annexAdditionalFiles
        )
      }
    },
    [
      homeEnergyAssessment
    ]
  );

  const handleFieldUpdated = useCallback(
    (
      value: string | number | File | Array<File> | Array<HomeEnergyAnnexItem>,
      field: string
    ) => {
      handleHeaFieldBlur(value, field);
    },
    [
      handleHeaFieldBlur
    ]
  );

  const theme = useTheme();

  return (
    <DialogContent
      sx={{
        width: "100%",
        flex: 1,
        overflowX: "hidden",
        overflowY: "hidden",
        padding: "0px 8px 0px 8px",
        gap: "2rem"
      }}
    >

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            borderRadius: "20px",
            backgroundColor: contentBackgroundColour,
            padding: "16px"
          }}
        >
          <img
            src={iconTitleHomeBlank}
            alt="Icon"
            style={{
              maxHeight: "48px"
            }}
          />

          <TextField
            error={sectionHeadingAnnexesError}
            variant="standard"
            fullWidth
            inputProps={{
              style: {
                fontSize: "1.4rem",
                backgroundColor: theme.palette.common.white
              }
            }}
            InputLabelProps={{
              style: {
                fontSize: "1.4rem"
              }
            }}
            FormHelperTextProps={{
              sx: {
                textAlign: "right",
                width: "100%"
              }
            }}
            value={sectionHeadingAnnexes}
            sx={{
              display: "flex",
              alignItems: "center",
              marginLeft: "16px",
              color: colors.grey["800"]
            }}
            onBlur={(event) => {
              if (!inputError) {
                handleFieldUpdated(
                  event.target.value,
                  "section_heading_annexes"
                );
              }
            }}
            onChange={(event) => {
              const {
                value
              } = event.target;
              setSectionHeadingAnnexesLength(value.length);
              if (value.length > sectionHeadingAnnexesLengthLimit) {
                setSectionHeadingAnnexesError(true);
              } else if (sectionHeadingAnnexesError) {
                  setSectionHeadingAnnexesError(false);
                }
              setSectionHeadingAnnexes(value)
            }}
            helperText={
              sectionHeadingAnnexesLengthLimit - sectionHeadingAnnexesLength <= (sectionHeadingAnnexesLengthLimit / 2) ?
                `${sectionHeadingAnnexesLength}/${sectionHeadingAnnexesLengthLimit}` : ""
            }
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                if (!inputError) {
                  if (
                    annexesParagraphInputRef !== undefined &&
                    annexesParagraphInputRef.current !== undefined
                  ) {
                    annexesParagraphInputRef.current.focus()
                  }
                }
              }
            }}
            inputRef={sectionHeadingAnnexesInputRef}
          />

        </Box>

      </Box>

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >
        <DwellingAndScenarioPerformanceBanner
          dwellingDetail={String(dwelling.mprn)}
          dwellingRating={dwelling.performance.rating}
          scenarioDetail={scenarioDetail}
          scenarioRating={scenarioRating}
        />

      </Box>

      {
        dwelling.performance.ber_edited ? <BEREdited/> : null
      }

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >

        <FormGroup
          sx={{
            flex: 1,
            flexDirection: "row",
            gap: "1.125rem",
            borderRadius: "20px",
            backgroundColor: contentBackgroundColour,
            padding: "16px"
          }}
        >

          {/* <FormControlLabel
            control={
              <Checkbox
                checked={homeEnergyAssessment.include_taf}
                onChange={(event) => {
                  handleValueChange(
                    event.target.value,
                    "include_taf"
                  );
                }}
              />
            }
            label={
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "0.875rem",
                  color: colors.grey["800"]
                }}
              >
                Include Technical Assessment Form
              </Typography>
            }
            sx={{
              flex: 1
            }}
          /> */}

          <FormControlLabel
            control={
              <Checkbox
                checked={includeOtherFiles}
                onChange={(event) => {
                  handleFieldUpdated(
                    event.target.value,
                    "include_other_files"
                  );
                }}
              />
            }
            label={
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "0.875rem",
                  color: colors.grey["800"]
                }}
              >
                Include other related files
              </Typography>
            }
            sx={{
              flex: 1
            }}
          />

        </FormGroup>

      </Box>

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >
        <TextField
          error={annexesParagraphError}
          label="Annex files description"
          sx={{
            backgroundColor: pageBackgroundColour,
            width: "100%"
          }}
          multiline
          size="small"
          variant="outlined"
          InputProps={{
            sx: {
              backgroundColor: theme.palette.common.white
            }
          }}
          FormHelperTextProps={{
            sx: {
              textAlign: "right"
            }
          }}
          value={annexesParagraph}
          onBlur={(event) => {
            if (!inputError) {
              handleFieldUpdated(
                event.target.value,
                "annexes_paragraph"
              );
            }
          }}
          onChange={(event) => {
            const {
              value
            } = event.target
            setAnnexesParagraphLength(value.length)
            if (value.length > annexesParagraphLengthLimit) {
              setAnnexesParagraphError(true)
            }
            else if (annexesParagraphError) {
                setAnnexesParagraphError(false)
              }
            setAnnexesParagraph(value)
          }}
          helperText={
            annexesParagraphLengthLimit - annexesParagraphLength <= (annexesParagraphLengthLimit / 2) ?
              `${annexesParagraphLength}/${annexesParagraphLengthLimit}` : ''
          }
          inputRef={annexesParagraphInputRef}
        />

      </Box>

      {
        annexFiles.length > 0 ? <Box
          sx={{
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingBottom: "16px"
          }}
        >

          <Box
            sx={{
              borderRadius: "20px",
              backgroundColor: contentBackgroundColour,
              padding: "8px"
            }}
          >
            <List>
              {
                annexFiles.map(
                  (annexFile) => <ListItem
                    key={annexFile.name}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => {
                          const newAnnexFilesArray = [
                            ...annexFiles
                          ]

                          const index = newAnnexFilesArray.indexOf(
                            annexFile
                          )

                          if (index > -1) {
                            newAnnexFilesArray.splice(index, 1);
                          }

                          console.log('newAnnexFilesArray = ', newAnnexFilesArray)

                          handleFieldUpdated(
                            newAnnexFilesArray,
                            "annex_files"
                          );
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <AttachmentIcon />
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                      primary={annexFile.name}
                    />
                  </ListItem>
                )
              }
            </List>

          </Box>

        </Box> : null
      }

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >

        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            if (addAnnexFilesInputRef.current !== null) {
              addAnnexFilesInputRef.current.click();
            }
          }}
        >
          {
            annexFiles.length > 0 ? "Add more files" : "Add files"
          }
        </Button>

        <input
          alt="Add Annex Files"
          ref={addAnnexFilesInputRef}
          style={{
            display: "none"
          }}
          type="file"
          multiple
          accept=".jpg,.jpeg,.svg,.png,.gif,.pdf"
          onChange={(event) => {

            const {
              files
            } = event.target

            if (files !== null && files.length > 0) {

              const filesArray = []
              for (let i = 0; i < files.length; i += 1) {
                filesArray.push(files[i])
              }

              handleFieldUpdated(
                filesArray,
                "annexAdditionalFiles"
              );
            }
          }}
        />

      </Box>

      {
        additionalAnnexFiles !== undefined &&
        additionalAnnexFiles.length > 0 ? <Box
          sx={{
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingBottom: "16px"
          }}
        >

          <Box
            sx={{
              borderRadius: "20px",
              backgroundColor: contentBackgroundColour,
              padding: "8px"
            }}
          >
            <List>
              {
                additionalAnnexFiles.map(
                  (additionalFile) => <ListItem
                    key={additionalFile.name}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => {
                          if (additionalAnnexFiles !== undefined) {
                            const newFilesArray = [
                              ...additionalAnnexFiles
                            ]

                            const index = newFilesArray.indexOf(
                              additionalFile
                            )
                            if (index > -1) {
                              newFilesArray.splice(index, 1);
                            }

                            handleFieldUpdated(
                              newFilesArray,
                              "annexAdditionalFiles"
                            );
                          }
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <AttachmentIcon />
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                      primary={additionalFile.name}
                    />
                  </ListItem>
                )
              }
            </List>

          </Box>

        </Box> : null
      }


    </DialogContent>
  );
}
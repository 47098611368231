import { AUTHENTICATION_ERROR } from './authentication';

export const USER_LOADING = '@@user/USER_LOADING';
export const USER_LOADED = '@@user/USER_LOADED';

export const getUser = () => {
  return (dispatch, getState) => {
    dispatch({ type: USER_LOADING });

    let headers = { 'Content-Type': 'application/json' };
    let { token } = getState().authentication;

    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }

    return fetch(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/v1/user/me/`,
      { headers }
    )
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          console.log('Server Error!');
          throw res;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return dispatch({ type: USER_LOADED, user: res.data });
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: AUTHENTICATION_ERROR, data: res.data });
          throw res.data;
        }
      });
  };
};

import React from "react";

import Typography from "@mui/material/Typography";

import { 
  BER_COLOUR_CODE_MAP 
} from "common/assets/rk_type_mappings/ber_typed";

interface BERChevronProps {
  rating: string;
}

export function BERChevron(
  {
    rating
  }: BERChevronProps) {
  return <Typography
    variant="h6"
    sx={{
      clipPath:
        "polygon( calc(75% - 0.50rem) 0%,  calc(100% - 0.50rem) 50%,  calc(75% - 0.50rem) 100%, 0% 100%, 24% 50%, 0% 0%)",
      color: "white",
      float: "left",
      clear: "left",
      background: BER_COLOUR_CODE_MAP[
        rating
        ],
      paddingLeft: "1em",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: `${48}px`,
      fontSize: "1rem",
      fontFamily: "Roboto",
      "@media": {
        paddingRight: "0.6rem!important",
        paddingLeft: "0.4rem!important"
      }
    }}
  >
    {
      rating
    }
  </Typography>
}
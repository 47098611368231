import {
    FETCH_ALL_UPLOADS,
    ADD_UPLOAD_TASK_ID_LOADED,
    ADD_UPLOAD_TASK_SUCCESS,
    ADD_UPLOAD_TASK_EXECUTING,
    ADD_UPLOAD_TASK_FAILED,
    ADD_UPLOAD_TASK_QUEUED,
    UPLOAD_ERROR,
    CLEAR_UPLOAD_TASKS
} from '../actions/uploads'

const initialState = {
    uploadList: [],
    uploadTaskList: [],
    errorsList: []
};

export default function uploadReducer(state = initialState, action) {

    switch (action.type) {
        case FETCH_ALL_UPLOADS:
            return {...state, uploadList: action.uploadList};
        case ADD_UPLOAD_TASK_ID_LOADED:
            return {
                ...state,
                uploadList: [...state.uploadList, action.upload],
                uploadTaskList: [...state.uploadTaskList, action.uploadTask]
            };
        case ADD_UPLOAD_TASK_EXECUTING:
        case ADD_UPLOAD_TASK_QUEUED:
        case ADD_UPLOAD_TASK_SUCCESS:
        case ADD_UPLOAD_TASK_FAILED:
            return {
                ...state,
                uploadTaskList: state.uploadTaskList.map(uploadTask => {
                    if (uploadTask.task_id === action.uploadTask.task_id) {
                        return {...uploadTask, task_status: action.uploadTask.task_status};
                    }
                    return uploadTask;
                })
            };
        case CLEAR_UPLOAD_TASKS:
            return {
                ...state,
                uploadTaskList: [],
                errorsList: []
            }
        case UPLOAD_ERROR:

            return {...state, errorsList: [...state.errorsList, action.error]};

            /*
            if (state.errorsList.length === 0) {

            }
            return  {
            ...state,
            errorsList: state.errorsList.map(error => {
                if (error.file === action.error.file) {
                    return {...error, text: action.error.text};
                }
                return error;
            })
            };
            */

        default:
            return state;
    }
}
import { useCallback, useState } from "react";

import { useLocation } from "react-router-dom";

import {
  CustomScenarioDrawer
} from "features/customScenarios/components/CustomScenarioDrawer";
import {
  CustomScenarioView
} from "features/customScenarios/components/CustomScenarioView";
import {
  NoCustomScenariosView
} from "features/customScenarios/components/NoCustomScenariosView";
import {
  CreateCustomScenarioDialog
} from "features/customScenarios/dialogs/CreateCustomScenarioDialog";
import {
  useCustomScenariosPage
} from "features/customScenarios/hooks/useCustomScenariosPage";

import type {
  CreateCustomScenarioApiRequest
} from "features/customScenarios/api/types";
import type { MinimalCustomScenario } from "features/customScenarios/types";
import Box from "@mui/material/Box";


type ScenariosPageProps = {
  scenariosMenuShowing: boolean;
  setScenariosMenuShowing: (showing: boolean) => void;
};

export function ScenariosPage(
  {
    scenariosMenuShowing,
    setScenariosMenuShowing
  }: ScenariosPageProps) {

  const location = useLocation();

  const {
    createCustomScenario,
    customScenarios,
    deleteCustomScenario,
    isGetCustomScenariosLoading
  } = useCustomScenariosPage();

  const locationState = location.state as {
    newCustomScenario: MinimalCustomScenario
  };

  const [
    selectedCustomScenario, setSelectedCustomScenario
  ] = useState<MinimalCustomScenario | undefined>(
    locationState?.newCustomScenario || undefined
  );

  const [
    isAddCustomScenarioOpen, setIsAddCustomScenarioOpen
  ] = useState<boolean>(false);

  const onCustomScenarioViewClicked = () => {
    if (scenariosMenuShowing) {
      setScenariosMenuShowing(false);
    }
  };

  const handleOnCreateCustomScenario = useCallback(
    (requestArgs: CreateCustomScenarioApiRequest) => {
      createCustomScenario(requestArgs);
    },
    [
      createCustomScenario
    ]
  );

  const handleOnDeleteCustomScenario = useCallback(
    () => {
      if (selectedCustomScenario) {
        setSelectedCustomScenario(undefined);
        deleteCustomScenario({
          id: selectedCustomScenario.id
        });
        setScenariosMenuShowing(true);
      }
    },
    [
      deleteCustomScenario,
      selectedCustomScenario,
      setScenariosMenuShowing
    ]
  );

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        padding: 0,
        margin: 0,
        display: "flex",
        flexDirection: "row"
      }}
    >

      <CustomScenarioDrawer
        onAddScenarioClick={() => setIsAddCustomScenarioOpen(true)}
        onClose={() => setScenariosMenuShowing(false)}
        open={scenariosMenuShowing}
        customScenarios={customScenarios}
        setSelectedScenario={setSelectedCustomScenario}
      />

      {
        selectedCustomScenario !== undefined ?
          <CustomScenarioView
            minimalCustomScenario={selectedCustomScenario}
            onDeleteCustomScenario={handleOnDeleteCustomScenario}
            onViewClicked={onCustomScenarioViewClicked}
          /> : <NoCustomScenariosView
            onViewClicked={onCustomScenarioViewClicked}
          />
      }

      <CreateCustomScenarioDialog
        open={isAddCustomScenarioOpen}
        onClose={() => setIsAddCustomScenarioOpen(false)}
        onCreateCustomScenario={(data) => {
          handleOnCreateCustomScenario(data);
          setIsAddCustomScenarioOpen(false);
        }}
      />

    </Box>
  );
}

import React, { useCallback, useEffect, useRef, useState } from "react";


import {
  colors
} from "@mui/material";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

import { BEREdited } from "common/components/BEREdited";
import {
  DwellingAndScenarioPerformanceBanner
} from "common/components/DwellingAndScenarioPerformanceBanner";
import iconTitleHomeBlank from "common/icons/icon-title-home-blank.png";
import { 
  useHomeEnergyAssessment 
} from "features/homeEnergyAssessment/hooks/useHomeEnergyAssessment";

import type { Dwelling } from "features/dwellings/types";


interface HomeEnergyAssessmentDialogContent8Props {
  dwelling: Dwelling;
  contentBackgroundColour: string;
  pageBackgroundColour: string;
  scenarioDetail: string;
  scenarioRating: string;
  inputError: boolean;
  setInputError: React.Dispatch<React.SetStateAction<boolean>>;
  handleHeaFieldBlur: (
    value: string | number | File,
    field: string
  ) => void;
}


export function HomeEnergyAssessmentDialogContent8(
  {
    dwelling,
    contentBackgroundColour,
    pageBackgroundColour,
    scenarioDetail,
    scenarioRating,
    inputError,
    setInputError,
    handleHeaFieldBlur
  }: HomeEnergyAssessmentDialogContent8Props
) {

  const {
    homeEnergyAssessment
  } = useHomeEnergyAssessment();

  const [
    sectionHeadingTechnicalNotes,
    setSectionHeadingTechnicalNotes
  ] = useState<string>("");

  const [
    sectionHeadingTechnicalNotesError,
    setSectionHeadingTechnicalNotesError
  ] = useState<boolean>(false);

  const [
    sectionHeadingTechnicalNotesLength,
    setSectionHeadingTechnicalNotesLength
  ] = useState<number>(0);

  const sectionHeadingTechnicalNotesLengthLimit = 100;

  const sectionHeadingTechnicalNotesInputRef = useRef<HTMLInputElement>();

  const [
    technicalNotesParagraph1,
    setTechnicalNotesParagraph1
  ] = useState<string>("");

  const [
    technicalNotesParagraph1Error,
    setTechnicalNotesParagraph1Error
  ] = useState<boolean>(false);

  const [
    technicalNotesParagraph1Length,
    setTechnicalNotesParagraph1Length
  ] = useState<number>(0);

  const technicalNotesParagraph1LengthLimit = 500;

  const technicalNotesParagraph1InputRef = useRef<HTMLInputElement>();

  const [
    technicalNotesParagraph2,
    setTechnicalNotesParagraph2
  ] = useState<string>("");

  const [
    technicalNotesParagraph2Error,
    setTechnicalNotesParagraph2Error
  ] = useState<boolean>(false);

  const [
    technicalNotesParagraph2Length,
    setTechnicalNotesParagraph2Length
  ] = useState<number>(0);

  const technicalNotesParagraph2LengthLimit = 800;

  useEffect(
    () => {

      if (
        technicalNotesParagraph1Error ||
        technicalNotesParagraph2Error ||
        sectionHeadingTechnicalNotesError
      ) {
        setInputError(true);
      }

      if (inputError) {
        if (
          !technicalNotesParagraph1Error &&
          !technicalNotesParagraph2Error &&
          !sectionHeadingTechnicalNotesError
        ) {
          setInputError(false);
        }
      }

    },
    [
      technicalNotesParagraph1Error,
      technicalNotesParagraph2Error,
      sectionHeadingTechnicalNotesError,
      inputError,
      setInputError
    ]
  );

  useEffect(
    () => {

      if (homeEnergyAssessment !== undefined) {
        setSectionHeadingTechnicalNotes(
          homeEnergyAssessment.section_heading_technical_notes
        );

        setSectionHeadingTechnicalNotesLength(
          homeEnergyAssessment.section_heading_technical_notes !== null ?
            homeEnergyAssessment.section_heading_technical_notes.length : 0
        );

        setTechnicalNotesParagraph1(
          homeEnergyAssessment.technical_notes_paragraph_1
        );

        setTechnicalNotesParagraph1Length(
          homeEnergyAssessment.technical_notes_paragraph_1 !== null ?
            homeEnergyAssessment.technical_notes_paragraph_1.length : 0
        );

        setTechnicalNotesParagraph2(
          homeEnergyAssessment.technical_notes_paragraph_2
        );

        setTechnicalNotesParagraph2Length(
          homeEnergyAssessment.technical_notes_paragraph_2 !== null ?
            homeEnergyAssessment.technical_notes_paragraph_2.length : 0
        );
      }
    },
    [
      homeEnergyAssessment
    ]
  );

  const handleFieldUpdated = useCallback(
    (
      value: string | number | File,
      field: string
    ) => {
      handleHeaFieldBlur(value, field);
    },
    [
      handleHeaFieldBlur
    ]
  );

  const theme = useTheme();

  return (
    <DialogContent
      sx={{
        width: "100%",
        flex: 1,
        overflowX: "hidden",
        overflowY: "hidden",
        padding: "0px 8px 0px 8px",
        gap: "2rem"
      }}
    >

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            borderRadius: "20px",
            backgroundColor: contentBackgroundColour,
            padding: "16px"
          }}
        >
          <img
            src={iconTitleHomeBlank}
            alt="Icon"
            style={{
              maxHeight: "48px"
            }}
          />

          <TextField
            error={sectionHeadingTechnicalNotesError}
            variant="standard"
            fullWidth
            inputProps={{
              style: {
                fontSize: "1.4rem",
                backgroundColor: theme.palette.common.white
              }
            }}
            InputLabelProps={{
              style: {
                fontSize: "1.4rem"
              }
            }}
            FormHelperTextProps={{
              sx: {
                textAlign: "right",
                width: "100%"
              }
            }}
            value={sectionHeadingTechnicalNotes}
            sx={{
              display: "flex",
              alignItems: "center",
              marginLeft: "16px",
              color: colors.grey["800"]
            }}
            onBlur={(event) => {
              if (!inputError) {
                handleFieldUpdated(
                  event.target.value,
                  "section_heading_technical_notes"
                );
              }
            }}
            onChange={(event) => {
              const {
                value
              } = event.target;
              setSectionHeadingTechnicalNotesLength(value.length);
              if (value.length > sectionHeadingTechnicalNotesLengthLimit) {
                setSectionHeadingTechnicalNotesError(true);
              } else if (sectionHeadingTechnicalNotesError) {
                setSectionHeadingTechnicalNotesError(false);
              }
              setSectionHeadingTechnicalNotes(value);
            }}
            helperText={
              sectionHeadingTechnicalNotesLengthLimit - sectionHeadingTechnicalNotesLength <= (sectionHeadingTechnicalNotesLengthLimit / 2) ?
                `${sectionHeadingTechnicalNotesLength}/${sectionHeadingTechnicalNotesLengthLimit}` : ""
            }
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                if (!inputError) {
                  if (
                    technicalNotesParagraph1InputRef !== undefined &&
                    technicalNotesParagraph1InputRef.current !== undefined
                  ) {
                    technicalNotesParagraph1InputRef.current.focus();
                  }
                }
              }
            }}
            inputRef={sectionHeadingTechnicalNotesInputRef}
          />

        </Box>

      </Box>

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >
        <DwellingAndScenarioPerformanceBanner
          dwellingDetail={String(dwelling.mprn)}
          dwellingRating={dwelling.performance.rating}
          scenarioDetail={scenarioDetail}
          scenarioRating={scenarioRating}
        />

      </Box>

      {
        dwelling.performance.ber_edited ? <BEREdited/> : null
      }

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >
        <TextField
          error={technicalNotesParagraph1Error}
          sx={{
            backgroundColor: pageBackgroundColour,
            width: "100%"
          }}
          multiline
          size="small"
          variant="outlined"
          InputProps={{
            sx: {
              backgroundColor: theme.palette.common.white
            }
          }}
          FormHelperTextProps={{
            sx: {
              textAlign: "right"
            }
          }}
          value={technicalNotesParagraph1}
          onBlur={(event) => {
            if (!inputError) {
              handleFieldUpdated(
                event.target.value,
                "technical_notes_paragraph_1"
              );
            }
          }}
          onChange={(event) => {
            const {
              value
            } = event.target;
            setTechnicalNotesParagraph1Length(value.length);
            if (value.length > technicalNotesParagraph1LengthLimit) {
              setTechnicalNotesParagraph1Error(true);
            } else if (technicalNotesParagraph1Error) {
              setTechnicalNotesParagraph1Error(false);
            }
            setTechnicalNotesParagraph1(value);
          }}
          helperText={
            technicalNotesParagraph1LengthLimit - technicalNotesParagraph1Length <= (technicalNotesParagraph1LengthLimit / 2) ?
              `${technicalNotesParagraph1Length}/${technicalNotesParagraph1LengthLimit}` : ""
          }
          inputRef={technicalNotesParagraph1InputRef}
        />

      </Box>

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >
        <TextField
          error={technicalNotesParagraph2Error}
          label="Caveats"
          sx={{
            backgroundColor: pageBackgroundColour,
            width: "100%"
          }}
          multiline
          size="small"
          variant="outlined"
          InputProps={{
            sx: {
              backgroundColor: theme.palette.common.white
            }
          }}
          FormHelperTextProps={{
            sx: {
              textAlign: "right"
            }
          }}
          value={technicalNotesParagraph2}
          onBlur={(event) => {
            if (!inputError) {
              handleFieldUpdated(
                event.target.value,
                "technical_notes_paragraph_2"
              );
            }
          }}
          onChange={(event) => {
            const {
              value
            } = event.target;
            setTechnicalNotesParagraph2Length(value.length);
            if (value.length > technicalNotesParagraph2LengthLimit) {
              setTechnicalNotesParagraph2Error(true);
            } else if (technicalNotesParagraph2Error) {
                setTechnicalNotesParagraph2Error(false);
              }
            setTechnicalNotesParagraph2(value)
          }}
          helperText={
            technicalNotesParagraph2LengthLimit - technicalNotesParagraph2Length <= (technicalNotesParagraph2LengthLimit / 2) ?
              `${technicalNotesParagraph2Length}/${technicalNotesParagraph2LengthLimit}` : ""
          }
        />

      </Box>

    </DialogContent>
  );
}
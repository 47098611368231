import React, { useCallback, useEffect, useState } from "react";

import { colors } from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import { ConfirmationDialog } from "common/components/ConfirmationDialog";
import {
  CustomScenarioDetails
} from "features/customScenarios/components/CustomScenarioDetails";
import {
  CustomScenarioMeasureCategorySection
} from "features/customScenarios/components/CustomScenarioMeasureCategorySection";
import {
  AddMeasuresToCustomScenarioDialog
} from "features/customScenarios/dialogs/AddMeasuresToCustomScenarioDialog";
import {
  useCustomScenarioView
} from "features/customScenarios/hooks/useCustomScenarioView";


import type {
  DeleteCustomScenarioApiRequest
} from "features/customScenarios/api/types";
import type { MinimalCustomScenario } from "features/customScenarios/types";
import type { UpdateProjectDetailsRequest } from "features/projects/api/types";


type ScenarioViewProps = {
  minimalCustomScenario: MinimalCustomScenario;
  onDeleteCustomScenario: (requestArgs: DeleteCustomScenarioApiRequest) => void;
  onViewClicked: () => void;
};

export function CustomScenarioView(
  {
    minimalCustomScenario,
    onDeleteCustomScenario,
    onViewClicked
  }: ScenarioViewProps) {

  const theme = useTheme();

  const {
    customScenario,
    isCustomScenarioLoading,
    defaultMeasuresNotInCustomScenario,
    customMeasuresNotInCustomScenario,
    updateCustomScenarioDetails,
    updateCustomScenarioDefaultMeasures,
    updateCustomScenarioCustomMeasures
  } = useCustomScenarioView(Number(minimalCustomScenario.id));

  const [
    confirmationDialogTitle, setConfirmationDialogTitle
  ] = useState<string>("");

  const [
    confirmationDialogMessage, setConfirmationDialogMessage
  ] = useState<string>("");

  const [
    confirmationDialogOpen, setConfirmationDialogOpen
  ] = useState<boolean>(false);

  const [
    addMeasuresDialogCategory, setAddMeasuresDialogCategory
  ] = useState<string>("");

  const [
    addMeasuresDialogTableHeaderColor, setAddMeasuresDialogTableHeaderColor
  ] = useState<string>(theme.palette.primary.light);

  const [
    addMeasuresDialogTableEvenRowColor, setAddMeasuresDialogTableEvenRowColor
  ] = useState<string>("#EEEEEE");

  const [
    addMeasuresDialogTableOddRowColor, setAddMeasuresDialogTableOddRowColor
  ] = useState<string>("#FFFFFF");

  useEffect(
    () => {
      if (addMeasuresDialogCategory === "Ventilation") {
        setAddMeasuresDialogTableHeaderColor(theme.retrokitPalette.red.main);
        setAddMeasuresDialogTableEvenRowColor(colors.red["100"]);
        setAddMeasuresDialogTableOddRowColor(colors.red["50"]);
      } else if (addMeasuresDialogCategory === "Fabric") {
        setAddMeasuresDialogTableHeaderColor(theme.retrokitPalette.green.main);
        setAddMeasuresDialogTableEvenRowColor(colors.green["100"]);
        setAddMeasuresDialogTableOddRowColor(colors.green["50"]);
      } else if (addMeasuresDialogCategory === "Heating") {
        setAddMeasuresDialogTableHeaderColor(theme.retrokitPalette.blue.main);
        setAddMeasuresDialogTableEvenRowColor(colors.blue["100"]);
        setAddMeasuresDialogTableOddRowColor(colors.blue["50"]);
      } else if (addMeasuresDialogCategory === "Renewables") {
        setAddMeasuresDialogTableHeaderColor(theme.retrokitPalette.orange.main);
        setAddMeasuresDialogTableEvenRowColor(colors.orange["100"]);
        setAddMeasuresDialogTableOddRowColor(colors.orange["50"]);
      } else if (addMeasuresDialogCategory === "Other") {
        setAddMeasuresDialogTableHeaderColor(theme.retrokitPalette.teal.main);
        setAddMeasuresDialogTableEvenRowColor(colors.teal["100"]);
        setAddMeasuresDialogTableOddRowColor(colors.teal["50"]);
      } else {
        setAddMeasuresDialogTableHeaderColor(theme.palette.primary.light);
        setAddMeasuresDialogTableEvenRowColor("#EEEEEE");
        setAddMeasuresDialogTableOddRowColor("#FFFFFF");
      }

    },
    [
      addMeasuresDialogCategory,
      theme.retrokitPalette.red.main,
      theme.retrokitPalette.green.main,
      theme.retrokitPalette.blue.main,
      theme.retrokitPalette.orange.main,
      theme.retrokitPalette.teal.main,
      theme.palette.primary.light
    ]
  );

  const [
    addMeasuresDialogOpen, setAddMeasuresDialogOpen
  ] = useState<boolean>(false);

  const handleCloseAddMeasuresDialog = () => {
    setAddMeasuresDialogOpen(false);
    setAddMeasuresDialogCategory("");
  };

  const handleCloseConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
  };

  const handleUpdateCustomScenarioDetails = useCallback(
    (requestArgs: UpdateProjectDetailsRequest) => {
      if (customScenario) {
        updateCustomScenarioDetails(
          requestArgs
        );
      }
    },
    [
      customScenario,
      updateCustomScenarioDetails
    ]
  );

  const handleOnUpdateCustomScenarioDefaultMeasures = useCallback(
    (data: { add: boolean; measures: Array<number>; }) => {
      if (customScenario) {
        updateCustomScenarioDefaultMeasures({
          id: customScenario.id,
          add: data.add,
          measures: data.measures
        });
      }
    },
    [
      customScenario,
      updateCustomScenarioDefaultMeasures
    ]
  );

  const handleOnUpdateCustomScenarioCustomMeasures = useCallback(
    (data: { add: boolean; custom_measures: Array<number>; }) => {
      if (customScenario) {
        updateCustomScenarioCustomMeasures({
          id: customScenario.id,
          add: data.add,
          custom_measures: data.custom_measures
        });
      }
    },
    [
      customScenario,
      updateCustomScenarioCustomMeasures
    ]
  );

  return customScenario &&
  defaultMeasuresNotInCustomScenario &&
  customMeasuresNotInCustomScenario ? (
    <Box
      sx={{
        flex: 1,
        height: "100%",
        overflowY: "auto",
        width: "100%"
      }}
      onClick={() => onViewClicked()}
    >

      <CustomScenarioDetails
        customScenario={customScenario}
        onDeleteCustomScenario={onDeleteCustomScenario}
        onUpdateCustomScenarioDetails={handleUpdateCustomScenarioDetails}
        setConfirmationDialogTitle={setConfirmationDialogTitle}
        setConfirmationDialogMessage={setConfirmationDialogMessage}
        setConfirmationDialogOpen={setConfirmationDialogOpen}
      />

      <Box
        sx={{
          paddingLeft: "32px",
          paddingRight: "32px",
          paddingBottom: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "16px"
        }}
      >
        <CustomScenarioMeasureCategorySection
          avatarColor={theme.retrokitPalette.red.dark}
          category="Ventilation"
          customMeasures={customScenario.custom_measures}
          defaultMeasures={customScenario.measures}
          handleSetAddMeasuresDialogCategory={setAddMeasuresDialogCategory}
          handleSetAddMeasuresDialogOpen={setAddMeasuresDialogOpen}
          isLoading={isCustomScenarioLoading}
          onUpdateCustomScenarioDefaultMeasures={
            handleOnUpdateCustomScenarioDefaultMeasures
          }
          onUpdateCustomScenarioCustomMeasures={
            handleOnUpdateCustomScenarioCustomMeasures
          }
          scenarioTitle={customScenario.title}
          setConfirmationDialogTitle={setConfirmationDialogTitle}
          setConfirmationDialogMessage={setConfirmationDialogMessage}
          setConfirmationDialogOpen={setConfirmationDialogOpen}
          subHeaderText="Mechanical ventilation and air tightness"
          tableHeaderColor={theme.retrokitPalette.red.main}
          tableEvenRowColor={colors.red["100"]}
          tableOddRowColor={colors.red["50"]}
        />

        <CustomScenarioMeasureCategorySection
          avatarColor={theme.retrokitPalette.green.dark}
          category="Fabric"
          customMeasures={customScenario.custom_measures}
          defaultMeasures={customScenario.measures}
          handleSetAddMeasuresDialogCategory={setAddMeasuresDialogCategory}
          handleSetAddMeasuresDialogOpen={setAddMeasuresDialogOpen}
          isLoading={isCustomScenarioLoading}
          onUpdateCustomScenarioDefaultMeasures={
            handleOnUpdateCustomScenarioDefaultMeasures
          }
          onUpdateCustomScenarioCustomMeasures={
            handleOnUpdateCustomScenarioCustomMeasures
          }
          scenarioTitle={customScenario.title}
          setConfirmationDialogTitle={setConfirmationDialogTitle}
          setConfirmationDialogMessage={setConfirmationDialogMessage}
          setConfirmationDialogOpen={setConfirmationDialogOpen}
          subHeaderText="Insulation for floors, roofs and walls. Replacement of doors and windows"
          tableHeaderColor={theme.retrokitPalette.green.main}
          tableEvenRowColor={colors.green["100"]}
          tableOddRowColor={colors.green["50"]}
        />

        <CustomScenarioMeasureCategorySection
          avatarColor={theme.retrokitPalette.blue.dark}
          category="Heating"
          customMeasures={customScenario.custom_measures}
          defaultMeasures={customScenario.measures}
          handleSetAddMeasuresDialogCategory={setAddMeasuresDialogCategory}
          handleSetAddMeasuresDialogOpen={setAddMeasuresDialogOpen}
          isLoading={isCustomScenarioLoading}
          onUpdateCustomScenarioDefaultMeasures={
            handleOnUpdateCustomScenarioDefaultMeasures
          }
          onUpdateCustomScenarioCustomMeasures={
            handleOnUpdateCustomScenarioCustomMeasures
          }
          scenarioTitle={customScenario.title}
          setConfirmationDialogTitle={setConfirmationDialogTitle}
          setConfirmationDialogMessage={setConfirmationDialogMessage}
          setConfirmationDialogOpen={setConfirmationDialogOpen}
          subHeaderText="Heating controls, heat emission and distribution systems, and boiler upgrades"
          tableHeaderColor={theme.retrokitPalette.blue.main}
          tableEvenRowColor={colors.blue["100"]}
          tableOddRowColor={colors.blue["50"]}
        />

        <CustomScenarioMeasureCategorySection
          avatarColor={theme.retrokitPalette.orange.dark}
          category="Renewables"
          customMeasures={customScenario.custom_measures}
          defaultMeasures={customScenario.measures}
          handleSetAddMeasuresDialogCategory={setAddMeasuresDialogCategory}
          handleSetAddMeasuresDialogOpen={setAddMeasuresDialogOpen}
          isLoading={isCustomScenarioLoading}
          onUpdateCustomScenarioDefaultMeasures={
            handleOnUpdateCustomScenarioDefaultMeasures
          }
          onUpdateCustomScenarioCustomMeasures={
            handleOnUpdateCustomScenarioCustomMeasures
          }
          scenarioTitle={customScenario.title}
          setConfirmationDialogTitle={setConfirmationDialogTitle}
          setConfirmationDialogMessage={setConfirmationDialogMessage}
          setConfirmationDialogOpen={setConfirmationDialogOpen}
          subHeaderText="Heat pumps, solar PV, solar thermal, and wood stove"
          tableHeaderColor={theme.retrokitPalette.orange.main}
          tableEvenRowColor={colors.orange["100"]}
          tableOddRowColor={colors.orange["50"]}
        />

        <CustomScenarioMeasureCategorySection
          avatarColor={theme.retrokitPalette.teal.dark}
          category="Other"
          customMeasures={customScenario.custom_measures}
          defaultMeasures={customScenario.measures}
          handleSetAddMeasuresDialogCategory={setAddMeasuresDialogCategory}
          handleSetAddMeasuresDialogOpen={setAddMeasuresDialogOpen}
          isLoading={isCustomScenarioLoading}
          onUpdateCustomScenarioDefaultMeasures={
            handleOnUpdateCustomScenarioDefaultMeasures
          }
          onUpdateCustomScenarioCustomMeasures={
            handleOnUpdateCustomScenarioCustomMeasures
          }
          scenarioTitle={customScenario.title}
          setConfirmationDialogTitle={setConfirmationDialogTitle}
          setConfirmationDialogMessage={setConfirmationDialogMessage}
          setConfirmationDialogOpen={setConfirmationDialogOpen}
          subHeaderText="Lighting, monitoring, and miscellaneous"
          tableHeaderColor={theme.retrokitPalette.teal.main}
          tableEvenRowColor={colors.teal["100"]}
          tableOddRowColor={colors.teal["50"]}
        />

        <AddMeasuresToCustomScenarioDialog
          category={addMeasuresDialogCategory}
          customMeasures={customScenario.custom_measures}
          customMeasuresNotInCustomScenario={
            customMeasuresNotInCustomScenario.filter(
              (measure) => measure.retrokit_category === addMeasuresDialogCategory
            )
          }
          defaultMeasures={customScenario.measures}
          defaultMeasuresNotInCustomScenario={
            defaultMeasuresNotInCustomScenario.filter(
              (measure) => measure.retrokit_category === addMeasuresDialogCategory
            )
          }
          isLoading={isCustomScenarioLoading}
          onClose={handleCloseAddMeasuresDialog}
          onUpdateCustomScenarioDefaultMeasures={
            handleOnUpdateCustomScenarioDefaultMeasures
          }
          onUpdateCustomScenarioCustomMeasures={
            handleOnUpdateCustomScenarioCustomMeasures
          }
          open={addMeasuresDialogOpen}
          scenarioTitle={customScenario.title}
          setConfirmationDialogTitle={setConfirmationDialogTitle}
          setConfirmationDialogMessage={setConfirmationDialogMessage}
          setConfirmationDialogOpen={setConfirmationDialogOpen}
          tableHeaderColor={addMeasuresDialogTableHeaderColor}
          tableEvenRowColor={addMeasuresDialogTableEvenRowColor}
          tableOddRowColor={addMeasuresDialogTableOddRowColor}
        />

      </Box>

      <ConfirmationDialog
        buttonText="Okay"
        message={confirmationDialogMessage}
        title={confirmationDialogTitle}
        onClose={handleCloseConfirmationDialog}
        onSubmit={() => {
          handleCloseConfirmationDialog();
        }}
        open={confirmationDialogOpen}
      />

    </Box>
  ) : null;
}
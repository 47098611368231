import React, { useState } from "react";

import { format } from "date-fns";


import AddIcon from "@mui/icons-material/Add";
import StopIcon from "@mui/icons-material/Stop";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";

import type {
  MinimalCustomScenario,
  CustomScenario
} from "features/customScenarios/types";
import MenuItem from "@mui/material/MenuItem";


type ScenarioDrawerProps = {
  customScenarios: Array<CustomScenario> | undefined;
  onAddScenarioClick: () => void;
  onClose: () => void;
  open: boolean;
  setSelectedScenario: (scenario: MinimalCustomScenario) => void;
};

const drawerWidth = 240;


export function CustomScenarioDrawer(
  {
    customScenarios,
    onAddScenarioClick,
    onClose,
    open,
    setSelectedScenario
  }: ScenarioDrawerProps) {

  const [
    selectedIndex,
    setSelectedIndex
  ] = useState<number>();

  const handleDrawerClose = () => {
    onClose();
  };

  const handleScenarioSelected = (scenario: CustomScenario) => {
    setSelectedIndex(scenario.id);
    setSelectedScenario(scenario);
  };

  return <Collapse
    orientation="horizontal"
    in={open}
    sx={{
      zIndex: 1150,
      position: "absolute",
      height: "100%"
    }}
  >
    <Box
      sx={{
        height: "100%",
        width: drawerWidth,
        flexShrink: 0,
        backgroundColor: "#FFFFFF",
        borderRight: "1px solid #dadce0"
      }}
    >

      <MenuItem
        key="text"
        disabled
        sx={{
          fontWeight: 600,
          fontSize: 16,
          opacity: 1
        }}
      >
        Scenarios
      </MenuItem>

      <Box
        key="spacer-1"
        sx={{
          background: "#dadce0",
          height: "1px",
          width: "100%"
        }}
      />

      <List
        sx={{
          marginBottom: "52px"
        }}
      >
        <ListItem
          disablePadding
          key="0"
        >
          <ListItemButton>
            <Tooltip
              title="Create a new scenario"
              placement="right"
            >
              <Button
                sx={{
                  width: "100%"
                }}
                variant="outlined"
                color="secondary"
                endIcon={<AddIcon />}
                onClick={() => onAddScenarioClick()}
              >
                Create
              </Button>
            </Tooltip>
          </ListItemButton>
        </ListItem>

        {
          customScenarios !== undefined ? customScenarios.slice().sort((scenarioA, scenarioB) => {
            if (scenarioA.title.toLowerCase() < scenarioB.title.toLowerCase()) {
              return -1;
            }
            if (scenarioA.title.toLowerCase() > scenarioB.title.toLowerCase()) {
              return 1;
            }
            return 0;
          }).map((scenario) => <Tooltip
              key={scenario.id}
              title={scenario.description}
              placement="right"
            >
              <ListItem
                disablePadding
                onClick={() => {
                  handleScenarioSelected(scenario);
                  handleDrawerClose();
                }}
              >
                <ListItemButton
                  selected={scenario.id === selectedIndex}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <StopIcon />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary={scenario.title}
                    secondary={String(format(
                      scenario.created_on,
                      "do MMM yyyy"
                    ))}
                  />

                </ListItemButton>

              </ListItem>
            </Tooltip>
          ) : null
        }

      </List>
    </Box>
  </Collapse>;
}

import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputBase from "@mui/material/InputBase";
import LinearProgress from "@mui/material/LinearProgress";
import ListItem from "@mui/material/ListItem";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import { alpha } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import {
  DWELLING_FILTER_CHECKBOXES,
  DWELLING_VIEW_CHECKBOXES,
  DASHBOARD_TABLE_COLUMNS
} from "common/assets/definitions/checkbox_groups";
import {
  RETRY_STATUSES
} from "common/assets/definitions/task_statuses";
import withMaterialBreakpointQuery from "common/assets/utilities/withMaterialBreakpointQuery";
import {
  RetroKitRoutes
} from "common/constants/retroKitRoutes";
import {
  withMaterialUserInterfaceTheme
} from "common/hocs/theme";
import {
  withUseDashboard
} from "features/dashboard/components/WithUseDashboard";
import {
  CreateProjectDialog
} from "features/projects/dialogs/CreateProjectDialog";
import {
  getGeoStock,
  getGeoStockTaskResult,
  getElectoralDivisions,
  getElectoralDivisionsTaskResult
} from "v1/actions/dashboard";
import TransitionAlerts from "v1/components/alerts/TransitionAlerts";
import DashboardGraph from "v1/components/dashboard/tabs/graph/DashboardGraphs";
import DashboardMap from "v1/components/dashboard/tabs/map/DashboardMap";
import DashboardTable from "v1/components/dashboard/tabs/table/DashboardTable";
import WelcomeDialog from "v1/components/dialogs/welcome/WelcomeDialog";


class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.geostockPollingIntervalTimeout = 5000;
    this.electoralDivisionsPollingIntervalTimeout = 5000;

    this.state = {
      // DASHBOARD
      geostockPollingActive: false,
      electoralDivisionsPollingActive: false,

      geostockPollingRetries: 5,
      electoralDivisionsPollingRetries: 5,

      selectedDwellings: [],

      columnsButtonAnchorEl: null,
      filtersButtonAnchorEl: null,
      kpisButtonAnchorEl: null,
      sortingButtonAnchorEl: null,

      alertIsOpen: false,
      alertTitle: "",
      alertContent: "",
      alertSeverity: "info",

      // FILTERS
      filters: [...DWELLING_FILTER_CHECKBOXES],
      filteredDwellings: [],

      // GRAPHS
      graphMode: "total",
      graphView: "age",
      views: [...DWELLING_VIEW_CHECKBOXES],

      // MAP
      mapData: null,
      electoralDivisions: [],
      electoralDivisionsLayerOpacity: 0.5,
      mapPosition: null,
      mapZoom: null,
      mapStyle: "Streets",
      showMapSelectionTip: true,

      // PROJECTS
      title: "",
      description: "",
      isAddingProject: false,
      speedDialOpen: false,

      // TABLE
      initialPage: 0,
      tablePage: 0,
      tableSearchActive: false,
      tableSearchQuery: "",
      tableColumns: [...DASHBOARD_TABLE_COLUMNS],
      tableOrderByCollection: [
        {
          orderBy: 0,
          orderDirection: "asc",
          sortOrder: 2
        },
        {
          orderBy: 1,
          orderDirection: "asc",
          sortOrder: 1
        }
      ],

      // TABS
      activeTabIndex: 0,

      // Add Project Modal
      addProjectModelOpen: false
    };

    // DASHBOARD
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleViewChange = this.handleViewChange.bind(this);
    this.handleSelectionCleared = this.handleSelectionCleared.bind(this);
    this.handleTableColumnChange = this.handleTableColumnChange.bind(this);
    this.handleTableColumnDrag = this.handleTableColumnDrag.bind(this);
    this.handleTableOrderCollectionChange = this.handleTableOrderCollectionChange.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.startPollingForGeoStock = this.startPollingForGeoStock.bind(this);
    this.startPollingForElectoralDivisions =
      this.startPollingForElectoralDivisions.bind(this);

    // TABLE
    this.handleTableSelectionChange =
      this.handleTableSelectionChange.bind(this);

    // MAP
    this.handleMapSelectionChange = this.handleMapSelectionChange.bind(this);
    this.onChangeElectoralDivisionsLayerOpacity =
      this.onChangeElectoralDivisionsLayerOpacity.bind(this);
    this.setMapZoom = this.setMapZoom.bind(this);
    this.setMapPosition = this.setMapPosition.bind(this);
    this.setMapStyle = this.setMapStyle.bind(this);
    this.setShowMapSelectionTip = this.setShowMapSelectionTip.bind(this);

    // PROJECTS
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCreateProject = this.handleCreateProject.bind(this);
  }

  // setTablePage = (page) => {
  //     this.setState({tablePage: page})
  // }

  // Map functions
  onChangeElectoralDivisionsLayerOpacity(opacity) {
    this.setState({ electoralDivisionsLayerOpacity: opacity });
  }

  setMapZoom(zoom) {
    this.setState({ mapZoom: zoom });
  }

  setMapPosition(position) {
    this.setState({ mapPosition: position });
  }

  setMapStyle(newStyle) {
    this.setState({ mapStyle: newStyle });
  }

  setShowMapSelectionTip(show) {
    this.setState({ showMapSelectionTip: show });
  }

  // Project functions
  handleInputChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleTableSelectionChange(rows) {

    console.log("rows =", rows)

    this.setState({ selectedDwellings: rows });
  }

  // TODO: The following code directly manipulates a prop. This is not advised. The reason for doing this
  //  here is that the material-table library directly manipulates the same prop later on in DashboardTable
  handleMapSelectionChange(dwellings) {

    const newSelectedDwellings = [
      ...this.state.selectedDwellings
    ];

    dwellings.forEach((dwelling) => {
      const selectedDwelling = this.props.geostock.features.find(
        (feature) => feature.id === dwelling.id
      );

      if (selectedDwelling.tableData) {
        selectedDwelling.tableData.checked = true;
      } else {
        selectedDwelling.tableData = { checked: true };
      }

      if (
        this.state.selectedDwellings.filter(
          (dwelling) => dwelling.id === selectedDwelling.id
        ).length === 0
      ) {
        newSelectedDwellings.push(
          selectedDwelling
        );
      }

    });

    this.setState(
      {
        ...this.state,
        selectedDwellings: newSelectedDwellings
      }
    );
  }

  // TODO: The following code directly manipulates a prop. This is not advised. The reason for doing this
  //  here is that the material-table library directly manipulates the same prop later on in DashboardTable
  handleSelectionCleared(dwellings) {
    let { selectedDwellings } = this.state;

    if (dwellings != null) {
      dwellings.forEach((dwelling) => {
        const selectedDwelling = this.props.geostock.features.find(
          (feature) => feature.id === dwelling.id
        );
        if (selectedDwelling.tableData) {
          selectedDwelling.tableData.checked = false;
        } else {
          selectedDwelling.tableData = { checked: false };
        }
        const selectedDwellingIndex = selectedDwellings
          .map((dwelling) => dwelling.id)
          .indexOf(selectedDwelling.id);
        if (selectedDwellingIndex > -1) {
          selectedDwellings.splice(selectedDwellingIndex, 1);
        }
      });
    } else {
      this.props.geostock.features.forEach((dwelling) => {
        if (dwelling.tableData) {
          dwelling.tableData.checked = false;
        } else {
          dwelling.tableData = { checked: false };
        }
      });
      selectedDwellings = [];
    }

    this.setState({ ...this.state, selectedDwellings });
  }

  handleTableColumnChange(column) {
    const newTableColumns = [...DASHBOARD_TABLE_COLUMNS];
    newTableColumns[column.id].hidden = !column.hidden;
    this.setState({ tableColumns: newTableColumns });
  }

  handleTableColumnDrag(sourceIndex, destinationIndex) {
    const newTableColumns = [...DASHBOARD_TABLE_COLUMNS];
    const column = newTableColumns[destinationIndex];
    column.id = sourceIndex;
    newTableColumns[destinationIndex] = newTableColumns[sourceIndex];
    newTableColumns[destinationIndex].id = destinationIndex;
    newTableColumns[destinationIndex] = newTableColumns[sourceIndex];
    newTableColumns[sourceIndex] = column;
    this.setState({ tableColumns: newTableColumns });
  }

  handleTableOrderCollectionChange(orderByCollection) {

    console.log("handleTableOrderCollectionChange =", orderByCollection)

    this.setState({
      tableOrderByCollection: orderByCollection
    })
  }

  async startPollingForGeoStock() {
    this.setState({ geostockPollingActive: true });

    do {
      await this.props.getGeoStockTaskResult(this.props.geostockTaskId);

      if (RETRY_STATUSES.includes(this.props.geostockTaskStatus)) {
        await new Promise((resolve) =>
          setTimeout(resolve, this.geostockPollingIntervalTimeout)
        );
      }
    } while (
      RETRY_STATUSES.includes(this.props.geostockTaskStatus) &&
      this.state.geostockPollingActive
      );

    this.setState({ geostockPollingActive: false });
  }

  async startPollingForElectoralDivisions() {
    this.setState({ electoralDivisionsPollingActive: true });

    do {
      await this.props.getElectoralDivisionsTaskResult(
        this.props.electoralDivisionsTaskId
      );

      if (RETRY_STATUSES.includes(this.props.electoralDivisionsTaskStatus)) {
        await new Promise((resolve) =>
          setTimeout(resolve, this.electoralDivisionsPollingIntervalTimeout)
        );
      }
    } while (
      RETRY_STATUSES.includes(this.props.electoralDivisionsTaskStatus) &&
      this.state.electoralDivisionsPollingActive
      );

    this.setState({ electoralDivisionsPollingActive: false });
  }

  componentDidMount() {
    this.props.getGeoStock();
    this.props.setDrawerSelectedIndex(0);
    this.props.setToolbarTitle("Stock");
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // DWELLINGS
    if (
      prevProps.geostockTaskStatus === null &&
      this.props.geostockTaskStatus === "JUST_RECEIVED"
    ) {
      if (this.state.geostockPollingActive === false) {
        this.startPollingForGeoStock();
      }

      this.setState({
        alertTitle: "Initializing",
        alertContent: "Requesting your dwellings.",
        alertSeverity: "info"
      });
      this.showAlert(true);
    } else if (
      prevProps.geostockTaskStatus === "JUST_RECEIVED" &&
      this.props.geostockTaskStatus === "SUCCESS"
    ) {
      this.setState({
        alertTitle: "Updating",
        alertContent: "Requesting updated dwellings.",
        alertSeverity: "info"
      });
      this.showAlert(true);
    } else if (
      prevProps.geostockTaskStatus === "JUST_RECEIVED" &&
      ["EXECUTING", "STARTED", "PENDING"].includes(
        this.props.geostockTaskStatus
      )
    ) {
      this.setState({
        alertTitle: "Processing",
        alertContent: "Gathering your dwellings.",
        alertSeverity: "info"
      });
      this.showAlert(true);
    } else if (
      prevProps.geostockTaskStatus !== "FAILURE" &&
      this.props.geostockTaskStatus === "FAILURE"
    ) {
      this.setState({
        alertTitle: "Error",
        alertContent:
          "There was an error loading your dwellings. Please refresh the page.",
        alertSeverity: "error"
      });
      this.showAlert(true);
    } else if (
      prevProps.geostockTaskStatus !== "REVOKED" &&
      this.props.geostockTaskStatus === "REVOKED"
    ) {
      this.setState({
        alertTitle: "Error",
        alertContent:
          "The request for your dwellings was revoked. Please refresh the page.",
        alertSeverity: "error"
      });
      this.showAlert(true);
    } else if (
      ["EXECUTING", "STARTED", "PENDING"].includes(
        prevProps.geostockTaskStatus
      ) &&
      this.props.geostockTaskStatus === "DOWNLOADING"
    ) {
      this.showAlert(true);
      this.setState({
        alertTitle: "Processed",
        alertContent: "Downloading your dwellings.",
        alertSeverity: "info"
      });
    } else if (
      prevProps.geostockTaskStatus === "DOWNLOADING" &&
      this.props.geostockTaskStatus === "SUCCESS"
    ) {
      this.props.getElectoralDivisions();
      this.showAlert(false);
      this.setState({
        alertTitle: "",
        alertContent: "",
        alertSeverity: "info"
      });

      if (this.props.user.first_use === true) {
        if (
          prevProps.geostock.features.length === 0 &&
          this.props.geostock.features.length === 0
        ) {
          console.log("componentDidUpdate() user has no stock");
          this.props.setShowWelcome(true);
        }
      }
    }

    // ELECTORAL DIVISIONS
    if (
      prevProps.electoralDivisionsTaskStatus === null &&
      this.props.electoralDivisionsTaskStatus === "JUST_RECEIVED"
    ) {
      if (this.state.electoralDivisionsPollingActive === false) {
        this.startPollingForElectoralDivisions();
      }
    } else if (
      prevProps.electoralDivisionsTaskStatus !== "FAILURE" &&
      this.props.electoralDivisionsTaskStatus === "FAILURE"
    ) {
      this.setState({
        alertTitle: "Error",
        alertContent:
          "There was an error loading your the electoral divisions for the map. Please refresh the page.",
        alertSeverity: "error"
      });
      this.showAlert(true);
    } else if (
      prevProps.electoralDivisionsTaskStatus !== "REVOKED" &&
      this.props.electoralDivisionsTaskStatus === "REVOKED"
    ) {
      this.setState({
        alertTitle: "Error",
        alertContent:
          "The request for electoral divisions to display on the map was revoked. Please refresh the page.",
        alertSeverity: "error"
      });
      this.showAlert(true);
    } else if (
      ["EXECUTING", "STARTED", "PENDING"].includes(
        prevProps.electoralDivisionsTaskStatus
      ) &&
      this.props.electoralDivisionsTaskStatus === "DOWNLOADING"
    ) {
    } else if (
      prevProps.electoralDivisionsTaskStatus === "DOWNLOADING" &&
      this.props.electoralDivisionsTaskStatus === "SUCCESS"
    ) {
    }

    // SELECTED DWELLINGS
    if (
      prevState.selectedDwellings.length !== this.state.selectedDwellings.length
    ) {
      if (this.state.selectedDwellings.length > 0) {
        console.log("componentDidUpdate() dwelling added to selectedDwellings");
      } else {
        console.log(
          "componentDidUpdate() no selectedDwellings"
        );
      }
    }

    // FILTERED DWELLINGS
    const previouslyActiveFilters = prevState.filters.filter(
      (item) => item.checked === true
    );
    const currentlyActiveFilters = this.state.filters.filter(
      (item) => item.checked === true
    );

    if (previouslyActiveFilters.length !== currentlyActiveFilters.length) {
      if (
        currentlyActiveFilters.length > 0 &&
        this.props.geostock.features.length > 0
      ) {
        const filteredDwellings = this.props.geostock.features.filter(
          (dwelling) => {
            const filterCategoryChecks = {
              age: [],
              type: [],
              construction: [],
              fuel: [],
              ber: []
            };

            this.state.filters
              .filter((item) => item.checked === true)
              .forEach((filter) => {
                switch (filter.name) {
                  case "check-box-pre-1971": {
                    let dateParts = ["1900", "01", "01"];
                    if (dwelling.properties.date_of_construction) {
                      dateParts =
                        dwelling.properties.date_of_construction.split("-");
                    }
                    filterCategoryChecks.age.push(
                      parseInt(dateParts[0]) < 1971
                    );
                    break;
                  }
                  case "check-box-1971-1990": {
                    let dateParts = ["1900", "01", "01"];
                    if (dwelling.properties.date_of_construction) {
                      dateParts =
                        dwelling.properties.date_of_construction.split("-");
                    }
                    const year = parseInt(dateParts[0]);
                    filterCategoryChecks.age.push(year >= 1971 && year <= 1990);
                    break;
                  }
                  case "check-box-1991-2000": {
                    let dateParts = ["1900", "01", "01"];
                    if (dwelling.properties.date_of_construction) {
                      dateParts =
                        dwelling.properties.date_of_construction.split("-");
                    }
                    const year = parseInt(dateParts[0]);
                    filterCategoryChecks.age.push(year >= 1991 && year <= 2000);
                    break;
                  }
                  case "check-box-2001-2010": {
                    let dateParts = ["1900", "01", "01"];
                    if (dwelling.properties.date_of_construction) {
                      dateParts =
                        dwelling.properties.date_of_construction.split("-");
                    }
                    const year = parseInt(dateParts[0]);
                    filterCategoryChecks.age.push(year >= 2001 && year <= 2010);
                    break;
                  }
                  case "check-box-post-2011": {
                    let dateParts = ["1900", "01", "01"];
                    if (dwelling.properties.date_of_construction) {
                      dateParts =
                        dwelling.properties.date_of_construction.split("-");
                    }
                    filterCategoryChecks.age.push(
                      parseInt(dateParts[0]) >= 2011
                    );
                    break;
                  }
                  case "check-box-apartment": {
                    filterCategoryChecks.type.push(
                      dwelling.properties.latest_archetype.dwelling_type ===
                      "Apartment"
                    );
                    break;
                  }
                  case "check-box-detached": {
                    filterCategoryChecks.type.push(
                      dwelling.properties.latest_archetype.dwelling_type ===
                      "Detached house"
                    );
                    break;
                  }
                  case "check-box-semi-detached": {
                    filterCategoryChecks.type.push(
                      dwelling.properties.latest_archetype.dwelling_type ===
                      "Semi-d house"
                    );
                    break;
                  }
                  case "check-box-terraced": {
                    filterCategoryChecks.type.push(
                      dwelling.properties.latest_archetype.dwelling_type ===
                      "Terraced house"
                    );
                    break;
                  }
                  case "check-box-timber": {
                    filterCategoryChecks.construction.push(
                      dwelling.properties.latest_archetype.main_wall_type ===
                      "Timber frame"
                    );
                    break;
                  }
                  case "check-box-solid-or-hollow": {
                    filterCategoryChecks.construction.push(
                      dwelling.properties.latest_archetype.main_wall_type ===
                      "Solid or hollow"
                    );
                    break;
                  }
                  case "check-box-cavity": {
                    filterCategoryChecks.construction.push(
                      dwelling.properties.latest_archetype.main_wall_type ===
                      "Cavity"
                    );
                    break;
                  }
                  case "check-box-electricity": {
                    filterCategoryChecks.fuel.push(
                      dwelling.properties.latest_archetype.main_fuel ===
                      "Electricity"
                    );
                    break;
                  }
                  case "check-box-gas": {
                    filterCategoryChecks.fuel.push(
                      dwelling.properties.latest_archetype.main_fuel ===
                      "Gas or LPG"
                    );
                    break;
                  }
                  case "check-box-group-heating": {
                    filterCategoryChecks.fuel.push(
                      dwelling.properties.latest_archetype.main_fuel ===
                      "Group Heating"
                    );
                    break;
                  }
                  case "check-box-heating-oil": {
                    filterCategoryChecks.fuel.push(
                      dwelling.properties.latest_archetype.main_fuel ===
                      "Heating oil"
                    );
                    break;
                  }
                  case "check-box-solid": {
                    filterCategoryChecks.fuel.push(
                      dwelling.properties.latest_archetype.main_fuel ===
                      "Solid fuel"
                    );
                    break;
                  }
                  case "check-box-a1": {
                    filterCategoryChecks.ber.push(
                      dwelling.properties.latest_performance.rating === "A1"
                    );
                    break;
                  }
                  case "check-box-a2": {
                    filterCategoryChecks.ber.push(
                      dwelling.properties.latest_performance.rating === "A2"
                    );
                    break;
                  }
                  case "check-box-a3": {
                    filterCategoryChecks.ber.push(
                      dwelling.properties.latest_performance.rating === "A3"
                    );
                    break;
                  }
                  case "check-box-b1": {
                    filterCategoryChecks.ber.push(
                      dwelling.properties.latest_performance.rating === "B1"
                    );
                    break;
                  }
                  case "check-box-b2": {
                    filterCategoryChecks.ber.push(
                      dwelling.properties.latest_performance.rating === "B2"
                    );
                    break;
                  }
                  case "check-box-b3": {
                    filterCategoryChecks.ber.push(
                      dwelling.properties.latest_performance.rating === "B3"
                    );
                    break;
                  }
                  case "check-box-c1": {
                    filterCategoryChecks.ber.push(
                      dwelling.properties.latest_performance.rating === "C1"
                    );
                    break;
                  }
                  case "check-box-c2": {
                    filterCategoryChecks.ber.push(
                      dwelling.properties.latest_performance.rating === "C2"
                    );
                    break;
                  }
                  case "check-box-c3": {
                    filterCategoryChecks.ber.push(
                      dwelling.properties.latest_performance.rating === "C3"
                    );
                    break;
                  }
                  case "check-box-d1": {
                    filterCategoryChecks.ber.push(
                      dwelling.properties.latest_performance.rating === "D1"
                    );
                    break;
                  }
                  case "check-box-d2": {
                    filterCategoryChecks.ber.push(
                      dwelling.properties.latest_performance.rating === "D2"
                    );
                    break;
                  }
                  case "check-box-e1": {
                    filterCategoryChecks.ber.push(
                      dwelling.properties.latest_performance.rating === "E1"
                    );
                    break;
                  }
                  case "check-box-e2": {
                    filterCategoryChecks.ber.push(
                      dwelling.properties.latest_performance.rating === "E2"
                    );
                    break;
                  }
                  case "check-box-f": {
                    filterCategoryChecks.ber.push(
                      dwelling.properties.latest_performance.rating === "F"
                    );
                    break;
                  }
                  case "check-box-g": {
                    filterCategoryChecks.ber.push(
                      dwelling.properties.latest_performance.rating === "G"
                    );
                    break;
                  }
                  default:
                    console.log("Error during filtering of", dwelling);
                }
              });

            const result = [];
            for (const key in filterCategoryChecks) {
              if (filterCategoryChecks[key].length > 0) {
                result.push(
                  filterCategoryChecks[key].some(
                    (activeFilterCheck) => activeFilterCheck === true
                  )
                );
              }
            }
            return result.every(
              (activeFilterCheck) => activeFilterCheck === true
            );
          }
        );

        this.setState({
          filteredDwellings,
          mapData: { ...this.props.geostock, features: filteredDwellings }
        });
      } else {
        this.setState({
          filteredDwellings: [],
          mapData: null
        });
      }
    }

    // PROJECTS
    if (prevProps.newProject === undefined && this.props.newProject !== undefined) {
      this.props.history.push(
        {
          pathname: RetroKitRoutes.PROJECTS,
          state: {
            newProject: this.props.newProject
          }
        }
      );
      this.props.setDrawerSelectedIndex(2);
    } else if (prevProps.newProject !== undefined && this.props.newProject !== undefined) {
      if (prevProps.newProject.id !== this.props.newProject.id) {
        this.props.history.push(
          {
            pathname: RetroKitRoutes.PROJECTS,
            state: {
              newProject: this.props.newProject
            }
          }
        );
        this.props.setDrawerSelectedIndex(2);
      }
    }

  }

  showAlert(alertIsOpen) {
    this.setState({ alertIsOpen });
  }

  componentWillUnmount() {
    this.setState({
      electoralDivisionsPollingActive: false,
      geostockPollingActive: false
    });
  }

  handleFilterChange(event, filter) {
    const filters = [...this.state.filters];

    if (filter.category === "all-filters") {
      filters.forEach((newFilter) => {
        newFilter.checked = false;
      });
      this.setState({ filters, filteredDwellings: [] });
    } else {
      filters[filter.id] = { ...filters[filter.id], checked: !filter.checked };
      this.setState({ filters });
    }
  }

  handleViewChange(event, view) {
    let { graphMode, graphView } = this.state;
    const views = [...this.state.views];

    views.forEach((viewInstance) => {
      if (
        viewInstance.category === view.category &&
        viewInstance.id !== view.id
      ) {
        viewInstance.checked = false;
      }
    });
    views[view.id] = { ...views[view.id], checked: !view.checked };

    if (views[view.id].checked === true) {
      if (views[view.id].category === "global") {
        if (views[view.id].name === "check-box-average") {
          graphMode = "average";
        } else {
          graphMode = "total";
        }
      } else if (views[view.id].name === "check-box-age-group") {
        graphView = "age";
      } else if (views[view.id].name === "check-box-dwelling-type") {
        graphView = "type";
      } else {
        graphView = "dwelling";
      }
    }

    this.setState({ graphMode, graphView, views });
  }

  handleCreateProject(data) {
    this.setState({ addProjectModelOpen: false });

    this.props.onCreateProject({
      title: data.title,
      description: data.description,
      dwellings: this.state.selectedDwellings.map((dwelling) => ({
        id: dwelling.id,
        type: dwelling.properties.dwelling_type,
      })),
    });
  }

  render() {
    const activeFilters = this.state.filters.filter(
      (item) => item.checked === true
    );

    const tabsHeight = 48;
    let toolbarHeight = 64;
    const toolbarFilterChipsHeight = activeFilters.length > 0 ? 36 : 0;
    const tableFooterHeight = 53;

    const toolBarStyle =
      this.state.selectedDwellings.length > 0
        ? {
          color: "#e91e63",
          backgroundColor: "#fce4ec"
        }
        : {
          color: "#000000",
          backgroundColor: "#ffffff"
        };

    const { breakpoint, theme } = this.props;

    const removeAllFilters = {
      id: 100,
      name: "check-box-uncheck-all",
      key: "checkBoxAll",
      label: "All",
      category: "all-filters",
      checked: false
    };

    let appBarHeight = 62;
    if (breakpoint === "xs") {
      toolbarHeight = 56;
    } else if (breakpoint === "sm") {
      appBarHeight = 64;
    }

    const bodyHeight = Math.round(
      document.body.offsetHeight -
      appBarHeight -
      tabsHeight -
      toolbarFilterChipsHeight -
      toolbarHeight
    );

    return (
      <Box
        sx={{
          height: "100%"
        }}
      >
        <TransitionAlerts
          id="dashboardAlert"
          open={this.state.alertIsOpen}
          setOpen={this.showAlert}
          title={this.state.alertTitle}
          content={this.state.alertContent}
          severity={this.state.alertSeverity}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            height: "100%"
          }}
        >
          <Paper
            id="dashboardTabs"
            sx={{
              zIndex: theme.zIndex.drawer - 1,
              // width: "100%",
              position: "sticky",
              top: 62,
              [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
                top: 62
              },
              [theme.breakpoints.up("sm")]: {
                top: 64
              },
              width: "100%"
            }}
            square
          >
            <Tabs
              value={this.state.activeTabIndex}
              onChange={(event, newValue) =>
                this.setState({ activeTabIndex: newValue })
              }
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Graphs" />
              <Tab label="Table" />

              {
                this.props.electoralDivisions === null ?
                  <Box
                    sx={{
                      width: "90px",
                      height: "48px",
                      paddingTop: "12px",
                      paddingBottom: "12px",
                      paddingLeft: "16px",
                      paddingRight: "16px",
                      display: "grid",
                      alignItems: "center"
                    }}
                  >
                    <LinearProgress />
                  </Box>
                  : <Tab label="Map" />
              }
            </Tabs>

            <div id="toolbar divider" />
          </Paper>

          <Paper
            id="dashboardToolbar"
            sx={{
              zIndex: theme.zIndex.drawer - 2,
              width: "100%",
              position: "sticky",
              top: 62 + 49,
              [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
                top: 62 + 49
              },
              [theme.breakpoints.up("sm")]: {
                top: 64 + 49
              },
              // necessary for content to be below app bar
              ...theme.mixins.toolbar,
              justifyContent: "flex-end"
            }}
            square
            style={toolBarStyle}
          >
            <Toolbar>
              <Typography
                variant="h6"
                color="inherit"
                noWrap
              >
                {this.props.geostock &&
                  (this.state.selectedDwellings.length > 0
                    ? `${this.state.selectedDwellings.length} dwelling(s) selected`
                    : activeFilters.length > 0
                      ? `Filtering for ${this.state.filteredDwellings.length} out of ${this.props.geostock.features.length} dwellings`
                      : `Showing ${this.props.geostock.features.length} dwellings`)}
              </Typography>

              <Box
                sx={{
                  flexGrow: 1
                }}
              />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                {
                  this.state.activeTabIndex === 0 ? (
                    <Tooltip title="Show KPIs" aria-label="show-kpis">
                      <IconButton
                        color="inherit"
                        onClick={(event) =>
                          this.setState({
                            kpisButtonAnchorEl: event.currentTarget
                          })
                        }
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                      >
                        {this.state.selectedDwellings.length > 0 ? (
                          <VisibilityIcon color="secondary" />
                        ) : (
                          <VisibilityIcon color="primary" />
                        )}
                      </IconButton>
                    </Tooltip>
                  ) : null
                }

                {
                  this.state.activeTabIndex === 0 ? (
                    <Tooltip title="Show Sorting" aria-label="show-sorting">
                      <IconButton
                        color="inherit"
                        onClick={(event) =>
                          this.setState({
                            sortingButtonAnchorEl: event.currentTarget
                          })
                        }
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                      >
                        {this.state.selectedDwellings.length > 0 ? (
                          <SortIcon color="secondary" />
                        ) : (
                          <SortIcon color="primary" />
                        )}
                      </IconButton>
                    </Tooltip>
                  ) : null
                }

                {
                  this.state.activeTabIndex === 1 ? (
                    <Tooltip
                      title="Use commas to search for more than one term"
                      aria-label="search-table"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          position: "relative",
                          borderRadius: theme.shape.borderRadius,
                          backgroundColor: alpha(theme.palette.common.white, 0.15),
                          "&:hover": {
                            backgroundColor: alpha(theme.palette.common.white, 0.25)
                          },
                          marginRight: theme.spacing(2),
                          marginLeft: 0,
                          width: "100%",
                          [theme.breakpoints.up("sm")]: {
                            marginLeft: theme.spacing(3),
                            width: "auto"
                          }
                        }}
                      >
                        <InputBase
                          id="dashboard-table-search-input"
                          placeholder="Search Table"
                          defaultValue={this.state.tableSearchQuery}
                          onChange={(event) => {
                            if (event.target.value === "") {
                              this.setState({
                                tableSearchQuery: "",
                                tableSearchActive: false
                              });
                            } else if (this.state.tableSearchActive === false) {
                              this.setState({ tableSearchActive: true });
                            }
                          }}
                          onKeyDown={(event) => {
                            // noinspection JSDeprecatedSymbols
                            if (event.key === "Enter" || event.keyCode === 13) {
                              this.setState({
                                tableSearchQuery:
                                  event.target.value.toLowerCase()
                              });
                            }
                          }}
                          onBlur={(event) => {
                            if (event) {
                              this.setState({
                                tableSearchQuery:
                                  event.target.value.toLowerCase()
                              });
                            }
                            if (event.target.value === "") {
                              this.setState({ tableSearchQuery: "" });
                            }
                          }}
                          classes={{
                            root: {
                              color: "inherit",
                              height: "100%"
                            },
                            input: {
                              padding: theme.spacing(1, 1, 1, 0),
                              // vertical padding + font size from searchIcon
                              paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
                              transition: theme.transitions.create("width"),
                              width: "100%",
                              [theme.breakpoints.up("md")]: {
                                width: "20ch"
                              }
                            }
                          }}
                          inputProps={{ "aria-label": "search" }}
                          endAdornment={
                            this.state.tableSearchActive ? (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() =>
                                    this.setState({
                                      tableSearchQuery: "",
                                      tableSearchActive: false
                                    })
                                  }
                                  onMouseDown={(event) => {
                                    document.getElementById(
                                      "dashboard-table-search-input"
                                    ).value = "";
                                    event.preventDefault();
                                  }}
                                  color="secondary"
                                >
                                  <ClearIcon />
                                </IconButton>
                              </InputAdornment>
                            ) : null
                          }
                        />
                        <IconButton
                          sx={{
                            padding: "10px"
                          }}
                          aria-label="search"
                        >
                          {
                            this.state.selectedDwellings.length > 0 ? (
                              <SearchIcon color="secondary" />
                            ) : (
                              <SearchIcon color="primary" />
                            )
                          }
                        </IconButton>
                      </Box>
                    </Tooltip>
                  ) : null
                }

                {
                  this.state.activeTabIndex === 1 ? (
                    <Tooltip
                      title="Show Columns"
                      aria-label="show-filters"
                    >
                      <IconButton
                        color="inherit"
                        onClick={(event) =>
                          this.setState({
                            columnsButtonAnchorEl: event.currentTarget
                          })
                        }
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                      >
                        {
                          this.state.selectedDwellings.length > 0 ? (
                            <ViewColumnIcon color="secondary" />
                          ) : (
                            <ViewColumnIcon color="primary" />
                          )
                        }
                      </IconButton>
                    </Tooltip>
                  ) : null
                }

                <Tooltip
                  title="Show Filters"
                  aria-label="show-filters"
                >
                  <IconButton
                    color="inherit"
                    onClick={(event) =>
                      this.setState({
                        filtersButtonAnchorEl: event.currentTarget
                      })
                    }
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                  >
                    {
                      this.state.selectedDwellings.length > 0 ? (
                        <FilterListIcon color="secondary" />
                      ) : (
                        <FilterListIcon color="primary" />
                      )
                    }
                  </IconButton>
                </Tooltip>

                {
                  this.state.selectedDwellings.length > 0 ? (
                    <Tooltip title="Clear Selection" aria-label="clear-selection">
                      <IconButton
                        color="inherit"
                        onClick={() => this.handleSelectionCleared(null)}
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                      >
                        <ClearIcon color="secondary" />
                      </IconButton>
                    </Tooltip>
                  ) : null
                }
              </Box>
            </Toolbar>

            <Box
              sx={{
                display: activeFilters.length > 0 ? "flex" : "none",
                justifyContent: "space-between",
                width: "100%",
                paddingRight: "16px"
              }}
            >
              <Box
                sx={{
                  paddingBottom: "6px",
                  paddingLeft: "24px",
                  paddingRight: "24px"
                }}
              >
                {
                  activeFilters.map((row) => (
                    <Chip
                      clickable
                      color="secondary"
                      key={row.name}
                      label={row.label}
                      size="small"
                      style={{ marginRight: 6, marginBottom: 6 }}
                      onClick={(event) => this.handleFilterChange(event, row)}
                      onDelete={(event) => this.handleFilterChange(event, row)}
                    />
                  ))
                }
              </Box>

              <Chip
                clickable
                // color="#c2c2cb"
                key="check-box-uncheck-all"
                label="Clear all filters"
                size="small"
                style={{ marginRight: 6 }}
                onClick={(event) =>
                  this.handleFilterChange(event, removeAllFilters)
                }
                onDelete={(event) =>
                  this.handleFilterChange(event, removeAllFilters)
                }
              />
            </Box>
          </Paper>

          {
            this.state.activeTabIndex === 0 && (
              <DashboardGraph
                dwellings={
                  this.state.selectedDwellings.length > 0
                    ? this.state.selectedDwellings
                    : this.state.filteredDwellings.length > 0
                      ? this.state.filteredDwellings
                      : this.props.geostock.features
                }
                mode={this.state.graphMode}
                view={this.state.graphView}
                numberOfSelectedDwellings={this.state.selectedDwellings.length}
                height={bodyHeight}
                hidden={this.state.activeTabIndex !== 0}
              />
            )
          }

          {
            this.state.activeTabIndex === 1 && (
              <DashboardTable
                dwellings={
                  this.state.filteredDwellings.length > 0
                    ? this.state.filteredDwellings
                    : this.props.geostock.features
                }
                searchQuery={this.state.tableSearchQuery}
                initialPage={this.state.initialPage}
                tableColumns={this.state.tableColumns}
                onColumnDragged={this.handleTableColumnDrag}
                selectedDwellings={this.state.selectedDwellings}
                onSelectionChange={this.handleTableSelectionChange}
                orderByCollection={this.state.tableOrderByCollection}
                onOrderCollectionChange={this.handleTableOrderCollectionChange}
                height={bodyHeight - tableFooterHeight - 2}
                breakpoint={this.props.breakpoint}
                hidden={this.state.activeTabIndex !== 1}
              />
            )
          }

          {
            this.state.activeTabIndex === 2 && (
              <DashboardMap
                geostock={
                  this.state.filteredDwellings.length > 0
                    ? this.state.mapData
                    : this.props.geostock
                }
                selectedDwellings={this.state.selectedDwellings}
                onSelectionChange={this.handleMapSelectionChange}
                onSelectionCleared={this.handleSelectionCleared}
                position={this.state.mapPosition}
                style={this.state.mapStyle}
                zoom={this.state.mapZoom}
                setPosition={this.setMapPosition}
                setStyle={this.setMapStyle}
                setZoom={this.setMapZoom}
                showSelectionTip={this.state.showMapSelectionTip}
                setShowSelectionTip={this.setShowMapSelectionTip}
                electoralDivisions={this.props.electoralDivisions}
                electoralDivisionsLayerOpacity={
                  this.state.electoralDivisionsLayerOpacity
                }
                onChangeElectoralDivisionsLayerOpacity={
                  this.onChangeElectoralDivisionsLayerOpacity
                }
                height={bodyHeight}
                hidden={this.state.activeTabIndex !== 2}
              />
            )
          }
        </Box>

        <SpeedDial
          ariaLabel="projectsSpeedDial"
          sx={{
            position: "absolute",
            "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
              bottom: theme.spacing(4),
              right: theme.spacing(4)
            },
            "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
              top: theme.spacing(4),
              left: theme.spacing(4)
            }
          }}
          hidden={this.state.selectedDwellings.length === 0}
          icon={<SpeedDialIcon />}
          onClose={() => this.setState({ speedDialOpen: false })}
          onOpen={() => this.setState({ speedDialOpen: true })}
          open={this.state.speedDialOpen}
          direction="up"
          FabProps={{
            style: { backgroundColor: theme.palette.secondary.main }
          }}
        >
          <SpeedDialAction
            key="Create a new project with selected dwellings(s)"
            icon={<AddIcon />}
            tooltipTitle="Create a new project with selected dwellings(s)"
            onClick={() => {
              this.setState({
                speedDialOpen: false,
                addProjectModelOpen: true
              });
            }}
          />
        </SpeedDial>

        <WelcomeDialog
          canViewUploads={this.props.user.can_view_uploads}
          showWelcome={this.props.showWelcome}
          closeWelcome={this.props.setShowWelcome}
        />

        <Menu
          id="kpi-menu"
          keepMounted
          anchorEl={this.state.kpisButtonAnchorEl}
          open={Boolean(this.state.kpisButtonAnchorEl)}
          onClose={() => this.setState({ kpisButtonAnchorEl: null })}
          PaperProps={{
            style: {
              maxWidth: 350,
              width: "100%"
            }
          }}
        >
          <MenuItem
            key="text"
            disabled
            sx={{
              fontWeight: 600,
              fontSize: 12,
              opacity: 1
            }}
          >
            Change KPI
          </MenuItem>

          <div
            key="spacer-1"
            style={{
              background: "#dadce0",
              height: 1,
              width: "100%"
            }}
          />

          <ListItem
            key="kpi-card"
          >
            <div
              style={{
                width: "100%"
              }}
            >
              <div>
                <Typography
                  variant="button"
                  component="span"
                  color="secondary"
                >
                  KPI
                </Typography>
              </div>

              <Grid
                container
                spacing={0}
                sx={{
                  paddingBottom: 0,
                  paddingTop: 0
                }}
              >
                {this.state.views
                  .filter((item) => item.category === "global")
                  .map((item) => (
                    <Grid item xs={6} key={item.name}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.checked}
                            onClick={(event) =>
                              this.handleViewChange(event, item)
                            }
                            name={item.name}
                            // color="primary"
                          />
                        }
                        label={item.label}
                      />
                    </Grid>
                  ))}
              </Grid>
            </div>

          </ListItem>
        </Menu>

        <Menu
          id="sorting-menu"
          keepMounted
          anchorEl={this.state.sortingButtonAnchorEl}
          open={Boolean(this.state.sortingButtonAnchorEl)}
          onClose={() => this.setState({ sortingButtonAnchorEl: null })}
          PaperProps={{
            style: {
              maxWidth: 350
            }
          }}
        >
          <MenuItem
            key="text"
            disabled
            sx={{
              fontWeight: 600,
              fontSize: 12,
              opacity: 1
            }}
          >
            Change Sorting
          </MenuItem>

          <Box
            key="spacer-1"
            sx={{
              background: "#dadce0",
              height: "1px",
              width: "100%"
            }}
          />

          <ListItem
            key="sort-card"
          >
            <div
              style={{
                width: "100%"
              }}
            >
              <div>
                <Typography
                  variant="button"
                  component="span"
                  color="secondary"
                >
                  Sort by
                </Typography>
              </div>

              <Grid
                container
                spacing={0}
                sx={{
                  paddingBottom: 0,
                  paddingTop: 0
                }}
              >
                {this.state.views
                  .filter((item) => item.category === "kpi")
                  .map((item) => (
                    <Grid item xs={6} key={item.name}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.checked}
                            onClick={(event) =>
                              this.handleViewChange(event, item)
                            }
                            name={item.name}
                          />
                        }
                        label={item.label}
                      />
                    </Grid>
                  ))}
              </Grid>
            </div>
          </ListItem>
        </Menu>

        <Menu
          id="filter-menu"
          keepMounted
          anchorEl={this.state.filtersButtonAnchorEl}
          open={Boolean(this.state.filtersButtonAnchorEl)}
          onClose={() => this.setState({ filtersButtonAnchorEl: null })}
          PaperProps={{
            style: {
              maxWidth: 350
            }
          }}
        >
          <MenuItem
            key="text"
            disabled
            sx={{
              fontWeight: 600,
              fontSize: 12,
              opacity: 1
            }}
          >
            Add or remove filters
          </MenuItem>

          <div
            key="spacer-1"
            style={{
              background: "#dadce0",
              height: 1,
              width: "100%"
            }}
          />

          <ListItem
            key="age-card"
          >
            <div
              style={{
                width: "100%"
              }}
            >
              <div>
                <Typography
                  variant="button"
                  component="span"
                  color="secondary"
                >
                  Age
                </Typography>
              </div>

              <Grid
                container
                spacing={0}
                sx={{
                  paddingBottom: 0,
                  paddingTop: 0
                }}
              >
                {this.state.filters
                  .filter((item) => item.category === "age")
                  .map((item) => (
                    <Grid item xs={6} key={item.name}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.checked}
                            onClick={(event) =>
                              this.handleFilterChange(event, item)
                            }
                            name={item.name}
                            // color="primary"
                          />
                        }
                        label={item.label}
                      />
                    </Grid>
                  ))}
              </Grid>
            </div>
          </ListItem>

          <div
            key="spacer-2"
            style={{
              background: "#dadce0",
              height: 1,
              width: "100%"
            }}
          />

          <ListItem
            key="type-card"
          >
            <div
              style={{
                width: "100%"
              }}
            >
              <div>
                <Typography
                  variant="button"
                  component="span"
                  color="secondary"
                >
                  Type
                </Typography>
              </div>

              <Grid
                container
                spacing={0}
                sx={{
                  paddingBottom: 0,
                  paddingTop: 0
                }}
              >
                {this.state.filters
                  .filter((item) => item.category === "type")
                  .map((item) => (
                    <Grid item xs={6} key={item.name}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.checked}
                            onClick={(event) =>
                              this.handleFilterChange(event, item)
                            }
                            name={item.name}
                            // color="primary"
                          />
                        }
                        label={item.label}
                      />
                    </Grid>
                  ))}
              </Grid>
            </div>
          </ListItem>

          <div
            key="spacer-3"
            style={{
              background: "#dadce0",
              height: 1,
              width: "100%"
            }}
          />

          <ListItem
            key="construction-card"
          >
            <div
              style={{ width: "100%" }}
            >
              <div>
                <Typography
                  variant="button"
                  component="span"
                  color="secondary"
                >
                  Construction
                </Typography>
              </div>

              <Grid
                container
                spacing={0}
                sx={{
                  paddingBottom: 0,
                  paddingTop: 0
                }}
              >
                {this.state.filters
                  .filter((item) => item.category === "construction")
                  .map((item) => (
                    <Grid item xs={6} key={item.name}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.checked}
                            onClick={(event) =>
                              this.handleFilterChange(event, item)
                            }
                            name={item.name}
                            // color="primary"
                          />
                        }
                        label={item.label}
                      />
                    </Grid>
                  ))}
              </Grid>
            </div>
          </ListItem>

          <div
            key="spacer-4"
            style={{
              background: "#dadce0",
              height: 1,
              width: "100%"
            }}
          />

          <ListItem
            key="fuel-card"
          >
            <div
              style={{
                width: "100%"
              }}
            >
              <div>
                <Typography
                  variant="button"
                  component="span"
                  color="secondary"
                >
                  Fuel
                </Typography>
              </div>

              <Grid
                container
                spacing={0}
                sx={{
                  paddingBottom: 0,
                  paddingTop: 0
                }}
              >
                {this.state.filters
                  .filter((item) => item.category === "fuel")
                  .map((item) => (
                    <Grid item xs={6} key={item.name}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.checked}
                            onClick={(event) =>
                              this.handleFilterChange(event, item)
                            }
                            name={item.name}
                            // color="primary"
                          />
                        }
                        label={item.label}
                      />
                    </Grid>
                  ))}
              </Grid>
            </div>
          </ListItem>

          <div
            key="spacer-5"
            style={{
              background: "#dadce0",
              height: 1,
              width: "100%"
            }}
          />

          <ListItem
            key="uploads-card"
          >
            <Box
              sx={{
                width: "100%"
              }}
            >
              <Box>
                <Typography
                  variant="button"
                  component="span"
                  color="secondary"
                >
                  BER
                </Typography>
              </Box>

              <Grid
                container
                spacing={0}
                sx={{
                  paddingBottom: 0,
                  paddingTop: 0
                }}
              >
                {
                  this.state.filters
                    .filter((item) => item.category === "ber")
                    .map((item) => (
                      <Grid item xs={3} key={item.name}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={item.checked}
                              onClick={(event) =>
                                this.handleFilterChange(event, item)
                              }
                              name={item.name}
                              // color="primary"
                            />
                          }
                          label={item.label}
                        />
                      </Grid>
                    ))
                }
              </Grid>
            </Box>
          </ListItem>
        </Menu>

        <Menu
          anchorEl={this.state.columnsButtonAnchorEl}
          open={Boolean(this.state.columnsButtonAnchorEl)}
          onClose={() => this.setState({ columnsButtonAnchorEl: null })}
        >
          <MenuItem
            key="text"
            disabled
            sx={{
              fontWeight: 600,
              fontSize: 12,
              opacity: 1
            }}
          >
            Add or remove columns
          </MenuItem>

          {
            this.state.tableColumns.map((column) => {
              if (!column.hidden || column.hiddenByColumnsButton) {
                return (
                  <ListItem
                    key={column.title}
                    style={{ width: "100%", paddingTop: 0, paddingBottom: 0 }}
                  >
                    <MenuItem
                      component="label"
                      htmlFor={column.title}
                      disabled={column.removable === false}
                      style={{ width: "100%" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={column.title}
                            checked={!column.hidden}
                            onClick={() => this.handleTableColumnChange(column)}
                            // color="primary"
                          />
                        }
                        label={column.title}
                      />
                    </MenuItem>
                  </ListItem>
                );
              }
              return null;
            })
          }
        </Menu>

        <CreateProjectDialog
          open={this.state.addProjectModelOpen}
          onClose={() => this.setState({ addProjectModelOpen: false })}
          onCreateProject={this.handleCreateProject}
        />
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  geostock: state.dashboard.geostock,
  geostockTaskId: state.dashboard.geostockTaskId,
  geostockTaskStatus: state.dashboard.geostockTaskStatus,
  electoralDivisions: state.dashboard.electoralDivisions,
  electoralDivisionsTaskId: state.dashboard.electoralDivisionsTaskId,
  electoralDivisionsTaskStatus: state.dashboard.electoralDivisionsTaskStatus,
  projects: state.projects,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  getGeoStock: () => dispatch(getGeoStock()),
  getGeoStockTaskResult: (taskId) => dispatch(getGeoStockTaskResult(taskId)),
  getElectoralDivisions: () => dispatch(getElectoralDivisions()),
  getElectoralDivisionsTaskResult: (taskId) =>
    dispatch(getElectoralDivisionsTaskResult(taskId))
});

Dashboard.propTypes = {
  breakpoint: PropTypes.string.isRequired,

  navDrawerOpen: PropTypes.bool.isRequired,
  setDrawerSelectedIndex: PropTypes.func.isRequired,
  setToolbarTitle: PropTypes.func.isRequired,

  showWelcome: PropTypes.bool.isRequired,
  setShowWelcome: PropTypes.func.isRequired,

  theme: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withMaterialUserInterfaceTheme(
    withMaterialBreakpointQuery()(
      withRouter(
        withUseDashboard(
          Dashboard
        )
      )
    )
  )
);

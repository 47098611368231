import { createTheme } from "@mui/material/styles";


declare module '@mui/material/styles' {
  interface Theme {
    retrokitPalette: {
      red: {
        light: string;
        main: string;
        dark: string;
      };
      pink: {
        light: string;
        main: string;
        dark: string;
      };
      purple: {
        light: string;
        main: string;
        dark: string;
      };
      deepPurple: {
        light: string;
        main: string;
        dark: string;
      };
      indigo: {
        light: string;
        main: string;
        dark: string;
      };
      blue: {
        light: string;
        main: string;
        dark: string;
      };
      lightBlue: {
        light: string;
        main: string;
        dark: string;
      };
      cyan: {
        light: string;
        main: string;
        dark: string;
      };
      teal: {
        light: string;
        main: string;
        dark: string;
      };
      green: {
        light: string;
        main: string;
        dark: string;
      };
      lightGreen: {
        light: string;
        main: string;
        dark: string;
      };
      lime: {
        light: string;
        main: string;
        dark: string;
      };
      yellow: {
        light: string;
        main: string;
        dark: string;
      };
      amber: {
        light: string;
        main: string;
        dark: string;
      };
      orange: {
        light: string;
        main: string;
        dark: string;
      };
      deepOrange: {
        light: string;
        main: string;
        dark: string;
      };
      brown: {
        light: string;
        main: string;
        dark: string;
      };
      grey: {
        light: string;
        main: string;
        dark: string;
      };
      blueGrey: {
        light: string;
        main: string;
        dark: string;
      };
    };
  }
  interface ThemeOptions {
    retrokitPalette: {
      red?: {
        light: string;
        main: string;
        dark: string;
      };
      pink?: {
        light: string;
        main: string;
        dark: string;
      };
      purple?: {
        light: string;
        main: string;
        dark: string;
      };
      deepPurple?: {
        light: string;
        main: string;
        dark: string;
      };
      indigo?: {
        light: string;
        main: string;
        dark: string;
      };
      blue?: {
        light: string;
        main: string;
        dark: string;
      };
      lightBlue?: {
        light: string;
        main: string;
        dark: string;
      };
      cyan?: {
        light: string;
        main: string;
        dark: string;
      };
      teal?: {
        light: string;
        main: string;
        dark: string;
      };
      green?: {
        light: string;
        main: string;
        dark: string;
      };
      lightGreen?: {
        light: string;
        main: string;
        dark: string;
      };
      lime?: {
        light: string;
        main: string;
        dark: string;
      };
      yellow?: {
        light: string;
        main: string;
        dark: string;
      };
      amber?: {
        light: string;
        main: string;
        dark: string;
      };
      orange?: {
        light: string;
        main: string;
        dark: string;
      };
      deepOrange?: {
        light: string;
        main: string;
        dark: string;
      };
      brown?: {
        light: string;
        main: string;
        dark: string;
      };
      grey?: {
        light: string;
        main: string;
        dark: string;
      };
      blueGrey?: {
        light: string;
        main: string;
        dark: string;
      };
    };
  }
}

export const theme = createTheme({
  palette: {
    background: {
      default: '#EEEEEE',
    },
    primary: {
      light: '#727285',
      main: '#353551',
      dark: '#222237',
      // contrastText: ?
    },
    secondary: {
      light: '#ff6090',
      main: '#E91E63',
      dark: '#B0003A',
      // contrastText: ?
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  retrokitPalette: {
    red: {
      light: '#EF9A9A',
      main: '#F44336',
      dark: '#D32F2F'
    },
    pink: {
      light: '#F48FB1',
      main: '#E91E63',
      dark: '#C2185B'
    },
    purple: {
      light: '#CE93D8',
      main: '#9C27B0',
      dark: '#7B1FA2'
    },
    deepPurple: {
      light: '#B39DDB',
      main: '#673AB7',
      dark: '#512DA8'
    },
    indigo: {
      light: '#9FA8DA',
      main: '#3F51B5',
      dark: '#303F9F'
    },
    blue: {
      light: '#90CAF9',
      main: '#2196F3',
      dark: '#1976D2'
    },
    lightBlue: {
      light: '#81D4FA',
      main: '#03A9F4',
      dark: '#0288D1'
    },
    cyan: {
      light: '#80DEEA',
      main: '#00BCD4',
      dark: '#0097A7'
    },
    teal: {
      light: '#80CBC4',
      main: '#009688',
      dark: '#00796B'
    },
    green: {
      light: '#A5D6A7',
      main: '#4CAF50',
      dark: '#388E3C'
    },
    lightGreen: {
      light: '#C5E1A5',
      main: '#8BC34A',
      dark: '#689F38'
    },
    lime: {
      light: '#E6EE9C',
      main: '#CDDC39',
      dark: '#AFB42B'
    },
    yellow: {
      light: '#FFF59D',
      main: '#FFEB3B',
      dark: '#FBC02D'
    },
    amber: {
      light: '#FFE082',
      main: '#FFC107',
      dark: '#FFA000'
    },
    orange: {
      light: '#FFCC80',
      main: '#FF9800',
      dark: '#F57C00'
    },
    deepOrange: {
      light: '#FFAB91',
      main: '#FF5722',
      dark: '#E64A19'
    },
    brown: {
      light: '#BCAAA4',
      main: '#795548',
      dark: '#5D4037'
    },
    grey: {
      light: '#EEEEEE',
      main: '#9E9E9E',
      dark: '#616161'
    },
    blueGrey: {
      light: '#B0BEC5',
      main: '#607D8B',
      dark: '#455A64'
    },
  }
});

import { useEffect } from 'react';

import { useLocation, matchPath } from 'react-router-dom';

import { PARAM_PROJECT_ID, RetroKitRoutes } from 'common/constants/retroKitRoutes';
import { setGlobalBreadCrumbs } from 'common/slice/globalLayoutSlice';
import { useAppDispatch } from 'store/hooks';

import type { BreadCrumb } from 'common/types';

function getBreadcrumbsForRoute(path: string): Array<BreadCrumb> {
  if (path === RetroKitRoutes.DASHBOARD) {
    return [];
  }

  if (path === RetroKitRoutes.UPLOADS) {
    return [{ title: 'Uploads', isActive: false }];
  }

  if (path === RetroKitRoutes.PROJECTS) {
    return [{ title: 'Projects', isActive: false }];
  }

  if (
    matchPath(path, {
      path: RetroKitRoutes.PROJECT_DETAIL(`:${PARAM_PROJECT_ID}`),
      exact: true,
      strict: false,
    }) !== null
  )  {
    return [
      { title: 'Projects', path: RetroKitRoutes.PROJECTS, isActive: true },
      { title: 'Project Detail', isActive: false },
    ];
  }

  return [];
}

/**
 * Custom hook to update breadcrumbs when user visits a page
 */
export function useUpdateAppBreadCrumb() {
  const location = useLocation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setGlobalBreadCrumbs(getBreadcrumbsForRoute(location.pathname)));
  }, [dispatch, location]);
}

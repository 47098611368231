import {
  useGenerateHEAReportMutation,
  useGenerateHUP1ReportMutation,
  useGenerateHUP2ReportMutation,
  useGetListOfHEA1ReportsQuery,
  useGetListOfHEA2ReportsQuery
} from 'features/reports/api/reportsApi';


export function useReportGenerationView() {

  const [generateHEAReport] = useGenerateHEAReportMutation()
  const [generateHUP1Report] = useGenerateHUP1ReportMutation();
  const [generateHUP2Report] = useGenerateHUP2ReportMutation();

  const listOfHea1QueryState = useGetListOfHEA1ReportsQuery();
  const listOfHea2QueryState = useGetListOfHEA2ReportsQuery();

  return {
    generateHEAReport,
    generateHUP1Report,
    generateHUP2Report,
    hea1List: listOfHea1QueryState.data,
    hea2List: listOfHea2QueryState.data
  };
}

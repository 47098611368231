import { retrokitApi } from "common/api/retrokitApi";

import type {
  CreateTAFApiRequest,
  UpdateTAFApiRequest
} from "./types";
import type {
  TechnicalAssessmentForm
} from "../types";

import {
  tafApiTransformer,
  tafApiUpdateTransformer
} from "./transformers";


export const tafApi = retrokitApi.injectEndpoints({
  endpoints: (builder) => ({
    createOrGetTAF: builder.query<
      TechnicalAssessmentForm,
      CreateTAFApiRequest
    >({
      query: (arg) => ({
        url: `integrations/kong/reports`,
        method: "POST",
        body: arg
      }),
      providesTags: [
        "TechnicalAssessmentForm"
      ],
      transformResponse: tafApiTransformer
    }),
    updateTAF: builder.mutation<
      TechnicalAssessmentForm,
      {
        tafUpdate: UpdateTAFApiRequest,
        existingTAFCacheKey: CreateTAFApiRequest
      }
    >({
      query: (arg) => (
        {
          url: `integrations/kong/reports`,
          method: "PUT",
          body: tafApiUpdateTransformer(
            arg.tafUpdate
          )
        }
      ),
      transformResponse: tafApiTransformer,
      async onQueryStarted(
        arg,
        {
          dispatch,
          queryFulfilled
        }
      ) {

        const patchResult = dispatch(
          tafApi.util.updateQueryData(
            "createOrGetTAF",
            arg.existingTAFCacheKey,
            (draft) => {
              Object.assign(draft, arg);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();

          // In case of an error during cache update,
          // invalidate the corresponding cache tags
          // to trigger a re-fetch
          dispatch(
            tafApi.util.invalidateTags(
              ["TechnicalAssessmentForm"]
            )
          );
        }
      }
    })
  })
});

export const {
  useLazyCreateOrGetTAFQuery,
  useUpdateTAFMutation
} = tafApi;
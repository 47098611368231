import {MAP_STYLE_URI_DICTIONARY} from "../../../../common/assets/maps/extras";
import "./MapStyleSwitcherControl.scss"


class MapStyleSwitcherControl {

    constructor(defaultStyle) {
        this.styles = [
            {title: "Dark", uri: MAP_STYLE_URI_DICTIONARY['Dark']},
            {title: "Light", uri: MAP_STYLE_URI_DICTIONARY['Light']},
            // {title: "Outdoors", uri: MAP_STYLE_URI_DICTIONARY['Outdoors']},
            {title: "Satellite", uri: MAP_STYLE_URI_DICTIONARY['Satellite']},
            {title: "Streets", uri: MAP_STYLE_URI_DICTIONARY['Streets']}
        ];
        this.defaultStyle = defaultStyle || "Streets";
        this.onDocumentClick = this.onDocumentClick.bind(this);
    }

    onAdd(map) {
        this.map = map;

        this.controlContainer = window.document.createElement("div");
        this.controlContainer.classList.add("mapboxgl-ctrl");
        this.controlContainer.classList.add("mapboxgl-ctrl-group");

        this.mapStyleContainer = window.document.createElement("div");

        this.styleButton = window.document.createElement("button");
        this.styleButton.type = "button";

        this.mapStyleContainer.classList.add("mapboxgl-style-list");

        for (const style of this.styles) {
            const styleElement = window.document.createElement("button");
            styleElement.type = "button";
            styleElement.innerText = style.title;
            styleElement.classList.add(style.title.replace(/[^a-z0-9-]/gi, '_'));
            styleElement.id = style.title.replace(/[^a-z0-9-]/gi, '_') + "-button";
            styleElement.dataset.uri = JSON.stringify(style.uri);
            if (style.title === this.defaultStyle) {
                styleElement.classList.add("active");
            }
            this.mapStyleContainer.appendChild(styleElement);
        }

        this.styleButton.classList.add("mapboxgl-ctrl-icon");
        this.styleButton.classList.add("mapboxgl-style-switcher");
        this.styleButton.addEventListener("click", () => {
            if (this.styleButton) {
                this.styleButton.style.display = "none";
            }
            if (this.mapStyleContainer) {
                this.mapStyleContainer.style.display = "block";
            }
        });

        window.document.addEventListener("click", this.onDocumentClick);

        this.controlContainer.appendChild(this.styleButton);
        this.controlContainer.appendChild(this.mapStyleContainer);

        return this.controlContainer;
    }

    onRemove() {
        if (!this.controlContainer || !this.controlContainer.parentNode || !this.map || !this.styleButton) {
            return;
        }
        this.styleButton.removeEventListener("click", this.onDocumentClick);
        this.controlContainer.parentNode.removeChild(this.controlContainer);
        window.document.removeEventListener("click", this.onDocumentClick);
        this.map = undefined;
    }

    onDocumentClick(event) {
        if (this.controlContainer && !this.controlContainer.contains(event.target) && this.mapStyleContainer && this.styleButton) {
            this.mapStyleContainer.style.display = "none";
            this.styleButton.style.display = "block";
        }
    }
}

export default MapStyleSwitcherControl;
import type { ChangeEvent, Dispatch, SetStateAction } from "react";
import React, { useCallback, useEffect, useState } from "react";

import { format } from "date-fns";

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  InfoOutlined as InfoOutlinedIcon
} from "@mui/icons-material";
import { colors, TextField, Zoom } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { ConfirmationDialog } from "common/components/ConfirmationDialog";

import type {
  DeleteCustomScenarioApiRequest,
  UpdateCustomScenarioDetailsRequest
} from "features/customScenarios/api/types";
import type { CustomScenario } from "features/customScenarios/types";


interface ScenarioDetailProps {
  customScenario: CustomScenario;
  onDeleteCustomScenario: (
    requestArgs: DeleteCustomScenarioApiRequest
  ) => void;
  onUpdateCustomScenarioDetails: (
    requestArgs: UpdateCustomScenarioDetailsRequest
  ) => void;
  setConfirmationDialogTitle: Dispatch<SetStateAction<string>>;
  setConfirmationDialogMessage: Dispatch<SetStateAction<string>>;
  setConfirmationDialogOpen: Dispatch<SetStateAction<boolean>>;
}

export function CustomScenarioDetails(
  {
    customScenario,
    onDeleteCustomScenario,
    onUpdateCustomScenarioDetails,
    setConfirmationDialogTitle,
    setConfirmationDialogMessage,
    setConfirmationDialogOpen
  }: ScenarioDetailProps) {

  const theme = useTheme();

  const handleInformationClick = useCallback(
    (title: string, message: string, show: boolean) => {
      setConfirmationDialogTitle(title);
      setConfirmationDialogMessage(message);
      setConfirmationDialogOpen(show);
    },
    [
      setConfirmationDialogTitle,
      setConfirmationDialogMessage,
      setConfirmationDialogOpen
    ]
  );

  const handleDeleteCustomScenario = useCallback(
    (scenarioToDelete: CustomScenario) => {
      onDeleteCustomScenario({
        id: scenarioToDelete.id
      });
    },
    [
      onDeleteCustomScenario
    ]
  );

  const handleUpdateCustomScenarioTitle = useCallback(
    (newTitle: string) => {
      onUpdateCustomScenarioDetails({
        id: customScenario.id,
        title: newTitle
      });
    },
    [
      customScenario,
      onUpdateCustomScenarioDetails
    ]
  );

  const handleUpdateCustomScenarioDescription = useCallback(
    (newDescription: string) => {
      onUpdateCustomScenarioDetails({
        id: customScenario.id,
        description: newDescription
      });
    },
    [
      customScenario,
      onUpdateCustomScenarioDetails
    ]
  );

  const [isDefault, setIsDefault] = useState<boolean>(customScenario.is_default);

  useEffect(
    () => {
      setIsDefault(customScenario.is_default);
    },
    [
      customScenario.is_default
    ]
  );

  const handleUpdateCustomScenarioIsDefault = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onUpdateCustomScenarioDetails({
        id: customScenario.id,
        is_default: checked
      });
    },
    [
      customScenario,
      onUpdateCustomScenarioDetails
    ]
  );

  const [
    editingDescription, setEditingDescription
  ] = useState<boolean>(false);

  const [
    editingTitle, setEditingTitle
  ] = useState<boolean>(false);

  const [
    deleteCustomScenarioConfirmationDialogOpen,
    setDeleteCustomScenarioConfirmationDialogOpen
  ] = useState<boolean>(false);

  const handleCloseDeleteCustomScenarioConfirmationDialog = () => {
    setDeleteCustomScenarioConfirmationDialogOpen(false);
  };

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 10
      }}
    >
      <CssBaseline />

      <AppBar
        elevation={1}
        sx={{
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: theme.palette.common.white,
          color: theme.palette.text.primary,
          position: "sticky"
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >

            <Typography
              variant="h6"
              style={{
                fontSize: "18px",
                fontWeight: 400,
                textOverflow: "ellipsis"
              }}
            >
              Scenario
            </Typography>

            <Box
              sx={{
                flex: 1
              }}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
                padding: "8px"
              }}
            >

              <Tooltip
                title="Details"
              >
                <IconButton
                  aria-label="custom-scenario-info"
                  onClick={() => handleInformationClick(
                    "Scenarios",
                    "Scenarios are packages of retrofit measures applied to dwellings. They can consist of default measures provided by RetroKit or custom measures created by users. The impact of scenarios on dwellings is modelled in projects.",
                    true
                  )}
                >
                  <InfoOutlinedIcon
                    sx={{
                      color: theme.palette.primary.main
                    }}
                  />
                </IconButton>
              </Tooltip>

              <Tooltip
                title="Delete this scenario"
              >
                <IconButton
                  aria-label="delete-custom-scenario"
                  onClick={() => {
                    setDeleteCustomScenarioConfirmationDialogOpen(
                      true
                    );
                  }}
                >
                  <DeleteIcon
                    sx={{
                      color: theme.palette.primary.main
                    }}
                  />
                </IconButton>
              </Tooltip>

            </Box>
          </Box>

        </Toolbar>

      </AppBar>

      <Box
        sx={{
          width: "100%",
          paddingTop: "16px",
          paddingBottom: "16px",
          paddingLeft: "32px",
          paddingRight: "32px",
          display: "flex",
          flexDirection: "row",
          gap: "16px"
        }}
      >

        <Paper
          sx={{
            paddingTop: "8px",
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingBottom: "8px",
            borderWidth: "0px",
            borderRadius: "20px",
            flex: 1
          }}
          elevation={0}
        >

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "8px",
              gap: "8px"
            }}
          >

            {
              editingTitle ? <Box
                sx={{
                  flex: 1
                }}
              >
                <TextField
                  autoFocus
                  defaultValue={customScenario.title}
                  fullWidth
                  id="scenario-title"
                  label="TITLE"
                  onBlur={(event) => {
                    handleUpdateCustomScenarioTitle(event.target.value);
                    setEditingTitle(false);
                  }}
                  variant="outlined"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleUpdateCustomScenarioTitle((event.target as HTMLInputElement).value);
                      setEditingTitle(false);
                      event.preventDefault();
                    }
                  }}
                />
              </Box> : <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  borderTopWidth: "0px",
                  borderLeftWidth: "0px",
                  borderRightWidth: "0px",
                  borderBottomWidth: "1px",
                  borderBottomStyle: "solid",
                  borderBottomColor: colors.grey["300"]
                }}
              >

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >

                  <Typography
                    variant="subtitle1"
                    style={{
                      fontSize: "11px",
                      fontWeight: 500,
                      textTransform: "uppercase",
                      color: colors.grey["700"]
                    }}
                  >
                    Title
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    style={{
                      fontSize: "1rem",
                      fontWeight: 400
                    }}
                  >
                    {customScenario.title}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "right"
                  }}
                >
                  <Tooltip
                    title="Edit scenario title"
                    arrow
                    TransitionComponent={Zoom}
                  >
                    <IconButton
                      aria-label="edit scenario title"
                      onClick={() => {
                        setEditingTitle(true);
                      }}
                    >
                      <EditIcon
                        sx={{
                          color: theme.retrokitPalette.green.dark
                        }}
                      />
                    </IconButton>
                  </Tooltip>

                </Box>
              </Box>
            }

            {
              editingDescription ? <Box
                sx={{
                  paddingTop: "16px",
                  flex: 1
                }}
              >
                <TextField
                  autoFocus
                  defaultValue={customScenario.description}
                  fullWidth
                  id="project-description"
                  label="DESCRIPTION"
                  onBlur={(event) => {
                    handleUpdateCustomScenarioDescription(event.target.value);
                    setEditingDescription(false);
                  }}
                  variant="outlined"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleUpdateCustomScenarioDescription((event.target as HTMLInputElement).value);
                      setEditingDescription(false);
                      event.preventDefault();
                    }
                  }}
                />
              </Box> : <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  borderTopWidth: "0px",
                  borderLeftWidth: "0px",
                  borderRightWidth: "0px",
                  borderBottomWidth: "1px",
                  borderBottomStyle: "solid",
                  borderBottomColor: colors.grey["300"]
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >

                  <Typography
                    variant="subtitle1"
                    style={{
                      fontSize: "11px",
                      fontWeight: 500,
                      textTransform: "uppercase",
                      color: colors.grey["700"]
                    }}
                  >
                    Description
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    style={{
                      fontSize: "1rem",
                      fontWeight: 400
                    }}
                  >
                    {customScenario.description}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "right"
                  }}
                >
                  <Tooltip
                    title="Edit scenario description"
                    arrow
                    TransitionComponent={Zoom}
                  >
                    <IconButton
                      aria-label="edit scenario description"
                      onClick={() => setEditingDescription(true)}
                    >
                      <EditIcon
                        sx={{
                          color: theme.retrokitPalette.green.dark
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            }

            <Tooltip
              title="Use this scenario when creating new projects, instead of the default scenarios provided by RetroKit"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isDefault}
                    onChange={handleUpdateCustomScenarioIsDefault}
                  />
                }
                label="Default"
              />
            </Tooltip>


          </Box>

        </Paper>

        <Paper
          sx={{
            paddingTop: "8px",
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingBottom: "8px",
            borderWidth: "0px",
            borderRadius: "20px",
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
          elevation={0}
        >

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              border: 0,
              width: "100%"
            }}
          >

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                alignItems: "center"
              }}
            >
              <Typography
                variant="subtitle1"
                style={{
                  fontSize: "1rem",
                  fontWeight: 500,
                  textTransform: "uppercase",
                  color: colors.grey["700"],
                  textAlign: "right",
                  flex: 1
                }}
              >
                Measures
              </Typography>

              <Typography
                variant="subtitle1"
                style={{
                  fontSize: "0.875rem",
                  fontWeight: 400,
                  textAlign: "center",
                  flex: 1
                }}
              >
                {customScenario.measures.length + customScenario.custom_measures.length}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                alignItems: "center",
                borderTop: `1px solid ${colors.grey["300"]}`
              }}
            >
              <Typography
                variant="subtitle1"
                style={{
                  fontSize: "1rem",
                  fontWeight: 500,
                  textTransform: "uppercase",
                  color: colors.grey["700"],
                  textAlign: "right",
                  flex: 1
                }}
              >
                Created
              </Typography>

              <Typography
                variant="subtitle1"
                style={{
                  fontSize: "0.875rem",
                  fontWeight: 400,
                  textAlign: "center",
                  flex: 1
                }}
              >
                {
                  String(format(
                    customScenario.created_on,
                    "do MMM yyyy"
                  ))
                }
              </Typography>
            </Box>

          </Box>

        </Paper>


      </Box>

      <ConfirmationDialog
        message="This will not remove the scenario from projects where it has been used."
        title="Delete Scenario"
        onClose={handleCloseDeleteCustomScenarioConfirmationDialog}
        onSubmit={() => {
          handleCloseDeleteCustomScenarioConfirmationDialog();
          handleDeleteCustomScenario(customScenario);
        }}
        open={deleteCustomScenarioConfirmationDialogOpen}
      />

    </Box>
  );
}
import { getDateFromUTCSeconds } from "common/utils/date-utils";
import { 
  customMeasuresApiTransformer 
} from "features/customMeasures/api/transformers";
import { 
  defaultMeasuresApiTransformer 
} from "features/defaultMeasures/api/transformers";

import type {
  ProjectScenarioApiResponse
} from "features/projectScenarios/api/types";
import type { ProjectScenario } from "features/projectScenarios/types";


export function projectScenariosApiTransformer(
  response: Array<ProjectScenarioApiResponse>
): Array<ProjectScenario> {

  return response.map(
    (scenariosApiResponse): ProjectScenario => ({
      id: scenariosApiResponse.id,
      title: scenariosApiResponse.title,
      description: scenariosApiResponse.description,
      created_on: getDateFromUTCSeconds(
        parseInt(
          scenariosApiResponse.created_on,
          10
        )
      ),
      measures: defaultMeasuresApiTransformer(
        scenariosApiResponse.measures
      ),
      custom_measures: customMeasuresApiTransformer(
        scenariosApiResponse.custom_measures
      ),
      related_performances: scenariosApiResponse.related_performances,
      recommended_systems: scenariosApiResponse.recommended_systems,
      root_scenario: scenariosApiResponse.root_scenario,
      type: scenariosApiResponse.type,
        oss_eligible: scenariosApiResponse.oss_eligible,
    })
  );
}

export function projectScenarioApiTransformer(
  response: ProjectScenarioApiResponse
): ProjectScenario {

  return {
    id: response.id,
    title: response.title,
    description: response.description,
    created_on: getDateFromUTCSeconds(
      parseInt(
        response.created_on,
        10
      )
    ),
    measures: defaultMeasuresApiTransformer(
      response.measures
    ),
    custom_measures: customMeasuresApiTransformer(
      response.custom_measures
    ),
    related_performances: response.related_performances,
    recommended_systems: response.recommended_systems,
    root_scenario: response.root_scenario,
    type: response.type,
      oss_eligible: response.oss_eligible
  };
}
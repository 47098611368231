import { AUTHENTICATION_ERROR } from './authentication';

export const ELECTORAL_DIVISIONS_TASK_ID_LOADED =
  '@@dash/ELECTORAL_DIVISIONS_TASK_ID_LOADED';
export const ELECTORAL_DIVISIONS_TASK_SUCCESS =
  '@@dash/ELECTORAL_DIVISIONS_TASK_SUCCESS';
export const ELECTORAL_DIVISIONS_TASK_DOWNLOADING_DATA =
  '@@dash/ELECTORAL_DIVISIONS_TASK_DOWNLOADING_DATA';
export const ELECTORAL_DIVISIONS_TASK_EXECUTING =
  '@@dash/ELECTORAL_DIVISIONS_TASK_EXECUTING';
export const ELECTORAL_DIVISIONS_TASK_FAILED =
  '@@dash/ELECTORAL_DIVISIONS_TASK_FAILED';
export const ELECTORAL_DIVISIONS_TASK_QUEUED =
  '@@dash/ELECTORAL_DIVISIONS_TASK_QUEUED';
export const GEOSTOCK_TASK_ID_LOADED = '@@dash/GEOSTOCK_TASK_ID_LOADED';
export const GEOSTOCK_TASK_SUCCESS = '@@dash/GEOSTOCK_TASK_SUCCESS';
export const GEOSTOCK_TASK_DOWNLOADING_DATA =
  '@@dash/GEOSTOCK_TASK_DOWNLOADING_DATA';
export const GEOSTOCK_TASK_EXECUTING = '@@dash/GEOSTOCK_TASK_EXECUTING';
export const GEOSTOCK_TASK_FAILED = '@@dash/GEOSTOCK_TASK_FAILED';
export const GEOSTOCK_TASK_QUEUED = '@@dash/GEOSTOCK_TASK_QUEUED';

export const getGeoStock = () => {
  return (dispatch, getState) => {
    let headers = { 'Content-Type': 'application/json' };
    let { token } = getState().authentication;

    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }

    return fetch(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/v1/dwellings/geojson/`,
      { headers }
    )
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          console.log(
            'Server error when attempting to retrieve task id for gathering user stock!'
          );
          throw res;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return dispatch({
            type: GEOSTOCK_TASK_ID_LOADED,
            geostockTaskId: res.data.task_id,
            geostockTaskStatus: 'JUST_RECEIVED',
          });
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: AUTHENTICATION_ERROR, data: res.data });
          throw res.data;
        }
      });
  };
};

export const getGeoStockTaskResult = (geostockTaskId) => {
  return (dispatch, getState) => {
    let headers = { 'Content-Type': 'application/json' };
    let { token } = getState().authentication;

    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }

    return fetch(
      `${
        process.env.REACT_APP_API_BASE_URL || ''
      }/api/v1/task-progress/${geostockTaskId}/`,
      { headers }
    )
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return {
              status: res.status,
              data,
            };
          });
        } else {
          console.log(
            'Server error when attempting to retrieve user stock geojson!'
          );
          throw res;
        }
      })
      .then(async (res) => {
        if (res.status === 200) {
          if (res.data.task_status === 'SUCCESS') {
            dispatch({
              type: GEOSTOCK_TASK_DOWNLOADING_DATA,
              geostockTaskId: res.data.task_id,
              geostockTaskStatus: 'DOWNLOADING',
            });

            let taskResult = await res.data.task_result;

            return dispatch({
              type: GEOSTOCK_TASK_SUCCESS,
              geostockTaskId: res.data.task_id,
              geostockTaskStatus: res.data.task_status,
              geostockTaskResult: taskResult,
            });
          } else if (res.data.task_status === 'PENDING') {
            return dispatch({
              type: GEOSTOCK_TASK_QUEUED,
              geostockTaskId: res.data.task_id,
              geostockTaskStatus: res.data.task_status,
            });
          } else if (
            res.data.task_status === 'FAILURE' ||
            res.data.task_status === 'REVOKED'
          ) {
            return dispatch({
              type: GEOSTOCK_TASK_FAILED,
              geostockTaskId: res.data.task_id,
              geostockTaskStatus: res.data.task_status,
            });
          }
          // res.data.task_status could also be STARTED, RETRY
          return dispatch({
            type: GEOSTOCK_TASK_EXECUTING,
            geostockTaskId: res.data.task_id,
            geostockTaskStatus: res.data.task_status,
          });
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: AUTHENTICATION_ERROR, data: res.data });
          throw res.data;
        }
      });
  };
};

export const getElectoralDivisions = () => {
  return (dispatch, getState) => {
    let headers = { 'Content-Type': 'application/json' };
    let { token } = getState().authentication;

    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }

    return fetch(
      `${
        process.env.REACT_APP_API_BASE_URL || ''
      }/api/v1/maps/electoral-divisions/average-energy/`,
      {
        headers,
      }
    )
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          console.log(
            'Server error when attempting to retrieve Electoral Divisions!'
          );
          throw res;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return dispatch({
            type: ELECTORAL_DIVISIONS_TASK_ID_LOADED,
            electoralDivisionsTaskId: res.data.task_id,
            electoralDivisionsTaskStatus: 'JUST_RECEIVED',
          });
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: AUTHENTICATION_ERROR, data: res.data });
          throw res.data;
        }
      });
  };
};

export const getElectoralDivisionsTaskResult = (electoralDivisionsTaskId) => {
  return (dispatch, getState) => {
    let headers = { 'Content-Type': 'application/json' };
    let { token } = getState().authentication;

    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }

    return fetch(
      `${
        process.env.REACT_APP_API_BASE_URL || ''
      }/api/v1/task-progress/${electoralDivisionsTaskId}/`,
      {
        headers,
      }
    )
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          console.log(
            'Server error when attempting to retrieve electoral divisions geojson!'
          );
          throw res;
        }
      })
      .then(async (res) => {
        if (res.status === 200) {
          if (res.data.task_status === 'SUCCESS') {
            dispatch({
              type: ELECTORAL_DIVISIONS_TASK_DOWNLOADING_DATA,
              electoralDivisionsTaskId: res.data.task_id,
              electoralDivisionsTaskStatus: 'DOWNLOADING',
            });

            let taskResult = await res.data.task_result;

            return dispatch({
              type: ELECTORAL_DIVISIONS_TASK_SUCCESS,
              electoralDivisionsTaskId: res.data.task_id,
              electoralDivisionsTaskStatus: res.data.task_status,
              electoralDivisionsTaskDownloadingData: 'DONE',
              electoralDivisionsTaskResult: taskResult,
            });
          } else if (res.data.task_status === 'PENDING') {
            return dispatch({
              type: ELECTORAL_DIVISIONS_TASK_QUEUED,
              electoralDivisionsTaskId: res.data.task_id,
              electoralDivisionsTaskStatus: res.data.task_status,
            });
          } else if (
            res.data.task_status === 'FAILURE' ||
            res.data.task_status === 'REVOKED'
          ) {
            return dispatch({
              type: ELECTORAL_DIVISIONS_TASK_FAILED,
              electoralDivisionsTaskId: res.data.task_id,
              electoralDivisionsTaskStatus: res.data.task_status,
            });
          }
          // res.data.task_status could also be STARTED, RETRY
          return dispatch({
            type: ELECTORAL_DIVISIONS_TASK_EXECUTING,
            electoralDivisionsTaskId: res.data.task_id,
            electoralDivisionsTaskStatus: res.data.task_status,
          });
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: AUTHENTICATION_ERROR, data: res.data });
          throw res.data;
        }
      });
  };
};
